import template from './upsell-box.html';
import './upsell-box.scss';

class UpsellBoxController {
	constructor() {}
}

export const UpsellBoxComponent = {
	template,
	controller: UpsellBoxController,
	bindings: {
		size: '@',
	},
	transclude: {
		title: '?upsellTitle',
		leftColumn: 'leftColumn',
		rightColumn: '?rightColumn',
	},
};
