import { VerbatimParametersType } from './type.verbatimParameters';

class VerbatimParametersService {
	constructor(i18n, surveyProviderService, apiHelper, Restangular) {
		'ngInject';

		this.i18n = i18n;
		this.restangular = Restangular;
		this.apiHelper = apiHelper;
		this.surveyProviderService = surveyProviderService;

		this.prepareLists();
	}

	prepareLists() {
		this.metaList = [
			{ id: 'id', title: this.i18n.__('ID wypełnienia', 'reports') },
			{ id: 'email', title: this.i18n.__('E-mail', 'reports') },
			{ id: 'endedAt', title: this.i18n.__('Data wypełnienia', 'reports') },
			{ id: 'externalKey', title: 'external_key' },
			{ id: 'userToken', title: 'user_token' },
			{ id: 'order', title: this.i18n.__('Numer wypełnienia', 'reports') },
		];

		this.defaultLists = {
			1: [], //ready for beatiful future
			2: [], //ready for beatiful future
			3: ['email', 'order', 'endedAt'], //metaList
		};
	}

	getStorageKey(parameterIndex) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				let key = `survey_${surveyId}_${parameterIndex}`;
				resolve(key);
			});
		});
	}

	getStoredParameters(parameterIndex) {
		return new Promise((resolve, reject) => {
			this.getStorageKey(parameterIndex).then((key) => {
				let data = JSON.parse(window.localStorage.getItem(key));
				if (data) {
					resolve(data);
				} else {
					data = this.getDefaultParameters(parameterIndex);

					resolve(data);
				}
			});
		});
	}

	storeParameters(parameterIndex, data) {
		return new Promise((resolve, reject) => {
			this.getStorageKey(parameterIndex).then((key) => {
				window.localStorage.setItem(key, JSON.stringify(data));
				resolve();
			});
		});
	}

	getDefaultParameters(parameterIndex) {
		if (this.defaultLists[parameterIndex]) {
			return this.defaultLists[parameterIndex];
		}
		return [];
	}

	getSegmentsList() {
		// [{id, name}]
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('segments')
					.get()
					.then((data) => {
						data = data && data.plain ? data.plain() : data;
						resolve(data.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	getQuestionsList() {
		// [{id, title, type, number, rowIndex}]
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('questions')
					.get()
					.then((data) => {
						data = data && data.plain ? data.plain() : data;
						resolve(data.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	getMetaList() {
		return new Promise((resolve) => {
			resolve(this.metaList);
		});
	}
	////
	getUpdatedParametersList(index, parameters) {
		return new Promise((resolve) => {
			this.getStoredParameters(index).then((storedParameters) => {
				storedParameters.some((el) => {
					let index = parameters.findIndex((par) => par.id === el);
					if (index !== -1) {
						parameters[index].active = 1;
						return false;
					}

					index = parameters.findIndex((par) => par.rowIndex === el);
					if (index !== -1) {
						parameters[index].active = 1;
						return false;
					}

					index = parameters.findIndex(
						(par) => par.totalRows && `${par.id}_${par.rowIndex}` === el
					);
					if (index !== -1) {
						parameters[index].active = 1;
						return false;
					}

					return false;
				});

				resolve([parameters, storedParameters]);
			});
		});
	}

	getAllParametersPromise() {
		let promises = [];

		promises[0] = new Promise((resolve) => {
			this.getQuestionsList().then((list) => {
				this.getUpdatedParametersList(VerbatimParametersType.questionsList, list).then((data) => {
					resolve(data);
				});
			});
		});
		promises[1] = new Promise((resolve) => {
			this.getSegmentsList().then((list) => {
				this.getUpdatedParametersList(VerbatimParametersType.segmentsList, list).then((data) => {
					resolve(data);
				});
			});
		});
		promises[2] = new Promise((resolve) => {
			this.getMetaList().then((list) => {
				this.getUpdatedParametersList(VerbatimParametersType.metaList, list).then((data) => {
					resolve(data);
				});
			});
		});

		return Promise.all(promises);
	}
}

export default VerbatimParametersService;
