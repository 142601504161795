import { AbstractResource, AbstractResourceConfig } from './AbstractResource';

import tpl from '../ChooserTemplate/MultipleChooser.html';

class QuestionRowsChooserController extends AbstractResource {
	constructor(i18n, analyzeSetStorage, trendsService, $rootScope) {
		'ngInject';

		super(i18n, analyzeSetStorage);

		this.trendsService = trendsService;
		this.$rootScope = $rootScope;

		this.placeholder = i18n.__('Wybierz zakres macierzy', 'reports');
		this.multiSelectLabel = i18n.__('Zakres macierzy', 'reports');
		this.additionalInfo = i18n._s(
			'%sInformacja:%s%sMożna wybrać maksymalnie 5 wierszy macierzy do stworzenia trendu.',
			'<strong>',
			'</strong>',
			'<br/>',
			'reports'
		);

		this.selectedResource = [];
		this.selectedIndexes = [];

		this.maxItems = 5;
	}

	$onInit() {
		super.$onInit();

		this.$rootScope.$on('CreatorQuestionChange', () => {
			this.selectedResource = [];
			this.selectedIndexes = [];

			delete this.set.rowIndexes;

			this.fetchResource();
		});
	}

	$onDestroy() {
		delete this.set.rowIndexes;
	}

	async fetchResource() {
		this.resourceList = $$await(
			await this.trendsService.getQuestionRowsPromise(this.set.question.id)
		);

		if (this.set.rowIndexes) {
			this.resourceList.forEach((rowIndex, i) => {
				if (this.set.rowIndexes.indexOf(rowIndex.index) !== -1) {
					this.selectedResource.push(rowIndex);
					this.selectedIndexes.push(i);
				}
			});
		}

		this.set.rowIndexes = this.selectedResource;
	}

	onItemSelect(selectedItem, index, event) {
		event.stopPropagation();

		const indexInSelected = this.selectedIndexes.indexOf(index);

		if (indexInSelected === -1) {
			if (this.selectedResource.length === this.maxItems) {
				return;
			}

			this.selectedIndexes.push(index);
			this.selectedResource.push(selectedItem);
		} else {
			this.selectedIndexes.splice(indexInSelected, 1);
			this.selectedResource.splice(indexInSelected, 1);
		}
	}
}

QuestionRowsChooserController.$inject = [
	'i18n',
	'analyzeSetStorage',
	'trendsService',
	'$rootScope',
];

export const QuestionRowsChooser = Object.assign(
	{
		template: tpl,
		controller: QuestionRowsChooserController,
	},
	AbstractResourceConfig
);
