import tpl from './SharedSurveysPermissions.html';

class SharedSurveysPermissionsController {
	constructor($rootScope, i18n) {
		'ngInject';

		this.$rootScope = $rootScope;
		this.i18n = i18n;
	}
}

export const SharedSurveysPermissions = {
	template: tpl,
	controller: SharedSurveysPermissionsController,
	transclude: true,
};
