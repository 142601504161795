const tpl = require('./QuestionSlider.html');

class QuestionSliderController {
	constructor(modalService, i18n) {
		'ngInject';

		this.modalService = modalService;
		this.i18n = i18n;
		this.ready = false;
	}

	$onInit() {
		this.ready = true;
		this.questionCtrl.setChild(this);
	}
	// $postLink() {
	// }
	isReady() {
		return this.ready;
	}

	showDistribution(slider) {
		this.modalService.openDistributionChartModal(
			this.questionCtrl.questionIndex,
			slider.index - 1,
			this.questionData
		);
	}
}

export const questionSlider = {
	template: tpl,
	controller: QuestionSliderController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
