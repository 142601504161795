import angular from 'angular';

import { AuthService } from './auth/auth.service';

import { routingConfig } from './config/routing.config';
import { restangularApiConfig } from './config/restangular-api.config';
import { uibTooltipConfig } from './config/uibTooltip.config';
import { authUser } from './init/auth-user.init';
import { tokenInterceptor } from './init/token.interceptor';
import { unauthorizedInterceptor } from './init/unauthorized.interceptor';
import { googleAnalyticsInit } from './init/google-analytics.init';
import { restangularCounterInit } from './init/restangular-counter.init';
import { featureGuard } from './init/feature-guard.init';
import { AppController } from './controller/app.controller';
import { SegmentService } from './tracking-scripts/segment.service';
import { LangService } from './lang/lang.service';

export const CommonModule = angular
	.module('common', ['ui.router', 'restangular', 'ui.bootstrap'])
	.service('authService', AuthService)
	.service('segmentService', SegmentService)
	.service('langService', LangService)
	.controller('appController', AppController)
	.config(routingConfig)
	.config(uibTooltipConfig)
	.run(restangularApiConfig)
	.run(tokenInterceptor)
	.run(unauthorizedInterceptor)
	.run(googleAnalyticsInit)
	.run(restangularCounterInit)
	.run(authUser)
	.run(featureGuard).name;
