import tpl from './ChooseFilterComponent.html';

require('../../../scss/filterComponent.scss');

class ChooseFilterComponentController {
	constructor(
		$scope,
		$rootScope,
		i18n,
		supportCRMService,
		filtersService,
		surveyProviderService,
		alertsService,
		userService,
		aclManager
	) {
		'ngInject';

		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.i18n = i18n;
		this.supportCRMService = supportCRMService;
		this.filtersService = filtersService;
		this.surveyProviderService = surveyProviderService;
		this.alertsService = alertsService;
		this.userService = userService;
		this.aclManager = aclManager;

		this.displayedFiltersList = [];
		this.filtersList = [];
		this.filterModalShown = false;
		this.selectedFilter = null;
		this.isLoading = true;

		this.fullAccess = false;

		this.disabled = false;
	}

	$onInit() {
		this.bindSurveyChange();
		this.bindDisablingFiltering();

		if (this.isQuickFilter) {
			this.bindQuickFilterEvent();
		}

		this.fetchSurveyAccessLevel().then((response) => {
			this.fullAccess = response;
			this.filtersInit();
		});
	}

	fetchSurveyAccessLevel() {
		return new Promise((resolve) => {
			this.surveyProviderService.getAccessLevel().then((data) => {
				const isFullAccess = data === this.surveyProviderService.accessLevels.ALL_INCLUSIVE;
				resolve(isFullAccess);
			});
		});
	}

	filtersInit() {
		this.fetchFilters().then((filterId) => {
			if (filterId) {
				this.handleChangeFilter({ id: filterId }).then(
					() => {
						this.emitFiltersFetched({ id: filterId });
						this.isLoading = false;
					},
					() => {
						this.emitFiltersFetched();
						this.isLoading = false;
					}
				);
				return;
			}

			this.emitFiltersFetched();
			this.isLoading = false;
		});
	}

	bindSurveyChange() {
		this.$scope.$on('SurveyChanged', () => {
			this.isLoading = true;
			this.selectedFilter = null;
			this.filtersList = [];
			this.displayedFiltersList = [];

			this.filtersInit();
		});
	}

	bindDisablingFiltering() {
		this.$scope.$on('DisableFiltering', (event, data) => {
			this.deactivateFilter().then(() => {
				this.emitFilterChanged(null, false);

				if (data && data.callback) {
					data.callback();
				}
			});
			this.disabled = true;
		});

		this.$scope.$on('EnableFiltering', (event, data) => {
			this.disabled = false;

			if (data && data.callback) {
				data.callback();
			}
		});
	}

	bindQuickFilterEvent() {
		this.$rootScope.$on('HandleQuickFilter', (event, data) => {
			if (data.edited) {
				this.selectedFilter = null;
				this.$scope.$apply();

				const key = this.displayedFiltersList.findIndex((filter) => {
					return Number(filter.id) === Number(data.filter.id);
				});

				if (data.removed) {
					this.displayedFiltersList.splice(key, 1);

					if (this.displayedFiltersList.length === 0) {
						this.setFiltersListPlaceholder();
					}

					this.filterSelectChange();
					return;
				}

				this.displayedFiltersList[key] = data.filter;
				this.filterSelectChange(this.displayedFiltersList[key]);

				return;
			}

			this.addFilterToList(data.filter);
		});
	}

	emitFiltersFetched(filter = null) {
		this.$rootScope.$broadcast('filtersFetched', {
			filterId: filter ? filter.id : null,
			fullAccess: this.fullAccess,
		});
	}

	emitFilterChanged(filter, refresh = true) {
		this.$rootScope.$broadcast('filterChanged', {
			filterId: filter ? filter.id : null,
			refresh,
		});
	}

	filterSelectChange(filter) {
		if (this.disabled) {
			return;
		}

		this.handleChangeFilter(filter).then(
			() => {
				this.emitFilterChanged(filter);
			},
			() => {}
		);
	}

	fetchFilters() {
		return new Promise((resolve) => {
			this.filtersService
				.fetchFiltersBasicList()
				.then((response) => {
					this.displayedFiltersList = response.filter((element) => !element.isDefaultApplied);
					this.filtersList = response;

					if (this.displayedFiltersList.length === 0) {
						this.setFiltersListPlaceholder();
						resolve();
					}

					if (this.$rootScope.surveyAccess.filter) {
						this.$rootScope.surveyHasTimePeriodFilter = this.checkTimePeriodFilter();
					}

					const filterId = this.userService.getUserSurveyInfo().filterId;

					if (this.isFilterOnList(filterId)) {
						resolve(filterId);
					} else {
						resolve();
					}
				})
				.catch((error) => {
					if (parseInt(error.status, 10) !== -1) {
						this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem', 'reports'));
					}
				});
		});
	}

	setFiltersListPlaceholder(onChange) {
		this.displayedFiltersList.push({
			id: null,
			title: this.i18n.__('Brak filtrów', 'reports'),
			disabled: true,
		});

		if (!onChange) {
			this.$scope.$broadcast('SurveyReady');
		}

		this.$scope.$apply();
	}

	handleChangeFilter(filter) {
		this.$rootScope.$broadcast('loadingOn');

		return new Promise((resolve, reject) => {
			if (!filter) {
				this.deactivateFilter().then(() => {
					this.$rootScope.$broadcast('loadingOff');
					resolve();
				});
			} else {
				this.tryToFilterResults(filter).then(
					() => {
						this.surveyProviderService.interruptQuestions();
						this.selectedFilter = this.displayedFiltersList.find(
							(filterItem) => Number(filterItem.id) === Number(filter.id)
						);
						this.activateFilterGlobal(filter.id).then(() => {
							this.$rootScope.$broadcast('loadingOff');
							resolve();
						});
					},
					() => {
						this.alertsService.error(this.i18n.__('Błąd podczas filtrowania wyników', 'reports'));
						this.deactivateFilter().then(() => {
							this.$rootScope.$broadcast('loadingOff');
							reject();
						});
					}
				);
			}
		});
	}

	tryToFilterResults(filter) {
		return new Promise((resolve, reject) => {
			this.filtersService.getFilterResultCountByFilterId(filter.id).then(
				(data) => {
					resolve();
				},
				(error) => {
					reject();
				}
			);
		});
	}

	addFilterToList(data) {
		this.removePlaceholderNoFilters();

		this.displayedFiltersList.unshift(data);
		this.filterSelectChange(this.displayedFiltersList[0]);
	}

	removePlaceholderNoFilters() {
		const hasPlaceholder = this.displayedFiltersList[0].disabled === true;

		if (hasPlaceholder) {
			this.displayedFiltersList = [];
		}
	}

	deactivateFilter() {
		return new Promise((resolve, reject) => {
			this.filtersService.deactivateFilter().then(() => {
				this.$rootScope.surveyHasTimePeriodFilter = this.checkTimePeriodFilter();
				this.selectedFilter = null;
				resolve();
			});
		});
	}

	activateFilterGlobal(filterId) {
		return new Promise((resolve) => {
			const isGlobalChange =
				Number(this.userService.getUserSurveyInfo().filterId) !== Number(filterId);

			this.filtersService.activateFilter(filterId).then(
				() => {
					const filter = this.displayedFiltersList.find((filter) => filter.id == filterId);
					this.$rootScope.surveyHasTimePeriodFilter = this.checkTimePeriodFilter(filter);

					this.supportCRMService.emitFilterApplied();
					this.userService.setUserSurveyInfo(null, this.selectedFilter.id);

					if (isGlobalChange) {
						this.alertsService.success(this.i18n.__('Zastosowano filtr', 'reports'));
					}

					resolve();
				},
				() => {
					this.alertsService.error(
						this.i18n.__('Błąd połączenia z serwerem. Nie udało się ustawić filtru', 'reports')
					);
				}
			);
		});
	}

	checkTimePeriodFilter(filter) {
		let timePeriodFilterExists = false;

		if (this.$rootScope.surveyAccess.filter) {
			const defaultAppliedFilters = this.filtersList.filter((filter) => filter.isDefaultApplied);
			timePeriodFilterExists = !!defaultAppliedFilters.find((filter) => filter.hasTimeRules);
		}

		if (filter && !timePeriodFilterExists) {
			timePeriodFilterExists = filter.hasTimeRules;
		}

		return timePeriodFilterExists;
	}

	newFilterClick() {
		if (!this.filterModalShown) {
			this.filterModalShown = true;

			this.filtersService.openCreator({
				id: null,
				isEditable: true,
				onCreateCallback: (data) => {
					this.addFilterToList(data);
				},
				onCloseCallback: () => {
					this.filterModalShown = false;
				},
				inResults: true,
			});
		}
	}

	isFilterOnList(id) {
		return this.displayedFiltersList.find((filter) => filter.id == id);
	}

	canCreateFilter() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}

	editFilter(event, filter) {
		event.stopPropagation();
		const ctrl = this;

		this.filtersService.openCreator({
			id: filter.id,
			inResults: true,
			isEditable: filter.isEditable,
			onCloseCallback: (revert, currentData) => {
				if (!revert) {
					ctrl.filtersService.replaceFilterData(currentData);

					const index = ctrl.filtersList.findIndex((filter) => {
						return Number(filter.id) === Number(currentData.id);
					});

					if (index !== -1) {
						ctrl.filtersList[index] = currentData;
						ctrl.filterSelectChange(currentData);
					}
				}
			},
		});
	}
}

export const chooseFilterComponent = {
	template: tpl,
	controller: ChooseFilterComponentController,
	bindings: {
		isQuickFilter: '<',
		disabled: '<',
	},
};
