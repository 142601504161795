import '@babel/polyfill';
import angular from 'angular';

import { stripTags } from './app/filters/StripTags';
import { findIndexPolyfill } from './app/utils/ArrayFindIndexPolyfill';
import { Observer } from './app/utils/Observer';
import { ComponentsModule } from './app/components/components.module';
import { ReactModule } from './app/_react/react.module';
import { ChartColors } from './app/common/charts/chart-colors';
import { CommonModule } from './app/app-common/app-common.module';
import { trustAsHtml } from './app/filters/TrustAsHtml';
import { attachPolyfills } from './app/utils/ie-polyfills';
import { i18nService } from './app/utils/i18n.service';

attachPolyfills();

require('angular-ui-tinymce/src/tinymce');
require('angular-ui-bootstrap');
require('angular-ui-router');
require('angular-cookies');
require('angular-animate');
require('angular-sanitize');
require('restangular');
require('angular-drag-and-drop-lists');
require('angular-bootstrap-colorpicker');

require('../node_modules/angular-timeago/dist/angular-timeago.min.js');
require('./app/utils/timeago.js');

require('angular-bootstrap-colorpicker/css/colorpicker.css');
require('ui-select/dist/select.css');
require('./index.scss');

window._ = require('lodash');

let { mainWrapper } = require('./app/common/MainWrapper');
let { mainMenu } = require('./app/common/MainMenu');
let { subMenu } = require('./app/common/SubMenu');
let { subMenuItem } = require('./app/common/SubMenuItem');
let { subMenuLink } = require('./app/common/SubMenuLink');
let { topBar } = require('./app/common/TopBar');
let { dropdownMenu } = require('./app/common/header/DropdownMenu');

let { surveyWrapper } = require('./app/common/SurveyWrapper');
let { surveyPageWrapper } = require('./app/common/SurveyPageWrapper');
let { SharedSurveysPermissions } = require('./app/common/SharedSurveysPermissions');

const { aggregatedEmptyScreen } = require('./app/aggregatedResults/AggregatedEmptyScreen');

let { aggregatedResultsWrapper } = require('./app/aggregatedResults/AggregatedResultsWrapper');
let { aRQuestion } = require('./app/aggregatedResults/ARQuestion');
let { aRQuestionSingle } = require('./app/aggregatedResults/ARQuestionSingle');
let { aRQuestionMultiple } = require('./app/aggregatedResults/ARQuestionMultiple');
let { questionNPS } = require('./app/aggregatedResults/QuestionNPS');
let { questionText } = require('./app/aggregatedResults/QuestionText');
let { questionMatrixSingle } = require('./app/aggregatedResults/QuestionMatrixSingle');
let { questionMatrixOpen } = require('./app/aggregatedResults/QuestionMatrixOpen');
let { questionMatrixMulti } = require('./app/aggregatedResults/QuestionMatrixMulti');
let { questionDate } = require('./app/aggregatedResults/QuestionDate');
let { questionForm } = require('./app/aggregatedResults/QuestionForm');
let { questionEmail } = require('./app/aggregatedResults/QuestionEmail');
let { questionNumber } = require('./app/aggregatedResults/QuestionNumber');
let { questionRanking } = require('./app/aggregatedResults/QuestionRanking');
let { questionSlider } = require('./app/aggregatedResults/QuestionSlider');
let { questionPoints } = require('./app/aggregatedResults/QuestionPoints');
let { questionDropdown } = require('./app/aggregatedResults/QuestionDropdown');
let { questionDragAndDrop } = require('./app/aggregatedResults/QuestionDragAndDrop');
let { questionAttachment } = require('./app/aggregatedResults/QuestionAttachment');
let { questionStaticText } = require('./app/aggregatedResults/QuestionStaticText');
let { questionRating } = require('./app/aggregatedResults/QuestionRating');

let { verbatimWrapper } = require('./app/verbatim/VerbatimWrapper');

let { getEmailModal } = require('./app/common/GetEmailModal');

let { chartSingleType } = require('./app/charts/ChartSingleType');
let { chartMultiType } = require('./app/charts/ChartMultiType');
let { timelineChart } = require('./app/charts/TimelineChart');
let { matrixDistributionChart } = require('./app/charts/MatrixDistributionChart');

let { colorFilter } = require('./app/filters/ColorFilter');
let { numeralFilter } = require('./app/filters/NumeralFilter');
let { emptyRowsFilter } = require('./app/filters/EmptyRowsFilter');
let { limitString } = require('./app/filters/LimitString');

let { subMenuReports } = require('./app/reports/SubMenuReports');
let { fileExporter } = require('./app/common/FileExporter');
let { PaginationManagerController } = require('./app/common/PaginationManager');
let { DynamicLoader } = require('./app/utils/DynamicLoader');
let { RefreshDynamicLoader } = require('./app/utils/RefreshDynamicLoader');

let ReportsService = require('./app/services/ReportsService').default;
let SurveyProviderService = require('./app/services/SurveyProviderService').default;

import ProjectsService from './app/services/ProjectsService';
import ProjectMembersService from './app/services/ProjectMembersService';

let ChartColorHelper = require('./app/services/ChartColorHelper').default;
let QuestionHelper = require('./app/services/QuestionHelper').default;
let ApiHelper = require('./app/services/ApiHelper').default;
let UserService = require('./app/services/UserService').default;
let SupportCRMService = require('./app/services/SupportCRMService').default;
let FiltersHelper = require('./app/analyzeFilters/FiltersHelper').default;
let QuickFilterGenerator = require('./app/quickFilter/QuickFilterGenerator').default;
let ExportService = require('./app/exportData/exportService').default;
let textOverflow = require('./app/common/TextOverflowDirective').default;
let scrollBottom = require('./app/filters/ScrollBottomDirective').default;

export const app = 'app';

angular
	.module(app, [
		CommonModule,
		'ui.tinymce',
		'app.reports',
		'app.analyze',
		'app.settings',
		'app.alerts',
		'yaru22.angular-timeago',
		'colorpicker.module',
		'ngCookies',
		'ngAnimate',
		ComponentsModule,
		ReactModule,
	])
	.constant('config', process.env)
	.constant('CHART_COLORS', ChartColors)
	.value('Observer', Observer)
	.value('EventEmitter', (payload) => ({ $event: payload }))
	.service('surveyProviderService', SurveyProviderService)
	.service('projectsService', ProjectsService)
	.service('projectMembersService', ProjectMembersService)
	.service('reportsService', ReportsService)
	.service('chartColorHelper', ChartColorHelper)
	.service('questionHelper', QuestionHelper)
	.service('filtersHelper', FiltersHelper)
	.service('quickFilterGenerator', QuickFilterGenerator)
	.service('apiHelper', ApiHelper)
	.service('userService', UserService)
	.service('supportCRMService', SupportCRMService)
	.service('exportService', ExportService)
	.service('i18n', i18nService)
	.component('mainWrapper', mainWrapper)
	.component('aggregatedEmptyScreen', aggregatedEmptyScreen)
	.component('aggregatedResultsWrapper', aggregatedResultsWrapper)
	.component('surveyWrapper', surveyWrapper)
	.component('surveyPageWrapper', surveyPageWrapper)
	.component('aRQuestion', aRQuestion)
	.component('aRQuestionSingle', aRQuestionSingle)
	.component('aRQuestionMultiple', aRQuestionMultiple)
	.component('questionNPS', questionNPS)
	.component('questionText', questionText)
	.component('questionMatrixSingle', questionMatrixSingle)
	.component('questionMatrixMulti', questionMatrixMulti)
	.component('questionMatrixOpen', questionMatrixOpen)
	.component('questionForm', questionForm)
	.component('questionDate', questionDate)
	.component('questionEmail', questionEmail)
	.component('questionNumber', questionNumber)
	.component('questionRanking', questionRanking)
	.component('questionSlider', questionSlider)
	.component('questionPoints', questionPoints)
	.component('questionDropdown', questionDropdown)
	.component('questionDragAndDrop', questionDragAndDrop)
	.component('questionAttachment', questionAttachment)
	.component('questionStaticText', questionStaticText)
	.component('questionRating', questionRating)
	.component('verbatimWrapper', verbatimWrapper)
	.component('chartSingleType', chartSingleType)
	.component('chartMultiType', chartMultiType)
	.component('timelineChart', timelineChart)
	.component('matrixDistributionChart', matrixDistributionChart)
	.component('subMenu', subMenu)
	.component('subMenuItem', subMenuItem)
	.component('subMenuLink', subMenuLink)
	.component('subMenuReports', subMenuReports)
	.component('topBar', topBar)
	.component('dropdownMenu', dropdownMenu)
	.component('mainMenu', mainMenu)
	.component('fileExporter', fileExporter)
	.component('getEmailModal', getEmailModal)
	.component('sharedSurveysPermissions', SharedSurveysPermissions)
	.filter('colorFilter', colorFilter)
	.filter('numeralFilter', numeralFilter)
	.filter('stripTags', stripTags)
	.filter('limitString', limitString)
	.filter('emptyRows', emptyRowsFilter)
	.filter('trustAsHtml', trustAsHtml)
	.directive('textOverflow', textOverflow)
	.directive('scrollBottom', scrollBottom)
	.factory('TokenRestangular', function (Restangular) {
		'ngInject';
		return Restangular.withConfig(function (RestangularConfigurer) {
			RestangularConfigurer.setBaseUrl('/user/token');
		});
	})
	.factory('UserRestangular', function (Restangular) {
		'ngInject';
		return Restangular.withConfig(function (RestangularConfigurer) {
			RestangularConfigurer.setBaseUrl('/user');
		});
	})
	.factory('AgreementRestangular', function (Restangular) {
		'ngInject';
		return Restangular.withConfig(function (RestangularConfigurer) {
			RestangularConfigurer.setBaseUrl('/agreement');
		});
	})
	.factory('PaginationManager', function () {
		return PaginationManagerController;
	})
	.factory('DynamicLoader', function () {
		return DynamicLoader;
	})
	.factory('RefreshDynamicLoader', function () {
		return RefreshDynamicLoader;
	})
	.run(function (
		$rootScope,
		Restangular,
		$state,
		$window,
		$transitions,
		aclManager,
		i18n,
		segmentService
	) {
		'ngInject';

		function trackPageEvent(pageName, pageTitle) {
			const excluded = [
				'analyze.aggregatedResults',
				'analyze.crossTables',
				'analyze.rankings',
				'analyze.trends',
				'reports.list',
				'analyze.verbatim',
				'analyze.sentiments',
			];

			if (!excluded.includes(pageName)) {
				segmentService.track(`${pageTitle} - wejście`);
			}
		}

		$rootScope.$watch(
			() => $state.current,
			(newValue) => {
				if (newValue.name && newValue.data) {
					$rootScope.currentState = newValue.name.replace(/\./g, '_');
					$rootScope.currentTitle = i18n.__(newValue.data.title, 'reports');

					trackPageEvent(newValue.name, newValue.data.title);
				}
			},
			true
		);

		findIndexPolyfill();
	});
