const tpl = require('./QuestionMatrix.html');
let { AbstractQuestionMatrixController } = require('./AbstractQuestionMatrixController');

class QuestionMatrixSingleController extends AbstractQuestionMatrixController {
	constructor(
		$scope,
		chartColorHelper,
		modalService,
		$filter,
		questionHelper,
		i18n,
		quickFilterGenerator,
		filtersHelper
	) {
		'ngInject';

		super(
			chartColorHelper,
			modalService,
			$filter,
			questionHelper,
			i18n,
			quickFilterGenerator,
			filtersHelper
		);

		this.$scope = $scope;

		this.matrixType = this.types.matrixSingle;
	}

	$onInit() {
		super.$onInit();

		if (this.reportBuilder) {
			this.reportBuilder.reportFullyLoaded();
		}
	}
}

export const questionMatrixSingle = {
	template: tpl,
	controller: QuestionMatrixSingleController,
	require: {
		questionCtrl: '^aRQuestion',
		reportBuilder: '?^reportBuilder',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
