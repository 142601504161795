class SentimentAPIService {
	constructor(Restangular, surveyProviderService, apiHelper) {
		this.restangular = Restangular;
		this.surveyProviderService = surveyProviderService;
		this.apiHelper = apiHelper;
	}

	async getTags(from, to) {
		const query = { from, to };

		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			const tags = await this.restangular
				.one(String(surveyId))
				.one('sentiment')
				.one('tags')
				.get(query);

			return tags && tags.plain ? tags.plain().items : tags.items;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async getDistribution(from, to, tags) {
		const query = { from, to, tags: tags.join(',') };

		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			const distribution = await this.restangular
				.one(String(surveyId))
				.one('sentiment')
				.one('distribution')
				.get(query);

			return distribution && distribution.plain ? distribution.plain().items : distribution.items;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async getTrend(from, to, tags) {
		const query = { from, to, tags: tags.join(',') };

		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			const distribution = await this.restangular
				.one(String(surveyId))
				.one('sentiment')
				.one('trend')
				.get(query);

			return distribution && distribution.plain ? distribution.plain() : distribution;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}
}

SentimentAPIService.$inject = ['Restangular', 'surveyProviderService', 'apiHelper'];

export { SentimentAPIService };
