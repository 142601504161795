import angular from 'angular';

import { SentimentsWrapper } from './SentimentsWrapper/SentimentsWrapper';
import { SentimentDistribution } from './SentimentDistribution/SentimentDistribution';
import { SentimentTrend } from './SentimentTrend/SentimentTrend';
import { SentimentTrendChart } from './SentimentTrend/SentimentTrendChart/SentimentTrendChart';
import { SentimentDistributionStorage } from './Storage/SentimentDistributionStorage';
import { SentimentTrendStorage } from './Storage/SentimentTrendStorage';
import { TagsStorage } from './Storage/TagsStorage';
import { SentimentAPIService } from './Service/SentimentAPIService';
import { SentimentCreator } from './SentimentCreator/SentimentCreator';
import { SentimentService } from './Service/SentimentService';
import { SentimentRanking } from './SentimentRanking/SentimentRanking';
import { SentimentRankingChart } from './SentimentRanking/SentimentRankingChart/SentimentRankingChart';
import { SentimentsEmptyScreenComponent } from './SentimentsEmptyScreen/SentimentsEmptyScreen.component';

require('./sentiments.scss');

export const SentimentsModule = angular
	.module('sentiments', [])
	.service('sentimentDistributionStorage', SentimentDistributionStorage)
	.service('sentimentTrendStorage', SentimentTrendStorage)
	.service('tagsStorage', TagsStorage)
	.service('sentimentAPIService', SentimentAPIService)
	.service('sentimentService', SentimentService)
	.component('sentimentsWrapper', SentimentsWrapper)
	.component('sentimentDistribution', SentimentDistribution)
	.component('sentimentCreator', SentimentCreator)
	.component('sentimentTrend', SentimentTrend)
	.component('sentimentTrendChart', SentimentTrendChart)
	.component('sentimentRanking', SentimentRanking)
	.component('sentimentRankingChart', SentimentRankingChart)
	.component('sentimentsEmptyScreen', SentimentsEmptyScreenComponent).name;
