import tpl from './VerbatimWrapper.html';

import { VerbatimParametersEvents } from './verbatimParameters/events.verbatimParameters';
import { TagEvents } from '../Settings/Tags/events.tags';

const angular = require('angular');

require('../../scss/verbatim.scss');

const EVENT_TYPES = {
	FILTER_CHANGED: 'FILTER_CHANGED',
	VERBATIM_EMPTY_SCREEN_VISIBILITY: 'VERBATIM_EMPTY_SCREEN_VISIBILITY',
};

class VerbatimWrapperController {
	constructor(
		$scope,
		$timeout,
		$rootScope,
		i18n,
		exportService,
		verbatimService,
		verbatimTagsService,
		verbatimLogTagsService,
		verbatimKeyboard,
		$document,
		sentimentService,
		sortingManager,
		segmentService,
		postMessageService,
		aclManager
	) {
		'ngInject';

		this.i18n = i18n;
		this.$timeout = $timeout;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$document = $document;

		this.dataExportOptions = exportService.exportOptions();
		this.dataExportClick = exportService.dataExportClick();

		this.verbatimTagsService = verbatimTagsService;
		this.verbatimService = verbatimService;
		this.verbatimLogTagsService = verbatimLogTagsService;
		this.verbatimKeyboard = verbatimKeyboard;

		this.sentimentService = sentimentService;
		this.sortingManager = sortingManager;
		this.postMessageService = postMessageService;

		this.infoHidden = 0;
		this.taggedLogsCounter = 0;

		this.aclManager = aclManager;
		this.segmentService = segmentService;
		this.isAnalyticsEventSent = false;
	}

	$onInit() {
		this.sentimentService.clearModel(false, true);

		this.bindSortingChange();
		this.prepareDefaultVariables();
		this.bindFirstLoad();
		this.bindActions();
		this.currentLogObservers();
		this.chosenParametersObserver();

		this.initInfoVisibility();

		this.$scope.$on('SurveyChanged', () => {
			this.verbatimKeyboard.currentLog = null;
			this.paginationInitalized = false;
			this.verbatimKeyboard.removeKeyUp(this.$document);
		});
	}

	$onDestroy() {
		this.sortingManager.removeObserver(this.sortingObserver);
		this.verbatimKeyboard.removeKeyUp(this.$document);
	}

	prepareDefaultVariables() {
		this.chosenParameters = [];
		this.parameters = [];

		this.isLoading = true;
		this.selectedFilter = null;
		this.paginationInitalized = false;

		this.currentLog = null;
		this.pagination = {
			currentPage: 1,
		};

		this.readyForLoadingLogs = 0;
		this.logsListLoaded = false;
		this.noVerbatims = true;
		this.areVerbatimsEnabled = false;

		this.logsList = [];
		this.tagsList = [];
		this.colors = [];

		this.emptyScreenStorageKeyName = 'verbatim_empty_screen_visited';
	}

	bindFirstLoad() {
		let handler = (event, data) => {
			if (event.name === VerbatimParametersEvents.loaded) {
				//waiting for parameters list
				this.parameters = data.parameters;
				this.chosenParameters = data.chosenParameters;
				this.readyForLoadingLogs++;
			}

			if (this.readyForLoadingLogs === 1) {
				//if everything is loaded
				this.loadLogs();
			}
		};

		let observer2 = this.$rootScope.$on(VerbatimParametersEvents.loaded, handler);

		this.$scope.$on('$destroy', observer2);
	}

	chosenParametersObserver() {
		let observer = this.$rootScope.$on(VerbatimParametersEvents.changed, (event, data) => {
			//new parameters
			this.chosenParameters = data.chosenParameters;
			this.loadLogs();
		});
		this.$scope.$on('$destroy', observer);
	}

	bindActions() {
		this.postMessageService.on(EVENT_TYPES.FILTER_CHANGED, (message) => {
			this.selectedFilter = message.data.filterId;
			this.paginationInitalized = false;
			this.currentLogEmitter(0);
			this.loadLogs();
		});

		this.$scope.$on('SurveyChanged', () => {
			this.prepareDefaultVariables();
		});
	}

	loadLogs(page = this.pagination.currentPage, keyUpdate = false) {
		this.isLoading = true;
		this.logsList = [];

		this.verbatimService
			.getLogs(page, this.chosenParameters, this.sorting.active.type, this.selectedFilter)
			.then((list) => {
				if (list.logs.length > 0) {
					this.logsList = list.logs;
				} else {
					this.logsList = [];
				}

				if (!this.paginationInitalized) {
					this.initPagination(list.paginationPageCount, list.paginationTotalCount);
					this.verbatimKeyboard.setPagination(this.pagination);
					this.verbatimKeyboard.addKeyUpEvent(this.$document);
					this.loadTagsAndColors();
				} else {
					this.$timeout(() => {
						this.$rootScope.$emit(TagEvents.colorsLoaded, { colors: this.colors });
						this.$rootScope.$emit(TagEvents.loaded, { tags: this.tagsList });
					});
				}

				if (keyUpdate) {
					//if it's change of page caused by key shortcut, we send again id of active log (run focus for input)
					this.$timeout(() => this.currentLogEmitter());
				}

				this.$timeout(() => {
					//send again to VerbatimElementInfo
					this.$rootScope.$emit(VerbatimParametersEvents.loaded, {
						chosenParameters: this.chosenParameters,
						parameters: this.parameters,
					});
				});

				this.$scope.$apply();
			});
	}

	//pagination things
	initPagination(logsPageCount, logsTotalCount) {
		this.$scope.paginationID = 'VerbatimPagination';
		this.paginationInitalized = true;

		this.pagination = {
			perPage: logsPageCount,
			total: logsTotalCount,
		};
	}

	updatePage = (newPageNumber, keyUpdate = false) => {
		this.pagination.currentPage = newPageNumber;
		this.loadLogs(newPageNumber, keyUpdate);
	};

	//current log things - observers and emitters
	currentLogObservers() {
		let observer1 = this.$rootScope.$on(TagEvents.activeSheetChanged, ($event, data) => {
			this.currentLog = data.currentLog;
			this.taggedLogsCounter++;

			if (this.taggedLogsCounter > 1) {
				this.setInfoVisibility(1);
			}
		});
		this.$scope.$on('$destroy', observer1);

		let observer2 = this.$rootScope.$on(TagEvents.activePageChanged, ($event, data) => {
			this.updatePage(data.page, true);
		});
		this.$scope.$on('$destroy', observer2);

		let observer3 = this.$rootScope.$on(TagEvents.loaded, () => {
			this.noTags = this.tagsList.length === 0;
			this.noVerbatims = !this.logsList || !this.logsList.length;

			this.areVerbatimsEnabled = !this.noVerbatims;

			this.isLoading = false;

			if (!this.isAnalyticsEventSent) {
				this.sendAnalyticsEvent();
				this.isAnalyticsEventSent = true;
			}
		});
		this.$scope.$on('$destroy', observer3);
	}

	currentLogEmitter(log = false) {
		if (angular.isNumber(log)) {
			this.currentLog = log;
		}

		this.$rootScope.$emit(TagEvents.activeSheetChanged, {
			currentLog: this.currentLog,
		});
	}

	//tags and colors loading and emitting
	loadTagsAndColors() {
		let loadTags = () => {
			this.verbatimTagsService.getTagsListPromise().then((list) => {
				this.tagsList = list.slice().map((tag) => {
					tag.tagId = tag.id;
					tag.id = null; //place for relationId

					return tag;
				});

				this.$rootScope.$emit(TagEvents.loaded, { tags: this.tagsList });
			});
		};

		let loadColors = () => {
			this.verbatimLogTagsService.getColors().then((list) => {
				this.colors = list.slice();
				this.$rootScope.$emit(TagEvents.colorsLoaded, { colors: this.colors });
			});
		};

		this.$timeout(() => {
			loadTags();
			loadColors();
		});
	}

	bindSortingChange() {
		this.sortingObserver = this.sortingManager.addObserver((sortingState) => {
			this.sorting = sortingState;
			this.loadLogs();
		});
	}

	onOrderSelect(event) {
		this.sortingManager.changeSorting(event.item);
	}

	setInfoVisibility(value = 1) {
		this.infoHidden = value;

		this.verbatimService.infoVisibility(value);
	}

	initInfoVisibility() {
		this.verbatimService.infoVisibility().then((value) => {
			this.infoHidden = value;
		});
	}

	sendAnalyticsEvent() {
		const getModuleState = () => {
			const isFeaturePurchased = this.aclManager.isFeatureEnabled(
				this.$rootScope.features.moduleVerbatims
			);

			if (!isFeaturePurchased) {
				return 'unavailable';
			} else if (isFeaturePurchased && this.noVerbatims) {
				return 'disabled';
			} else if (isFeaturePurchased && !this.wasEmptyScreenButtonClicked()) {
				return 'enabled';
			} else {
				return 'activated';
			}
		};

		const moduleState = getModuleState();

		this.segmentService.track('Tagowanie verbatimów - wejście', {
			state: moduleState,
		});
	}

	wasEmptyScreenButtonClicked() {
		const surveyId = this.$rootScope.currentSurveyData && this.$rootScope.currentSurveyData.id;
		const keyNameWithSurveyId = `${this.emptyScreenStorageKeyName}_${surveyId}`;

		return Boolean(Number(localStorage.getItem(keyNameWithSurveyId)));
	}

	shouldDisplayEmptyScreen() {
		if (this.selectedFilter && this.noVerbatims) {
			return false;
		}

		const isFeaturePurchased = this.aclManager.isFeatureEnabled(
			this.$rootScope.features.moduleVerbatims
		);

		const result =
			(!isFeaturePurchased || this.noVerbatims || !this.wasEmptyScreenButtonClicked()) &&
			!this.hideEmptyScreen;

		this.postMessageService.emit(EVENT_TYPES.VERBATIM_EMPTY_SCREEN_VISIBILITY, result);

		return result;
	}

	emptyScreenOnButtonClick = () => {
		const keyNameWithSurveyId = `${this.emptyScreenStorageKeyName}_${this.$rootScope.currentSurveyData.id}`;
		this.hideEmptyScreen = true;
		localStorage.setItem(keyNameWithSurveyId, 1);

		this.postMessageService.emit(EVENT_TYPES.VERBATIM_EMPTY_SCREEN_VISIBILITY, false);
	};

	getEmptyScreenTooltipText() {
		if (this.noVerbatims) {
			return this.i18n.__(
				'Aby otagować odpowiedzi, musi być zebrany minimum 1 wynik ankiety',
				'user'
			);
		}

		if (this.noTags) {
			return this.i18n.__(
				'Przed rozpoczęciem tagowania musisz najpierw zdefiniować tagi. Możesz to zrobić w Ustawieniach konta',
				'user'
			);
		}
	}

	getDisabledDescriptionText() {
		if (this.noVerbatims) {
			return this.i18n.__('Nie masz jeszcze zebranych odpowiedzi.', 'user');
		}

		if (this.noTags) {
			return this.i18n.__('Nie masz zdefiniowanych tagów.', 'user');
		}

		return null;
	}

	closeNewRawExportModal() {
		const modal = document.querySelector('[data-target="single-result-modal"]');
		modal.classList.add('hidden');
	}
}

export const verbatimWrapper = {
	template: tpl,
	controller: VerbatimWrapperController,
	bindings: {
		sorting: '<',
	},
};
