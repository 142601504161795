import angular from 'angular';

class AnalyzeSetStorage {
	constructor(
		$rootScope,
		i18n,
		crossTablesApiService,
		verbatimTagsService,
		rankingsService,
		trendsService
	) {
		'ngInject';

		this.crossTablesApiService = crossTablesApiService;
		this.verbatimTagsService = verbatimTagsService;
		this.rankingsService = rankingsService;
		this.trendsService = trendsService;

		this.questionsList = null;
		this.rankingQuestionsList = null;
		this.trendQuestionsList = null;
		this.segmentsList = null;
		this.tagsList = null;
		this.metricsList = null;
		this.periodsList = [
			{ type: 2, title: i18n.__('tygodniowo', 'reports'), number: 1 },
			{ type: 3, title: i18n.__('miesięcznie', 'reports'), number: 2 },
			{ type: 4, title: i18n.__('kwartalnie', 'reports'), number: 3 },
		];

		this.trendRangesList = [
			{ type: 1, title: i18n.__('cały okres badania', 'reports') },
			{ type: 2, title: i18n.__('wybrany okres badania', 'reports') },
		];

		this.allPeriods = {
			2: null,
			3: null,
			4: null,
		};

		$rootScope.$on('SurveyChanged', () => {
			this.questionsList = null;
			this.rankingQuestionsList = null;
			this.trendQuestionsList = null;
			this.segmentsList = null;
			this.tagsList = null;
			this.metricsList = null;
			this.allPeriods = {
				2: null,
				3: null,
				4: null,
			};
		});
	}

	async fetchQuestions() {
		if (this.questionsList) {
			return this.questionsList;
		}

		this.questionsList = await this.crossTablesApiService.getQuestions();

		return this.questionsList;
	}

	async fetchQuestionsForRanking() {
		if (this.rankingQuestionsList) {
			return this.rankingQuestionsList;
		}

		this.rankingQuestionsList = await this.rankingsService.getQuestionsForRankingPromise();

		return this.rankingQuestionsList;
	}

	async fetchQuestionsForTrend() {
		if (this.trendQuestionsList) {
			return this.trendQuestionsList;
		}

		this.trendQuestionsList = await this.trendsService.getQuestionsForTrendPromise();

		return this.trendQuestionsList;
	}

	async fetchSegments() {
		if (this.segmentsList) {
			return this.segmentsList;
		}

		this.segmentsList = await this.crossTablesApiService.getSegments();

		return this.segmentsList;
	}

	async fetchTags() {
		if (this.tagsList) {
			return this.tagsList;
		}

		this.tagsList = await this.verbatimTagsService.getTagsListPromise();

		return this.tagsList;
	}

	async fetchMetrics() {
		if (this.metricsList) {
			return this.metricsList;
		}

		this.metricsList = await this.rankingsService.getMetricsForRankingPromise();

		return this.metricsList;
	}

	async fetchAllPeriods(type) {
		if (this.allPeriods[type]) {
			return angular.copy(this.allPeriods[type]);
		}

		this.allPeriods[type] = await this.trendsService.getPeriodsByTypePromise(type);

		return angular.copy(this.allPeriods[type]);
	}

	fetchTrendRanges() {
		return this.trendRangesList;
	}

	fetchPeriods() {
		return this.periodsList;
	}
}

AnalyzeSetStorage.$inject = [
	'$rootScope',
	'i18n',
	'crossTablesApiService',
	'verbatimTagsService',
	'rankingsService',
	'trendsService',
];

export { AnalyzeSetStorage };
