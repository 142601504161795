import Template from './FiltersListEmptyScreen.html';

class FiltersListEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Filtry pozwalają zawężać dane zebrane w ankietach. Dzięki tej funkcji możesz zobaczyć jak odpowiadały na pozostałe pytania osoby, które zaznaczyły konkretną odpowiedź w ankiecie.',
					'user'
				),
			],
			2: [
				this.i18n.__(
					'Dzięki filtrom możesz również zobaczyć odpowiedzi z konkretnego przedziału czasowego lub filtrować wyniki na podstawie danych, które posiadasz o respondencie (np. odpowiedzi pracowników działu sprzedaży, odpowiedzi klientów VIP).',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}
}

export const FiltersListEmptyScreenComponent = {
	template: Template,
	controller: ['i18n', FiltersListEmptyScreenController],
	bindings: {
		onButtonClick: '<',
	},
};
