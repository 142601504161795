import { AbstractResource, AbstractResourceConfig } from './AbstractResource';

import tpl from '../ChooserTemplate/SingleChooser.html';

class MetricChooserController extends AbstractResource {
	constructor(i18n, analyzeSetStorage) {
		'ngInject';

		super(i18n, analyzeSetStorage);

		this.placeholder = i18n.__('Ocena z pytania', 'reports');
	}

	$onDestroy() {
		delete this.set.metric;
	}

	async fetchResource() {
		this.resourceList = $$await(await this.analyzeSetStorage.fetchMetrics());

		if (this.set.metric) {
			this.selectedResource = this.set.metric;
		}
	}

	onSelectResource() {
		this.set.metric = this.selectedResource;
	}
}

MetricChooserController.$inject = ['i18n', 'analyzeSetStorage'];

export const MetricChooser = Object.assign(
	{
		template: tpl,
		controller: MetricChooserController,
	},
	AbstractResourceConfig
);
