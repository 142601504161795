function promisePolyfill() {
	// polyfill dla IE i Phantoma
	if (!window.Promise) {
		window.Promise = require('es6-promise');
	}
}

function isIntegerPolyfill() {
	if (!Number.isInteger) {
		Number.isInteger = function (val) {
			return typeof val === 'number' && isFinite(val) && Math.floor(val) === val;
		};
	}
}

function arrayFindPolyfill() {
	if (!Array.prototype.find) {
		let _apro = Array.prototype;
		_apro.find = function (predicate) {
			if (this === null) {
				throw new TypeError('Array.prototype.find called on null or undefined');
			}
			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function');
			}
			var list = Object(this);
			var length = list.length >>> 0;
			var thisArg = arguments[1];
			var value;

			for (var i = 0; i < length; i++) {
				value = list[i];
				if (predicate.call(thisArg, value, i, list)) {
					return value;
				}
			}
			return undefined;
		};
	}
}

function attachPolyfills() {
	promisePolyfill();
	isIntegerPolyfill();
	arrayFindPolyfill();
}

export { attachPolyfills };
