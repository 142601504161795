const Entities = require('html-entities').AllHtmlEntities;

class CrossTablesService {
	constructor(i18n, modalService, questionHelper) {
		'ngInject';

		this.i18n = i18n;
		this.$uibModal = modalService.getUibModal();
		this.questionHelper = questionHelper;
		this.entities = new Entities();
	}

	handleTranslation(crossTableResult) {
		if (this.isNpsSet(crossTableResult.sets[0].questionType)) {
			crossTableResult.rows.map((row) => {
				row.title = this.i18n.__(row.title, 'reports');
				return row;
			});
		}

		if (this.isNpsSet(crossTableResult.sets[1].questionType)) {
			crossTableResult.columns.map((column) => {
				column.title = this.i18n.__(column.title, 'reports');
				return column;
			});
		}

		return crossTableResult;
	}

	isNpsSet(questionType) {
		return Number(questionType) === Number(this.questionHelper.getTypes().nps);
	}

	openCreator(opts) {
		let onCreateCallback = opts && opts.onCreateCallback ? opts.onCreateCallback : null;
		let onCloseCallback = opts && opts.onCloseCallback ? opts.onCloseCallback : null;
		let crossTableId = opts && opts.crossTableId ? opts.crossTableId : null;

		this.creatorModal = this.$uibModal.open({
			component: 'crossTableCreator',
			size: '700',
			resolve: {
				onCreateCallback: () => onCreateCallback,
				onCloseCallback: () => onCloseCallback,
				crossTableId: () => crossTableId,
			},
			keyboard: false,
		});

		return this.creatorModal;
	}

	closeCreator() {
		this.creatorModal.close();
	}

	openAddCrossTableToReportModal(opts) {
		this.addCrossTableToReportModal = this.$uibModal.open({
			component: 'addCrossTableToReportModal',
			size: '400',
			resolve: {
				crossTableData: () => opts.crossTableData,
				additionalSettings: () => opts.additionalSettings,
				filterId: () => opts.filterId,
				successCallback: () => opts.successCallback,
			},
		});
		this.addCrossTableToReportModal.result.then(
			() => {},
			() => {}
		);
	}

	parsePreparedData(crossTableData) {
		const sets = crossTableData.sets;

		if (!crossTableData.title) {
			crossTableData.title = this.prepareCrossTableTitle(sets);
		}

		let data = {
			title: crossTableData.title,
			sets: [],
		};

		sets.forEach((set) => {
			let setObject = {
				type: set.type,
			};

			if (Object.prototype.hasOwnProperty.call(set, 'question')) {
				setObject.question = set.question.id;
				setObject.rowIndexes = set.question.rowIndex ? [set.question.rowIndex] : [];
			} else if (Object.prototype.hasOwnProperty.call(set, 'segment')) {
				setObject.rowIndexes = [set.segment.rowIndex];
			} else if (Object.prototype.hasOwnProperty.call(set, 'tags')) {
				setObject.rowIndexes = set.tags.map((tag) => {
					return Number(tag.id);
				});
			}

			data.sets.push(setObject);
		});
		return data;
	}

	prepareCrossTableTitle(crossTableSets) {
		const sets = crossTableSets;
		let rowTitle = '';
		let columnTitle = this.entities.decode(sets[1].question.title.replace(/(\r\n|\n|\r)/gm, ''));

		if (Object.prototype.hasOwnProperty.call(sets[0], 'question')) {
			rowTitle = this.entities.decode(sets[0].question.title.replace(/(\r\n|\n|\r)/gm, ''));
		} else if (Object.prototype.hasOwnProperty.call(sets[0], 'segment')) {
			rowTitle = this.entities.decode(sets[0].segment.title.replace(/(\r\n|\n|\r)/gm, ''));
		} else {
			rowTitle = this.i18n.__('Tagi', 'reports');
		}

		return this.i18n._s('[Wiersz] %s [Kolumna] %s', rowTitle, columnTitle, 'reports');
	}
}

CrossTablesService.$inject = ['i18n', 'modalService', 'questionHelper'];

export { CrossTablesService };
