import tpl from './AlertsLayer.html';

import './../../../scss/alerts.scss';

import { AlertEvent } from '../events.alerts';

class AlertsLayerController {
	constructor(alertsService, $rootScope, $scope, $timeout) {
		'ngInject';

		this.$rootScope = $rootScope;
		this.$scope = $scope;
		this.$timeout = $timeout;

		this.alerts = alertsService.alerts.slice();
	}

	$onInit() {
		this.bindAlertsEvents();
	}

	bindAlertsEvents() {
		this.$rootScope.$on(AlertEvent.updated, (event, data) => {
			this.$timeout(() => {
				this.alerts = data.alerts;
			});
		});
	}
}

export const AlertsLayer = {
	template: tpl,
	controller: AlertsLayerController,
};
