const tpl = require('./FilterCreatorDNDQuestion.html');

let FilterCreatorRangeQuestionController = require('./FilterCreatorRangeQuestion')
	.filterCreatorRangeQuestion.controller;

class FilterCreatorDNDQuestionController extends FilterCreatorRangeQuestionController {
	constructor($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper) {
		'ngInject';

		super($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper);
		this.isDND = true;

		this.data = {
			answerLabel: this.i18n.__('odpowiedź', 'reports'),
			answerData: null,
			rangeLabel: this.i18n.__('należy do grupy', 'reports'),
			rangeData: null,
		};
	}

	$onChanges(changes) {
		super.$onChanges(changes);

		if (this.question) {
			this.data.answerData = this.question.rows || [];
			this.data.rangeData = this.question.columns || [];
		}
		if (this.ruleData.answers.length) {
			this.selectedAnswers = this.ruleData.answers.slice(0).map((a) => Number(a));
		}
	}

	$onInit() {
		super.$onInit();
		this.selectedCondition = 1;
		this.ruleData.condition = 1;
	}

	answerSelectChange(item, selected) {
		this.creator.setEdited(true);

		if (item.index !== undefined) {
			this.ruleData.answers = this.selectedAnswers.slice(0);

			this.validate();
			this.creator.validate();
		}
	}

	optionClick(index, item, multiSelectController, ev, isLast) {
		this.creator.setEdited(true);
		if (
			this.selectedAnswers &&
			this.selectedAnswers.length &&
			this.selectedAnswers.indexOf(item.index) !== -1
		) {
			this.selectedAnswers.splice(this.selectedAnswers.indexOf(item.index), 1);
			this.ruleData.answers = this.selectedAnswers.slice(0);

			ev.stopPropagation();
		}

		if (!this.selectedAnswers.length || this.ruleData.condition === null) {
			this.valid = false;
			this.creator.validate();
		}
	}
}

export const filterCreatorDNDQuestion = {
	template: tpl,
	controller: FilterCreatorDNDQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
