import tpl from './ARQuestionSingle.html';
import jquery from 'jquery';
// import highcharts from 'highcharts';

class ARQuestionSingleController {
	constructor($element, $scope, $filter, i18n) {
		'ngInject';

		this.$filter = $filter;
		this.i18n = i18n;

		this.dom = jquery($element);

		this.colorFilter = $filter('colorFilter');
		this.ready = false;
	}

	$onInit() {
		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	isReady() {
		return this.ready;
	}

	setChartReference(chartCtrl) {
		this.chartCtrl = chartCtrl;
		this.chart = chartCtrl.chart;
		// console.log('setChartReference', chart);

		if (Number(this.reportType) === this.reportHelper.getReportPartTypes().CHART && this.chart) {
			if (this.chart.options.chart.type === 'pie') {
				// console.log('pie');
				this.chart.update({
					chart: {
						width: 500,
					},
					plotOptions: {
						pie: {
							showInLegend: true,
						},
					},
					legend: {
						enabled: true,
						align: 'left',
						layout: 'vertical',
						floating: true,
					},
				});
			} else if (this.chart.options.chart.type === 'column') {
				this.chart.update({
					chart: {
						width: 500,
						height: 300,
						marginTop: 30,
					},
					legend: {
						enabled: true,
					},
					xAxis: {
						labels: {
							enabled: false,
						},
						crosshair: false,
						visible: true,
					},
					yAxis: {
						labels: {
							enabled: true,
						},
						title: {
							enabled: false,
						},
						visible: true,
					},
				});
			}
		}
	}

	getQuickFilterData(answer) {
		return {
			questionData: this.questionData,
			answerTitle: answer.title,
			answers: answer.index,
		};
	}
}

export const aRQuestionSingle = {
	template: tpl,
	controller: ARQuestionSingleController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		chartColor: '<',
		questionData: '<',
	},
	transclude: {
		chart: '?questionChart',
	},
};
