import tpl from './single-select.html';
import angular from 'angular';

require('./single-select.scss');

class SingleSelectController {
	constructor(EventEmitter, $scope, $compile, $transclude, $element) {
		'ngInject';

		this.EventEmitter = EventEmitter;

		this.$scope = $scope;
		this.$compile = $compile;
		this.transclude = $transclude;
		this.$element = $element;

		this.selected = null;
	}

	$onInit() {
		var templateEl = angular.element(tpl);

		this.transclude(this.$scope, (clonedContent) => {
			const tags = ['SELECT-MATCH', 'SELECT-ITEM'];

			Object.values(clonedContent).forEach((element) => {
				if (element.tagName && tags.indexOf(element.tagName) !== -1) {
					templateEl.find(element.tagName).replaceWith(element);
				}
			});

			this.$compile(templateEl)(this.$scope, (clonedTemplate) => {
				this.$element.append(clonedTemplate);
			});
		});
	}

	onSelectChange() {
		if (this.onSelect) {
			this.onSelect(
				this.EventEmitter({
					selected: this.selected,
				})
			);
		}
	}
}

export const SingleSelectComponent = {
	controller: SingleSelectController,
	bindings: {
		items: '<',
		selected: '<',
		large: '<',
		disabled: '<',
		onSelect: '&',
		placeholder: '@',
		noItemsPlaceholder: '@',
	},
	transclude: true,
};
