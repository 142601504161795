const Entities = require('html-entities').AllHtmlEntities;
class TrendsService {
	constructor(
		i18n,
		modalService,
		surveyProviderService,
		Restangular,
		apiHelper,
		questionHelper,
		reportHelper
	) {
		'ngInject';

		this.i18n = i18n;
		this.modalService = modalService;
		this.$uibModal = modalService.getUibModal();
		this.surveyProviderService = surveyProviderService;
		this.restangular = Restangular;
		this.apiHelper = apiHelper;
		this.questionHelper = questionHelper;
		this.reportHelper = reportHelper;
		this.entities = new Entities();
	}

	// modal

	openCreator(opts) {
		let onCreateCallback = opts && opts.onCreateCallback ? opts.onCreateCallback : null;
		let onCloseCallback = opts && opts.onCloseCallback ? opts.onCloseCallback : null;
		let trendId = opts && opts.trendId ? opts.trendId : null;

		this.creatorModal = this.$uibModal.open({
			component: 'trendCreator',
			size: '700',
			resolve: {
				onCreateCallback: () => onCreateCallback,
				onCloseCallback: () => onCloseCallback,
				trendId: () => trendId,
			},
			keyboard: false,
		});

		return this.creatorModal;
	}

	closeCreator() {
		this.creatorModal.close();
	}

	// 1 survey-id/trend  GET, POST

	getTrendsListPromise() {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend')
					.get()
					.then((trendsList) => {
						trendsList = trendsList && trendsList ? trendsList.plain() : trendsList;
						resolve(trendsList.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	createTrendPromise(data) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.all('trend')
					.post(data)
					.then((trendData) => {
						trendData = trendData && trendData.plain ? trendData.plain() : trendData;
						resolve(trendData);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	// 2 survey-id/trend/trend-id  GET, PUT, DELETE

	getTrendDataPromise(trendId) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend', trendId)
					.get()
					.then((trendData) => {
						trendData = trendData && trendData.plain ? trendData.plain() : trendData;
						resolve(trendData);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	updateTrendDataPromise(trendId, data) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend', trendId)
					.customPUT(data)
					.then((trendData) => {
						trendData = trendData && trendData.plain ? trendData.plain() : trendData;
						resolve(trendData);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	removeTrendPromise(trendId) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend', trendId)
					.remove()
					.then(
						() => {
							resolve();
						},
						(errData) => {
							if (errData && errData.status === 409) {
								reject();
							} else {
								this.apiHelper.handleDataError(errData);
							}
						}
					);
			}, this.apiHelper.handleDataError);
		});
	}

	// 3 /survey-id/trend/questions GET

	getQuestionsForTrendPromise() {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend')
					.one('questions')
					.get()
					.then((questionsList) => {
						questionsList =
							questionsList && questionsList.plain ? questionsList.plain() : questionsList;
						resolve(questionsList.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	// 4 /trend/trend-id/calculate

	getTrendResultPromise(trendId, filterId = null) {
		let queryData = filterId ? { filter: filterId } : {};

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend', trendId)
					.one('calculate')
					.get(queryData)
					.then((trendResult) => {
						trendResult = trendResult && trendResult.plain ? trendResult.plain() : trendResult;
						resolve(trendResult);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	// 5 /survey-id/trend/question-id/rows

	getQuestionRowsPromise(questionId) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend', questionId)
					.one('rows')
					.get()
					.then((questionRows) => {
						questionRows = questionRows && questionRows.plain ? questionRows.plain() : questionRows;
						resolve(questionRows.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	getPeriodsByTypePromise(type) {
		const queryData = {
			period_id: type,
		};

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('trend')
					.one('periods')
					.get(queryData)
					.then((periods) => {
						periods = periods && periods.plain ? periods.plain() : periods;
						resolve(periods.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	parsePreparedData(trendData) {
		const sets = trendData.sets;

		if (!trendData.title) {
			trendData.title = this.prepareTrendTitle(sets);
		}

		let data = {
			title: trendData.title,
			sets: [],
		};

		sets.forEach((set) => {
			let setObject = {
				type: set.type,
				rowIndexes: [],
			};

			if (Object.prototype.hasOwnProperty.call(set, 'question')) {
				setObject.question = set.question.id;
				setObject.rowIndexes = set.rowIndexes
					? set.rowIndexes.map((rowIndex) => {
							return Number(rowIndex.index);
					  })
					: [];
			} else if (Object.prototype.hasOwnProperty.call(set, 'period')) {
				setObject.period = set.period.type;
				setObject.trendRange = set.trendRange.type;

				if (set.trendRange.type === 2) {
					setObject.periods = [];

					Object.keys(set.periods).forEach((year) => {
						const yearObject = { year, indexes: [] };

						set.periods[year].forEach((period) => {
							yearObject.indexes.push(period.rowIndex);
						});

						if (yearObject.indexes.length > 0) {
							setObject.periods.push(yearObject);
						}
					});
				}
			} else if (Object.prototype.hasOwnProperty.call(set, 'tags')) {
				setObject.rowIndexes = set.tags.map((tag) => {
					return Number(tag.id);
				});
			}

			data.sets.push(setObject);
		});

		return data;
	}

	prepareTrendTitle(trendSets) {
		const sets = trendSets;
		const rowTitle = this.entities.decode(sets[0].period.title.replace(/(\r\n|\n|\r)/gm, ''));
		let columnTitle = '';

		if (Object.prototype.hasOwnProperty.call(sets[1], 'question')) {
			columnTitle = this.entities.decode(sets[1].question.title.replace(/(\r\n|\n|\r)/gm, ''));
		} else if (Object.prototype.hasOwnProperty.call(sets[1], 'tags')) {
			columnTitle = this.i18n.__('Tagi', 'reports');
		}

		return this.i18n._s('[Wiersz] %s [Kolumna] %s', rowTitle, columnTitle, 'reports');
	}

	convertNpsTypeToIndex(type) {
		switch (type) {
			case 12:
				return 0;
			case 13:
				return 1;
			case 14:
				return 2;
			default:
				return 0;
		}
	}

	mapTypeToColumn(type) {
		switch (type) {
			case this.reportHelper.additionalSettingsTypes.npsLtr:
				return 'ltr';
			case this.reportHelper.additionalSettingsTypes.npsScore:
				return 'nps';
			case this.reportHelper.additionalSettingsTypes.ratingTopBox:
				return 'topBox';
			case this.reportHelper.additionalSettingsTypes.ratingTopTwoBoxes:
				return 'topTwoBoxes';
			case this.reportHelper.additionalSettingsTypes.ratingBottomBox:
				return 'bottomBox';
			case this.reportHelper.additionalSettingsTypes.ratingBottomTwoBoxes:
				return 'bottomTwoBoxes';
			case this.reportHelper.additionalSettingsTypes.total:
				return 'totalRows';
			case this.reportHelper.additionalSettingsTypes.ratingAvg:
				return 'average';
			case this.reportHelper.additionalSettingsTypes.trendCritics:
				return 'critics';
			case this.reportHelper.additionalSettingsTypes.trendNeutrals:
				return 'neutrals';
			case this.reportHelper.additionalSettingsTypes.trendPromotors:
				return 'promotors';
			case this.reportHelper.additionalSettingsTypes.trendDate:
				return 'rows';
			default:
				return 'ltr';
		}
	}

	// reports

	openAddTrendToReportModal(opts) {
		this.addTrendToReportModal = this.$uibModal.open({
			component: 'addTrendToReportModal',
			size: '400',
			resolve: {
				trendData: () => opts.trendData,
				additionalSettings: () => opts.additionalSettings,
				filterId: () => opts.filterId,
				successCallback: () => opts.successCallback,
			},
		});
		this.addTrendToReportModal.result.then(
			() => {},
			() => {}
		);
	}
}

export default TrendsService;
