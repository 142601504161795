import tpl from './TagItem.html';
import angular from 'angular';

import { TagEvents } from '../events.tags';

class TagItemController {
	constructor(i18n, modalService, verbatimTagsService, $scope, alertsService) {
		'ngInject';

		this.i18n = i18n;
		this.modalService = modalService;
		this.$uibModal = modalService.getUibModal();
		this.verbatimTagsService = verbatimTagsService;
		this.alertsService = alertsService;
		this.$scope = $scope;
	}

	editTag() {
		const tagEditor = this.$uibModal.open({
			component: 'tagEditor',
			size: '500',
			resolve: {
				tag: () => angular.copy(this.tag),
			},
		});

		tagEditor.result.then(
			(tag) => {
				this.$scope.$emit(TagEvents.updated, { tag });
			},
			() => {}
		);
	}

	removeTag(event) {
		event.stopPropagation();

		this.showRemoveConfirm()
			.then(() => {
				return this.verbatimTagsService.deleteTagPromise(this.tag.id);
			})
			.then(() => {
				this.$scope.$emit(TagEvents.removed, { id: this.tag.id });
				this.alertsService.success(this.i18n.__('Tag został usunięty.', 'reports'));
			});
	}

	showRemoveConfirm() {
		return this.modalService.show({
			size: '400',
			title: this.i18n.__('Uwaga!', 'tags'),
			text: this.i18n._s('Zamierzasz usunąć tag %s', `<b>${this.tag.title}</b>`, 'tags'),
			icon: {
				color: 'red',
				utilIcon: 'deleting-tag',
			},
			button: {
				id: 'ok',
				text: this.i18n.__('Usuń', 'tags'),
				type: 'button--wide',
				closeOnClick: true,
			},
		});
	}
}

export const TagItem = {
	template: tpl,
	controller: TagItemController,
	bindings: {
		tag: '<',
	},
};
