class ApiHelper {
	constructor(Restangular, TokenRestangular, alertsService, i18n, $q) {
		'ngInject';
		let self = this;

		// czas ważności danych w localStorage
		this.timeDiff = 360000;

		// serwisy do ankiet, filtrów, raportów się tu dodają
		this.dataServices = {};

		this.apiPrefix = window.DATA_INFO.api;

		// obiekty Promise
		this.pro = null;
		this.csrfPromise = null;
		this.$q = $q;

		this.csrf = null;

		this.handleNoToken = function handleNoToken() {
			alertsService.error(i18n.__('Błąd autoryzacji', 'reports'));
			parent.window.location.href = '/info/link-login';
		};

		this.handleDataError = (err) => {
			if (err) {
				if (parseInt(err.status, 10) !== -1) {
					alertsService.error(i18n.__('Błąd połączenia z serwerem', 'reports'));
				}
			}
		};

		this.checkToken = function checkToken() {
			return (
				window.localStorage.getItem('token') &&
				Number(new Date()) - window.localStorage.getItem('timeSet') < self.timeDiff
			);
		};

		this.init = () =>
			new this.$q(function (resolve) {
				resolve();
			});

		this.getCSRF = () =>
			new this.$q(function (resolve, reject) {
				// potrzebne do komunikacji ze starą częścią serwisu
				// np raporty zbiorcze i eksport do xls

				self.init().then(() => {
					if (!self.csrfPromise || (self.csrfPromise && self.csrfPromise.$$state.status !== 0)) {
						self.csrfPromise = TokenRestangular.one('/csrf').get();
					}
					self.csrfPromise.then(
						(data) => {
							self.csrf = data.data;
							resolve(data.data);
							return data.data;
						},
						(err) => {
							reject(err);
							return err;
						}
					);
				});
			});

		this.invalidate = function invalidate() {
			// wymusza reset danych w serwisach do raportów, filtrów, ankiet itp

			const promises = [];

			for (let serv in this.dataServices) {
				if (Object.prototype.hasOwnProperty.call(this.dataServices, serv)) {
					promises.push(this.dataServices[serv].invalidate());
				}
			}

			return Promise.all(promises);
		};
		this.setDataService = function setDataService(key, val) {
			this.dataServices[key] = val;
		};

		this.getApiPrefix = () => this.apiPrefix;
	}
}

export default ApiHelper;
