class LangService {
	constructor($cookies) {
		'ngInject';

		this.$cookies = $cookies;
	}

	getLang() {
		return parent.window.countryCode || this.$cookies.get('lang') || 'pl';
	}
}

export { LangService };
