class AclApiService {
	constructor($q, apiHelper, AgreementRestangular) {
		'ngInject';

		this.apiHelper = apiHelper;
		this.agreementRestangular = AgreementRestangular;
		this.$q = $q;
	}

	fetchOfferDetails() {
		return this.$q((resolve) => {
			this.agreementRestangular
				.one('/')
				.get()
				.then((response) => {
					resolve(response.data);
				}, this.apiHelper.handleDataError);
		});
	}
}

export { AclApiService };
