import Template from './list.html';
require('./list.scss');

class EmptyScreenListController {
	contructor() {}
}

export const EmptyScreenListComponent = {
	template: Template,
	controller: EmptyScreenListController,
	bindings: {
		list: '<',
	},
};
