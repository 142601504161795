const tpl = require('./QuestionForm.html');

class QuestionFormController {
	constructor(modalService, $scope, $timeout, i18n, quickFilterGenerator, surveyProviderService) {
		'ngInject';

		this.quickFilterGenerator = quickFilterGenerator;
		this.surveyProviderService = surveyProviderService;

		this.modalService = modalService;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.i18n = i18n;

		this.answers = [];
		this.ready = false;
	}

	$onInit() {
		this.handlePageLimit();

		this.isLimitExceeded = this.questionData.answers.length > this.attachmentAnswersLimit;

		if (this.displayLimit === undefined && this.showAll) {
			// nie ma z bindingu tu ustawiamy domyślną wartość
			this.displayLimit = 4;
		} else {
			this.displayLimit = Number(this.displayLimit);
		}
		if (this.showAll) {
			// potrzebne do filtra limitTo
			this.displayLimit = undefined;
		}

		this.answers = this.questionData.answers.slice(0);

		if (this.questionCtrl && this.questionCtrl.paginationManager) {
			this.questionCtrl.paginationManager.paginationInit(this.answers);
			this.pagination = this.questionCtrl.paginationManager.pagination;
			this.questionCtrl.paginationManager.setPaginationView(this);
		}
		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	isReady() {
		return this.ready;
	}

	updatePagination(opts) {
		if (opts) {
			if (opts.answers) {
				this.questionData.answers = opts.answers;
				this.answers = opts.answers;
			}
		}
	}

	showCompletionsClick() {
		this.modalService.openCompletionsWithQuestionModal(
			this.questionCtrl.questionIndex,
			this.questionData
		);
	}

	getQuickFilterData(data) {
		return {
			questionData: this.questionData,
			answerTitle: this.quickFilterGenerator.prepareTwoPartAnswerTitle(
				this.questionData.fields[data.index].title,
				data.column
			),
			answers: data.index + 1,
			internalAnswers: data.column,
		};
	}

	handlePageLimit() {
		if (this.questionCtrl.reportAttachment) {
			this.questionCtrl.pageLimit = 1000;
		}
	}
}

export const questionForm = {
	template: tpl,
	controller: QuestionFormController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		displayLimit: '@',
		showAll: '@',
		showPagination: '<',
	},
};
