import tpl from './rankings-wrapper.html';

class RankingsWrapperController {
	constructor(
		$scope,
		$rootScope,
		i18n,
		modalService,
		alertsService,
		reportsService,
		rankingsService,
		userService,
		aclManager,
		segmentService
	) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.reportsService = reportsService;
		this.rankingsService = rankingsService;
		this.aclManager = aclManager;
		this.segmentService = segmentService;
		this.userService = userService;

		this.isLoading = true;

		this.selectedFilter = null;

		this.selectedRanking = null;
		this.rankingModalShown = false;
		this.rankingResult = null;
		this.rankingsList = null;

		this.LOCAL_STORAGE_KEY = 'ranking';
		this.COOKIE_KEY = 'infoRankings';

		this.ELEMENT_FOR_EMPTY = {
			id: null,
			title: this.i18n.__('Brak rankingów', 'reports'),
			disabled: true,
		};

		this.rankingsLoaded = false;
		this.noRankings = true;
		this.areRankingsEnabled = false;

		this.deprecatedFunctionText = this.i18n.__(
			'Dodawanie nowych analiz w wygaszanych funkcjach (Filtry, Kreator raportów, Tabele krzyżowe, Rankingi, Trendy) nie jest już możliwe. Zapraszamy do nowego modułu Wyników.',
			'user'
		);
	}

	$onInit() {
		this.bindFiltersActions();
		this.bindSurveyChange();
		this.bindWatchers();
		this.setRankingsCreationAvailability();
	}

	bindSurveyChange() {
		this.$scope.$on('SurveyChanged', () => {
			this.isLoading = true;

			this.reportsService.clearCache('addToReport');
			this.selectedFilter = null;
			this.rankingsList = [];
			this.selectedRanking = null;
			this.rankingResult = null;

			this.removeRankingFromLocalStorage();
			this.setRankingsCreationAvailability();
		});
	}

	bindFiltersActions() {
		this.$scope.$on('filtersFetched', (event, data) => {
			this.$rootScope.$broadcast('loadingOn');

			this.fetchRankings().then(() => {
				this.selectedFilter = data.filterId;
				this.checkLocalStorage();
				this.isLoading = false;
				this.$scope.$apply();
				this.$rootScope.$broadcast('loadingOff');
			});
		});

		this.$scope.$on('filterChanged', (event, data) => {
			this.selectedFilter = data.filterId;
			this.rankingsSelectChange(this.selectedRanking);
		});
	}

	bindWatchers() {
		this.$scope.$watch(
			() => this.rankingsList,
			() => {
				if (!this.rankingsList) {
					this.noRankings = true;
				} else {
					this.noRankings = Boolean(this.rankingsList[0] && this.rankingsList[0].id === null);
				}
			},
			true
		);

		this.$scope.$watch(
			() => this.rankingsLoaded,
			() => {
				if (this.rankingsLoaded) {
					this.sendAnalyticsEvent();
				}
			}
		);
	}

	sendAnalyticsEvent() {
		const getModuleState = () => {
			const isFeaturePurchased = this.aclManager.isFeatureEnabled(
				this.$rootScope.features.moduleRankings
			);

			if (!isFeaturePurchased) {
				return 'unavailable';
			} else if (isFeaturePurchased && !this.areRankingsEnabled) {
				return 'disabled';
			} else if (isFeaturePurchased && this.noRankings) {
				return 'enabled';
			} else {
				return 'activated';
			}
		};

		const moduleState = getModuleState();

		this.segmentService.track('Rankingi - wejście', {
			state: moduleState,
		});
	}

	//Ranking creation, editing and removing actions

	createNewRanking() {
		if (!this.rankingModalShown) {
			this.rankingModalShown = true;

			this.rankingsService.openCreator({
				onCreateCallback: (createdRanking) => {
					if (!this.rankingsList[0].id) {
						this.rankingsList.splice(0, 1);
					}
					this.rankingsList.unshift({
						id: createdRanking.id,
						title: createdRanking.title,
					});
					this.selectedRanking = this.rankingsList[0];
					this.rankingsSelectChange(this.selectedRanking);

					this.rankingsService.closeCreator();
					this.alertsService.success(this.i18n.__('Pomyślnie utworzono ranking', 'reports'));
				},
				onCloseCallback: () => {
					this.rankingModalShown = false;
				},
			});
		}
	}

	emptyScreenOnButtonClick = () => {
		this.createNewRanking();
	};

	editRanking(event, index, ranking) {
		event.stopPropagation();
		if (!this.rankingModalShown) {
			this.rankingModalShown = true;

			this.rankingsService.openCreator({
				onCreateCallback: (editedRanking) => {
					let listItem = {
						id: editedRanking.id,
						title: editedRanking.title,
					};

					this.rankingsList.splice(index, 1, listItem);

					if (this.selectedRanking && Number(this.selectedRanking.id) === Number(listItem.id)) {
						this.selectedRanking = listItem;
						this.rankingsSelectChange(listItem);
					}

					this.rankingsService.closeCreator();
					this.alertsService.success(this.i18n.__('Pomyślnie zedytowano ranking', 'reports'));
				},
				onCloseCallback: () => {
					this.rankingModalShown = false;
				},
				rankingId: ranking.id,
			});
		}
	}

	removeRanking(event, index, ranking) {
		event.stopPropagation();

		if (ranking.isReportConnected) {
			this.showDisabledModal();
			return;
		}

		this.confirmRemovingPromise().then(() => {
			this.rankingsService.removeRankingPromise(ranking.id).then(
				() => {
					this.rankingsList.splice(index, 1);

					if (this.selectedRanking && Number(this.selectedRanking.id) === Number(ranking.id)) {
						this.selectedRanking = null;
						this.rankingResult = null;
					}
					if (this.rankingsList.length === 0) {
						this.rankingsList = [this.ELEMENT_FOR_EMPTY];
					}

					this.modalService.close();
					this.alertsService.success(this.i18n.__('Pomyślnie usunięto ranking', 'reports'));
				},
				() => {
					this.modalService.close();
					this.showDisabledModal();
				}
			);
		});
	}

	confirmRemovingPromise() {
		let ctrl = this;

		return new Promise((resolve, reject) => {
			let config = {
				size: '400',
				title: ctrl.i18n.__('Usuwanie rankingu', 'reports'),
				text: ctrl.i18n.__('Czy na pewno chcesz usunąć?', 'reports'),
				icon: {
					font: 'fa-trash',
					color: 'gray',
				},
				button: {
					text: ctrl.i18n.__('Anuluj', 'reports'),
					type: 'button--big button--gray',
				},
				button2: {
					text: ctrl.i18n.__('Tak, usuń', 'reports'),
					type: 'button--big',
				},
			};

			this.modalService.show(config).then((reason) => {
				if (reason === 'button1') {
					ctrl.modalService.close();
				}

				if (reason === 'button2') {
					ctrl.modalService.loading(this.i18n.__('Usuwanie rankingu...', 'reports'));
					resolve();
				}
			});
		});
	}

	//Selects actions

	rankingsSelectChange($item) {
		this.$rootScope.$broadcast('loadingOn');

		if ($item) {
			this.rankingsService
				.getRankingResultPromise($item.id, this.selectedFilter)
				.then((rankingResult) => {
					this.rankingResult = this.rankingsService.handleTranslation(rankingResult);
					this.saveRankingInLocalStorage($item.id);

					this.$scope.$apply();
					this.$rootScope.$broadcast('loadingOff');
				});
		} else {
			this.rankingResult = null;
			this.removeRankingFromLocalStorage();
			this.$rootScope.$broadcast('loadingOff');
		}
	}

	//Fetching data

	fetchRankings() {
		return new Promise((resolve) => {
			this.rankingsService.getRankingsListPromise().then((rankingsList) => {
				if (rankingsList.length > 0) {
					this.rankingsList = rankingsList;
				} else {
					this.rankingsList = [this.ELEMENT_FOR_EMPTY];
				}

				this.rankingsLoaded = true;

				resolve();
			});
		});
	}

	showDisabledModal() {
		this.modalService.show({
			size: '400',
			title: this.i18n.__('Nie można usunąć rankingu', 'reports'),
			text: this.i18n.__('Ranking jest użyty w raporcie', 'reports'),
			icon: {
				font: 'fa-exclamation-triangle',
				color: 'gray',
			},
			button: {
				text: this.i18n.__('Zamknij', 'reports'),
				type: 'button--big button--gray',
				closeOnClick: true,
			},
		});
	}

	// local storage methods

	checkLocalStorage() {
		const idFromLocalStorage = this.getRankingFromLocalStorage();

		if (idFromLocalStorage) {
			this.selectedRanking = this.rankingsList.find(
				(ranking) => Number(ranking.id) === Number(idFromLocalStorage)
			);
			this.rankingsSelectChange(this.selectedRanking);
		} else if (!this.rankingsList[0].disabled) {
			this.selectedRanking = this.rankingsList[0];
			this.rankingsSelectChange(this.selectedRanking);
		}
	}

	saveRankingInLocalStorage(rankingId) {
		window.localStorage.setItem(this.LOCAL_STORAGE_KEY, rankingId);
	}

	removeRankingFromLocalStorage() {
		window.localStorage.removeItem(this.LOCAL_STORAGE_KEY);
	}

	getRankingFromLocalStorage() {
		return window.localStorage.getItem(this.LOCAL_STORAGE_KEY);
	}

	addToReport() {
		let ctrl = this;

		//Emit event -> child controller should trigger proper function
		this.$scope.$broadcast('addToReport', {
			filterId: this.selectedFilter,
			rankingData: this.selectedRanking,
			successCallback: () => {
				ctrl.selectedRanking.isReportConnected = true;
			},
		});
	}

	setRankingsCreationAvailability() {
		this.rankingsService.getMetricsForRankingPromise().then((data) => {
			this.areRankingsEnabled = !!data.length;
		});
	}

	canCreateRanking() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}

	canCreateReport() {
		return this.canCreateRanking();
	}
}

export const RankingsWrapperComponent = {
	template: tpl,
	controller: RankingsWrapperController,
};
