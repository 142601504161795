export function dynamicHoverDirective($document, $rootScope, $timeout) {
	'ngInject';

	return {
		restrict: 'A',
		link: (scope, element, attrs) => {
			let timeout;

			const onMouseEnter = () => {
				timeout = setTimeout(() => {
					if ($rootScope.loadingCompletions) {
						return;
					}

					$rootScope.loadingCompletions = true;
					scope.$apply(attrs.dynamicHover).then(() => {
						$rootScope.loadingCompletions = false;
						$(element).unbind('mouseenter', onMouseEnter);
					});
				}, 1000);
			};

			$(element).bind('mouseenter', onMouseEnter);
			$(element).bind('mouseleave', () => clearTimeout(timeout));
		},
	};
}
