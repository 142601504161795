import { SupportEvent } from '../SupportCRM/event.support';

class SupportCRMService {
	constructor(segmentService) {
		'ngInject';

		this.segmentService = segmentService;
	}

	event(title, props) {
		if (props) {
			this.segmentService.track(title, props);
			return;
		}

		this.segmentService.track(title);
	}

	emitReportCreated() {
		this.event(SupportEvent.reportCreated);
	}

	emitReportColorsUpdated() {
		this.event(SupportEvent.reportColorsUpdated);
	}

	emitReportPartAdded() {
		this.event(SupportEvent.reportPartAdded);
	}

	emitReportLogoUploaded() {
		this.event(SupportEvent.reportLogoUploaded);
	}

	emitReportTextBlockCreated() {
		this.event(SupportEvent.reportTextBlockCreated);
	}

	emitReportChapterCreated() {
		this.event(SupportEvent.reportChapterCreated);
	}

	emitRawExportClicked(format) {
		this.event(SupportEvent.rawExportClicked, { Rodzaj: format });
	}

	emitReportExportFormat(format) {
		this.event(SupportEvent.reportExportFormat, { Rodzaj: format });
	}

	emitReportDownloaded() {
		this.event(SupportEvent.reportDownloaded);
	}

	emitFilterApplied() {
		this.event(SupportEvent.filterApplied);
	}
}

export default SupportCRMService;
