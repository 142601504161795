// const tpl = require('./ReportExport.html');
let { fileExporter } = require('../common/FileExporter');

class ReportExportController extends fileExporter.controller {
	constructor(
		$scope,
		reportsService,
		reportHelper,
		modalService,
		i18n,
		supportCRMService,
		exportService,
		segmentService
	) {
		'ngInject';
		super($scope, reportHelper);

		this.ctrl = this;
		this.modalService = modalService;
		this.i18n = i18n;

		this.reportsService = reportsService;
		this.exportService = exportService;
		this.supportCRMService = supportCRMService;
		this.segmentService = segmentService;

		this.modalData = {};
		this.modalData.title = this.i18n.__('Eksportuj raport', 'reports');
		this.modalData.buttonText = this.i18n.__('Eksportuj', 'reports');
		this.modalData.text = '<h3>' + this.i18n.__('Informacja', 'reports') + '</h3>';
		this.modalData.text +=
			'<p>' +
			this.i18n.__(
				'Wyniki zostaną przedstawione na stan z momentu kliknięcia w przycisk Eksportuj.',
				'reports'
			) +
			'</p>';

		this.extraClass = 'reportExport';

		this.disabled = false;
	}

	downloadAction(checked) {
		if (this.disabled) {
			return;
		}

		this.disabled = true;

		const exportData = {
			reportId: this.resolve.report.id,
			format: checked.format,
		};

		if (checked.format === this.EXPORT_FORMATS[1].format && checked.additionalOptions) {
			if (checked.additionalOptions.live && checked.additionalOptions.live.checked) {
				exportData.format = checked.additionalOptions.live.name;
			}

			if (checked.additionalOptions.seo && checked.additionalOptions.seo.checked) {
				exportData.public = true;
			}
		}

		const ctrl = this;
		this.currentReport = this.resolve.report;

		this.reportsService.exportReport(this.currentReport.id, exportData).then((data) => {
			if (data.id) {
				this.exportService.displayDownloadCenterPopup();

				ctrl.supportCRMService.emitReportExportFormat(exportData.format);

				this.ctrl.dismiss();

				if (this.resolve && this.resolve.onExport) {
					this.resolve.onExport();
				}
			}

			this.disabled = false;
		});
	}

	close() {
		this.dismiss();
	}
}

export const reportExport = {
	template: fileExporter.template,
	controller: ReportExportController,
	bindings: {
		resolve: '<',
		dismiss: '&',
	},
};
