export const quickFiltersConfig = {
	title: {
		separator: ';',
		separatorReplacement: ',',
	},
	npsGroups: {
		detractors: {
			type: 0,
			values: [1, 2, 3, 4, 5, 6, 7],
		},
		neutrals: {
			type: 1,
			values: [8, 9],
		},
		promoters: {
			type: 2,
			values: [10, 11],
		},
	},
};
