// require('script!imports?this=>window!colorPicker/colors.js');
// const Colors = window.Colors;
const tinycolor = require('tinycolor2');

class ChartColorHelper {
	constructor() {
		'ngInject';
		// let ctrl

		let colorsCount = 5;
		// let colors = ['bbe889', 'c286fd', '7ad5ff', '857dfd', 'fec873'];
		let colors = [
			'BBE889',
			'7ED6FE',
			'C286FD',
			'8779FF',
			'FEC873',
			'99DFB4',
			'77A1FF',
			'FF6CAB',
			'C4C8A5',
			'7FD88D',
			'FFB343',
			'FF7F7F',
			'FFB4F1',
			'76D2C9',
			'FAE249',
			'DEB794',
			'99E285',
			'FFA9A9',
			'9B7A7A',
			'FFD951',
			'518EFF',
			'B466BD',
			'C23055',
			'22AE88',
			'8EACFF',
			'FFD628',
			'C860FF',
			'B7E2DF',
			'859DD1',
			'FFD4A2',
		];
		let index = 0;

		let npsColors = {
			detractors: '#ff6868',
			neutrals: '#ffc970',
			promoters: '#b8e986',
		};

		let sourceColor = null;
		let sourceColorObj = null;
		let colorsFromSource = null;
		let hasSource = false;

		let colorRegex = /^(#)?[A-Fa-f0-9]+$/g;

		this.getNextColor = function getNextColor() {
			// console.log('getNextColor', index);
			// let currentColors = colors;
			let currentColors;

			if (hasSource) {
				currentColors = colorsFromSource;
			} else {
				currentColors = this.getColorsTableWithHash();
			}

			if (index === currentColors.length) {
				index = 0;
			}

			return currentColors[index++];
		};

		this.getColorsTable = function getColorsTable(withHash, resultLength) {
			// console.log('getColorsTable', withHash, resultLength);
			if (withHash) {
				return this.getColorsTableWithHash(resultLength);
			}

			// console.log(resultLength, Number.isFinite(resultLength), isFinite(resultLength), Number.isFinite(resultLength) === isFinite(resultLength));
			// TODO: zoptymalizowac
			if (isFinite(resultLength)) {
				// if (Number.isFinite(resultLength)) {
				let result = [];
				let currentColors = hasSource ? colorsFromSource : colors;
				for (let i = 0; i < resultLength; i++) {
					result[i] = currentColors[i % currentColors.length];
				}
				return result;
			}
			return hasSource ? colorsFromSource : colors;
		};

		this.getColorsTableWithHash = function getColorsTableWithHash(resultLength) {
			// console.log('getColorsTableWithHash');
			let result;
			let currentColors = hasSource ? colorsFromSource : colors;

			result = currentColors.map(function (value) {
				return '#' + value;
			});

			return result;
		};

		this.getColorsTableForChart = function getColorsTableForChart() {
			// console.log('getColorsTableForChart', hasSource);
			if (hasSource) {
				// colorsFromSource = [];

				// for (let i = 0; i < colorsCount; i++) {
				//     colorsFromSource[i] = sourceColorObj.clone().setAlpha(1 / (i + 1)).toRgbString();
				// }

				return colorsFromSource;
			}

			return this.getColorsTableWithHash();
		};

		this.setSourceColor = function setSourceColor(val) {
			// console.log('setSourceColor', val);
			if (val) {
				let tmp;
				let alpha = 1;

				sourceColor = val;
				hasSource = true;
				sourceColorObj = tinycolor(sourceColor);

				colorsFromSource = [];

				for (let i = 0; i < colorsCount; i++) {
					alpha = 1.2 * (1 / (i + 1));
					// $log.log(alpha);
					colorsFromSource[i] = sourceColorObj.clone().setAlpha(alpha).toRgbString();
				}
			} else {
				sourceColor = null;
				sourceColorObj = null;
				colorsFromSource = null;
				hasSource = false;
			}
		};

		this.validateColor = (color) => {
			// let colorRegex = /^(#)?[A-Fa-f0-9]+$/g;
			// colorRegex.lastIndex = 0;
			// console.log('validateColor', color, color.length, colorRegex.test(color));
			if (color[0] === '#') {
				if (color.length !== 7) {
					return false;
				}
			} else if (color.length !== 6) {
				return false;
			}

			colorRegex.lastIndex = 0;
			return colorRegex.test(color);
		};

		this.reset = function reset() {
			// $log.log('reset');
			index = 0;
			// scaleIndex = 0;
			hasSource = false;
		};

		this.getNPSColors = () => npsColors;
	}
}

export default ChartColorHelper;
