import tpl from './SentimentCreator.html';

class SentimentCreatorController {
	constructor($rootScope, i18n, tagsStorage, sentimentService, surveyProviderService) {
		'ngInject';

		this.i18n = i18n;
		this.$rootScope = $rootScope;
		this.tagsStorage = tagsStorage;
		this.sentimentService = sentimentService;
		this.surveyProviderService = surveyProviderService;

		this.model = {};
	}

	async $onInit() {
		this.$rootScope.$on('SurveyChanged', async () => {
			this.tagsStorage.clear();
			this.sentimentService.clearModel(true);
			await this.init();
		});

		await this.init();
	}

	async init() {
		const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();

		this.model = $$await(await this.sentimentService.getModel(surveyId));

		this.model.from = updateFromTime(this.model.from);
		this.model.to = updateToTime(this.model.to);

		this.tags = $$await(await this.tagsStorage.fetchTags(this.model.from, this.model.to));

		if (this.isValidModel()) {
			await this.onCreate();
		}

		function updateFromTime(timestamp) {
			const updatedDate = new Date(timestamp);
			updatedDate.setHours(0);
			updatedDate.setMinutes(0);
			updatedDate.setSeconds(0);

			return updatedDate.getTime();
		}

		function updateToTime(timestamp) {
			const updatedDate = new Date(timestamp);
			updatedDate.setHours(23);
			updatedDate.setMinutes(59);
			updatedDate.setSeconds(59);

			return updatedDate.getTime();
		}
	}

	async onRangeChange({ from, to }) {
		this.model = {
			from,
			to,
			tags: [],
		};

		this.tagsStorage.clear();
		this.tags = $$await(await this.tagsStorage.fetchTags(this.model.from, this.model.to));
	}

	isValidModel() {
		return this.model.from && this.model.to && this.model.tags.length;
	}

	async onCreate() {
		const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
		this.sentimentService.calculate(this.model, surveyId);
	}
}

SentimentCreatorController.$inject = [
	'$rootScope',
	'i18n',
	'tagsStorage',
	'sentimentService',
	'surveyProviderService',
];

export const SentimentCreator = {
	controller: SentimentCreatorController,
	template: tpl,
};
