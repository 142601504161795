const ngIndeterminateDirective = ($document) => {
	return {
		restrict: 'A',
		link: (scope, element, attributes) => {
			scope.$watch(attributes.ngIndeterminate, function (value) {
				element.prop('indeterminate', !!value);
			});
		},
	};
};

ngIndeterminateDirective.$inject = ['$document'];

export { ngIndeterminateDirective };
