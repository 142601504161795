class CrossTablesApiService {
	constructor(Restangular, surveyProviderService, apiHelper) {
		this.restangular = Restangular;
		this.surveyProviderService = surveyProviderService;
		this.apiHelper = apiHelper;
	}

	async getQuestions() {
		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			let questionsList = await this.restangular
				.one(String(surveyId))
				.one('cross-tables')
				.one('questions')
				.get();

			return questionsList && questionsList.plain
				? questionsList.plain().items
				: questionsList.items;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async getSegments() {
		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			let segmentsList = await this.restangular
				.one(String(surveyId))
				.one('cross-tables')
				.one('segments')
				.get();

			return segmentsList && segmentsList.plain ? segmentsList.plain().items : segmentsList.items;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async create(data) {
		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			let crossTableData = await this.restangular
				.one(String(surveyId))
				.all('cross-tables')
				.post(data);

			return crossTableData && crossTableData.plain ? crossTableData.plain() : crossTableData;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async update(crossTableId, data) {
		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			let crossTableData = await this.restangular
				.one(String(surveyId))
				.one('cross-tables', crossTableId)
				.customPUT(data);

			return crossTableData && crossTableData.plain ? crossTableData.plain() : crossTableData;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async remove(crossTableId) {
		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			await this.restangular.one(String(surveyId)).one('cross-tables', crossTableId).remove();

			return;
		} catch (error) {
			if (error && error.status === 409) {
				throw new Error();
			} else {
				this.apiHelper.handleDataError(error);
			}
		}
	}

	async getOne(crossTableId) {
		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			let crossTableData = await this.restangular
				.one(String(surveyId))
				.one('cross-tables', crossTableId)
				.get();

			return crossTableData && crossTableData.plain ? crossTableData.plain() : crossTableData;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async getResult(crossTableId, filterId = null) {
		let queryData = filterId ? { filter: filterId } : {};

		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			let result = await this.restangular
				.one(String(surveyId))
				.one('cross-tables', crossTableId)
				.one('calculate')
				.get(queryData);

			return result && result.plain ? result.plain() : result;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}

	async getList() {
		try {
			const surveyId = await this.surveyProviderService.getCurrentSurveyIdPromise();
			let list = await this.restangular.one(String(surveyId)).one('cross-tables').get();

			return list && list.plain ? list.plain().items : list.items;
		} catch (error) {
			this.apiHelper.handleDataError(error);
		}
	}
}

CrossTablesApiService.$inject = ['Restangular', 'surveyProviderService', 'apiHelper'];

export { CrossTablesApiService };
