import tpl from './SentimentDistribution.html';

class SentimentDistributionController {
	constructor(i18n) {
		'ngInject';
		this.i18n = i18n;
	}
}

export const SentimentDistribution = {
	template: tpl,
	controller: SentimentDistributionController,
	bindings: {
		sentimentDistributionData: '<',
	},
};
