import tpl from './VerbatimParameters.html';
import { VerbatimParametersEvents } from './events.verbatimParameters';
import { VerbatimParametersType } from './../../services/verbatims/type.verbatimParameters';

class VerbatimParameters {
	constructor(i18n, $scope, $rootScope, $timeout, verbatimParametersService) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.verbatimParametersService = verbatimParametersService;

		this.verbatimParameters = VerbatimParametersType;
		this.verbatimParametersIndexes = Object.keys(VerbatimParametersType).map(
			(e) => VerbatimParametersType[e]
		); //Object.values in IE11
	}

	$onInit() {
		this.prepareEmptyArrays();
		this.bindSurveyChange();
		this.loadParameters();

		this.parameterClickObserver();
	}

	prepareEmptyArrays() {
		this.parameters = [];
		this.chosenParameters = [];

		this.verbatimParametersIndexes.forEach((el) => {
			this.parameters[el] = [];
			this.chosenParameters[el] = [];
		});
	}

	bindSurveyChange() {
		this.$scope.$on('SurveyChanged', () => {
			this.prepareEmptyArrays();
			this.loadParameters();
		});
	}

	loadParameters() {
		this.verbatimParametersService.getAllParametersPromise().then((lists) => {
			this.verbatimParametersIndexes.forEach((el, index) => {
				this.parameters[el] = lists[index][0];
				this.chosenParameters[el] = lists[index][1];
			});

			this.$rootScope.$emit(VerbatimParametersEvents.loaded, {
				parameters: this.parameters,
				chosenParameters: this.chosenParameters,
			});
		});
	}

	parameterClickObserver() {
		this.$scope.$on(VerbatimParametersEvents.parameterClick, (event, data) => {
			this.parameterChange(data.indexOfList, data.parameter);
		});
	}

	parameterChange(listIndex, element) {
		let list = this.chosenParameters[listIndex];

		let matrix = false;
		if (element.totalRows) {
			//matrix
			matrix = true;
		}

		let newElement = ''; //elements should be "id" or "rowIndex" or "id_rowIndex"
		if (matrix) {
			newElement = `${element.id}_${element.rowIndex}`;
		} else if (element.id) {
			newElement = element.id;
		} else if (element.rowIndex) {
			newElement = element.rowIndex;
		} else {
			return false;
		}

		let i = list.findIndex((parameter) => parameter === newElement);
		if (element.active && i === -1) {
			list.push(newElement);
		} else if (i > -1) {
			list.splice(i, 1);
		}

		this.verbatimParametersService.storeParameters(listIndex, list);

		this.$rootScope.$emit(VerbatimParametersEvents.changed, {
			chosenParameters: this.chosenParameters,
		});
	}
}

export const verbatimParameters = {
	template: tpl,
	controller: VerbatimParameters,
};
