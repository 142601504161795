const tpl = require('./AddToReportModal.html');
let { AbstractAddToReportModal } = require('./AbstractAddToReportModal');

class AddRankingToReportModal extends AbstractAddToReportModal {
	constructor(
		reportsService,
		reportHelper,
		$scope,
		surveyProviderService,
		modalService,
		i18n,
		$timeout
	) {
		'ngInject';
		super(
			reportsService,
			reportHelper,
			$scope,
			surveyProviderService,
			i18n,
			$timeout,
			modalService
		);

		let ctrl = this;

		this.partType = String(this.types.RANKING);
	}

	$onInit() {
		super.$onInit();
		this.getResolveData();
	}

	getResolveData() {
		this.rankingData = this.resolve.rankingData;
		this.additionalSettings = this.resolve.additionalSettings;
		this.filterId = this.resolve.filterId;
		this.successCallback = this.resolve.successCallback;
	}

	add() {
		if (this.locked || this.saved) {
			return;
		}
		this.locked = true;

		let self = this;
		let config = this.getBaseConfig();

		this.reportsService
			.addPartDataToReportPromise(config, (newPart) => {
				newPart.title = self.rankingData.title;
				newPart.type = self.partType;
				newPart.text = '';
				newPart.filter = self.filterId;
				newPart.ranking = self.rankingData.id;
				newPart.additionalSettings = self.additionalSettings;

				return newPart;
			})
			.then(
				(reportData) => {
					self.addSuccessCallback(reportData, config);
					this.successCallback();
				},
				(errorData) => {
					self.addErrorCallback(errorData);
				}
			);
	}
}

export const addRankingToReportModal = {
	template: tpl,
	controller: AddRankingToReportModal,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
