const tpl = require('./ReportPart.html');
import '../../scss/reportPart.scss';

class ReportPartController {
	constructor(
		$scope,
		surveyProviderService,
		reportPartEditorService,
		$element,
		reportHelper,
		$sanitize,
		$rootScope,
		reportBuilderHelper,
		filtersService,
		questionHelper,
		i18n,
		$timeout,
		crossTablesApiService,
		rankingsService,
		trendsService
	) {
		'ngInject';

		this.$scope = $scope;
		this.$element = $element;
		this.surveyProviderService = surveyProviderService;
		this.reportPartEditorService = reportPartEditorService;
		this.reportHelper = reportHelper;
		this.reportPartEditorService = reportPartEditorService;
		this.$rootScope = $rootScope;
		this.reportBuilderHelper = reportBuilderHelper;
		this.filtersService = filtersService;
		this.questionHelper = questionHelper;
		this.i18n = i18n;
		this.$timeout = $timeout;
		this.crossTablesApiService = crossTablesApiService;
		this.rankingsService = rankingsService;
		this.trendsService = trendsService;

		this.builder = this.reportBuilderHelper.getBuilder();

		this.showEdit = false;
		this.colors = null;
		this.types = reportHelper.getReportPartTypes();
		this.id = null;
		this.questionIndexInReport = null;
		this.attachmentIndex = null;
		this.filterName = '';

		this.isPlaceholder = false;
		this.hasAdditionalCompletions = false;

		this.initialized = false;
		this.canLoad = false;
	}

	$onInit() {
		this.getPart();
		this.loadDataInit();

		if (this.part.type === 0) {
			if (!this.part.text && !this.part.title && !this.part.id) {
				this.isPlaceholder = true;
			}
		}

		if (this.part._showEdit !== undefined) {
			this.setShowEdit(this.part._showEdit);
			delete this.part._showEdit;
		}

		this.colors = this.builder.getColors();
	}

	loadDataInit() {
		this.canLoad = true;

		if (
			!this.reportHelper.isSpecialPart(this.part.type) &&
			this.part.question !== undefined &&
			!this.questionData
		) {
			if (this.$rootScope.xhr <= 10 && this.canLoad) {
				this.loadData();
			} else {
				this.clearWatch = this.$scope.$watch(
					() => {
						return this.$rootScope.xhr;
					},
					(newVal, oldVal) => {
						if (this.canLoad && newVal <= 10) {
							this.loadTimeoutId = setTimeout(this.loadData, 1);
						}
					}
				);
			}
		}
	}

	getPart() {
		this.$scope.part = this.part = this.chapterCtrl.chapter.parts[this.partIndex];
		this.id = this.part.id;
	}

	loadData() {
		this.canLoad = false;

		if (this.clearWatch) {
			this.clearWatch();
		}

		if (this.reportHelper.isCrossTable(this.part.type)) {
			this.loadCrossTableResult();
		} else if (this.reportHelper.isRanking(this.part.type)) {
			this.loadRankingResult();
		} else if (this.reportHelper.isTrend(this.part.type)) {
			this.loadTrendResult();
		} else {
			this.loadQuestion();
		}

		if (this.part.filter || this.part.filter === 0) {
			this.filtersService.getFiltersPromise(this.part.filter, true).then((data) => {
				this.filterName = data.title;
			});
		}
	}

	loadCrossTableResult() {
		let self = this;
		if (this.part.crossTable) {
			this.crossTablesApiService.getResult(this.part.crossTable, this.part.filter).then((data) => {
				this.crossTableResult = data;
				this.initialized = true;

				this.$timeout(() => this.$scope.$apply());
			});
		}
	}

	loadRankingResult() {
		if (this.part.ranking) {
			this.rankingsService
				.getRankingResultPromise(this.part.ranking, this.part.filter)
				.then((data) => {
					this.rankingResult = data;
					this.initialized = true;

					this.$timeout(() => this.$scope.$apply());
				});
		}
	}

	loadTrendResult() {
		let self = this;
		if (this.part.trend) {
			this.trendsService.getTrendResultPromise(this.part.trend, this.part.filter).then((data) => {
				this.trendResult = data;
				this.initialized = true;
				this.$timeout(() => this.$scope.$apply());
			});
		}
	}

	loadQuestion() {
		this.surveyProviderService.getQuestionPromiseById(this.part.question, this.part.filter).then(
			(data) => {
				this.questionData = data;

				if (this.indexInReport) {
					this.handleAnswerWithOthers();
				}

				this.initialized = true;

				this.$timeout(() => this.$scope.$apply());
			},
			function error(data) {
				console.error(data);
			}
		);
	}

	$onDestroy() {
		this.builder.removeAnswersAttachment(this.id, this.attachmentIndex);
	}

	getPartData() {
		return this.part;
	}

	/**
	 * setShowEdit - ustawia flage widoczności edytora i dodaje element edytora
	 *
	 * @param  {Boolean} val nowa wartosc
	 */
	setShowEdit(val) {
		let isNew = Boolean(this.part._isNew);
		delete this.part._isNew;

		if (this.builder.isDisabled()) {
			this.builder.showDisabledModal();
			return;
		}
		this.showEdit = val;

		if (val) {
			this.builder.hideAllPartsEditors();
			this.reportPartEditorService.attachEditor(this, this.$element, isNew);
		} else {
			this.reportPartEditorService.detach();
		}
	}

	/**
	 * toggleEditor - Pokazuje/ukrywa edytor
	 *
	 * @return {type}  description
	 */
	toggleEditor() {
		if (this.builder.isDisabled()) {
			this.builder.showDisabledModal();
			return;
		}
		this.setShowEdit(!this.showEdit);
	}

	deletePart(force) {
		this.chapterCtrl.deletePart(this.partIndex, force, this.isPlaceholder, this.part);
	}

	/**
	 * update - aktualzuje dane partu
	 *
	 * @param  {type} opts description
	 * @return {type}      description
	 */
	update(opts) {
		let changed = false;
		if (opts.title) {
			this.part.title = opts.title;
			changed = true;
		}
		if (opts.text) {
			this.part.text = opts.text;
			changed = true;
		}
		if (changed) {
			this.$scope.part = this.part;

			if (!this.isPlaceholder) {
				this.builder.updatePart(this.chapterCtrl.getId(), this.part.id, opts);
			} else {
				this.builder.saveCommentPart(this.chapterCtrl.chapter, this.part);
			}
		}
	}

	/**
	 * handleAnswerWithOthers - description
	 *
	 * @return {type}  description
	 */
	handleAnswerWithOthers() {
		let hasOthers = this.hasOthers();
		this.hasAdditionalCompletions = this.questionHelper.questionHasAdditionalCompletions(
			this.questionData
		);

		if (hasOthers || this.hasAdditionalCompletions) {
			this.reportBuilderHelper.builder.addAnswersAttachment({
				partCtrl: this,
				id: this.id,
				questionId: this.questionData.id,
				isCompletionsAttachment: this.hasAdditionalCompletions,
			});
		}
	}

	/**
	 * hasOthers - sprawdza czy pytanie w tym parcie ma inne (czyli czy powinien miec zalacznik)
	 *
	 * @return {type}  description
	 */
	hasOthers() {
		if (
			this.questionHelper.typeHasReasons(this.questionData) ||
			this.questionHelper.typeHasOthers(this.questionData)
		) {
			if (this.questionHelper.isMatrixOpen(this.questionData)) {
				for (var i = 0; i < this.questionData.answers.length; i++) {
					if (this.questionData.answers[i].some((item) => !!item.count)) {
						return true;
					}
				}
			} else if (
				this.questionHelper.isMatrix(this.questionData) ||
				this.questionHelper.isRating(this.questionData)
			) {
				let rows = this.questionData.rows.some(
					(item) => item.hasOthers && item.otherIndexes && item.otherIndexes.length
				);
				let cols = this.questionData.columns.some(
					(item) => item.hasOthers && item.otherIndexes && item.otherIndexes.length
				);
				return rows || cols;
			}
			return this.questionData.answers.some((item) => item.hasOthers);
		}

		return false;
	}

	setAttachmentIndex(val) {
		this.attachmentIndex = val;
	}

	// funkcje pomocnicze dla theme raportu
	getTextColor() {
		if (this.colors) {
			return this.colors.textColor;
		}
		return null;
	}

	getHeadlineColor() {
		if (this.colors) {
			return this.colors.headlineColor;
		}
		return null;
	}

	getReportHelper() {
		return this.reportHelper;
	}

	getAdditionalSettingsAsObject(additionalSettings) {
		if (this.part.additionalSettings.length > 0) {
			this.part.additionalSettings.forEach((setting) => {
				let key = Object.keys(this.reportHelper.additionalSettingsTypes).find(
					(key) => this.reportHelper.additionalSettingsTypes[key] === setting.type
				);

				additionalSettings[key] = setting.setup;
			});
		}
	}
}

export const reportPart = {
	template: tpl,
	controller: ReportPartController,
	require: {
		chapterCtrl: '^reportChapter',
	},
	bindings: {
		partIndex: '<',
		isLast: '<',
		indexInReport: '<',
	},
};
