import Template from './SentimentsEmptyScreen.html';

class SentimentsEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Zobacz jak zmieniał się sentyment wypowiedzi dotyczący poszczególnych tematów i czy Twoje zmiany przynoszą rezultaty.',
					'user'
				),
				this.i18n.__(
					'Sprawdź, które obszary są najlepiej oceniane, a które budzą największe niezadowolenie.',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}

	getTooltipText() {
		return this.i18n.__(
			'Aby przeprowadzić analizę sentymentu musisz najpierw otagować odpowiedzi w sekcji Tagowanie odpowiedzi.',
			'user'
		);
	}

	getDescriptionText() {
		const description = this.i18n.__('Nie masz jeszcze analizy sentymentu.', 'user');

		const enabledPropertyExists = typeof this.enabled !== 'undefined';

		if (!enabledPropertyExists) {
			return description;
		}

		const disabledDescription = this.i18n.__('Nie masz jeszcze otagowanych odpowiedzi.', 'user');

		return this.enabled ? description : disabledDescription;
	}
}
export const SentimentsEmptyScreenComponent = {
	template: Template,
	controller: ['i18n', SentimentsEmptyScreenController],
	bindings: {
		onButtonClick: '<',
		enabled: '<',
	},
};
