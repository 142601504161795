import angular from 'angular';

const jquery = require('jquery');

class AbstractAddToReportModal {
	constructor(
		reportsService,
		reportHelper,
		$scope,
		surveyProviderService,
		i18n,
		$timeout,
		alertsService
	) {
		this.reportsService = reportsService;
		this.reportHelper = reportHelper;
		this.$scope = $scope;
		this.surveyProviderService = surveyProviderService;
		this.i18n = i18n;
		this.$timeout = $timeout;
		this.alertsService = alertsService;

		this.types = reportHelper.getReportPartTypes();
		this.chapterLocked = true;
		this.partsLocked = true;
		this.chapters = [];
		this.reports = [];
		this.parts = [];

		this.additionalSettings = null;

		this.addNewChapter = false;
		this.newChapterName = '';
		this.cacheKey = 'addToReport';
		this.currentReport = null;

		this.textMaxLength = 45;

		this.locked = false;
		this.saved = false;
		this.loading = true;
		this.creatorLoading = true;

		this.chooserOptions = {
			new: 'new',
			existing: 'existing',
		};

		this.addChapterPlaceholder = {
			title: i18n.__('Dodaj nowy rozdział', 'reports'),
			position: 'new',
		};

		this.DATA_INFO = window.DATA_INFO;
	}

	$onInit() {
		if (!angular.isDefined(this.onlyNewMode)) {
			this.onlyNewMode = undefined;
		}

		this.reportChooser = this.chooserOptions.new;
		this.$scope.positionSelect = undefined;
		this.$scope.reportsSelect = undefined;

		let cached = this.getChoicesFromCache();
		if (cached.list && cached.reportId && !this.onlyNewMode) {
			this.getDataFromCache(cached);
		} else {
			this.getReportsData();
		}
		window.ATRM = this;
	}

	$onDestroy() {
		this.reportsService.interrupt();
	}

	getReportsData() {
		this.reportsService.getReportsListPromise(true, 1).then(
			(data) => {
				this.reports = angular.copy(data);

				if (!this.reports.length) {
					this.reportChooser = this.chooserOptions.new;
					this.onlyNewMode = true;
				} else {
					this.onlyNewMode = false;
				}
				this.creatorLoading = false;

				this.reportsService.continouslyLoadReports(
					this.reports,
					(list) => {
						this.reports = angular.copy(list);
						// this.loading = true;
					},
					(count) => {
						this.parseReportData();
						this.loading = false;
						this.$timeout(() => {
							this.$scope.$apply();
						});
					}
				);
			},
			function error(data) {
				console.error(data);
			}
		);
	}

	getDataFromCache(cached) {
		this.creatorLoading = false;
		this.loading = false;
		this.reports = JSON.parse(cached.list);

		// console.log('pobierz z cache');
		this.reportChooser = this.chooserOptions.existing;
		this.currentReport = JSON.parse(cached.report);
		this.parseReportData();

		this.$scope.reportsSelect = '';
		this.$scope.reportsSelect = this.currentReport;

		this.reportChosen(String(cached.reportId), true);
		this.$scope.chapterSelect = String(cached.chapterIndex);
		this.$scope.chapterSelect = this.currentReport.chapters.find(
			(chapter) => Number(chapter.position) === Number(cached.chapterIndex)
		);
		this.chapterChosen(cached.chapterIndex);
	}

	saveChoicesToCache(reportId, chapterIndex, newChapterName) {
		if (this.reportChooser !== this.chooserOptions.new) {
			let cache = this.getChoicesFromCache();
			cache.list = JSON.stringify(this.reports);
			cache.chapterIndex = newChapterName
				? this.currentReport.chapters[this.currentReport.chapters.length - 1].position
				: chapterIndex;
			cache.reportId = reportId;
			cache.report = JSON.stringify(this.currentReport);
		} else {
			this.reportsService.clearCache(this.cacheKey);
		}
	}

	getChoicesFromCache() {
		let item = this.reportsService.getCacheObject(this.cacheKey);
		// console.log('getChoicesFromCache', item, this.currentReport);
		return item;
	}

	reportChosen(reportId, fromCache) {
		this.chapterLocked = true;
		this.partsLocked = true;
		this.addNewChapter = false;

		if (!reportId) {
			return false;
		}

		this.loading = true;

		if (fromCache) {
			this.setChapters(this.currentReport.chapters);
			this.chapterLocked = false;
			this.loading = false;
			// this.$scope.$digest();
		} else {
			this.reportsService.getReportPromise(reportId).then(
				(data) => {
					this.currentReport = data;
					this.setChapters(data.chapters);
					this.chapterLocked = false;
					this.loading = false;
					this.$scope.$digest();
				},
				function error(data) {
					console.error(data);
				}
			);
		}
	}

	chapterChosen(chapterPosition) {
		// console.log('chapterChosen', chapterPosition);
		this.partsLocked = true;
		this.loading = true;
		this.addNewChapter = false;
		this.$scope.positionSelect = null;
		if ((chapterPosition || chapterPosition === 0) && chapterPosition > -1) {
			chapterPosition--;

			this.$scope.selectedChapter = chapterPosition;

			let parts = this.chapters[chapterPosition].parts;
			let count = parts.length;
			let hasQuestions = false;

			for (let i = 0; i < count; i++) {
				if (parts[i].question !== null && !parts[i].title) {
					hasQuestions = true;

					this.surveyProviderService.getQuestionPromiseById(parts[i].question).then((data) => {
						if (data.title && data.title.length > this.textMaxLength) {
							parts[i].title = data.title.substr(0, this.textMaxLength) + '...';
						} else {
							parts[i].title = data.title;
						}
						this.partsLocked = false;
						this.loading = false;
						this.$timeout(() => {
							this.$scope.$apply();
						});
					});
				} else if (parts[i].title === null) {
					parts[i].title = this.reportHelper.getGeneratedPartTitle(parts[i].type);
				} else if (parts[i].title && parts[i].title.length > this.textMaxLength) {
					parts[i].title = parts[i].title.substr(0, this.textMaxLength) + '...';
				}
			}
			if (!hasQuestions) {
				this.loading = false;
				this.partsLocked = false;
			}
		} else if (chapterPosition === this.addChapterPlaceholder.position) {
			this.loading = false;
			this.partsLocked = true;
			this.addNewChapter = true;
		}
	}

	setChapters(chapters) {
		this.chapters = chapters && chapters.length ? chapters.slice(1) : [];
		this.chapters.push(this.addChapterPlaceholder);

		this.parseChapterData();

		this.$timeout(() => {
			this.$scope.$apply();
		});
	}

	getConst() {
		return this.reportsService.getConst();
	}

	parseReportData() {
		if (this.reports) {
			this.reports.forEach((report) => {
				if (report.title.length > this.textMaxLength) {
					report.title = report.title.substr(0, this.textMaxLength) + '...';
				}
				report.id = String(report.id);
			});
		}
	}

	parseChapterData() {
		if (this.chapters) {
			this.chapters.forEach((chapter) => {
				if (chapter.title.length > this.textMaxLength) {
					chapter.title = chapter.title.substr(0, this.textMaxLength) + '...';
				}
			});
		}
	}

	isButtonDisabled() {
		if (this.reportChooser === 'existing') {
			if (this.addNewChapter) {
				return !(this.newChapterName.length > 0);
			}

			return !this.$scope.positionSelect;
		}

		if (this.$scope.newReportName) {
			let tmp = this.reportHelper.cleanUpReportTitle(this.$scope.newReportName);
			if (!tmp.length) {
				return true;
			}
		}

		return !this.$scope.newReportName;
	}

	// New functions

	addSuccessCallback(reportData, config, saveToCache = true) {
		if (saveToCache) {
			this.currentReport = reportData;
			this.saveChoicesToCache(reportData.id, config.chapterIndex, config.newChapterName);
		}
		this.reportHelper.openReportStatusModal({
			new: this.reportChooser === this.chooserOptions.new,
			id: config.reportId !== undefined ? config.reportId : reportData.id,
		});

		this.close();
		this.saved = true;
		this.locked = false;
	}

	addErrorCallback() {
		this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem ', 'reports'));
		this.locked = false;
	}

	getBaseConfig() {
		let report = this.$scope.reportsSelect;
		let newReportName = this.reportChooser === 'new' ? this.$scope.newReportName : '';
		let reportId = report ? report.id : undefined;
		let chapterIndex = this.$scope.chapterSelect ? this.$scope.chapterSelect.position : undefined;

		return {
			reportId,
			chapterIndex,
			position: this.$scope.positionSelect,
			newReportName,
			newChapterName: this.newChapterName,
		};
	}
}

export { AbstractAddToReportModal };
