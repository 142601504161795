const tpl = require('./ReportThemeEditor.html');
const $ = require('jquery');
import angular from 'angular';

const tinycolor = require('tinycolor2');

require('../../scss/reportThemeEditor.scss');

/**
 * @class ReportThemeEditorController
 */
class ReportThemeEditorController {
	constructor(
		$element,
		$scope,
		reportHelper,
		reportsService,
		reportBuilderHelper,
		chartColorHelper,
		$timeout,
		i18n,
		$rootScope,
		supportCRMService,
		aclManager
	) {
		'ngInject';
		let ctrl = this;

		this.$element = $element;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.i18n = i18n;
		this.reportHelper = reportHelper;
		this.reportsService = reportsService;
		this.reportBuilderHelper = reportBuilderHelper;
		this.chartColorHelper = chartColorHelper;
		this.supportCRMService = supportCRMService;
		this.aclManager = aclManager;

		this.loading = true;

		this.builder = null;
		this.report = null;
		this.checkboxedLocked = false;
		this.currentTheme = null;
		this.defaultTheme = null;

		this.colorObjects = {
			headline: null,
			text: null,
			description: null,
			chart: null,
		};

		this.validColors = {
			headline: true,
			text: true,
			description: true,
			chart: true,
		};

		this.disabled = true;
		this.DATA_INFO = window.DATA_INFO;

		reportBuilderHelper.setThemeEditor(this);
	}

	init(report) {
		this.builder = this.reportBuilderHelper.getBuilder();
		this.report = report;
		this.currentTheme = this.$scope.currentTheme = this.builder.currentReport.theme;

		this.reportHelper.getDefaultThemePromise().then((defaultTheme) => {
			this.defaultTheme = defaultTheme.theme;
			this.loading = false;
		});

		this.initColors();
		this.bindUIActions();
	}

	initColors() {
		this.colorObjects.headline = tinycolor(this.currentTheme.headlineColor);
		this.colorObjects.text = tinycolor(this.currentTheme.textColor);
		this.colorObjects.description = tinycolor(this.currentTheme.descriptionColor);
		this.colorObjects.chart = tinycolor(this.currentTheme.chartColor);
	}

	bindUIActions() {
		let self = this;

		this.$scope.$on('colorpicker-shown', function () {
			let elem = $('.colorpicker.colorpicker-visible');
			let top =
				window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

			elem[0].style.top = elem.offset().top - 2 * top + 'px';
		});

		if (this.builder.disabled) {
			$('.reportThemeEditor__colorControl input').on('click', () => {
				self.builder.showDisabledModal();
			});
		} else {
			this.$scope.$watchCollection('currentTheme', (value, oldVal) => {
				if (value && value !== oldVal) {
					self.debouncedThemeUpdate();
				}
			});
		}

		this.handleFakeFileInput = function (inputController) {
			self.$scope.$watch(
				() => inputController.filePath,
				(newVal, oldVal) => {
					if (self.builder) {
						self.builder.setLogoPath(newVal);
					}
				}
			);
		};
	}

	//colors
	debouncedThemeUpdate() {
		if (this.toQuickly) {
			return;
		}
		this.toQuickly = true;

		let newTheme = angular.copy(this.currentTheme);

		delete newTheme.id;
		this.validColors.headline = this.chartColorHelper.validateColor(newTheme.headlineColor);
		this.validColors.text = this.chartColorHelper.validateColor(newTheme.textColor);
		this.validColors.description = this.chartColorHelper.validateColor(newTheme.descriptionColor);
		this.validColors.chart = newTheme.chartColor
			? this.chartColorHelper.validateColor(newTheme.chartColor)
			: true;

		if (newTheme.chartColor === undefined) {
			newTheme.chartColor = null;
		}

		if (
			!this.validColors.headline ||
			!this.validColors.text ||
			!this.validColors.description ||
			!this.validColors.chart
		) {
			this.$scope.$digest();
			return;
		}

		this.reportsService.postThemePromise(this.currentTheme.id, newTheme).then((data) => {
			this.builder.updateQuestions(newTheme);
			this.builder.updateCrossTables(newTheme);
			this.builder.updateTrends(newTheme);

			this.supportCRMService.emitReportColorsUpdated();

			this.$timeout(() => {
				this.toQuickly = false;
			}, 500);
		});
	}

	resetColor(colorName) {
		if (this.builder.disabled) {
			this.builder.showDisabledModal();
			return;
		}

		this.currentTheme[colorName] = this.defaultTheme[colorName];
		this.debouncedThemeUpdate();
	}

	showTableOfContentsClick(val) {
		if (this.builder.disabled) {
			this.builder.showDisabledModal();
			return;
		}

		this.builder.toggleTableOfContents(val);
	}

	showPageNumbersClick(e) {
		if (this.builder.disabled) {
			e.preventDefault();
			this.builder.showDisabledModal();
			return;
		}
		this.builder.togglePageNumbers();
	}

	lockCheckboxes() {
		this.checkboxedLocked = true;
	}

	unlockCheckboxes() {
		this.checkboxedLocked = false;
	}

	isLoading() {
		return this.loading;
	}

	editName(e) {
		if (this.builder.disabled) {
			e.preventDefault();
			this.builder.showDisabledModal();
			return;
		}

		this.builder.editNameModal();
	}

	isReportsCreatorEnabled() {
		return this.aclManager.isFeatureEnabled(this.$rootScope.features.moduleReportsCreator);
	}

	backClick() {
		parent.window.location = '/user#angular?r=/reports/list';
	}
}

export const reportThemeEditor = {
	template: tpl,
	controller: ReportThemeEditorController,
};
