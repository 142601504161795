import angular from 'angular';

class AbstractQuestionMatrixController {
	constructor(chartColorHelper, modalService, $filter, questionHelper, i18n, quickFilterGenerator) {
		'ngInject';
		let ctrl = this;

		this.barColors = [];
		this.chartColorHelper = chartColorHelper;
		this.modalService = modalService;
		this.questionHelper = questionHelper;
		this.i18n = i18n;
		this.quickFilterGenerator = quickFilterGenerator;

		// this.extraChart = null;
		this.colorFilter = $filter('colorFilter');

		// this.columnReasonsObj = {};
		this.types = {
			matrixSingle: 0,
			matrixMulti: 1,
			matrixOpen: 2,
			rating: 3,
		};
		this.ready = false;
		// this.showTrend = true;
	}

	init() {
		this.updateBarColors();
	}

	$onInit() {
		this.init();
		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	isReady() {
		return this.ready;
	}

	getBarWidth(columnData, rowData, answer) {
		const percent = answer.percent;
		return percent >= 100 ? 100 : percent;
	}

	updateBarColors(newTheme) {
		let count = 0;

		if (this.questionData.answers.length) {
			if (newTheme) {
				this.chartColorHelper.setSourceColor(newTheme.chartColor);
			}
			this.barColors.length = 0;
			this.barColors = this.chartColorHelper.getColorsTable(
				false,
				this.questionData.columns.length
			);
		}
	}

	getBarColor(index) {
		return this.colorFilter(this.barColors[index]);
	}

	showOthersForRowClick(index, forAttachment) {
		let others = [];
		let row = this.questionData.rows[index];

		if (row.hasOthers && row.otherIndexes && row.otherIndexes.length) {
			if (forAttachment) {
				return {
					row,
					others: true,
				};
			}
			this.modalService.openExtendedAnswersModal(this.questionData, {
				row,
				others: true,
			});
		}
	}

	getQuickFilterData(answers) {
		return {
			questionData: this.questionData,
			answerTitle: this.quickFilterGenerator.prepareTwoPartAnswerTitle(
				answers.row.title,
				answers.column.title
			),
			answers: answers.row.index,
			internalAnswers: answers.column.index,
		};
	}
}

export { AbstractQuestionMatrixController };
