import tpl from './TagsWrapper.html';
import { TagEvents } from '../events.tags';
import '../../../../scss/tags.scss';

class TagsWrapperController {
	constructor(i18n, verbatimTagsService, modalService, $scope, alertsService) {
		'ngInject';

		this.i18n = i18n;
		this.verbatimTagsService = verbatimTagsService;
		this.$uibModal = modalService.getUibModal();
		this.$scope = $scope;
		this.alertsService = alertsService;

		this.tagsList = [];

		this.tooltipText = this.i18n.__(
			'Stwórz tagi (kategorie) aby móc określić o czym mówi respondent. Prowadzisz badania satysfakcji? Stwórz tagi: Obsługa klienta, Cena, Produkt i zobacz na jaki temat i w jaki sposób wypowiada się Twój klient.',
			'tags'
		);
	}

	$onInit() {
		this.bindEvents();

		this.verbatimTagsService.getTagsListPromise().then((tags) => {
			this.tagsList = tags;
		});
	}

	addNewTag() {
		const tagCreator = this.$uibModal.open({
			component: 'tagCreator',
			size: '500',
		});

		tagCreator.result.then(
			(tag) => {
				this.tagsList.push(tag);
				this.sortAlphabetically();
			},
			() => {}
		);
	}

	bindEvents() {
		this.$scope.$on(TagEvents.removed, (event, data) => {
			const indexToRemove = this.tagsList.findIndex((tag) => {
				return Number(tag.id) === Number(data.id);
			});

			if (indexToRemove !== -1) {
				this.tagsList.splice(indexToRemove, 1);
			}
		});

		this.$scope.$on(TagEvents.updated, (event, data) => {
			const indexToUpdate = this.tagsList.findIndex((tag) => {
				return Number(tag.id) === Number(data.tag.id);
			});

			if (indexToUpdate !== -1) {
				this.tagsList[indexToUpdate] = data.tag;
				this.sortAlphabetically();
			}
		});
	}

	sortAlphabetically() {
		this.tagsList.sort((tag1, tag2) => {
			return tag1.title.localeCompare(tag2.title);
		});
	}
}

export const TagsWrapper = {
	template: tpl,
	controller: TagsWrapperController,
};
