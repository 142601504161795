import angular from 'angular';

class SortingManager {
	constructor(sortingType, $window, Observer) {
		'ngInject';

		this.$window = $window;
		this.observer = new Observer();
		this.types = sortingType.getTypes();

		this.state = this.initState();
	}

	static get storageKey() {
		return 'verbatim_sorting';
	}

	initState() {
		let sorting = this.$window.localStorage.getItem(SortingManager.storageKey);

		if (!sorting) {
			return { types: this.types, active: this.types[0] };
		}

		sorting = this.types.find((item) => {
			return item.type === sorting;
		});

		if (!sorting) {
			return { types: this.types, active: this.types[0] };
		}

		return { types: this.types, active: sorting };
	}

	changeSorting(sorting) {
		this.$window.localStorage.setItem(SortingManager.storageKey, sorting.type);
		this.state.active = sorting;

		this.observer.notify(angular.copy(this.state));
	}

	getState() {
		return angular.copy(this.state);
	}

	addObserver(observer) {
		return this.observer.addObserver(observer);
	}

	removeObserver(observer) {
		this.observer.removeObserver(observer);
	}
}

export { SortingManager };
