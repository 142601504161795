const tpl = require('./FilterCreatorSliderQuestion.html');

let FilterCreatorRangeQuestionController = require('./FilterCreatorRangeQuestion')
	.filterCreatorRangeQuestion.controller;

class FilterCreatorSliderQuestionController extends FilterCreatorRangeQuestionController {
	constructor($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper) {
		'ngInject';
		super($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper);

		this.isSlider = true;
	}

	$onInit() {
		super.$onInit();

		if (this.question && this.question.max) {
			this.data.rangeData = [];
			for (let i = 1; i < this.question.max + 1; i++) {
				this.data.rangeData.push({
					index: i,
					title: i,
				});
			}
		}

		this.selectedCondition = 1;
		this.ruleData.condition = 1;
	}
}

export const filterCreatorSliderQuestion = {
	template: tpl,
	controller: FilterCreatorSliderQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
