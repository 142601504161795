class Observer {
	constructor() {
		this.observersList = [];
	}

	addObserver(observer) {
		this.observersList.push(observer);

		return observer;
	}

	removeObserver(observer) {
		const index = this.observersList.indexOf(observer);

		if (index === -1) {
			return false;
		}

		this.observersList.splice(index, 1);
	}

	async notify(promiseData) {
		const data = await promiseData;

		this.observersList.forEach((observer) => {
			observer(data);
		});
	}
}

export { Observer };
