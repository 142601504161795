const tpl = require('./QuestionEmail.html');

class QuestionEmailController {
	constructor(i18n) {
		'ngInject';

		this.i18n = i18n;

		this.domainsTop = [];

		this.displayLimit = 6;
		this.ready = false;
	}

	getPopular() {
		if (
			this.questionData &&
			this.questionData.answers &&
			this.questionData.answers.length &&
			this.questionData.domains.length >= 3 &&
			this.questionData.fillCount > 6
		) {
			this.domainsTop = this.questionData.domains;
		}
	}

	$onInit() {
		this.getPopular();
		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	isReady() {
		return this.ready;
	}

	getQuickFilterData(answer) {
		return {
			questionData: this.questionData,
			answerTitle: answer.title,
			answers: answer.title,
		};
	}
}

export const questionEmail = {
	template: tpl,
	controller: QuestionEmailController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
	},
};
