const tpl = require('./ARQuestionSingle.html');

class QuestionDropdownController {
	constructor($filter, i18n) {
		'ngInject';

		this.colorFilter = $filter('colorFilter');
		this.ready = false;
		this.i18n = i18n;
	}

	$onInit() {
		this.ready = true;
		this.questionCtrl.setChild(this);
	}
	// $postLink() {
	// }
	isReady() {
		return this.ready;
	}

	getQuickFilterData(answer) {
		return {
			questionData: this.questionData,
			answerTitle: answer.title,
			answers: answer.index,
		};
	}
}

export const questionDropdown = {
	template: tpl,
	controller: QuestionDropdownController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
	},
	transclude: {
		chart: '?questionChart',
	},
};
