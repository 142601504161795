import angular from 'angular';

const tpl = require('./NewReportModal.html');
let { fileExporter } = require('../common/FileExporter');

let priv = {};
priv.values = {
	results: 'results',
	empty: 'empty',
	copy: 'copy',
};

class NewReportModalController extends fileExporter.controller {
	constructor(
		$scope,
		reportsService,
		reportHelper,
		alertsService,
		i18n,
		supportCRMService,
		$timeout,
		$state,
		surveyProviderService,
		segmentService
	) {
		'ngInject';
		super($scope, reportHelper, i18n);

		this.alertsService = alertsService;
		this.reportsService = reportsService;
		this.supportCRMService = supportCRMService;
		this.$timeout = $timeout;
		this.$state = $state;
		this.surveyProviderService = surveyProviderService;
		this.segmentService = segmentService;

		this.modalData = {};
		this.modalData.title = i18n.__('Stwórz nowy raport', 'reports');
		this.modalData.buttonText = i18n.__('Stwórz raport', 'reports');
		this.modalData.text = '<h3>' + i18n.__('Informacja', 'reports') + '</h3>';
		this.modalData.text +=
			'<p>' +
			i18n.__(
				'Wyniki zostaną przedstawione na stan z momentu kliknięcia w przycisk Eksportuj.',
				'reports'
			) +
			'</p>';

		this.$scope.newReportName = '';
		this.reportsSelect = null;
		this.loading = true;
		this.saved = false;

		this.EXPORT_FORMATS = [
			{
				format: priv.values.results,
				title: i18n.__('Stwórz z wyników', 'reports'),
				icon: 'report-aggregate',
				disabledTooltip: i18n.__('Brak pytań w projekcie', 'reports'),
			},
			{
				format: priv.values.empty,
				title: i18n.__('Stwórz pusty', 'reports'),
				icon: 'report-empty',
			},
			{
				format: priv.values.copy,
				title: i18n.__('Skopiuj raport', 'reports'),
				icon: 'report-copy',
				disabledTooltip: i18n.__('Nie masz żadnych raportów', 'reports'),
			},
		];
		this.exportFormat = this.EXPORT_FORMATS[0].format;

		this.extraClass = 'newReport';

		this.disabled = false;
		this.reports = [];
	}

	$onInit() {
		this.surveyProviderService.hasQuestionsPromise().then((data) => {
			if (!data) {
				this.EXPORT_FORMATS[0].disabled = true;
				if (this.exportFormat === this.EXPORT_FORMATS[0].format) {
					this.exportFormat = this.EXPORT_FORMATS[1].format;
				}
				this.$timeout(() => {
					this.$scope.$apply();
				});
			}
		});

		this.reportsService.getReportsListPromise(true, 1).then(
			(data) => {
				this.reports = angular.copy(data);
				this.reportsService.continouslyLoadReports(
					this.reports,
					(list) => {
						this.reports = angular.copy(list);
						// this.loading = true;
					},
					(count) => {
						// this.parseReportData();
						this.loading = false;
						this.$timeout(() => {
							this.$scope.$apply();
						});
						if (!count) {
							this.EXPORT_FORMATS[2].disabled = true;
						}
					}
				);
			},
			function error(data) {
				console.error(data);
			}
		);
	}

	downloadAction(val) {
		if (this.disabled || this.saved) {
			return;
		}

		this.saved = true;

		const handleError = () => {
			this.saved = false;
			this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem ', 'reports'));
		};

		const sendAnalyticsEvent = (reportType) => {
			this.segmentService.track('Dodano nowy raport', { Rodzaj: reportType });
		};

		this.newReportName = this.reportHelper.cleanUpReportTitle(this.$scope.newReportName);
		if (this.newReportName && val) {
			if (val === priv.values.results) {
				this.reportsService
					.addWholeSurveyToReportPromise({
						newReportName: this.newReportName,
						chapterIndex: undefined,
						filterId: null,
						newChapterName: '',
						partType: 3,
						reportId: '',
					})
					.then(
						(data) => {
							sendAnalyticsEvent('z wyników');
							parent.location.href = `/user#angular?r=/reports/generate/${data.id}`;
							this.close();
						},
						() => handleError()
					);
			} else if (val === priv.values.copy) {
				if (this.reportsSelect) {
					this.reportsService.copyReportPromise(this.reportsSelect.id, this.newReportName).then(
						(id) => {
							sendAnalyticsEvent('kopia');
							parent.location.href = `/user#angular?r=/reports/generate/${id}`;
							this.close();
						},
						() => handleError()
					);
				}
			} else if (val === priv.values.empty) {
				this.reportsService.createEmptyReportPromise(this.newReportName).then(
					(data) => {
						sendAnalyticsEvent('pusty');
						parent.location.href = `/user#angular?r=/reports/generate/${data.id}`;
						this.close();
					},
					() => handleError()
				);
			}
		}
	}

	isButtonDisabled() {
		if (this.$scope.newReportName !== '') {
			let tmp = this.reportHelper.cleanUpReportTitle(this.$scope.newReportName);

			if (this.exportFormat === 'copy') {
				return !(tmp.length > 0 && this.reportsSelect);
			}

			return !(tmp.length > 0);
		}

		return !this.$scope.newReportName;
	}

	close() {
		this.dismiss();
	}
}

export const newReportModal = {
	template: tpl,
	// template: fileExporter.template,
	controller: NewReportModalController,
	bindings: {
		resolve: '<',
		dismiss: '&',
	},
};
