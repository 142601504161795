import tpl from './DropdownMenu.html';
import '../../../scss/headerDropdownMenu.scss';

class DropdownMenuController {
	constructor($element, $scope) {
		'ngInject';

		this.isOpen = false;
		this.$element = $element;
		this.$scope = $scope;
	}

	toggleMenu() {
		this.isOpen = !this.isOpen;
	}

	$postLink() {
		document.addEventListener('click', (e) => {
			if (this.isOpen && !this.$element[0].contains(e.target)) {
				this.$scope.$apply(() => {
					this.isOpen = false;
				});
			}
		});
	}
}

export const dropdownMenu = {
	template: tpl,
	controller: DropdownMenuController,
	bindings: {
		triggerTitle: '@',
	},
	transclude: {
		trigger: '?trigger',
		menu: '?menu',
	},
};
