const tpl = require('./FilterCreatorRangeQuestion.html');

let { FilterCreatorAbstractQuestionController } = require('./FilterCreatorAbstractQuestion');

class FilterCreatorRangeQuestionController extends FilterCreatorAbstractQuestionController {
	constructor($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper) {
		'ngInject';

		super($scope, surveyProviderService, reportHelper, i18n);
		this.questionHelper = questionHelper;

		this.selectedAnswers = [];
		this.selectedRange = [];

		this.slider = {
			answerLabel: this.i18n.__('na skali', 'reports'),
			answerData: null,
			rangeLabel: this.i18n.__('pozycja', 'reports'),
			rangeData: null,
			conditionLabel: this.i18n.__('jest', 'reports'),
			conditionData: filtersHelper.getConditionsForSlider(),
			conditionDisabled: true,
		};
		this.ranking = {
			answerLabel: this.i18n.__('odpowiedź', 'reports'),
			answerData: null,
			rangeLabel: this.i18n.__('na pozycji', 'reports'),
			rangeData: null,
			conditionLabel: this.i18n.__('jest', 'reports'),
			conditionData: filtersHelper.getConditionsForRanking(),
		};
		this.matrix = {
			answerLabel: this.i18n.__('odpowiedź', 'reports'),
			answerData: null,
			rangeLabel: this.i18n.__('na skali', 'reports'),
			rangeData: null,
			conditionLabel: this.i18n.__('jest', 'reports'),
			conditionData: filtersHelper.getStandardConditions(),
		};
		this.rating = {
			answerLabel: this.i18n.__('odpowiedź', 'reports'),
			answerData: null,
			rangeLabel: this.i18n.__('ocena', 'reports'),
			rangeData: null,
			conditionLabel: this.i18n.__('jest', 'reports'),
			conditionData: filtersHelper.getStandardConditions(),
		};

		this.data = {};

		this.isSlider = false;
		this.isRanking = false;
		this.isMatrix = false;
		this.isRating = false;
	}

	$onInit() {
		super.$onInit();

		if (this.isSlider) {
			this.data = this.slider;

			this.data.answerData = this.question.answers;
			// this.data.answerData = this.questionData.answers;
		} else if (this.type === this.questionHelper.getTypes().ranking) {
			this.isRanking = true;
			this.data = this.ranking;

			this.data.answerData = this.question.answers;
			if (this.question.answers.length) {
				this.data.rangeData = [];
				for (let i = 1; i <= this.question.answers.length; i++) {
					this.data.rangeData.push({
						index: i,
						title: i,
					});
				}
			}
			// } else if (this.type === this.questionHelper.getTypes().matrixSingle || this.type === this.questionHelper.getTypes().matrixMulti) {
		} else if (
			this.type === this.questionHelper.getTypes().rating ||
			this.type === this.questionHelper.getTypes().matrixSingle ||
			this.type === this.questionHelper.getTypes().matrixMulti
		) {
			if (this.type === this.questionHelper.getTypes().rating) {
				this.isRating = true;
				this.data = this.rating;
			} else {
				this.isMatrix = true;
				this.data = this.matrix;
			}

			// this.data.answerData = this.question.answers;
			this.data.answerData = this.question.rows;
			this.data.rangeData = this.question.columns;

			if (this.isRating && this.data.answerData.length === 1) {
				// W pytaniu ocena musimy wziąć pod uwagę, że dla jednej odpowiedzi ukrywamy jej wybór na widoku.
				this.singleRate = true;
				// Jeśli pojedyncza ocena to przerobione pod to, żeby w zakresie była możliwość wyboru "Żadna zaznaczona"
				if (
					(this.data.rangeData[0] && !this.data.rangeData[0].none) ||
					!this.data.rangeData.length
				) {
					this.data.rangeData.unshift(this.noneAnswer);
				}
			} else if (
				(this.data.answerData[0] && !this.data.answerData[0].none) ||
				!this.data.answerData.length
			) {
				this.data.answerData.unshift(this.noneAnswer);
			}
		}

		if (this.data.conditionData && this.data.conditionData.length === 1) {
			this.selectedCondition = this.data.conditionData[0].type;
		}

		if (this.data.answerData && this.data.answerData.length === 1) {
			this.selectedAnswers = this.data.answerData[0].index;
			this.answerSelectChange(this.data.answerData[0]);
		}

		if (this.data.rangeData && this.data.rangeData.length === 1) {
			this.selectedRange[0] = this.data.rangeData[0].index;
		}

		if (this.selectedAnswers) {
			if (this.ruleData.internalAnswers && this.ruleData.internalAnswers.length) {
				this.selectedRange = this.ruleData.internalAnswers.slice(0).map((item) => Number(item));
				// console.log('jestjest', this.selectedRange);
			}

			if (this.ruleData.condition === 4 && this.singleRate) {
				this.nonSelectedForRange();
			}
		}
	}

	$onChanges(changes) {
		// console.log('onChanges', changes, changes.question.isFirstChange(), changes.ruleData.isFirstChange());
		if (changes.ruleData && changes.ruleData.isFirstChange()) {
			// console.log('ruleData isFirstChange');
			this.init();
			if (!this.ruleData.internalAnswers || !this.ruleData.internalAnswers.length) {
				this.ruleData.internalAnswers = [];
			}

			this.selectedCondition = this.ruleData.condition;

			this.validate();
		}
		if (changes.question) {
			if (!changes.question.isFirstChange()) {
				this.onQuestionChange();
			}

			this.selectedCondition = this.ruleData.condition;

			if (this.ruleData.answers.length) {
				this.selectedAnswers = Number(this.ruleData.answers[0]);
			} else {
				this.selectedAnswers = null;
			}

			if (this.isNoneSelected()) {
				// console.log('none', this.isNoneSelected(), this.ruleData);
				this.selectedAnswers = [this.noneAnswer];
				this.selectedCondition = null;
				this.noneSelected();
			}
		}
	}

	answerSelectChange(item, selected) {
		// console.log('answerSelectChange', item, selected, this.data.rangeData, this.isNoneSelected());
		this.creator.setEdited(true);
		if (item.none) {
			this.noneSelected();
			this.selectedRange = [];
			this.selectedCondition = null;
		} else if (item.index !== undefined) {
			if (this.isNoneSelected() && !this.singleRate) {
				this.ruleData.condition = null;
				this.selectedCondition = null;
			}
			this.ruleData.answers = Array.isArray(this.selectedAnswers)
				? this.selectedAnswers
				: [this.selectedAnswers];

			this.validate();
			this.creator.validate();
		}
	}

	rangeSelectChange(item, selected) {
		this.creator.setEdited(true);
		if (item.none) {
			this.nonSelectedForRange();
		} else if (item.index !== undefined) {
			if (this.isNoneSelected()) {
				this.removeNoneSelectedForRange();
			}
			this.ruleData.internalAnswers = this.selectedRange.map((item) =>
				Array.isArray(item) ? item[0] : item
			);

			this.validate();
			this.creator.validate();
		}
	}

	rangeSelectRemove(item, selected) {
		this.ruleData.internalAnswers = this.selectedRange.slice(0);
		this.creator.setEdited(true);
		this.validate();
		this.creator.validate();
	}

	conditionSelectChange(item, selected) {
		this.ruleData.condition = selected;
		this.validate();
		this.creator.setEdited(true);
		this.creator.validate();
	}

	rangeOptionClick(index, item, multiSelectController, ev, isLast) {
		this.creator.setEdited(true);

		if (this.selectedRange.length && this.selectedRange.indexOf(item.index) !== -1) {
			this.selectedRange.splice(this.selectedRange.indexOf(item.index), 1);
			this.ruleData.internalAnswers = this.selectedRange.slice(0);

			ev.stopPropagation();
		}

		if (!this.selectedRange.length || this.ruleData.condition === null) {
			this.valid = false;
			this.creator.validate();
		}
	}

	validate() {
		let valid =
			this.ruleData.answers &&
			this.ruleData.answers.length &&
			this.ruleData.internalAnswers &&
			this.ruleData.internalAnswers.length &&
			(this.ruleData.condition || this.ruleData.condition === 0);

		this.valid = valid;
		if (this.isNoneSelected()) {
			this.valid = true;
		}
	}

	nonSelectedForRange() {
		this.noneSelected();
		this.selectedRange = [0];
	}

	removeNoneSelectedForRange() {
		this.removeNone();
		this.selectedRange.splice(0, 1);
		this.selectedAnswers = this.data.answerData[0].index;
		this.ruleData.answers = [1];
	}
}

export const filterCreatorRangeQuestion = {
	template: tpl,
	controller: FilterCreatorRangeQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
