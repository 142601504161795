import Template from './ReportsLabel.html';

require('../analyzeFilters/filtersLabel/filtersLabel.scss');

class ReportsLabelController {
	constructor(i18n) {
		'ngInject';

		this.i18n = i18n;
	}
}

export const ReportsLabelComponent = {
	template: Template,
	controller: ReportsLabelController,
};
