import angular from 'angular';
import { CrossTablesModule } from './cross-tables/cross-tables.module';
import { RankingsModule } from './rankings/rankings.module';
import { TrendsModule } from './trends/trends.module';

export const AnalysisModule = angular.module('analysis', [
	CrossTablesModule,
	RankingsModule,
	TrendsModule,
]).name;
