function emptyRowsFilter() {
	return function (rows, isFilterActive, totalRows) {
		if (!rows) {
			return;
		}

		return rows.map(function (row, index) {
			if (!isFilterActive || !totalRows) {
				row.isEmpty = false;
			} else {
				row.isEmpty =
					typeof totalRows[index] === 'object'
						? totalRows[index].count === 0
						: totalRows[index] === 0;
			}

			return row;
		});
	};
}

export { emptyRowsFilter };
