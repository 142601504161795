const tpl = require('./FilterCreatorTimeRule.html');
const angular = require('angular');

import flatpickr from 'flatpickr';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { Slovak } from 'flatpickr/dist/l10n/sk';
import { Czech } from 'flatpickr/dist/l10n/cs';
require('flatpickr/dist/themes/material_green.css');

class FilterCreatorTimeRuleController {
	constructor(filtersService, $scope, $element, $rootScope, i18n, $timeout, langService) {
		'ngInject';
		this.filtersService = filtersService;
		this.$scope = $scope;
		this.$element = $element;
		this.$rootScope = $rootScope;
		this.i18n = i18n;
		this.$timeout = $timeout;
		this.langService = langService;

		this.fromDate = null;
		this.toDate = null;

		this.defaultRule = '0';

		this.dom = { main: $($element) };

		this.calendarInstance = null;
		this.datepickerClass = 'timeRules__datepickers';
		this.calendarConfig = {
			mode: 'range',
			dateFormat: 'd/m/Y',
		};

		this.valid = true;

		const lang = this.langService.getLang();

		this.setCalendarLang(lang);
		this.setCalendarDateFormat(lang);
	}

	setCalendarLang(lang) {
		const LANGS = {
			pl: Polish,
			cs: Czech,
			sk: Slovak,
		};

		if (['pl', 'cs', 'sk'].includes(lang)) {
			flatpickr.localize(LANGS[lang]);
		}
	}

	setCalendarDateFormat(lang) {
		if (lang === 'cs' || lang === 'sk') {
			this.calendarConfig.dateFormat = 'd.m.Y';
		}
	}

	$onInit() {
		this.fromDate = this.ruleDataFrom === null ? new Date() : new Date(this.ruleDataFrom * 1000);
		this.toDate = this.ruleDataTo === null ? new Date() : new Date(this.ruleDataTo * 1000);

		this.creator.addTimeRuleController(this);

		this.setMinDate();

		const additionalConfigAttrs = {
			defaultDate: [this.getFormattedDate(this.fromDate), this.getFormattedDate(this.toDate)],
			onChange: (selectedDates) => {
				this.fromDate = selectedDates[0];

				if (selectedDates.length === 2) {
					const formattedFromDate = this.getFormattedDate(this.fromDate);
					const formattedToDate = this.getFormattedDate(selectedDates[1]);

					if (formattedFromDate !== formattedToDate) {
						this.toDate = selectedDates[1];
						this.handleChangeDate();
					}
				}

				this.$scope.$digest();
			},
		};

		const extendedConfig = Object.assign(this.calendarConfig, additionalConfigAttrs);

		this.dom.calendarElement = this.dom.main.find('input.calendar');
		this.calendarInstance = flatpickr(this.dom.calendarElement, extendedConfig);

		this.ruleDataPeriod = String(this.ruleDataPeriod);
		this.handleChangePeriod(true);
	}

	setMinDate() {
		if (!this.$rootScope.currentSurveyData.publishedDate) return;

		const surveyStartDate = new Date(this.$rootScope.currentSurveyData.publishedDate * 1000);

		this.calendarConfig.minDate = this.getFormattedDate(surveyStartDate);
	}

	getFormattedDate(dateObject) {
		return flatpickr.formatDate(dateObject, this.calendarConfig.dateFormat);
	}

	$onDestroy() {
		this.creator.removeTimeRuleController(this);
		this.calendarInstance.destroy();
	}

	handleChangeDate() {
		this.ruleDataFrom = (Number(this.fromDate) / 1000) | 0;
		this.ruleDataTo = (Number(this.toDate) / 1000) | 0;

		this.valid = true;
		this.creator.onChangeTimeRule();
	}

	handleChangeRelativeDate() {
		this.valid = true;
		this.creator.onChangeTimeRule();
	}

	handleChangePeriod(fromInit = false) {
		this.valid = true;

		if (Number(this.ruleDataPeriod) !== Number(this.filtersService.timePeriodValues.relative)) {
			this.ruleDataRelative = null;

			if (
				Number(this.ruleDataPeriod) === this.filtersService.timePeriodValues.fragment &&
				(!this.ruleDataFrom || !this.ruleDataTo)
			) {
				this.valid = false;
			}

			if (Number(this.ruleDataPeriod) === this.filtersService.timePeriodValues.all) {
				this.valid = false;
			}
		} else if (!this.ruleDataRelative && this.ruleDataRelative !== 0) {
			this.valid = false;
		}

		if (Number(this.ruleDataPeriod) === Number(this.filtersService.timePeriodValues.relative)) {
			setTimeout(() => {
				let s = this.$element.find('.ui-select-container').scope();
				if (!fromInit) {
					this.$timeout(() => {
						s.$select.open = true;
					});
				}
			}, 50);
		}

		this.creator.onChangeTimeRule();
	}

	setRelativeFocus() {
		this.$scope.$broadcast('OpenRelative');
	}

	getDatepickerClass() {
		return this.datepickerClass;
	}

	isValid() {
		return this.valid;
	}

	removeQuestionRule() {
		this.ruleDataPeriod = this.defaultRule;
		this.handleChangePeriod();
	}
}

export const filterCreatorTimeRule = {
	template: tpl,
	controller: FilterCreatorTimeRuleController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleDataPeriod: '=',
		ruleDataFrom: '=',
		ruleDataTo: '=',
		ruleDataRelative: '=',
	},
};
