const tpl = require('./DistributionChartModal.html');
const highcharts = require('highcharts');

class DistributionChartModalController {
	constructor($element, chartColorHelper, questionHelper, i18n, surveyProviderService, $scope) {
		'ngInject';

		this.$element = $element;
		this.chartColorHelper = chartColorHelper;
		this.questionHelper = questionHelper;
		this.i18n = i18n;
		this.surveyProviderService = surveyProviderService;
		this.$scope = $scope;

		this.questionIndex = null;
		this.answerIndex = null;
		this.questionData = null;
		this.others = null;
		this.isSlider = false;

		this.answer = null;
		this.count = null;
		this.chart = null;
		this.loading = true;

		this.displayDelayId = null;
		this.lineChartTreshold = 20;
		this.isSlider = false;

		this.chartConfig = {
			chart: {
				type: 'column',
				// type: 'line',
				boderWidth: 0,
				backgroundColor: 'transparent',
			},
			plotOptions: {
				column: {
					dataLabels: {
						enabled: false,
					},
				},
				line: {
					dataLabels: {
						enabled: false,
					},
				},
				series: {
					borderRadius: 4,
					borderWidth: 0,
				},
			},
			xAxis: {
				visible: true,
				// allowDecimals: false,
				labels: {
					enabled: true,
					style: {
						color: '#aaa',
						fontSize: '12px',
						fontWeight: '600',
					},
				},
				title: {
					enabled: true,
					text: i18n.__('Odpowiedzi', 'reports'),
				},
				crosshair: false,
				tickWidth: 0,
				lineWidth: 2,
				lineColor: '#777',
			},
			yAxis: {
				allowDecimals: false,
				labels: {
					enabled: true,
				},
				title: {
					enabled: true,
					text: '',
				},
				visible: true,
			},
			colors: chartColorHelper.getColorsTableForChart(),
			legend: {
				enabled: false,
			},
			title: {
				text: undefined,
			},
			tooltip: {
				enabled: false,
			},
			credits: {
				enabled: false,
			},
		};
	}

	$onInit() {
		this.questionIndex = this.resolve.questionIndex;
		this.answerIndex = this.resolve.answerIndex;
		this.questionData = this.resolve.questionData;
		// this.isSlider = this.questionHelper.isSlider(this.questionData);

		this.answer = this.questionData.answers[this.answerIndex];
		this.chartConfig.series = [{ data: [] }];
		this.chartConfig.xAxis.categories = [];

		this.surveyProviderService
			.getDistributionForQuestionPromise(this.questionData.id, this.answer.index)
			.then(
				(data) => {
					this.others = data;
					this.count = this.others.length;
					if (this.count >= this.lineChartTreshold) {
						this.chartConfig.chart.type = 'line';
					}
					this.others.forEach((item, i) => {
						this.chartConfig.series[0].data.push({
							id: i,
							name: item.title,
							y: Number(item.count),
						});
						this.chartConfig.xAxis.categories.push(item.title);
					});

					let type = this.questionData.type;
					let types = this.questionHelper.getTypes();
					// if (type === types.slider || type === types.ranking) {
					//     this.chartConfig.yAxis.title.text = this.i18n.__('Średnia pozycja', 'reports');
					// } else if (type === types.points) {
					// this.chartConfig.yAxis.title.text = this.i18n.__('Średni wynik', 'reports');
					// }
					this.chartConfig.yAxis.title.text = this.i18n.__('Liczba wypełnień', 'reports');
					if (type === types.slider) {
						this.isSlider = true;
						this.chartConfig.xAxis.title.text = this.i18n.__('Pozycja suwaka', 'reports');
					}

					this.loading = false;
					this.drawChart();
				},
				(errData) => {
					this.loading = false;
				}
			);
	}

	$onDestroy() {
		clearTimeout(this.displayDelayId);
		this.displayDelayId = null;
	}

	drawChart() {
		// console.log('drawChart', this.chartConfig, this.loading);
		this.displayDelayId = setTimeout(() => {
			this.chart = highcharts.chart(this.$element[0].querySelector('.chart'), this.chartConfig);
			this.$scope.$apply();
		}, 100);
	}
}

export const distributionChartModal = {
	template: tpl,
	controller: DistributionChartModalController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
