import tpl from './acl-label.html';

require('./acl-label.scss');

class AclLabelController {
	constructor(i18n, aclManager) {
		'ngInject';

		this.i18n = i18n;
		this.aclManager = aclManager;
	}

	isLabelVisible() {
		return !this.aclManager.isFeatureEnabled(this.feature);
	}
}

export const AclLabelComponent = {
	template: tpl,
	controller: AclLabelController,
	bindings: {
		feature: '<',
		inline: '<',
	},
	transclude: {
		body: '?aclBody',
	},
};
