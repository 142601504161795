import styled from 'styled-components';

const PaginationInput = styled.input.attrs(() => ({
	type: 'tel',
}))`
	width: 50px;
	padding: 5px 10px;
	border-radius: 4px;
	border: 1px solid #cacaca;
	font-size: 14px;
	font-weight: 600;
	line-height: 14px;
`;

export default PaginationInput;
