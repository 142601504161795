const routingConfig = ($stateProvider, $urlRouterProvider, $locationProvider, $httpProvider) => {
	'ngInject';

	$locationProvider.html5Mode(false);
	$urlRouterProvider.otherwise('/');

	$httpProvider.defaults.useXDomain = true;

	$stateProvider.state({
		name: 'app',
		url: '/',
		redirectTo: 'reports',
	});
};

export { routingConfig };
