import angular from 'angular';
import { ControlsModule } from './controls/controls.module';
import { AnalysisModule } from './analysis/analysis.module';
import { ChartsModule } from './charts/charts.module';
import { UpsellModule } from './upsell/upsell.module';
import { AclModule } from './acl/acl.module';
import { EmptyScreenModule } from './empty-screen/empty-screen.module';
import { BannersModule } from './banners/banners.module';

export const ComponentsModule = angular.module('app.components', [
	ControlsModule,
	AnalysisModule,
	ChartsModule,
	UpsellModule,
	AclModule,
	EmptyScreenModule,
	BannersModule,
]).name;
