import tpl from './cross-table-chart.html';
import jquery from 'jquery';
import highcharts from 'highcharts';

const Entities = require('html-entities').AllHtmlEntities;

class CrossTableChartController {
	constructor($scope, $element, chartColorHelper, i18n, $filter, crossTablesService) {
		'ngInject';

		this.$element = $element;
		this.chartColorHelper = chartColorHelper;
		this.$scope = $scope;
		this.colorFilter = $filter('colorFilter');
		this.crossTablesService = crossTablesService;
		this.i18n = i18n;

		this.barColors = null;

		this.chartConfig = {
			chart: {
				type: 'column',
				animation: false,
				height: 400,
				spacingBottom: 20,
				spacingRight: 25,
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			xAxis: {
				title: {
					text: '',
				},
				labels: {
					style: {
						color: '#032c3e',
						fontSize: '12px',
					},
				},
				crosshair: false,
				tickWidth: 0,
				lineWidth: 2,
				lineColor: '#777',
				categories: [],
			},
			yAxis: {
				allowDecimals: false,
				lineWidth: 2,
				lineColor: '#777',
				labels: {
					style: {
						color: '#032c3e',
						fontSize: '12px',
					},
				},
				title: {
					text: '',
				},
				endOnTick: false,
				stackLabels: {
					enabled: false,
				},
			},
			legend: {
				enabled: true,
				symbolRadius: 2,
				margin: 30,
				itemStyle: {
					width: '120px',
				},
			},
			plotOptions: {
				column: {
					stacking: 'percent',
					borderWidth: 0,
					dataLabels: {
						enabled: false,
					},
				},
				bar: {
					stacking: 'normal',
				},
				series: {
					animation: !this.external,
				},
			},
			tooltip: {
				useHTML: true,
				pointFormat:
					'<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
			},
		};

		this.chart = null;
	}

	$onInit() {
		this.$scope.$parent.$ctrl.chartCtrl = this;
	}

	$onDestroy() {
		this.chartColorHelper.reset();
	}

	drawChart(crossTableResult) {
		this.loading = true;

		this.crossTableResult = crossTableResult;
		this.updateBarColors();
		this.chartConfig.series = this.createSeries();
		this.chart = highcharts.chart(jquery(this.$element).find('.chart__main')[0], this.chartConfig);
		this.loading = false;
	}

	$onChanges(changes) {
		if (changes && this.crossTableResult) {
			this.drawChart(this.crossTableResult);
		}
	}

	createSeries() {
		const series = [];
		const entities = new Entities();

		this.chartConfig.chart.type = this.type.chart;
		this.chartConfig.plotOptions[this.type.chart].stacking = this.format.stacking;

		if (this.crossTableResult.rows && this.crossTableResult.columns) {
			this.chartConfig.xAxis.categories = [];

			const rowsLength = this.crossTableResult.rows.length;
			for (let j = 0; j < rowsLength; j++) {
				const row = this.crossTableResult.rows[j];

				if (row) {
					const text = row.shouldBeTranslated ? this.i18n.__(row.title, 'reports') : row.title;
					const rowTitle = entities.decode(text);
					this.chartConfig.xAxis.categories.push(rowTitle);
				}
			}

			const columnLength = this.crossTableResult.columns.length;
			for (let i = 0; i < columnLength; i++) {
				const column = this.crossTableResult.columns[i];

				if (column) {
					const text = column.shouldBeTranslated
						? this.i18n.__(column.title, 'reports')
						: column.title;

					let title = entities.decode(text);

					let single = {
						name: title,
						data: [],
						color: this.colorFilter(this.barColors[i]),
					};
					series.unshift(single);

					for (let j = 0; j < rowsLength; j++) {
						if (this.crossTableResult.answers[j]) {
							single.data.push(Number(this.crossTableResult.answers[j][i].count));
						}
					}
				}
			}
		}

		let rowsCount = this.crossTableResult.rows.length;
		let rowBarCount = this.crossTableResult.columns.length;
		let height = rowsCount * rowBarCount * 15;

		if (height > 1200) {
			height = 1200;
		} else if (height < 400) {
			height = 400;
		}

		this.chartConfig.chart.height = height;

		return series;
	}

	updateBarColors() {
		if (this.crossTablesService.isNpsSet(this.crossTableResult.sets[1].questionType)) {
			this.barColors = jquery.map(this.chartColorHelper.getNPSColors(), (value, index) => {
				return [value];
			});
		} else {
			this.barColors = this.chartColorHelper.getColorsTable(
				false,
				this.crossTableResult.columns.length
			);
		}
	}

	updateThemeColor(newTheme) {
		this.chartColorHelper.setSourceColor(newTheme.chartColor);
	}
}

export const CrossTableChartComponent = {
	template: tpl,
	controller: CrossTableChartController,
	bindings: {
		format: '<',
		type: '<',
	},
};
