import angular from 'angular';

require('ng-tags-input');
require('ng-tags-input/build/ng-tags-input.min.css');

import { verbatimParameters } from './verbatimParameters/VerbatimParameters';
import { verbatimElementTags } from './verbatimElement/VerbatimElementTags';
import { verbatimElementQuestions } from './verbatimElement/VerbatimElementQuestions';
import { verbatimElementInfo } from './verbatimElement/VerbatimElementInfo';
import { verbatimElement } from './verbatimElement/VerbatimElement';
import { VerbatimEmptyScreenComponent } from './VerbatimEmptyScreen/VerbatimEmptyScreen.component';
import { verbatimParametersElement } from './verbatimParameters/VerbatimParametersElement';
import { VerbatimKeyboard } from './VerbatimKeyboard';
import { SortingType } from './sorting/SortingType';
import { SortingManager } from './sorting/SortingManager';
import { PostMessageService } from '../services/PostMessageService';

angular
	.module('app.verbatims', ['ngTagsInput'])
	.service('verbatimKeyboard', VerbatimKeyboard)
	.service('sortingType', SortingType)
	.service('sortingManager', SortingManager)
	.service('postMessageService', PostMessageService)
	.component('verbatimElement', verbatimElement)
	.component('verbatimElementInfo', verbatimElementInfo)
	.component('verbatimElementQuestions', verbatimElementQuestions)
	.component('verbatimElementTags', verbatimElementTags)
	.component('verbatimParameters', verbatimParameters)
	.component('verbatimParametersElement', verbatimParametersElement)
	.component('verbatimEmptyScreen', VerbatimEmptyScreenComponent);
