const tpl = require('./QuestionRating.html');
let { AbstractQuestionMatrixController } = require('./AbstractQuestionMatrixController');

class QuestionRatingController extends AbstractQuestionMatrixController {
	constructor(
		$scope,
		chartColorHelper,
		modalService,
		$filter,
		questionHelper,
		i18n,
		quickFilterGenerator
	) {
		'ngInject';

		super(chartColorHelper, modalService, $filter, questionHelper, i18n);

		this.$scope = $scope;
		this.quickFilterGenerator = quickFilterGenerator;

		this.matrixType = this.types.rating;
	}

	$onChanges(diff) {
		if (diff.questionData) {
			// do smth
		}
	}

	getQuickFilterData(answers) {
		return {
			questionData: this.questionData,
			answerTitle: this.quickFilterGenerator.prepareTwoPartAnswerTitle(
				answers.row.title,
				answers.column.title
			),
			answers: answers.row.index,
			internalAnswers: answers.column.index,
		};
	}
}

export const questionRating = {
	template: tpl,
	controller: QuestionRatingController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
