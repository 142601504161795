const tpl = require('./ShowOtherAnswersModal.html');

const { AbstractPaginationManager } = require('./AbstractPaginationManager.js');

class ShowOtherAnswersModalController extends AbstractPaginationManager {
	constructor(
		$scope,
		i18n,
		questionHelper,
		surveyProviderService,
		modalService,
		$rootScope,
		$timeout
	) {
		'ngInject';

		super(surveyProviderService, modalService, i18n, questionHelper);
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.i18n = i18n;
		this.questionHelper = questionHelper;
		this.surveyProviderService = surveyProviderService;
		this.modalService = modalService;
		this.$timeout = $timeout;

		this.isRanking = false;
		this.isSimple = false;
		this.isMatrix = false;
		this.isMatrixOpen = false;
		this.isNPS = false;
		this.isRating = false;
		this.isDate = false;
		this.isEmail = false;
		this.isNumber = false;

		this.showQuestionNumbers = true;

		this.inReport = false;

		this.mostPopular = {};

		this.pageLimit = 10;
	}

	$onInit() {
		if (this.attachment) {
			this.attachment.addChild(this);
			this.inReport = true;
		}

		this.questionIndex = this.resolve.questionIndex;
		this.answerIndex = this.resolve.answerIndex;
		this.questionData = this.resolve.questionData;
		this.answer = this.questionData ? this.questionData.answers[this.answerIndex] : null;
		this.row = this.resolve.row;
		this.hasOthers = this.resolve.hasOthers;
		this.matrixData = this.resolve.matrixData;

		this.$scope.paginationID = 'ShowOtherAnswersModal' + this.answerIndex;

		this.filterId =
			this.attachment && this.attachment.questionCtrl.reportPart
				? this.attachment.questionCtrl.reportPart.part.filter
				: NaN;
		this.globalFilter = isNaN(this.filterId);

		this.isRanking = this.questionHelper.isRanking(this.questionData);
		this.isSimple =
			this.questionHelper.isSingle(this.questionData) ||
			this.questionHelper.isMultiple(this.questionData);
		this.isNPS = this.questionHelper.isNPS(this.questionData);
		this.isMatrix = this.questionHelper.isMatrix(this.questionData);
		this.isMatrixOpen = this.questionHelper.isMatrixOpen(this.questionData);
		this.isRating = this.questionHelper.isRating(this.questionData);
		this.isDate = this.questionHelper.isDate(this.questionData);
		this.isEmail = this.questionHelper.isEmail(this.questionData);
		this.isNumber = this.questionHelper.isNumber(this.questionData);

		this.bindType();

		this.mostPopular = this.questionHelper.getMostPopularAnswer(this.questionData);

		this.bindQuickFilterGenerating();
	}

	$postLink() {
		if (this.$scope.$root.currentSurveyData) {
			this.showQuestionNumbers = this.$scope.$root.currentSurveyData.questionNumbers;
		}
	}

	bindType() {
		if (this.isSimple || this.isNPS) {
			this.surveyProviderService
				.getOthersForQuestionPromise(
					this.questionData.id,
					this.answer.index,
					1,
					this.filterId,
					this.globalFilter,
					this.pageLimit
				)
				.then(
					(data) => {
						if (data && data.total) {
							// tymczasowa naprawa this.$scope.$apply();
							// url: https://devstyle.pl/2014/02/06/angularjs-digest-already-in-progress/
							this.$timeout(() => {
								this.pagination.total = data.total;
								this.answers = data.items;

								this.paginationInit(this.answers, {
									other: true,
									perPage: this.pageLimit ? this.pageLimit : data.count,
									total: data.total,
									isSimple: this.isSimple,
									isNPS: this.isNPS,
									isMatrixOpen: this.isMatrixOpen,
									isRating: this.isRating,
									answerIndex: this.answer.index,
								});
							});
						}
					},
					() => {}
				);
		} else if (this.isMatrix || this.isRating) {
			let row = this.row;
			let col = { index: this.answerIndex + 1 };

			if (this.matrixData && (this.isMatrixOpen || !this.hasOthers)) {
				row = this.matrixData.row;
				col = this.matrixData.column;
			}

			this.surveyProviderService
				.getOthersForMatrixQuestionPromise(
					this.questionData.id,
					row,
					col,
					this.hasOthers,
					1,
					this.filterId,
					this.globalFilter,
					this.pageLimit
				)
				.then(
					(data) => {
						if (data && data.total) {
							this.$timeout(() => {
								this.pagination.total = data.total;
								this.answers = data.items;

								this.paginationInit(this.answers, {
									other: this.hasOthers,
									perPage: this.pageLimit ? this.pageLimit : data.count,
									total: data.total,
									isSimple: this.isSimple,
									isNPS: this.isNPS,
									isMatrixOpen: this.isMatrixOpen,
									isMatrix: this.isMatrix,
									isRating: this.isRating,
									rowIndex: row.index,
									columnIndex: col.index,
								});
							});
						}
					},
					() => {}
				);
		} else {
			let opts = {};

			if (this.surveyProviderService.filterId) {
				opts.filterId = this.surveyProviderService.filterId;
			}

			if (this.pageLimit) {
				opts.perPage = this.pageLimit;
			}

			this.answers = this.questionData.answers.slice(0);
			this.paginationInit(this.answers, opts);

			if (this.isRanking) {
				this.pagination.total = this.answers.length;
				this.pagination.loading = false;
			} else if (this.answers.length < this.pagination.total) {
				let start = this.answers.length;
				let filler = [];

				this.answers.length = this.pagination.total;
				filler.length = this.pagination.total - this.answers.length;
				this.answers.fill({ count: 0 }, start);
				filler.fill({ count: 0 });
				this.answers = this.answers.concat(filler);
			} else {
				this.pagination.loading = false;
			}
		}
	}

	isQuickFilterAvailable() {
		if (!this.questionData) {
			return false;
		}

		if (this.attachment) {
			return false;
		}

		return (
			this.questionHelper.isNumber(this.questionData) ||
			this.questionHelper.isEmail(this.questionData) ||
			this.questionHelper.isDate(this.questionData) ||
			this.questionHelper.isText(this.questionData)
		);
	}

	bindQuickFilterGenerating() {
		this.$rootScope.$on('CloseCompletionsModal', () => {
			this.close();
		});
	}

	getQuickFilterData(answer) {
		return {
			questionData: this.questionData,
			answerTitle: answer.title,
			answers: answer.title,
		};
	}
}

export const showOtherAnswersModal = {
	template: tpl,
	controller: ShowOtherAnswersModalController,
	require: {
		attachment: '?^reportAttachment',
	},
	bindings: {
		resolve: '<',
		close: '&',
		noPagination: '<',
		simpleView: '<',
	},
};
