import Template from './LegacyBanner.html';
require('./LegacyBanner.scss');

class LegacyBannerController {
	constructor(i18n, langService, segmentService) {
		'ngInject';

		this.i18n = i18n;
		this.langService = langService;
		this.segmentService = segmentService;
	}

	onClick(event) {
		event.preventDefault();

		if (this.linkEventName) {
			this.segmentService.track(this.linkEventName);
		}

		const link = event.target.getAttribute('href');

		window.parent.open(link, '_blank');
	}
}

export const LegacyBannerComponent = {
	template: Template,
	controller: LegacyBannerController,
	bindings: {
		text: '@',
		link: '@',
		linkText: '@',
		linkEventName: '@',
	},
	transclude: true,
};
