const unauthorizedInterceptor = (Restangular) => {
	'ngInject';

	Restangular.addResponseInterceptor((data, operation, what, url, response, deferred) => {
		if (response.status === 401) {
			parent.window.location.href = '/info/link-login';
		}

		return data;
	});
};

export { unauthorizedInterceptor };
