import angular from 'angular';

import { MultipleSelectComponent } from './multiple-select/multiple-select.component';
import { RangePickerComponent } from './range-picker/range-picker.component';
import { SingleSelectComponent } from './single-select/single-select.component';
import { SingleDropdownComponent } from './single-dropdown/single-dropdown.component';
import { GroupedSelectComponent } from './grouped-select/grouped-select.component';
import { ngIndeterminateDirective } from './directives/ng-indeterminate.directive';
import { ngClickOutsideDirective } from './directives/ng-click-outside.directive';
import { ngEscapeDirective } from './directives/ng-escape.directive';

export const ControlsModule = angular
	.module('controls', [])
	.directive('ngClickOutside', ngClickOutsideDirective)
	.directive('ngIndeterminate', ngIndeterminateDirective)
	.directive('ngEscape', ngEscapeDirective)
	.component('multipleSelect', MultipleSelectComponent)
	.component('singleSelect', SingleSelectComponent)
	.component('rangePicker', RangePickerComponent)
	.component('singleDropdown', SingleDropdownComponent)
	.component('groupedSelect', GroupedSelectComponent).name;
