class RespondentService {
	constructor(surveyProviderService, Restangular, apiHelper) {
		'ngInject';
		let self = this;

		this.apiHelper = apiHelper;

		this.labels = null;
		this.surveyId = null;
		this.labelRestPromise = null;

		this.getLabelsPromise = () =>
			new Promise(function (resolve, reject) {
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					// console.log('labels', self.labels, surveyId !== self.surveyId || !self.labels, self.labelRestPromise);

					if (surveyId !== self.surveyId || !self.labels) {
						if (
							!self.labelRestPromise ||
							(self.labelRestPromise && self.labelRestPromise.$$state.status !== 0) ||
							surveyId !== self.surveyId
						) {
							self.labelRestPromise = Restangular.one(String(surveyId)).one('filter-labels').get();
						}
						self.surveyId = surveyId;

						// Restangular.one(surveyId).one('filter-labels').get().then(function done2(data) {
						self.labelRestPromise.then(
							function done2(data) {
								let result = [];
								let dataTmp = data.plain();

								for (var key in dataTmp) {
									if (Object.prototype.hasOwnProperty.call(dataTmp, key)) {
										let tmp = {
											key,
											val: dataTmp[key],
										};
										tmp[key] = dataTmp[key];
										result.push(tmp);
									}
								}

								self.labels = result;
								resolve(self.labels);
								return self.labels;
							},
							function error(data) {
								console.error(data);
								self.apiHelper.handleDataError(data);
								reject(data);
							}
						);
					} else {
						resolve(self.labels);
						self.apiHelper.handleDataError();
						return self.labels;
					}
				});
			});
	}
}

export default RespondentService;
