import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
	padding: 5px 10px;
	border: 1px solid #cacaca;
	border-radius: 4px;
	background-color: transparent;
	font-size: large;
	color: #009b85;
	line-height: 18px;

	&:active {
		box-shadow: inset 1px 1px 2px #d8d8d8;
	}

	&:nth-child(1) {
		border-right: none;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&:nth-child(2) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
`;

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const MyComponent = ({ onDecrease, onIncrease }) => {
	return (
		<Container>
			<Button onClick={onDecrease}>
				<i className="fa fa-chevron-left" aria-hidden="true"></i>
			</Button>
			<Button onClick={onIncrease}>
				<i className="fa fa-chevron-right" aria-hidden="true"></i>
			</Button>
		</Container>
	);
};

MyComponent.propTypes = {
	onDecrease: PropTypes.func,
	onIncrease: PropTypes.func,
};

export default MyComponent;
