const tpl = require('./FiltersList.html');

require('../../scss/question.scss');
require('../../scss/filters.scss');

class FiltersListController {
	constructor(
		filtersService,
		$scope,
		modalService,
		alertsService,
		i18n,
		surveyProviderService,
		aclManager
	) {
		'ngInject';

		this.filtersService = filtersService;
		this.$scope = $scope;
		this.$rootScope = $scope.$root;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.surveyProviderService = surveyProviderService;
		this.aclManager = aclManager;
		this.i18n = i18n;

		this.filters = {
			defaultApplied: [],
			available: [],
		};
		this.deletedFilters = 0;

		this.isLoading = true;
		this.pageLoaded = 0;
		this.nothingToLoad = false;
		this.questionCount = 0;
		this.clickDisabled = false;

		this.setFiltersText = this.i18n.__(
			'Ustaw filtry i zobacz informacje, które najbardziej Cię interesują. Filtr możesz ustawić na podstawie odpowiedzi respondenta albo jego etykiety. Jeśli Twoi respondenci są podzieleni na regiony albo działy, stwórz takie filtry i dodaj Alert spełniający taki warunek.',
			'reports'
		);

		this.deprecatedFunctionText = this.i18n.__(
			'Dodawanie nowych analiz w wygaszanych funkcjach (Filtry, Kreator raportów, Tabele krzyżowe, Rankingi, Trendy) nie jest już możliwe. Zapraszamy do nowego modułu Wyników.',
			'user'
		);
	}

	$onInit() {
		this.fetchFilters();
		this.bindEvents();
	}

	bindEvents() {
		this.$scope.$on('SurveyChanged', () => {
			this.filters = {
				defaultApplied: [],
				available: [],
			};
			this.pageLoaded = 0;

			this.fetchFilters();
		});

		this.$scope.$on('filterCreated', (event, data) => {
			this.filters.available.unshift(data.filterData);
			this.deletedFilters -= this.deletedFilters > 0 ? 1 : 0;
			this.filtersService.invalidate({ onlyBasic: true });
			this.alertsService.success(this.i18n.__('Dodano filtr', 'reports'));
		});

		this.$scope.$on('filterDeleted', (event, data) => {
			const index = this.filters.available.findIndex(
				(filter) => Number(filter.id) === Number(data.filterData.id)
			);

			this.filters.available.splice(index, 1);
			this.deletedFilters += 1;
			this.alertsService.success(this.i18n.__('Filtr usunięty', 'reports'));
		});
	}

	openCreator() {
		if (!this.clickDisabled) {
			this.clickDisabled = true;
			setTimeout(() => {
				this.clickDisabled = false;
			}, 1000);
		} else {
			return false;
		}

		this.flashId = null;

		this.questionCount = 0;
		this.surveyProviderService.getPagesPromise(true).then((data) => {
			data.forEach((item) => {
				if (item.questionIds && item.questionIds.length) {
					this.questionCount += item.questionIds.length;
				}
			});

			if (!this.questionCount) {
				let config = {
					size: '400',
					title: this.i18n.__('Nie można stworzyć filtru', 'reports'),
					text: this.i18n.__(
						'Twoja ankieta nie posiada pytań. Dodaj pytania w module <b>Tworzenie</b>.',
						'reports'
					),
					icon: {
						font: 'fa-exclamation-triangle',
						color: 'gray',
					},
					button: {
						text: this.i18n.__('Przejdź do tworzenia ankiety', 'reports'),
						type: 'button--big',
					},
				};

				this.modalService.show(config).then((data) => {
					if (data === 'button1') {
						parent.window.location.href = '/user#editing/questionnaire';
					}
				});
			} else {
				let ctrl = this;
				this.filtersService.openCreator({
					id: null,
					onCreateCallback: (filterData) => {
						ctrl.flashId = filterData.id;

						// this.updateFiltersResults(filterData.id);
						this.filters.available.unshift(filterData);
						ctrl.filtersService.invalidate({ onlyBasic: true });
						this.deletedFilters -= this.deletedFilters > 0 ? 1 : 0;
						this.alertsService.success(this.i18n.__('Dodano filtr', 'reports'));
					},
					isEditable: true,
					onCloseCallback: null,
				});
			}
		});
	}

	emptyScreenOnButtonClick = () => {
		this.openCreator();
	};

	getFiltersNumber() {
		return this.filters.defaultApplied.length + this.filters.available.length;
	}

	fetchFilters(filterCount = 0) {
		this.fetching = true;
		this.pageLoaded += 1;

		this.filtersService.getFiltersPromise(null, false, this.pageLoaded).then(
			(data) => {
				// Doładowanie elementów w przypadku usuniętych filtrów
				if (filterCount !== 0 && filterCount < data.length) {
					data = data.slice(-filterCount);
				}

				const newDefaultAppliedFilters = this.getDefaultAppliedFilters(data);
				const newAvailableFilters = this.getAvailableFilters(data);

				this.filters.defaultApplied = this.filters.defaultApplied.concat(newDefaultAppliedFilters);
				this.filters.available = this.filters.available.concat(newAvailableFilters);

				this.nothingToLoad = this.filtersService.getTotalCount() <= this.getFiltersNumber();
				this.isLoading = false;
				this.fetching = false;
				this.$scope.$apply();
			},
			() => {
				this.isLoading = false;
				this.fetching = false;
			}
		);
	}

	getDefaultAppliedFilters(fetchedFilters) {
		if (this.$rootScope.surveyAccess.readOnly) {
			return [];
		}

		return fetchedFilters.filter((filter) => filter.isDefaultApplied);
	}

	getAvailableFilters(fetchedFilters) {
		if (this.$rootScope.surveyAccess.readOnly) {
			return fetchedFilters;
		}

		return fetchedFilters.filter((filter) => !filter.isDefaultApplied);
	}

	updateFilters() {
		// Liczę na bieżąco ile wszystkich bo TotalCount przeliczane jest po fetchu
		let totalCount = this.filtersService.getTotalCount() - this.deletedFilters;

		const filtersNumber = this.getFiltersNumber();

		if (totalCount > filtersNumber && this.pageLoaded >= 1) {
			// Sprawdzam czy są jakieś usunięte po wcześniejszym doładowaniu - jeśli tak, trzeba je doładować z poprzedniej/poprzednich stron bo tam wskoczyły
			if (this.deletedFilters) {
				// Ile potrzebuje doładować do pełnej dziesiątki
				let filtersToLoad = this.deletedFilters % 10;

				// Sprawdzam czy w ogóle tyle jest do doładowania, jeśli nie - doładowuję tyle ile zostało
				if (filtersToLoad + filtersNumber > totalCount) {
					filtersToLoad = totalCount - filtersNumber;
				}

				// Ustawiam stronę z której należy doładować filtry
				this.pageLoaded -= Math.ceil(this.deletedFilters / 10);

				// Pobieram filtry które przeskoczyły między stronami - doładowuję do pełnej 10
				this.fetchFilters(filtersToLoad);

				this.deletedFilters = 0;
			} else {
				// w przypadku gdy zaskoczą dwa doładowania jednocześnie
				let wait = () => {
					if (!this.fetching) {
						this.fetchFilters();
					} else {
						setTimeout(() => {
							wait();
						}, 500);
					}
				};

				wait();
			}
		}
	}

	canCreateFilter() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}
}

export const filtersList = {
	template: tpl,
	controller: FiltersListController,
};
