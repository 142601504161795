const tpl = require('./SubMenuItem.html');

/**
 * SubMenuItemController
 *
 * @class
 * @module app
 */
class SubMenuItemController {
	constructor() {
		'ngInject';

		this.DATA_INFO = window.DATA_INFO;
	}
}

export const subMenuItem = {
	template: tpl,
	controller: SubMenuItemController,
	transclude: true,
	bindings: {
		state: '<',
		imgFile: '<',
		text: '<',
		type: '<',
		level: '<',
	},
};
