import { AbstractStorage } from './AbstractStorage';
import angular from 'angular';

class SentimentTrendStorage extends AbstractStorage {
	constructor(sentimentAPIService, Observer) {
		'ngInject';

		super(sentimentAPIService, Observer);
	}

	async fetchFromApi(from, to, tags) {
		return await this.sentimentAPIService.getTrend(from, to, tags);
	}

	async fetch(from, to, tags) {
		if (this.data) {
			this.observer.notify(angular.copy(this.data));
			return;
		}

		this.data = {
			tags,
			trend: $$await(
				await this.fetchFromApi(
					Math.floor(from / 1000),
					Math.floor(to / 1000),
					tags.map((tag) => {
						return tag.id;
					})
				)
			),
		};

		this.observer.notify(angular.copy(this.data));
	}
}

SentimentTrendStorage.$inject = ['sentimentAPIService', 'Observer'];

export { SentimentTrendStorage };
