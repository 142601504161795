import { KeyCodes } from '../utils/KeyCodes';
import { TagEvents } from '../Settings/Tags/events.tags';

class VerbatimKeyboard {
	constructor($rootScope) {
		'ngInject';

		this.$rootScope = $rootScope;

		this.keyEvent = null;
		this.pagination = null;
	}

	updateCurrentLog(log) {
		this.currentLog = log;

		this.$rootScope.$emit(TagEvents.activeSheetChanged, {
			currentLog: log,
		});
	}

	updatePage(page) {
		this.$rootScope.$emit(TagEvents.activePageChanged, { page });
	}

	setPagination(pagination) {
		this.pagination = pagination;
	}

	addKeyUpEvent(document) {
		if (this.keyEvent !== null) {
			return;
		}

		function debounce(func, wait, immediate) {
			let timeout;
			return function () {
				let context = this;
				let args = arguments;
				let later = function () {
					timeout = null;
					if (!immediate) {
						func.apply(context, args);
					}
				};
				let callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) {
					func.apply(context, args);
				}
			};
		}

		this.keyEvent = debounce((e) => {
			let keyCode = parseInt(e.keyCode, 10);
			let isShift = e.shiftKey;
			let isArrowUp = keyCode === KeyCodes.arrowUp;
			let isArrowDown = keyCode === KeyCodes.arrowDown;

			if (!isShift || (!isArrowDown && !isArrowUp)) {
				//only shift + arrow up/down
				return false;
			}

			let currentPage = this.pagination.currentPage || 1;
			let perPage = this.pagination.perPage;
			let total = this.pagination.total;
			let allPages = Math.ceil(total / this.pagination.perPage);

			if (!perPage || !total) {
				//stop if loading is in progress
				return false;
			}

			if (isArrowDown) {
				if (!this.currentLog) {
					//if = null, first event after load page
					this.updateCurrentLog((currentPage - 1) * perPage + 1); //first log on page
				} else {
					this.currentLog++; //arrow down, so next log

					if (this.currentLog > total) {
						//if next log is too much, go to begin (first page, first log)
						if (currentPage !== 1) {
							//if there is more than one page
							this.updatePage(1);
						}
						this.currentLog = 1;
					} else if (this.currentLog % perPage === 1) {
						//if it's first log on page (we need to switch page)
						this.updatePage(++currentPage);
					}

					this.updateCurrentLog(this.currentLog);
				}

				e.preventDefault();

				return false;
			} else if (isArrowUp) {
				if (!this.currentLog) {
					//if = null, first event after load page
					this.currentLog = currentPage * perPage; //last log on current page

					if (this.currentLog > total) {
						this.currentLog = total; //or if there is less than 10 logs, we choose last log
					}
					this.updateCurrentLog(this.currentLog);
				} else {
					this.currentLog--; //arrow up, so prev log

					if (this.currentLog <= 0) {
						//if we are in first log, we are going to last page and last log
						this.currentLog = total;
						if (allPages !== currentPage) {
							//if there is more than one page
							this.updatePage(allPages);
						}
					} else if (this.currentLog % perPage === 0) {
						//we need to go to previous page (if it's possible)
						this.updatePage(--currentPage);
					}

					this.updateCurrentLog(this.currentLog);
				}

				e.preventDefault();
				return false;
			}
		}, 250);

		document.keydown(this.keyEvent); //without debouncing it was some problems
	}

	removeKeyUp(document) {
		if (this.keyEvent) {
			document.off('keydown', this.keyEvent);
			this.keyEvent = null;
		}
	}
}

export { VerbatimKeyboard };
