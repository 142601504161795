import tpl from './SentimentTrendChart.html';
import highcharts from 'highcharts';

class SentimentTrendChartController {
	constructor(i18n, $element, CHART_COLORS) {
		'ngInject';
		this.i18n = i18n;
		this.$element = $element;
		this.chartContainer = null;
		this.chartConfig = null;
		this.chart = null;
		this.addPlugin(highcharts);
		this.CHART_COLORS = CHART_COLORS;
	}

	prepareElements() {
		this.chartContainer = this.$element.find('.chart__main')[0];
	}

	addPlugin(Highcharts) {
		(function (H) {
			let Axis = H.Axis;
			let inArray = H.inArray;
			let wrap = H.wrap;

			wrap(Axis.prototype, 'adjustTickAmount', function (proceed) {
				let chart = this.chart;
				let primaryAxis = chart[this.coll][0];
				let primaryThreshold;
				let primaryIndex;
				let index;
				let newTickPos;
				let threshold;

				// Find the index and return boolean result
				function isAligned(axis) {
					index = inArray(threshold, axis.tickPositions); // used in while-loop
					return axis.tickPositions.length === axis.tickAmount && index === primaryIndex;
				}

				if (chart.options.chart.alignThresholds && this !== primaryAxis) {
					primaryThreshold =
						(primaryAxis.series[0] && primaryAxis.series[0].options.threshold) || 0;
					threshold = (this.series[0] && this.series[0].options.threshold) || 0;

					primaryIndex =
						primaryAxis.tickPositions && inArray(primaryThreshold, primaryAxis.tickPositions);

					if (
						this.tickPositions &&
						this.tickPositions.length &&
						primaryIndex > 0 &&
						primaryIndex < primaryAxis.tickPositions.length - 1 &&
						this.tickAmount
					) {
						// Add tick positions to the top or bottom in order to align the threshold
						// to the primary axis threshold
						while (!isAligned(this)) {
							if (index < primaryIndex) {
								newTickPos = this.tickPositions[0] - this.tickInterval;
								this.tickPositions.unshift(newTickPos);
								this.min = newTickPos;
							} else {
								newTickPos = this.tickPositions[this.tickPositions.length - 1] + this.tickInterval;
								this.tickPositions.push(newTickPos);
								this.max = newTickPos;
							}
							proceed.call(this);
						}
					}
				} else {
					proceed.call(this);
				}
			});
		})(Highcharts);
	}

	setSingleTrendChartConfig() {
		this.chartConfig = {
			chart: {
				alignThresholds: true,
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			xAxis: [
				{
					title: '',
					categories: [],
					crosshair: true,
					labels: {
						style: {
							color: '#032c3e',
							fontSize: '12px',
						},
						rotation: -45,
					},
					lineWidth: 0,
					tickWidth: 0,
				},
			],
			yAxis: [
				{
					// Primary yAxis
					title: '',
					lineWidth: 2,
					lineColor: '#777',
					labels: {
						style: {
							color: '#032c3e',
							fontSize: '12px',
						},
						formatter() {
							const label = this.axis.defaultLabelFormatter.call(this);
							if (Number(label) % 1 !== 0) {
								return null;
							}
							return label;
						},
					},
					gridLineWidth: 0,
					plotLines: [
						{
							color: '#777',
							value: 0,
							width: 3,
							zIndex: 5,
						},
						{
							color: '#e6e6e6',
							value: -1,
							width: 1,
						},
						{
							color: '#e6e6e6',
							value: 1,
							width: 1,
						},
					],
					min: -1,
					max: 1,
				},
				{
					// Secondary yAxis
					title: '',
					lineWidth: 2,
					lineColor: '#777',
					gridLineWidth: 0,
					labels: {
						style: {
							color: '#032c3e',
							fontSize: '12px',
						},
						formatter() {
							const label = this.axis.defaultLabelFormatter.call(this);
							if (Number(label) < 0) {
								return null;
							}
							return label;
						},
					},
					allowDecimals: false,
					opposite: true,
				},
			],
			tooltip: {
				shared: true,
			},
			legend: {
				enabled: true,
				symbolRadius: 2,
				margin: 20,
				itemStyle: {
					width: '120px',
				},
			},
			series: [
				{
					name: this.i18n.__('Liczba', 'reports'),
					type: 'column',
					yAxis: 1,
					data: [],
				},
				{
					name: '',
					type: 'line',
					yAxis: 0,
					data: [],
				},
			],
			plotOptions: {
				column: {
					showInLegend: false,
					borderRadius: 3,
					color: 'rgba(135, 121, 252, 0.3)',
					pointWidth: 18,
				},
				line: {
					color: '#8779fc',
					lineWidth: 3,
					marker: {
						radius: 6,
						symbol: 'circle',
					},
				},
			},
		};
	}

	setAggregatedTrendChartConfig() {
		this.chartConfig = {
			chart: {
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			xAxis: [
				{
					title: '',
					categories: [],
					crosshair: true,
					labels: {
						style: {
							color: '#032c3e',
							fontSize: '12px',
						},
						rotation: -45,
					},
					lineWidth: 0,
					tickWidth: 0,
				},
			],
			yAxis: [
				{
					// Primary yAxis
					title: '',
					lineWidth: 2,
					lineColor: '#777',
					labels: {
						style: {
							color: '#032c3e',
							fontSize: '12px',
						},
						formatter() {
							const label = this.axis.defaultLabelFormatter.call(this);
							if (Number(label) % 1 !== 0) {
								return null;
							}
							return label;
						},
					},
					gridLineWidth: 0,
					plotLines: [
						{
							color: '#777',
							value: 0,
							width: 3,
							zIndex: 5,
						},
						{
							color: '#e6e6e6',
							value: -1,
							width: 1,
						},
						{
							color: '#e6e6e6',
							value: 1,
							width: 1,
						},
					],
					min: -1,
					max: 1,
				},
			],
			tooltip: {
				shared: true,
			},
			legend: {
				enabled: true,
				symbolRadius: 2,
				margin: 20,
				itemStyle: {
					width: '120px',
				},
			},
			series: [],
			plotOptions: {
				line: {
					lineWidth: 3,
					marker: {
						radius: 6,
						symbol: 'circle',
					},
				},
			},
		};
	}

	prepareSingleTrendChartData() {
		const index = this.chartData.data.trend.columns.findIndex((column) => {
			return column.title === this.chartData.mode.title;
		});

		const columnSeries = this.chartConfig.series[0];
		const lineSeries = this.chartConfig.series[1];

		lineSeries.name = this.chartData.data.trend.columns[index].title;

		this.chartData.data.trend.answers.forEach((answer) => {
			columnSeries.data.push(Number(answer[index].count));
			lineSeries.data.push(Number(answer[index].score));
		});

		this.chartData.data.trend.rows.forEach((row) => {
			this.chartConfig.xAxis[0].categories.push(row.title);
		});
	}

	prepareAggregatedTrendChartData() {
		this.chartData.data.trend.columns.forEach((column, index) => {
			const series = {
				type: 'line',
				name: this.chartData.data.trend.columns[index].title,
				color: this.CHART_COLORS[index % this.CHART_COLORS.length],
				data: [],
			};

			this.chartConfig.series.push(series);
		});

		this.chartConfig.series.forEach((series, index) => {
			this.chartData.data.trend.answers.forEach((answer) => {
				series.data.push(Number(answer[index].score));
			});
		});

		this.chartData.data.trend.rows.forEach((row) => {
			this.chartConfig.xAxis[0].categories.push(row.title);
		});
	}

	prepareSeries() {
		if (this.chartData.mode.id !== null) {
			this.setSingleTrendChartConfig();
			this.prepareSingleTrendChartData();
		} else if (this.chartData.mode.id === null) {
			this.setAggregatedTrendChartConfig();
			this.prepareAggregatedTrendChartData();
		}
	}

	drawChart() {
		this.chart = highcharts.chart(this.chartContainer, this.chartConfig);
	}

	initChanges() {
		this.prepareSeries();
		this.prepareElements();
		this.drawChart();
	}

	$onChanges() {
		this.initChanges();
	}
}

export const SentimentTrendChart = {
	template: tpl,
	controller: SentimentTrendChartController,
	bindings: {
		chartData: '<',
	},
};
