import tpl from './SentimentTrend.html';
import angular from 'angular';

class SentimentTrendController {
	constructor(i18n) {
		'ngInject';
		this.i18n = i18n;
		this.tags = [{ title: this.i18n.__('Zbiorczy', 'reports'), id: null }];
		this.selectedMode = this.tags[0];
		this.dataForChart = { mode: this.selectedMode, data: angular.copy(this.sentimentTrendData) };
	}

	$onChanges(changes) {
		if (changes.sentimentTrendData) {
			this.tags.splice(1, this.tags.length - 1);
			this.tags = this.tags.concat(angular.copy(this.sentimentTrendData.tags));
			this.selectedMode = this.tags[0];
			this.updateDataForChart();
		}
	}

	onModeChanged(item) {
		this.selectedMode = item.selected;
		this.updateDataForChart();
	}

	updateDataForChart() {
		this.dataForChart = { mode: this.selectedMode, data: angular.copy(this.sentimentTrendData) };
	}
}

export const SentimentTrend = {
	template: tpl,
	controller: SentimentTrendController,
	bindings: {
		sentimentTrendData: '<',
	},
};
