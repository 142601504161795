const uibTooltipConfig = ($uibTooltipProvider) => {
	'ngInject';

	$uibTooltipProvider.options({
		appendToBody: true,
		position: 'top',
		popupDelay: 250,
	});
};

export { uibTooltipConfig };
