import tpl from './SubMenuAnalyze.html';
import { AclFeature } from '../components/acl/acl.config';
import { log } from 'async';

class SubMenuAnalyzeController {
	constructor(i18n, aclManager, userService, $cookies, $state) {
		'ngInject';

		this.i18n = i18n;
		this.aclManager = aclManager;
		this.$cookies = $cookies;
		this.userService = userService;
		this.$state = $state;
	}

	isVerbatimsEnabled() {
		return this.aclManager.isFeatureEnabled(AclFeature.moduleVerbatims);
	}

	newResultsRoute() {
		var projectId = this.userService.getUserSurveyInfo().surveyId;
		var lastVisitedReport = JSON.parse(window.localStorage.getItem(`last-visited-report`));
		if (lastVisitedReport && lastVisitedReport[projectId]) {
			return `/user#report/saved?id=${lastVisitedReport[projectId]}`;
		}
		return '/user#report/predefined';
	}
}

export const subMenuAnalyze = {
	template: tpl,
	controller: SubMenuAnalyzeController,
};
