import { AclFeature } from '../components/acl/acl.config';
import FileSaver from 'file-saver';

class ExportService {
	constructor(
		surveyProviderService,
		$rootScope,
		$timeout,
		userService,
		i18n,
		reportsService,
		questionHelper,
		modalService,
		alertsService,
		supportCRMService,
		aclManager,
		UserRestangular,
		$state,
		segmentService
	) {
		'ngInject';

		this.i18n = i18n;
		this.surveyProviderService = surveyProviderService;
		this.reportsService = reportsService;
		this.questionHelper = questionHelper;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.supportCRMService = supportCRMService;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.userService = userService;
		this.aclManager = aclManager;
		this.$state = $state;

		this.questionTypes = this.questionHelper.getTypes();
		this.exportPrefix = '/user/export/';

		this.UserRestangular = UserRestangular;
		this.exportRestangular = UserRestangular.one('export');
		this.segmentService = segmentService;
	}

	prepareExportLink(item, csrf) {
		if (item.csrf) {
			item.url = item.url + '/csrf/' + csrf;
		}
	}

	reportOptions() {
		return this.attachCSRF(this.reportOptionsList());
	}

	attachCSRF(options) {
		this.reportsService.apiHelper.getCSRF().then((csrf) => {
			options.forEach((item) => {
				this.prepareExportLink(item, csrf);
			});
		});

		return options;
	}

	reportOptionsList() {
		const reportOptions = [];

		reportOptions.push({
			id: 'docx',
			title: this.i18n.__('Raport zbiorczy DOCX', 'reports'),
			url: 'aggregated-docx',
			csrf: true,
			feature: AclFeature.reportDOCX,
			featureEnabled: this.aclManager.isFeatureEnabled(AclFeature.reportDOCX),
		});

		reportOptions.push({
			id: 'pptx',
			title: this.i18n.__('Raport zbiorczy PPT', 'reports'),
			url: 'power-point',
			csrf: true,
			feature: AclFeature.reportPPTX,
			featureEnabled: this.aclManager.isFeatureEnabled(AclFeature.reportPPTX),
		});

		return reportOptions;
	}

	exportOptions() {
		return this.attachCSRF(this.exportOptionsList());
	}

	exportOptionsList() {
		return [
			{
				id: 'xlsx_zbiorcze',
				title: this.i18n.__('Wyniki zbiorcze (XLSX)', 'reports'),
				url: 'aggregated-xls',
				popupHeader: this.i18n.__('Eksport wyników zbiorczych', 'reports'),
				icon: 'fa fa-file-excel-o',
				csrf: true,
				feature: AclFeature.reportXLSX,
				featureEnabled: this.aclManager.isFeatureEnabled(AclFeature.reportXLSX),
			},
			{
				id: 'csv_zbiorcze',
				title: this.i18n.__('Wyniki zbiorcze (CSV)', 'reports'),
				url: 'aggregated-csv',
				popupHeader: this.i18n.__('Eksport wyników zbiorczych', 'reports'),
				icon: 'fa fa-file-excel-o',
				csrf: true,
				feature: AclFeature.reportCSV,
				featureEnabled: this.aclManager.isFeatureEnabled(AclFeature.reportCSV),
			},
			{
				id: 'xlsx_surowe',
				title: this.i18n.__('Surowe dane (XLSX)', 'reports'),
				icon: 'fa fa-file-excel-o',
				url: 'raw-xls',
				chooseScopeMode: true,
				popupHeader: this.i18n.__('Eksport surowych danych', 'reports'),
				csrf: true,
				feature: AclFeature.reportRawXLSX,
				featureEnabled: this.aclManager.isFeatureEnabled(AclFeature.reportRawXLSX),
			},
			{
				id: 'csv_surowe',
				title: this.i18n.__('Surowe dane (CSV)', 'reports'),
				icon: 'fa fa-file-excel-o',
				url: 'raw-csv',
				chooseScopeMode: true,
				popupHeader: this.i18n.__('Eksport surowych danych', 'reports'),
				csrf: true,
				feature: AclFeature.reportRawCSV,
				featureEnabled: this.aclManager.isFeatureEnabled(AclFeature.reportRawCSV),
			},
			{
				id: 'kwestionariusz_wypelniony',
				title: this.i18n.__('Wypełnione arkusze', 'reports'),
				url: 'all-sheets',
				icon: 'fa fa-file-zip-o',
				csrf: true,
			},
			{
				id: 'attachments',
				title: this.i18n.__('Załączniki', 'reports'),
				url: 'attachments',
				icon: 'fa fa-file-zip-o',
				hide: () => !this.surveyProviderService.hasQuestionWithType(this.questionTypes.attachment),
				csrf: true,
			},
			{
				id: 'spss',
				title: this.i18n.__('SPSS', 'reports'),
				url: 'spss',
				chooseScopeMode: true,
				popupHeader: this.i18n.__('Eksport SPSS', 'analyse'),
				icon: 'fa fa-table',
				csrf: true,
				feature: AclFeature.reportSPSS,
				featureEnabled: this.aclManager.isFeatureEnabled(AclFeature.reportSPSS),
			},
		];
	}

	dataExportClick() {
		return (options, event) => {
			event.stopPropagation();

			if (['xlsx_surowe', 'csv_surowe'].includes(options.id)) {
				this.supportCRMService.emitRawExportClicked(options.id);
				this.handleRawExport();
				return;
			}

			this.$rootScope.xhr++;
			this.supportCRMService.emitReportExportFormat(options.id);

			if (options.chooseScopeMode) {
				this.checkNoFillsInSurvey().then((hasNoFills) => {
					if (hasNoFills) {
						const extendedOptions = { ...options, allResults: true };
						this.sendExportRequest(extendedOptions);
					} else {
						this.modalService.openExportDataModal(
							options,
							() => {
								const extendedOptions = { ...options, allResults: false };
								this.sendExportRequest(extendedOptions);
							},
							() => {
								const extendedOptions = { ...options, allResults: true };
								this.sendExportRequest(extendedOptions);
							},
							() => {}
						);
					}
				});
			} else {
				this.sendExportRequest(options);
			}
		};
	}

	handleRawExport() {
		this.setOpenModalIntention();
		this.showRawExportInstructionModal();
	}

	setOpenModalIntention() {
		const OPEN_MODAL_STORAGE_KEY = 'open-modal';
		const openModalStorageValue = JSON.parse(window.localStorage.getItem(OPEN_MODAL_STORAGE_KEY));

		const updatedStorageValue = Object.assign({}, openModalStorageValue, {
			'raw-export': true,
		});

		window.localStorage.setItem(OPEN_MODAL_STORAGE_KEY, JSON.stringify(updatedStorageValue));
	}

	showRawExportInstructionModal() {
		const modal = document.querySelector('[data-target="single-result-modal"]');

		modal.classList.remove('hidden');
	}

	sendExportRequest(options) {
		const self = this;

		this.segmentService.track('Eksport wyników', {
			Plik: options.title,
		});

		self.sendExportRequestWithErrorCallback(options, () => {
			options.url = options.url + '?email=1';

			self.sendExportRequestWithErrorCallback(options, (error) => console.log(error));
		});
	}

	sendExportRequestWithErrorCallback(options, errorCallback) {
		let postData = {};

		if (options.allResults) {
			postData.allResults = true;
		}

		this.UserRestangular.setFullResponse(true);

		this.exportRestangular
			.one(options.url)
			.withHttpConfig({ responseType: 'arraybuffer' })
			.customGET('', postData, { 'X-Requested-With': 'XMLHttpRequest' })
			.then((response) => {
				this.UserRestangular.setFullResponse(false);

				const headers = response.headers();
				let contentType = headers['content-type'];

				if (contentType === 'application/json') {
					this.displayDownloadCenterPopup();
				} else {
					this.openFile(response.data, headers);
				}
			}, errorCallback);

		this.$rootScope.xhr--;
	}

	displayDownloadCenterPopup() {
		this.modalService
			.show({
				size: '500',
				title: this.i18n.__('Trwa generowanie pliku', 'reports'),
				text: this.i18n._s(
					'Przejdź do %sCentrum pobierania%s, by sprawdzić jego status',
					'<strong>',
					'</strong>',
					'reports'
				),
				button: {
					text: this.i18n.__('Przejdź do Centrum pobierania', 'reports'),
					type: 'button button--white',
					closeOnClick: true,
					'data-testid': 'go-download-center-link',
				},
			})
			.then(() => {
				parent.window.location.href = '/user#download-center';
			});
	}

	openFile(response, headers) {
		const filename = headers['content-disposition'].split('filename=')[1];
		const contentType = headers['content-type'] + ';charset=utf-8';

		const blob = new Blob([response], { type: contentType });

		FileSaver.saveAs(blob, filename);
	}

	checkNoFillsInSurvey() {
		return new Promise((resolve) => {
			this.surveyProviderService.getCurrentSurveyPromise().then((survey) => {
				resolve(survey && survey.fillCount === 0);
			});
		});
	}
}

export default ExportService;
