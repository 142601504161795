const aclAccessDirective = ($state, aclManager) => {
	'ngInject';

	return {
		restrict: 'A',
		priority: -1,
		scope: {
			aclAccess: '<',
			aclAccessCustom: '<',
			aclAccessCallback: '&',
		},
		link: (scope, element) => {
			if (aclManager.isFeatureEnabled(scope.aclAccess)) {
				return;
			}

			element.on('click', (e) => {
				e.stopImmediatePropagation();

				window.parent.postMessage(
					{
						type: 'ANGULAR_ACL_LIMITATION',
						feature: scope.aclAccess,
					},
					'*'
				);
			});
		},
	};
};

export { aclAccessDirective };
