import Template from './empty-screen.html';
require('./empty-screen.scss');

class EmptyScreenController {
	constructor() {}
}

export const EmptyScreenComponent = {
	template: Template,
	controller: EmptyScreenController,
	bindings: {
		description: '@',
		upsellDescription: '@?',
		featureId: '<?',
		upsellText: '@?',
		normalButtonText: '@?',
		normalButtonOnClick: '<?',
		enabled: '<?',
		tooltipText: '@?',
	},
	transclude: {
		mainContainer: '?mainContainer',
	},
};
