const tpl = require('./QuestionDate.html');

class QuestionDateController {
	constructor(i18n, questionHelper) {
		'ngInject';

		this.i18n = i18n;
		this.questionHelper = questionHelper;

		this.mostPopular = null;
		this.mostPopularDate = null;
		this.day = null;
		this.month = null;
		this.year = null;

		this.monthNames = [
			i18n.__('Styczeń', 'reports'),
			i18n.__('Luty', 'reports'),
			i18n.__('Marzec', 'reports'),
			i18n.__('Kwiecień', 'reports'),
			i18n.__('Maj', 'reports'),
			i18n.__('Czerwiec', 'reports'),
			i18n.__('Lipiec', 'reports'),
			i18n.__('Sierpień', 'reports'),
			i18n.__('Wrzesień', 'reports'),
			i18n.__('Październik', 'reports'),
			i18n.__('Listopad', 'reports'),
			i18n.__('Grudzień', 'reports'),
		];
		this.dayNames = [
			i18n.__('Niedziela', 'reports'),
			i18n.__('Poniedziałek', 'reports'),
			i18n.__('Wtorek', 'reports'),
			i18n.__('Środa', 'reports'),
			i18n.__('Czwartek', 'reports'),
			i18n.__('Piątek', 'reports'),
			i18n.__('Sobota', 'reports'),
		];
		this.separator = '/';

		this.displayLimit = 4;
		this.ready = false;
	}

	getPopular() {
		this.mostPopular = this.questionHelper.getMostPopularAnswer(this.questionData);

		if (this.mostPopular && this.mostPopular.title) {
			[this.day, this.month, this.year] = this.mostPopular.title.split(this.separator);
			this.mostPopularDate = new Date(this.year, this.month - 1, this.day);
		}
	}

	$onInit() {
		this.getPopular();

		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	$postLink() {}

	isReady() {
		return this.ready;
	}

	getQuickFilterData(answer) {
		return {
			questionData: this.questionData,
			answerTitle: answer.title,
			answers: answer.title,
		};
	}
}

export const questionDate = {
	template: tpl,
	controller: QuestionDateController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		reportdata: '<',
		chartColor: '<',
	},
};
