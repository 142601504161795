import tpl from './SubMenuSettings.html';
import $ from 'jquery';
import { AclFeature } from '../../components/acl/acl.config';

class SubMenuSettingsController {
	constructor($element, i18n, $rootScope, userService, aclManager) {
		'ngInject';

		this.i18n = i18n;
		this.$element = $element;
		this.userService = userService;
		this.aclManager = aclManager;

		this.subLevelClassNameRegex = /subMenu--level-[0-9]+/g;
		this.subLevelElement = null;
	}

	$onInit() {
		this.settingsWrapper.addSubMenuReference(this);
	}

	$postLink() {
		this.subLevelElement = $(this.$element).find('.subMenu');
	}

	activateLevel(subLevel) {
		if (subLevel === 1) {
			this.subLevelElement.addClass('subMenu--level-1');
		} else if (subLevel === 0) {
			this.subLevelElement.removeClass(function (i, className) {
				let matchArray = className.match(this.subLevelClassNameRegex);

				if (matchArray && matchArray.length) {
					return matchArray.join(' ');
				}

				return undefined;
			});
		}
	}

	areVerbatimsEnabled() {
		return this.aclManager.isFeatureEnabled(AclFeature.moduleVerbatims);
	}
}

export const SubMenuSettings = {
	template: tpl,
	controller: SubMenuSettingsController,
	require: {
		settingsWrapper: '^settingsWrapper',
	},
};
