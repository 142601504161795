import angular from 'angular';

class VerbatimTagsService {
	constructor(surveyProviderService, Restangular, apiHelper, $q) {
		'ngInject';

		this.surveyProviderService = surveyProviderService;
		this.restangular = Restangular;
		this.apiHelper = apiHelper;
		this.$q = $q;

		this.tags = null;
	}

	getTagsListPromise() {
		return new this.$q((resolve, reject) => {
			if (this.tags) {
				resolve(angular.copy(this.tags));
			} else {
				this.surveyProviderService.getCurrentSurveyIdPromise().then(() => {
					this.restangular
						.one('verbatim')
						.one('tag')
						.get()
						.then((data) => {
							data = data && data.plain ? data.plain() : data;
							this.tags = data.items;
							resolve(angular.copy(this.tags));
						}, this.apiHelper.handleDataError);
				}, this.apiHelper.handleDataError);
			}
		});
	}

	createTagPromise(title) {
		return new Promise((resolve, reject) => {
			this.restangular
				.all('verbatim')
				.all('tag')
				.post({ title })
				.then((data) => {
					data = data && data.plain ? data.plain() : data;
					this.clearSavedTags();
					resolve(data);
				})
				.catch((response) => {
					reject(response.status);
				});
		});
	}

	updateTagPromise(id, title) {
		return new Promise((resolve, reject) => {
			this.restangular
				.all('verbatim')
				.one('tag', id)
				.customPUT({ title })
				.then((data) => {
					data = data && data.plain ? data.plain() : data;
					this.clearSavedTags();
					resolve(data);
				})
				.catch((response) => {
					reject(response.status);
				});
		});
	}

	deleteTagPromise(id) {
		return new Promise((resolve, reject) => {
			this.restangular
				.all('verbatim')
				.one('tag', id)
				.remove()
				.then((data) => {
					data = data && data.plain ? data.plain() : data;
					this.clearSavedTags();
					resolve(data);
				}, this.apiHelper.handleDataError);
		});
	}

	clearSavedTags() {
		this.tags = null;
	}
}

export default VerbatimTagsService;
