import tpl from './MainMenu.html';
import '../../scss/mainMenu.scss';

class MainMenuController {
	constructor(surveyProviderService, $scope) {
		'ngInject';

		this.$scope = $scope;
		this.surveyProviderService = surveyProviderService;
	}

	$onInit() {
		this.surveyProviderService.getCurrentSurveyPromise();

		this.$scope.$on('SurveyReady', () => {
			this.surveyProviderService.getCurrentSurveyPromise();
		});

		this.$scope.$on('SurveyChanged', () => {
			this.surveyProviderService.getCurrentSurveyPromise();
		});
	}
}

export const mainMenu = {
	template: tpl,
	controller: MainMenuController,
};
