import { VerbatimParametersType } from './type.verbatimParameters';

class VerbatimService {
	constructor($q, surveyProviderService, Restangular, apiHelper, UserRestangular) {
		'ngInject';

		this.$q = $q;
		this.restangular = Restangular;
		this.apiHelper = apiHelper;
		this.UserRestangular = UserRestangular;
		this.projectsRestangular = UserRestangular.one('projects');
		this.surveyProviderService = surveyProviderService;
	}

	deleteLog(logId, orderId) {
		// /user/results/delete-sheet-api/idlog/22649251

		return new Promise((resolve, reject) => {
			this.apiHelper.getCSRF().then((csrf) => {
				this.UserRestangular.one('results')
					.one('delete-sheet-api')
					.one('idlog', logId)
					.one('csrf', csrf)
					.get()
					.then((data) => {
						if (Number(data.status) === 1) {
							resolve();
						} else {
							this.apiHelper.handleDataError(data);
							reject();
						}
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	getLogs(page = 1, parameters, order, filter = null, limit = 10) {
		// GET /api/{survey-id}/verbatim?filter&limit&page&segments&questions&meta

		let [segments, questions, meta] = this.prepareParameters(parameters);
		let params = { page, filter, segments, questions, meta, limit, order };

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.projectsRestangular
					.one(String(surveyId))
					.one('tags')
					.one('fills')
					.get(params)
					.then((data) => {
						data = data && data.plain ? data.plain() : data;
						resolve(data);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	prepareSegments(segmentsList, metaList) {
		let list = [];

		if (segmentsList && segmentsList.length) {
			list = segmentsList.sort((a, b) => a - b);
		}

		if (metaList && metaList.indexOf('email') !== -1 && list.indexOf(0) === -1) {
			list.push(0);
		}

		return list;
	}

	prepareQuestions(questionsList) {
		let list = [];

		if (questionsList && questionsList.length) {
			list = questionsList;

			//translate matrix (id_rowIndex) to ids only (remove _x part)
			list = list.map((par) =>
				par.length && par.indexOf('_') !== -1 ? parseInt(par.split('_')[0], 10) : par
			);

			//removeDuplicates
			list = list.filter((par, i) => list.indexOf(par) === i);
		}

		return list;
	}

	prepareParameters(parameters) {
		let segments = this.prepareSegments(
			parameters[VerbatimParametersType.segmentsList],
			parameters[VerbatimParametersType.metaList]
		);
		let questions = this.prepareQuestions(parameters[VerbatimParametersType.questionsList]);
		let meta = parameters[VerbatimParametersType.metaList] || [];
		let params = [segments, questions, meta];

		return params.map((par) => par.join());
	}

	infoVisibilityKey(surveyId) {
		return 'verbatim_info_hidden_' + surveyId;
	}

	infoVisibility(value) {
		if (value) {
			return this.$q((resolve) => {
				this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
					window.localStorage.setItem(this.infoVisibilityKey(surveyId), 1);

					resolve(value);
				});
			});
		}

		return this.$q((resolve) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				let value = window.localStorage.getItem(this.infoVisibilityKey(surveyId));

				resolve(value);
			});
		});
	}
}

export default VerbatimService;
