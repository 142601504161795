import tpl from './cross-table-creator.html';
import angular from 'angular';

import { AbstractCreator } from '../../creator/abstract-creator';

class CrossTableCreatorController extends AbstractCreator {
	constructor(
		CROSS_TABLES_CONFIG,
		$scope,
		i18n,
		modalService,
		analyzeSetStorage,
		crossTablesService,
		crossTablesApiService,
		segmentService
	) {
		'ngInject';

		super($scope, i18n, modalService, analyzeSetStorage);

		this.CROSS_TABLES_CONFIG = CROSS_TABLES_CONFIG;
		this.crossTablesService = crossTablesService;
		this.crossTablesApiService = crossTablesApiService;
		this.segmentService = segmentService;

		this.TYPES_OF_DATA_HELPER = [
			{
				type: CROSS_TABLES_CONFIG.setType.question,
				text: i18n.__('Pytanie', 'reports'),
				icon: 'analyze-set-question',
				disabled: false,
				disabledTooltip: '',
			},
			{
				type: CROSS_TABLES_CONFIG.setType.segment,
				text: i18n.__('Segment danych', 'reports'),
				icon: 'analyze-set-segment',
				disabled: false,
				disabledTooltip: '',
			},
			{
				type: CROSS_TABLES_CONFIG.setType.tag,
				text: i18n.__('Tag', 'reports'),
				icon: 'analyze-set-tag',
				disabled: false,
			},
		];

		this.crossTableData = {
			sets: [
				{
					type: CROSS_TABLES_CONFIG.setType.question,
					details: true,
				},
				{
					type: CROSS_TABLES_CONFIG.setType.question,
				},
			],
		};

		this.crossTableBase = null;
	}

	async $onInit() {
		super.$onInit();

		if (this.resolve.crossTableId) {
			this.getCrossTableData();
		} else {
			$$await(await this.analyzeSetStorage.fetchQuestions());
			this.isPrefetchingCompleted = true;
		}
	}

	validate() {
		let firstValidationStatement;
		let secondValidationStatement = this.crossTableData.sets[1].question;

		if (this.crossTableData.sets[0].type === this.CROSS_TABLES_CONFIG.setType.question) {
			firstValidationStatement = this.crossTableData.sets[0].question;
		} else if (this.crossTableData.sets[0].type === this.CROSS_TABLES_CONFIG.setType.segment) {
			firstValidationStatement = this.crossTableData.sets[0].segment;
		} else if (this.crossTableData.sets[0].type === this.CROSS_TABLES_CONFIG.setType.tag) {
			firstValidationStatement =
				this.crossTableData.sets[0].tags && this.crossTableData.sets[0].tags.length;
		}

		if (firstValidationStatement && secondValidationStatement && this.isAnyChanges()) {
			return true;
		}

		return false;
	}

	async getCrossTableData() {
		const crossTableData = await this.crossTablesApiService.getOne(this.resolve.crossTableId);
		this.parseFetchedData(crossTableData);
	}

	async save() {
		this.generating = true;

		const data = this.crossTablesService.parsePreparedData(this.crossTableData);

		let crossTableResult;

		if (this.resolve.crossTableId) {
			crossTableResult = await this.crossTablesApiService.update(this.resolve.crossTableId, data);
		} else {
			crossTableResult = await this.crossTablesApiService.create(data);
			this.segmentService.track('Dodano nową tabelą krzyżową');
		}

		this.resolve.onCreateCallback(crossTableResult);
	}

	async parseFetchedData(crossTableData) {
		this.crossTableData.title = crossTableData.title;

		const [questions, segments, tags] = $$await(
			await Promise.all([
				this.analyzeSetStorage.fetchQuestions(),
				this.analyzeSetStorage.fetchSegments(),
				this.analyzeSetStorage.fetchTags(),
			])
		);

		crossTableData.sets.forEach((set, index) => {
			this.crossTableData.sets[index].type = set.type;

			if (set.type === this.CROSS_TABLES_CONFIG.setType.question) {
				this.crossTableData.sets[index].question = questions.find((question) => {
					const sameQuestions = Number(question.id) === Number(set.question);

					if (question.rowIndex) {
						return sameQuestions && Number(question.rowIndex) === Number(set.rowIndexes[0]);
					}

					return sameQuestions;
				});
			} else if (set.type === this.CROSS_TABLES_CONFIG.setType.segment) {
				this.crossTableData.sets[index].segment = segments.find(
					(segment) => Number(segment.rowIndex) === Number(set.rowIndexes[0])
				);
			} else if (set.type === this.CROSS_TABLES_CONFIG.setType.tag) {
				this.crossTableData.sets[index].tags = tags.filter((tag) => {
					return set.rowIndexes.indexOf(Number(tag.id)) !== -1;
				});
			}
		});

		this.crossTableBase = angular.copy(this.crossTableData);

		this.isPrefetchingCompleted = true;
	}

	isAnyChanges() {
		if (
			this.crossTableBase &&
			angular.toJson(this.crossTableData) !== angular.toJson(this.crossTableBase)
		) {
			return true;
		} else if (
			!this.crossTableBase &&
			(this.crossTableData.sets[0].question ||
				this.crossTableData.sets[0].segment ||
				this.crossTableData.sets[0].tags ||
				this.crossTableData.sets[1].question ||
				this.crossTableData.title)
		) {
			return true;
		}

		return false;
	}

	closeWithConfirmation() {
		if (!this.isAnyChanges()) {
			this.close();
		} else {
			this.confirmClosing(this.i18n.__('Tabela krzyżowa nie została zapisana', 'reports'));
		}
	}
}

CrossTableCreatorController.$inject = [
	'CROSS_TABLES_CONFIG',
	'$scope',
	'i18n',
	'modalService',
	'analyzeSetStorage',
	'crossTablesService',
	'crossTablesApiService',
	'segmentService',
];

export const CrossTableCreatorComponent = {
	template: tpl,
	controller: CrossTableCreatorController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
