const tpl = require('./QuestionPoints.html');

class QuestionPointsController {
	constructor(modalService, i18n) {
		'ngInject';

		this.modalService = modalService;
		this.i18n = i18n;

		this.maxSum = 0;
		this.percentage = [];
		this.ready = false;
	}

	$onInit() {
		if (this.questionData.answers && this.questionData.answers.length) {
			let limit = this.questionData.answers.length;
			for (let i = 0; i < limit; i++) {
				let sum = Number(this.questionData.answers[i].sum);
				if (sum > this.maxSum) {
					this.maxSum = sum;
				}
			}
			for (let i = 0; i < limit; i++) {
				this.percentage[i] = ((Number(this.questionData.answers[i].sum) / this.maxSum) * 100) | 0;
			}
		}
		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	isReady() {
		return this.ready;
	}

	showDistribution(index) {
		let answer = this.questionData.answers[index];

		this.modalService.openDistributionChartModal(
			this.questionCtrl.questionIndex,
			index,
			this.questionData,
			answer.others
		);
	}

	getPercentage(index) {
		const percentage = this.percentage[index];
		return percentage >= 100 ? 100 : percentage;
	}
}

export const questionPoints = {
	template: tpl,
	controller: QuestionPointsController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
