const tpl = require('./ChartMultiType.html');
const jquery = require('jquery');
const highcharts = require('highcharts');
const angular = require('angular');
let { AbstractChartController } = require('./AbstractChartController');

class ChartMultiTypeController extends AbstractChartController {
	constructor($element, chartColorHelper) {
		'ngInject';
		super(chartColorHelper);

		let ctrl = this;

		this.$element = $element;
		this.chartColorHelper = chartColorHelper;

		this.chartConfig = {
			chart: {
				type: 'column',
				boderWidth: 0,
			},
			plotOptions: {
				column: {
					dataLabels: {
						enabled: false,
					},
				},
				series: {
					borderRadius: 4,
					borderWidth: 4,
					animation: false,
				},
			},
			xAxis: {
				visible: true,
			},
			title: {
				text: undefined,
			},
			colors: chartColorHelper.getColorsTableForChart(),
		};
	}

	$onInit() {
		// console.log('$onInit');
	}

	$onChanges(changes) {
		// console.log('**CHART multi** $onChanges', changes.answers);

		if (changes.colorOverwrite && changes.colorOverwrite.currentValue && this.chart) {
			let dataSeries = this.createSeries(this.questionTitle, this.answers);
			this.chart.update({ series: dataSeries }, true);
		}
	}

	$postLink() {
		// console.log('$postLink-ChartMulti');
		this.init();

		if (this.questionCtrl) {
			this.questionCtrl.setChartReference(this);
			this.questionCtrl.chartInitialized();
		}
	}

	init() {
		if (!this.chart) {
			let dataSeries = this.createSeries(this.questionTitle, this.answers);
			let config = this.createConfig(dataSeries);

			if (!this.nps) {
				config.plotOptions.series.borderWidth = 0;
			}

			this.chart = highcharts.chart(jquery(this.$element).find('.chart')[0], config);
		}
	}

	createSeries(title, answers) {
		let result = [];
		let colors = this.chartColorHelper.getColorsTableForChart();

		// answers = this.answers;
		answers = angular.copy(this.answers);

		// console.log('colors', colors);
		if (this.colorOverwrite) {
			colors = new Array(Number(this.limit || answers.length));
			// console.log('colors', colors);
			if (colors.fill) {
				colors.fill(this.colorOverwrite);
			} else {
				for (let i = 0; i < colors.length; i++) {
					colors[i] = this.colorOverwrite;
				}
			}
		}

		if (this.singleSeries) {
			// console.log('singleSeries', answers);
			result[0] = { data: [] };
			answers.forEach((elem, i) => {
				let color;
				if (this.questionCtrl.isAnswerNeutral(elem)) {
					return;
				}
				if (this.limit && i >= this.limit) {
					return;
				}
				if (this.colorOverwrite) {
					if (answers.length > colors.length) {
						color = colors[colors.length % (i + 1)];
					} else {
						color = colors[i];
					}
				} else {
					color = this.chartColorHelper.getNextColor();
				}

				result[0].data.push({
					id: i,
					name: elem.title,
					y: Number(elem.count),
					percent: Number(elem.percent),
					color,
					borderWidth: 0,
					borderColor: color,
				});
			});
		} else if (this.nps) {
			let npsColors = this.chartColorHelper.getNPSColors();
			let color;

			result[0] = { data: [] };
			answers.forEach((elem, i) => {
				if (i < 7) {
					color = npsColors.detractors;
				} else if (i < 9) {
					color = npsColors.neutrals;
				} else {
					color = npsColors.promoters;
				}
				result[0].data.push({
					y: elem.count,
					color,
					borderColor: color,
				});
			});
		} else {
			answers.forEach((elem, i) => {
				if (this.questionCtrl.isAnswerNeutral(elem)) {
					return;
				}
				if (this.limit && i >= this.limit) {
					return;
				}
				result.push({
					id: i,
					name: elem.title,
					data: [elem.count],
					borderWidth: 0,
					// ,
					// color: '#ff0000',
					// percent: elem.percent
				});
			});
		}

		// console.log('series result', result);
		return result;
	}

	updateColor(color) {
		// console.log('multi - updateColor', color, this.singleSeries);
		if (!this.nps) {
			this.chartColorHelper.setSourceColor(color);
			this.chartColorHelper.reset();

			if (this.singleSeries) {
				let series = this.createSeries(this.questionTitle, this.answers);
				this.chart.update(
					{
						series,
					},
					true
				);
			} else {
				super.updateColor(color);
			}
		}
	}
}

export const chartMultiType = {
	template: tpl,
	controller: ChartMultiTypeController,
	require: {
		questionCtrl: '?^aRQuestion',
	},
	bindings: {
		answers: '<',
		questionTitle: '<',
		singleSeries: '<',
		nps: '<',
		limit: '@',
		colorOverwrite: '<',
	},
};
