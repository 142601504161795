import tpl from './grouped-select.html';
import angular from 'angular';

require('./grouped-select.scss');

class GroupedSelectController {
	constructor(EventEmitter) {
		'ngInject';

		this.EventEmitter = EventEmitter;

		this.presentation = '';
	}

	$onChanges(changes) {
		if (!changes.groups.isFirstChange() && changes.groups.previousValue) {
			angular.copy({}, this.model);
		}

		if (changes.groups && changes.groups.currentValue) {
			this.prepareModelBasedOnGroups();
		}

		if (this.groups && changes.model && changes.model.currentValue) {
			this.updatePresentation();
		}
	}

	prepareModelBasedOnGroups() {
		Object.keys(this.groups).forEach((key) => {
			if (!Object.prototype.hasOwnProperty.call(this.model, key)) {
				this.model[key] = [];
			}
		});

		this.updatePresentation();
	}

	onGroupClick(group) {
		if (this.model[group].length < this.groups[group].length) {
			this.model[group] = angular.copy(this.groups[group]);
		} else {
			this.model[group] = [];
		}

		this.updatePresentation();
		this.emitChange();
	}

	onItemClick(item, group) {
		const index = this.findIndexInSelected(item, group);

		if (index !== -1) {
			this.model[group].splice(index, 1);
		} else {
			this.model[group].push(item);
		}

		this.updatePresentation();
		this.emitChange();
	}

	isAnyItems() {
		return this.groups && Object.keys(this.groups).length > 0;
	}

	isItemChecked(item, group) {
		return this.findIndexInSelected(item, group) !== -1;
	}

	findIndexInSelected(item, group) {
		if (!this.model || !Object.prototype.hasOwnProperty.call(this.model, group)) {
			return -1;
		}

		return this.model[group].findIndex((element) => {
			return angular.equals(element, item);
		});
	}

	updatePresentation() {
		let presentation = '';

		Object.keys(this.groups).forEach((group) => {
			this.model[group].forEach((item) => {
				presentation += `${item.title}; `;
			});
		});

		this.presentation = presentation;
	}

	emitChange() {
		if (!this.onSelect) {
			return;
		}

		this.onSelect(
			this.EventEmitter({
				items: angular.copy(this.model),
			})
		);
	}
}

export const GroupedSelectComponent = {
	controller: GroupedSelectController,
	template: tpl,
	bindings: {
		groups: '<',
		model: '<',
		onSelect: '&',
		placeholder: '@',
		noGroupsPlaceholder: '@',
	},
};
