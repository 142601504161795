class AbstractCreator {
	constructor($scope, i18n, modalService, analyzeSetStorage) {
		this.analyzeSetStorage = analyzeSetStorage;
		this.modalService = modalService;
		this.$scope = $scope;
		this.i18n = i18n;

		this.generating = false;
		this.isPrefetchingCompleted = false;
	}

	$onInit() {
		this.bindActions();
	}

	bindActions() {
		let ctrl = this;

		this.$scope.$on('modal.closing', (ev) => {
			if (ctrl.resolve.onCloseCallback && typeof ctrl.resolve.onCloseCallback === 'function') {
				ctrl.resolve.onCloseCallback();
			}
		});
	}

	confirmClosing(title) {
		let ctrl = this;

		let config = {
			size: '500',
			title,
			text: ctrl.i18n.__('Czy na pewno chcesz przerwać edycję?', 'reports'),
			icon: {
				font: 'fa-question',
				color: 'gray',
			},
			button: {
				text: ctrl.i18n.__('Kontynuuj edycję', 'reports'),
				type: 'button--big button--gray',
			},
			button2: {
				text: ctrl.i18n.__('Zamknij bez zapisywania', 'reports'),
				type: 'button--big',
			},
		};

		ctrl.modalService.show(config).then((data) => {
			ctrl.modalService.close();

			if (data === 'button2') {
				ctrl.close();
			}
		});
	}
}

export { AbstractCreator };
