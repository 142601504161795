import angular from 'angular';

import { aclAccessDirective } from './directives/acl-access.directive';

import { AclApiService } from './services/acl-api.service';
import { AclService } from './services/acl.service';

import { AclLabelComponent } from './acl-label/acl-label.component';

export const AclModule = angular
	.module('acl', [])
	.service('aclApiService', AclApiService)
	.service('aclManager', AclService)
	.directive('aclAccess', aclAccessDirective)
	.component('aclLabel', AclLabelComponent).name;
