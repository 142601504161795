const tpl = require('./MatrixDistributionChart.html');
import jquery from 'jquery';
import highcharts from 'highcharts';
const Entities = require('html-entities').AllHtmlEntities;
let priv = {};

class MatrixDistributionChartController {
	constructor($scope, $element, chartColorHelper, i18n, $timeout, questionHelper) {
		'ngInject';

		this.$element = $element;
		this.chartColorHelper = chartColorHelper;
		this.$scope = $scope;

		priv.$timeout = $timeout;
		priv.questionHelper = questionHelper;

		this.modeSwitcherOptions = questionHelper.getTimelineModes();
		this.external = $scope.$root.userInfo.external;

		this.loading = true;
		this.inReport = false;
		this.isSingle = false;
		this.isMulti = false;
		this.questionData = null;

		// config highcharts
		this.chartConfig = {
			chart: {
				type: 'column',
				animation: false,
				height: 396,
				spacingBottom: 40,
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
			},
			title: {
				text: undefined,
			},
			credits: {
				enabled: false,
			},
			xAxis: {
				title: {
					text: '',
				},
				labels: {
					style: {
						color: '#032c3e',
						fontSize: '12px',
					},
					autoRotationLimit: 130,
				},
				crosshair: false,
				tickWidth: 0,
				lineWidth: 2,
				lineColor: '#777',
				categories: [],
			},
			yAxis: {
				min: 0,
				max: 120,
				lineWidth: 2,
				lineColor: '#777',
				labels: {
					format: '{value}%',
					style: {
						color: '#032c3e',
						fontSize: '12px',
					},
				},
				title: {
					text: '',
				},
				endOnTick: false,
				stackLabels: {
					enabled: false,
				},
			},
			legend: {
				enabled: true,
				symbolRadius: 2,
				margin: 40,
				itemStyle: {
					width: '120px',
				},
			},
			plotOptions: {
				column: {
					borderWidth: 0,
					dataLabels: {
						enabled: false,
					},
				},
				series: {
					animation: !this.external,
				},
			},
			tooltip: {
				pointFormat:
					'<br><span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}%</b><br/>',
				useHTML: true,
			},
		};
	}

	$onInit() {
		this.inReport = !!this.reportPart;
	}

	$onChanges(changes) {
		if (this.questionCtrl) {
			this.questionData = this.questionCtrl.getQuestionData();
			this.isSingle = priv.questionHelper.isMatrixSingle(this.questionData);
			this.isMulti = priv.questionHelper.isMatrixMultiple(this.questionData);
			let dataSeries = this.createSeries();
			let config = this.createConfig(dataSeries);
			this.chart = highcharts.chart(jquery(this.$element).find('.chart__main')[0], config);
			this.loading = false;

			this.mainWrapper = this.$element[0].children[0];
			// this.mainWrapper = this.$element[0];
			if (
				this.chart.renderTo &&
				this.chart.renderTo.children &&
				this.chart.renderTo.children.length
			) {
				this.chartContainer = this.chart.renderTo.children[0];
			} else {
				this.chartContainer = null;
			}
			if (this.chartContainer) {
				// odświeżanie widoku po zmianie rozmiaru
				this.$scope.$watch(
					() => this.chart.renderTo.clientWidth,
					() => {
						try {
							if (this.chartContainer.clientWidth > this.mainWrapper.clientWidth) {
								this.chart.reflow();
							}
						} catch (error) {
							console.error(error);
						}
					}
				);
			}
			this.questionCtrl.setChartReference(this);
			this.questionCtrl.chartInitialized();
		}
	}

	createSeries() {
		let series = [];
		const entities = new Entities();

		if (
			this.questionData &&
			this.questionData.rows &&
			this.questionData.rows.length &&
			this.questionData.columns &&
			this.questionData.columns.length
		) {
			let child = this.questionCtrl.getChild();

			for (let j = 0; j < this.questionData.rows.length; j++) {
				let title = this.questionData.rows[j].title;
				entities.decode(title);
				title = entities.decode(title);

				this.chartConfig.xAxis.categories.push(title);
			}

			for (let i = 0; i < this.questionData.columns.length; i++) {
				let title = this.questionData.columns[i].title;
				title = entities.decode(title);

				let single = {
					name: title,
					data: [],
					color: child.getBarColor(i),
				};
				series.push(single);

				for (let j = 0; j < this.questionData.rows.length; j++) {
					single.data.push(Number(this.questionData.answers[j][i].percent));
				}
			}
		}

		return series;
	}

	createConfig(dataSeries) {
		if (this.isSingle) {
			this.chartConfig.plotOptions.column.stacking = 'normal';
		}
		this.chartConfig.series = dataSeries;

		return this.chartConfig;
	}

	updateColor(color) {
		this.loading = true;
		this.chartColorHelper.setSourceColor(color);
		this.chartColorHelper.reset();
		let dataSeries = this.createSeries();
		let config = this.createConfig(dataSeries);
		this.chart = highcharts.chart(jquery(this.$element).find('.chart__main')[0], config);
		this.loading = false;
	}

	isReady() {
		return !this.isLoading;
	}
}

export const matrixDistributionChart = {
	template: tpl,
	controller: MatrixDistributionChartController,
	require: {
		questionCtrl: '?^aRQuestion',
		reportPart: '?^reportPart',
	},
	bindings: {
		type: '<',
	},
};
