import tpl from './SettingsWrapper.html';

class SettingsWrapperController {
	constructor($state) {
		'ngInject';

		this.$state = $state;

		this.subMenu = null;
	}

	$postLink() {
		this.activateSubState();
	}

	activateSubState() {
		if (this.$state.current.data && this.subMenu) {
			if (this.$state.current.data.subLevel !== undefined) {
				this.subMenu.activateLevel(this.$state.current.data.subLevel);
			}
		}
	}

	addSubMenuReference(ctrl) {
		this.subMenu = ctrl;
	}
}

export const SettingsWrapper = {
	template: tpl,
	controller: SettingsWrapperController,
};
