const Entities = require('html-entities').AllHtmlEntities;

class AbstractResource {
	constructor(i18n, analyzeSetStorage) {
		this.noResourceListPlaceholder = i18n.__('Brak danych', 'reports');

		this.resourceList = null;
		this.selectedResource = null;
		this.entities = new Entities();

		this.analyzeSetStorage = analyzeSetStorage;
	}

	$onInit() {
		this.fetchResource();
	}

	async fetchResource() {
		throw new Error('You have to implement method');
	}
}

const AbstractResourceConfig = {
	bindings: {
		set: '<',
		onChange: '&',
	},
};

export { AbstractResource, AbstractResourceConfig };
