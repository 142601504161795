const tpl = require('./PagerComponent.html');

class PagerComponentController {
	constructor($scope, surveyProviderService, i18n) {
		'ngInject';

		this.$scope = $scope;
		this.surveyProviderService = surveyProviderService;
		this.i18n = i18n;

		this.pageLimit = 10;
		this.PAGE_LIMITS = [10, 20, 50, 100];
		this.LOCAL_STORAGE_KEY = 'pageLimit';

		this.isPagerVisible = false;
	}

	$onChanges(changes) {
		if (changes.totalCount && changes.totalCount.currentValue > 0) {
			this.optimizePageLimits();
			this.checkLocalStorage();
		} else {
			this.emitPagerInitialized();
		}
	}

	emitPageLimitChange(isFirst = false) {
		this.$scope.$emit('pageLimitChange', {
			pageLimit: this.pageLimit,
			isFirst,
		});
	}

	emitPagerInitialized() {
		this.$scope.$emit('pagerInitialized', {
			visibility: this.isPagerVisible,
		});
	}

	optimizePageLimits() {
		const key = this.PAGE_LIMITS.findIndex((limit) => {
			return limit >= this.totalCount;
		});

		if (key !== -1) {
			this.PAGE_LIMITS.splice(key + 1);
		}

		this.isPagerVisible = this.PAGE_LIMITS.length > 1;
		this.emitPagerInitialized();
	}

	pageLimitChange() {
		this.savePageLimitToLocalStorage();
		this.emitPageLimitChange();
	}

	checkLocalStorage() {
		const limit = Number(this.getPageLimitFromLocalStorage());

		if (limit !== this.pageLimit && this.PAGE_LIMITS.indexOf(limit) !== -1) {
			this.pageLimit = limit;
			this.emitPageLimitChange(true);
		}
	}

	savePageLimitToLocalStorage() {
		window.localStorage.setItem(this.getLocalStorageKey(), this.pageLimit);
	}

	getPageLimitFromLocalStorage() {
		return window.localStorage.getItem(this.getLocalStorageKey());
	}

	getLocalStorageKey() {
		return `${this.LOCAL_STORAGE_KEY}_${this.questionId}`;
	}
}

export const pagerComponent = {
	template: tpl,
	controller: PagerComponentController,
	bindings: {
		questionId: '<',
		totalCount: '<',
	},
};
