const googleAnalyticsInit = ($rootScope, $window) => {
	'ngInject';

	// $rootScope.$on('$locationChangeSuccess', () => {
	// 	let title = $rootScope.currentTitle;
	// 	let url = '/app/' + $window.location.hash;
	//
	// 	if (typeof $window.dataLayer !== 'undefined') {
	// 		$window.dataLayer.push({
	// 			event: 'VirtualPageview',
	// 			virtualPageURL: url,
	// 			virtualPageTitle: title,
	// 		});
	// 	} else {
	// 		if (typeof $window._gaq !== 'undefined') {
	// 			$window._gaq.push(['_trackPageview', url]);
	// 		}
	// 		if (typeof $window.ga !== 'undefined') {
	// 			$window.ga('send', 'pageview', {
	// 				page: url,
	// 			});
	// 		}
	// 	}
	// });
};

export { googleAnalyticsInit };
