import template from './VerbatimElementInfo.html';

import { NpsQuestion } from '../../utils/NpsQuestion';
import { VerbatimParametersEvents } from '../verbatimParameters/events.verbatimParameters';
import { VerbatimParametersType } from '../../services/verbatims/type.verbatimParameters';

class VerbatimElementInfoController {
	constructor(i18n, $scope, $rootScope, $interpolate) {
		'ngInject';

		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$interpolate = $interpolate;
		this.i18n = i18n;

		this.sheet = null;
		this.segmentsList = [];
		this.metaList = [];
		this.date = null;
	}

	$onInit() {
		this.parametersObserver();
		this.date = this.fillingDateText();
	}

	parametersObserver() {
		let observer = this.$rootScope.$on(VerbatimParametersEvents.loaded, (event, data) => {
			this.metaList = data.chosenParameters[VerbatimParametersType.metaList];
			this.segmentsList = data.parameters[VerbatimParametersType.segmentsList];

			this.$scope.$apply();
		});
		this.$scope.$on('$destroy', observer);
	}

	getNameOfSegment(key) {
		//key is - field1, field2, field3... field15
		key = parseInt(key.replace('field', ''), 10);

		if (this.segmentsList.length) {
			let el = this.segmentsList.find((el) => Number(el.rowIndex) === key);
			return el.title;
		}

		return '';
	}

	npsColor(nps) {
		if (NpsQuestion.promoters.indexOf(nps) !== -1) {
			return 'dataList__countBox--promoter';
		}
		if (NpsQuestion.neutrals.indexOf(nps) !== -1) {
			return 'dataList__countBox--neutral';
		}
		if (NpsQuestion.critics.indexOf(nps) !== -1) {
			return 'dataList__countBox--critic';
		}

		return '';
	}

	getMetaList() {
		return this.metaList;
	}

	isMetaParameterChosen(parameter) {
		return this.getMetaList().indexOf(parameter) > -1;
	}

	isMetaParameterInLog(parameter) {
		return !!this.sheet.meta[parameter];
	}

	isNpsScoreVisible() {
		return this.sheet.npsScore !== null;
	}

	isRatingScoreVisible() {
		return !this.isNpsScoreVisible() && this.sheet.ratingScore !== null;
	}

	isEmailInTitleVisible() {
		return this.sheet.segment && this.isMetaParameterChosen('email');
	}

	isOrderInTitleVisible() {
		if (this.isMetaParameterChosen('order') && !this.isEmailInTitleVisible()) {
			return true;
		}

		if (
			this.getMetaList().length === 0 ||
			(this.sheet.segment && this.sheet.segment.length === 0)
		) {
			return true;
		}

		return false;
	}

	isOrderVisible() {
		return this.isMetaParameterChosen('order') && !this.isOrderInTitleVisible();
	}

	isIdVisible() {
		return this.sheet.meta.id && this.isMetaParameterChosen('id');
	}

	isEndedAtVisible() {
		return this.isMetaParameterInLog('endedAt') && this.isMetaParameterChosen('endedAt');
	}

	isExternalKeyVisible() {
		return this.isMetaParameterInLog('externalKey') && this.isMetaParameterChosen('externalKey');
	}

	isUserTokenVisible() {
		return this.isMetaParameterInLog('userToken') && this.isMetaParameterChosen('userToken');
	}

	fillingDateText() {
		let exp = this.$interpolate("{{ dateParameter | date: 'dd/MM/yyyy HH:mm'}}");
		return this.i18n._s(
			'Wypełniono: %s',
			exp({ dateParameter: this.sheet.meta.endedAt * 1000 }),
			'reports'
		);
	}
}

export const verbatimElementInfo = {
	template,
	controller: VerbatimElementInfoController,
	bindings: {
		sheet: '<',
	},
};
