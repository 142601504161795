class SortingType {
	constructor(i18n) {
		'ngInject';

		this.types = [
			{
				label: i18n.__('Od najstarszych', 'reports'),
				type: 'asc',
			},
			{
				label: i18n.__('Od najnowszych', 'reports'),
				type: 'desc',
			},
		];
	}

	getTypes() {
		return this.types;
	}
}

export { SortingType };
