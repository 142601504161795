import Template from './call-to-action-box.html';
require('./call-to-action-box.scss');

class CallToActionBoxController {
	constructor(aclManager, i18n, segmentService, userService) {
		'nginject';

		this.aclManager = aclManager;
		this.i18n = i18n;
		this.segmentService = segmentService;
		this.userService = userService;
	}

	shouldShowButtons() {
		return this.featureId || (this.normalButtonText && this.normalButtonOnClick);
	}

	isFeaturePurchased() {
		if (!this.featureId && this.normalButtonText && this.normalButtonOnClick) {
			return true;
		}

		if (!this.featureId) {
			return false;
		}

		return this.aclManager.isFeatureEnabled(this.featureId);
	}

	isButtonDisabled() {
		if (typeof this.enabled === 'undefined') {
			return false;
		}

		return !this.enabled;
	}

	getText() {
		if (!this.featureId) {
			return this.text;
		}

		return this.isFeaturePurchased() ? this.text : this.upsellText;
	}

	isTrial() {
		return this.userService.userInfo.trial;
	}

	sendInactiveButtonEvent() {
		function getPageName() {
			const bodyClasses = document.body.classList;

			if (bodyClasses.contains('analyze_trends')) {
				return 'Trendy';
			} else if (bodyClasses.contains('analyze_rankings')) {
				return 'Rankingi';
			} else if (bodyClasses.contains('analyze_crossTables')) {
				return 'Tabele krzyżowe';
			}
		}

		const pageName = getPageName();

		this.segmentService.track('Nieaktywny button', {
			page: pageName,
		});
	}

	onClick(event) {
		if (this.isButtonDisabled()) {
			this.sendInactiveButtonEvent();
			return;
		}

		event.preventDefault();
		this.normalButtonOnClick();
	}
}

export const CallToActionComponent = {
	template: Template,
	controller: ['aclManager', 'i18n', 'segmentService', 'userService', CallToActionBoxController],
	bindings: {
		text: '@',
		upsellText: '@?',
		bold: '<?',
		featureId: '<?',
		normalButtonText: '@?',
		normalButtonOnClick: '<?',
		inline: '<?',
		enabled: '<?',
		tooltipText: '@?',
		tooltipDirection: '@?',
	},
};
