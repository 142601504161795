import Template from './AggregatedEmptyScreen.html';

class AggregatedEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Wyniki zbiorcze przedstawiają w formie graficznej i liczbowej udzielone odpowiedzi na pytania.',
					'user'
				),
				this.i18n.__(
					'Wyniki aktualizują się w czasie rzeczywistym, tzn. od razu po zakończeniu wypełnienia ankiety przez respondenta.',
					'user'
				),
			],
			2: [
				this.i18n.__(
					'Wyniki możesz łatwo filtrować tzn. sprawdzać jak odpowiadały osoby, które zaznaczyły konkretną odpowiedź np. mężczyźni lub kobiety z wielkich miast.',
					'user'
				),
				this.i18n.__(
					'Zebrane wyniki i gotowe raporty możesz też wyeksportować do najpopularniejszych programów jak Excel, PowerPoint, Word czy SPSS.',
					'user'
				),
			],
			3: [
				this.i18n.__(
					'Każdy z wykresów można dodać do końcowego raportu z badania, zmieniać jego pozycję i zakres danych.',
					'user'
				),
			],
			4: [
				this.i18n.__(
					'Niektóre z rodzajów pytań (np. Net Promoter Score), posiadają dedykowaną wizualizację danych z automatycznym liczeniem wyniku.',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}
}

export const aggregatedEmptyScreen = {
	template: Template,
	controller: ['i18n', AggregatedEmptyScreenController],
};
