// const tpl = require('./QuestionMatrixMulti.html');
const tpl = require('./QuestionMatrix.html');
let { AbstractQuestionMatrixController } = require('./AbstractQuestionMatrixController');

class QuestionMatrixMultiController extends AbstractQuestionMatrixController {
	constructor(
		chartColorHelper,
		modalService,
		$filter,
		questionHelper,
		i18n,
		quickFilterGenerator,
		filtersHelper
	) {
		'ngInject';

		super(
			chartColorHelper,
			modalService,
			$filter,
			questionHelper,
			i18n,
			quickFilterGenerator,
			filtersHelper
		);

		this.matrixType = this.types.matrixMulti;
	}

	$onInit() {
		super.$onInit();
	}

	/**
	 * getBarWidth - description
	 *
	 * @param  {Object} columnData   description
	 * @return {Number}              description
	 */
	getBarWidth(columnData, rowData, answer) {
		const percent = answer.percent;
		return percent >= 100 ? 100 : percent;
	}
}

export const questionMatrixMulti = {
	template: tpl,
	controller: QuestionMatrixMultiController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
