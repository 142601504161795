const tpl = require('./QuestionNPS.html');
const jquery = require('jquery'); // todo: Remove ?

class QuestionNPSController {
	constructor($scope, $element, i18n, questionHelper, quickFilterGenerator) {
		'ngInject';

		this.$scope = $scope;
		this.$element = $element;
		this.i18n = i18n;
		this.questionHelper = questionHelper;
		this.quickFilterGenerator = quickFilterGenerator;

		this.chart = null;
		this.extraChart = null;
		this.mainWrapper = null;
		this.chartContainer = null;
		this.timelineData = null;
		this.timelinePeriod = null;
		this.timelinePeriodSettingId = null;
		this.partCtrl = null;
		this.partData = null;
		this.additionalSettingsTypes = null;

		this.hasReasons = false;
		this.showTrend = true;
		this.showTable = true;
		this.ready = false;
		this.isDynamicY = 0;
		this.isDynamicYSettingId = null;

		this.distributionValues = {
			detractors: 0,
			neutrals: 0,
			promoters: 0,
		};
	}

	$onInit() {
		let opts = {};
		this.chart = this.questionCtrl.getChart();
		this.mainWrapper = this.$element[0].children[0];
		this.questionCtrl.setChild(this);
		this.partCtrl = this.questionCtrl.getPartController();
		this.additionalSettingsTypes = this.questionCtrl.reportHelper.getAdditionalSettingsTypes();
		let types = this.questionCtrl.reportHelper.getReportPartTypes();

		if (
			this.questionCtrl.reportType &&
			this.questionCtrl.reportType !== types.TABLE_TREND &&
			this.questionCtrl.reportType !== types.TREND
		) {
			this.showTrend = false;
			this.ready = true;

			if (this.reportBuilder) {
				this.reportBuilder.reportFullyLoaded();
			}
		} else if (this.questionCtrl && this.questionCtrl.getPartController()) {
			let partCtrl = this.questionCtrl.getPartController();
			let partData = partCtrl.getPartData();
			opts.filterId = partData.filter;

			if (partData && partData.additionalSettings) {
				for (let i = 0; i < partData.additionalSettings.length; i++) {
					if (partData.additionalSettings[i].type === this.additionalSettingsTypes.timelineMode) {
						this.timelinePeriod = partData.additionalSettings[i].setup;
						this.timelinePeriodSettingId = partData.additionalSettings[i].id;
					}
					if (partData.additionalSettings[i].type === this.additionalSettingsTypes.isDynamicAxisY) {
						this.isDynamicY = 1;
						this.isDynamicYSettingId = partData.additionalSettings[i].id;
					}
				}

				this.partData = partData;
			}
		} else if (this.questionCtrl && this.questionCtrl.surveyProviderService.filterId) {
			opts.filterId = this.questionCtrl.surveyProviderService.filterId;
		}
		if (
			this.questionCtrl.reportType &&
			(this.questionCtrl.reportType === types.CHART || this.questionCtrl.reportType === types.TREND)
		) {
			this.showTable = false;
		}
		this.hasReasons = this.questionCtrl.hasReasons();

		opts.firstLoad = 1;
		this.updateTimeline(opts);
	}

	$postLink() {
		this.chart = this.questionCtrl.getChart();
		if (
			this.chart.renderTo &&
			this.chart.renderTo.children &&
			this.chart.renderTo.children.length
		) {
			this.chartContainer = this.chart.renderTo.children[0];
		} else {
			this.chartContainer = null;
		}

		this.chart.update(
			{
				plotOptions: {
					column: {
						dataLabels: {
							enabled: true,
							style: {
								color: '#032c3e',
								fontSize: '12px',
								fontWeight: 'normal',
								textOutline: '1px 1px contrast',
							},
						},
					},
				},
				chart: {
					spacingLeft: 22,
					spacingRight: 22,
				},
				xAxis: {
					labels: {
						enabled: true,
					},
					categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
				},
			},
			true
		);

		this.distributionValues.detractors = Math.round(this.questionData.detractors);
		this.distributionValues.promoters = Math.round(this.questionData.promoters);
		this.distributionValues.neutrals =
			100 - this.distributionValues.detractors - this.distributionValues.promoters;

		if (this.chartContainer) {
			this.$scope.$watch(
				() => this.chart.renderTo.clientWidth,
				() => {
					try {
						if (this.chartContainer.clientWidth > this.mainWrapper.clientWidth) {
							this.chart.reflow();
						}
					} catch (error) {
						console.error(error);
					}
				}
			);
		}
	}

	updateTimelineOptions(opts) {
		if (this.partData && this.partData.filter) {
			opts.filterId = this.partData.filter;
		}

		if (opts.firstLoad) {
			opts.firstLoad = !opts.firstLoad;
		} else {
			if (opts.timeline) {
				this.updateTimeline(opts);
			}

			if (this.reportPart) {
				let settingId = null;
				let data = null;
				let partId = this.partData.id;
				let chapterId = this.questionCtrl.getPartController().chapterCtrl.getId();

				if (opts.timeline) {
					data = { type: this.additionalSettingsTypes.timelineMode, setup: opts.mode };
					settingId = this.timelinePeriodSettingId;
				} else if (opts.dynamicY) {
					data = { type: this.additionalSettingsTypes.isDynamicAxisY, setup: Number(opts.mode) };
					settingId = this.isDynamicYSettingId;
				}

				if (settingId) {
					this.questionCtrl.reportsService.updateAdditionalSettingsPromise(
						null,
						chapterId,
						partId,
						settingId,
						data
					);
				} else {
					this.questionCtrl.reportsService
						.addAdditionalSettingsPromise(null, chapterId, partId, data)
						.then((response) => {
							if (opts.timeline) {
								this.timelinePeriodSettingId = response.id;
							} else {
								this.isDynamicYSettingId = response.id;
							}
						});
				}
			}
		}
	}

	updateTimeline(opts) {
		let filterId = opts && opts.filterId ? opts.filterId : undefined;
		let mode = this.timelinePeriod || this.questionHelper.getTimelineModes().week;

		mode = opts && opts.mode ? opts.mode : mode;

		let successCallback = (opts && opts.successCallback) || function () {};
		let errorCallback = (opts && opts.errorCallback) || function () {};
		this.questionCtrl.surveyProviderService
			.getResultsTimelinePromise(this.questionData.id, mode, filterId)
			.then((timelineData) => {
				this.timelineData = timelineData;
				this.timelinePeriod = mode;
				this.ready = true;

				if (this.reportBuilder) {
					this.reportBuilder.reportFullyLoaded();
				}

				successCallback(this.timelineData);
			}, errorCallback);
	}

	getTimelineMode() {
		return this.timelinePeriod;
	}

	setExtraChart(ctrl) {
		this.extraChart = ctrl;
	}

	isReady() {
		if (this.extraChart) {
			return this.ready && this.extraChart.isReady();
		}
		return this.ready;
	}

	getQuickFilterData(answers) {
		return {
			questionData: this.questionData,
			answerTitle: this.quickFilterGenerator.prepareTitleForNPS(answers),
			answers: answers.values,
		};
	}
}

export const questionNPS = {
	template: tpl,
	controller: QuestionNPSController,
	require: {
		questionCtrl: '^aRQuestion',
		reportBuilder: '?^reportBuilder',
		reportPart: '?^reportPart',
	},
	bindings: {
		questionData: '<',
	},
};
