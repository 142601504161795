class AbstractPaginationManager {
	constructor(surveyProviderService, alertsService, i18n, questionHelper, $scope) {
		// "ngInject";

		this.surveyProviderService = surveyProviderService;
		this.alertsService = alertsService;
		this.i18n = i18n;
		this.questionHelper = questionHelper;
		this.$scope = $scope;

		this.pagination = {
			perPage: 10,
			total: 0,
			show: false,
			loadedPages: [1],
			currentPage: 1,
			ready: false,
			loading: true,
		};
		this.answers = null;
		this.paginationView = null;
		this.loader = null;
		this.filterId = null;

		this.isSimple = false;
		this.isNPS = false;
		this.isMatrixOpen = false;
		this.isMatrix = false;
		this.isRating = false;
		this.isOther = false;
		this.isList = false;

		this.noPagination = false;
		this.refreshTimeout = 0;
		this.refreshTimeoutId = null;

		this.isPagerRequired = true;
	}

	paginationInit(answers, opts) {
		this.bindPager();

		clearInterval(this.refreshTimeoutId);

		this.pagination = {
			perPage: 10,
			total: 0,
			show: false,
			loadedPages: [1],
			currentPage: 1,
			ready: false,
			loading: true,
		};

		if (opts && opts.filterId) {
			this.filterId = opts.filterId;
		}

		this.pageLimit = opts && opts.pageLimit;

		this.answers = answers;
		let tmpAnswers = answers.slice(0);

		this.pagination.ready = true;
		this.pagination.loading = false;

		this.isSimple = opts && opts.isSimple;
		this.isNPS = opts && opts.isNPS;
		this.isMatrixOpen = opts && opts.isMatrixOpen;
		this.isMatrix = opts && opts.isMatrix;
		this.isRating = opts && opts.isRating;

		this.isOther = opts && opts.other;
		this.columnIndex = opts && opts.columnIndex;
		this.rowIndex = opts && opts.rowIndex;

		this.isList = opts && opts.isList;
		this.loader = opts && opts.loader;
		this.refreshTimeout = opts && opts.refreshTimeout;

		if (this.isOther || this.isList || this.isRating || this.isMatrix) {
			this.pagination.perPage = opts && opts.perPage ? opts.perPage : this.pagination.perPage;
			this.pagination.total = opts && opts.total ? opts.total : this.pagination.total;
		} else {
			if (this.questionData.paginationPageCount) {
				this.pagination.perPage = this.questionData.paginationPageCount;
			}
			if (this.questionData.paginationTotalCount) {
				this.pagination.total = this.questionData.paginationTotalCount;
			}
		}

		if (this.answers.length < this.pagination.total) {
			let start = this.answers.length;
			let filler = [];
			let fillElement = null;

			if (this.questionData && this.questionData.fields && this.questionData.fields.length) {
				fillElement = {};
				for (let i = 0; i < this.questionData.fields.length; i++) {
					fillElement[i] = '';
				}
			} else {
				fillElement = { count: 0 };
			}

			for (let i = this.answers.length; i < this.pagination.total; i++) {
				this.answers[i] = fillElement;
			}

			if (this.isSimple || this.isNPS) {
				this.surveyProviderService.continouslyLoadOthers(
					this.questionData,
					this.pagination,
					opts.answerIndex,
					tmpAnswers,
					(data, pageNumber, items) => {
						this.handleDownloadedAnswers(data, pageNumber);
						this.answers = items.slice(0);
					},
					() => {
						if (this.attachment) {
							this.attachment.attachmentFullyLoaded();
						}
					}
				);
			} else if (this.isMatrix || this.isRating) {
				this.surveyProviderService.continouslyLoadOthersForMatrix(
					this.questionData,
					this.pagination,
					{ index: this.rowIndex },
					{ index: this.columnIndex },
					this.isOther,
					tmpAnswers,
					(data, pageNumber, items) => {
						this.handleDownloadedAnswers(data, pageNumber);
						this.answers = items.slice(0);
					},
					(count) => {
						if (this.attachment) {
							this.attachment.attachmentFullyLoaded();
						}
					}
				);
			} else {
				this.surveyProviderService.continouslyLoadAnswers(
					this.questionData,
					(data, pageNumber) => {
						this.handleDownloadedAnswers(data, pageNumber);

						this.answers = this.questionData.answers.slice(0);
					},
					(count) => {
						if (this.attachment) {
							// console.log('ELSE');
							this.attachment.attachmentFullyLoaded();
						}
					}
				);
				// }
			}
		} else if (this.attachment) {
			// console.log('else if (this.attachment');
			this.attachment.attachmentFullyLoaded();
		}
		this.initRefresh();
	}

	initRefresh() {
		if (this.refreshTimeout) {
			this.refreshTimeoutId = setInterval(() => {
				this.handlePageDownload(this.pagination.currentPage, true);
			}, this.refreshTimeout);
		}
	}

	handlePageDownload(pageNumber, refresh) {
		if (this.pagination.loadedPages.indexOf(pageNumber) === -1 || refresh) {
			this.pagination.loading = true;

			if (this.isMatrix || this.isRating) {
				this.surveyProviderService
					.getOthersForMatrixQuestionPromise(
						this.questionData.id,
						{ index: this.rowIndex },
						{ index: this.columnIndex },
						this.isOther,
						pageNumber,
						this.filterId,
						this.filterId === null,
						this.pagination.perPage
					)
					.then((data) => {
						if (data && data.total) {
							this.handleDownloadedAnswers({ answers: data.items }, pageNumber);
						}
					}, this.errorHandlerFactory());
			} else if (this.isOther) {
				this.surveyProviderService
					.getOthersForQuestionPromise(
						this.questionData.id,
						this.answer.index,
						pageNumber,
						this.filterId,
						isNaN(this.filterId),
						this.pagination.perPage
					)
					.then((data) => {
						this.handleDownloadedAnswers({ answers: data.items }, pageNumber);
					}, this.errorHandlerFactory());
			} else if (this.isList && this.loader) {
				this.loader(pageNumber).then((data) => {
					this.handleDownloadedAnswers({ items: data }, pageNumber, refresh);
				}, this.errorHandlerFactory());
			} else {
				this.surveyProviderService
					.getQuestionPromiseById(
						this.questionData.id,
						this.filterId,
						pageNumber,
						this.filterId === null,
						this.pagination.perPage
					)
					.then((data) => {
						this.handleDownloadedAnswers(data, pageNumber);
					}, this.errorHandlerFactory());
			}
		} else {
			this.$scope.$apply();
		}
	}

	handleDownloadedAnswers(data, pageNumber, refresh) {
		data = this.checkOtherConditions(data);

		let tmp = [];
		tmp[0] = (pageNumber - 1) * this.pagination.perPage;
		tmp[1] = this.pagination.perPage;

		tmp = tmp.concat(data.answers || data.items);
		Array.prototype.splice.apply(this.answers, tmp);
		if (!refresh) {
			this.pagination.loadedPages.push(pageNumber);
		}
		this.pagination.loading = false;

		this.updatePaginationView();
	}

	checkOtherConditions(data) {
		if (this.questionHelper.isNumber(data)) {
			return this.questionHelper.filterDotForNumeric(data);
		}

		return data;
	}

	errorHandlerFactory() {
		return (errData) => {
			this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem', 'reports'));
			this.pagination.loading = false;
		};
	}

	setPaginationView(view) {
		this.paginationView = view;
	}

	updatePaginationView() {
		if (this.paginationView && this.paginationView.updatePagination) {
			this.paginationView.updatePagination({ answers: this.answers });
		}
	}

	bindPager() {
		this.$scope.$on('pageLimitChange', (event, data) => {
			this.pagination.perPage = data.pageLimit;
			this.handlePageDownload(this.pagination.currentPage, true);

			if (!data.isFirst) {
				this.scrollUp();
			}
		});

		this.$scope.$on('pagerInitialized', (event, data) => {
			this.isPagerRequired = data.visibility;
		});
	}

	scrollUp() {
		$('.modal').animate({ scrollTop: 0 }, 500);
	}

	pageChanged = (newPage) => {
		this.pagination.currentPage = newPage;
		this.handlePageDownload(newPage);
	};
}

export { AbstractPaginationManager };
