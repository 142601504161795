const tpl = require('./SubMenuLink.html');

/**
 * SubMenuItemController
 *
 * @class
 * @module app
 */
class SubMenuLinkController {
	constructor() {
		'ngInject';

		this.DATA_INFO = window.DATA_INFO;
	}
}

export const subMenuLink = {
	template: tpl,
	controller: SubMenuLinkController,
	transclude: true,
	bindings: {
		text: '<',
		href: '<',
		level: '<',
		beta: '<',
	},
};
