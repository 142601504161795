import Template from './ReportsListEmptyScreen.html';

class ReportsListEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Stwórz własny raport dodając i modfikując dane, które oglądasz w sekcji Wyniki.',
					'user'
				),
				this.i18n.__(
					'Dopracuj wygląd raportu, logo, kolorystykę,  rodzaj wykresów, dodawaj komentarze.',
					'user'
				),
				this.i18n.__(
					'Zapisz raport jako PDF lub stwórz interaktywny raport online dostępny jako link, który będzie odświeżał się gdy w ankiecie pojawią się nowe wypełnienia.',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}
}

export const ReportsListEmptyScreenComponent = {
	template: Template,
	controller: ['i18n', ReportsListEmptyScreenController],
	bindings: {
		onButtonClick: '<',
	},
};
