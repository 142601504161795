export const SupportEvent = {
	reportCreated: 'Dodano nowy raport',
	reportColorsUpdated: 'Zmieniono kolory w raporcie',
	reportPartAdded: 'Dodano wyniki do raportu',
	reportLogoUploaded: 'Dodano logo do raportu',
	reportTextBlockCreated: 'Dodano tekst do raportu',
	reportChapterCreated: 'Dodano rozdział do raportu',
	reportExportFormat: 'Wyeksportowano nowy raport',
	reportDownloaded: 'Pobrano raport',
	filterApplied: 'Zastosowano filtr',
	rawExportClicked: 'Wyniki zbiorcze_surowe klik',
};
