import { AbstractResource, AbstractResourceConfig } from './AbstractResource';

import tpl from '../ChooserTemplate/SingleChooser.html';

class QuestionChooserController extends AbstractResource {
	constructor(i18n, analyzeSetStorage) {
		'ngInject';

		super(i18n, analyzeSetStorage);

		this.placeholder = i18n.__('Pytanie', 'reports');
	}

	$onDestroy() {
		delete this.set.question;
	}

	async fetchResource() {
		this.resourceList = $$await(await this.analyzeSetStorage.fetchQuestions());

		if (this.set.question) {
			this.selectedResource = this.set.question;
		}
	}

	onSelectResource() {
		this.set.question = this.selectedResource;
	}
}

QuestionChooserController.$inject = ['i18n', 'analyzeSetStorage'];

export const QuestionChooser = Object.assign(
	{
		template: tpl,
		controller: QuestionChooserController,
	},
	AbstractResourceConfig
);
