import tpl from './QuickFilterRevertComponent.html';

class QuickFilterRevertComponentController {
	constructor(i18n, quickFilterGenerator) {
		'ngInject';

		this.i18n = i18n;
		this.quickFilterGenerator = quickFilterGenerator;
	}

	onQuickFilterRevert(event) {
		event.stopPropagation();
		this.quickFilterGenerator.revertFilter(this.filter);
	}
}

export const quickFilterRevertComponent = {
	template: tpl,
	controller: QuickFilterRevertComponentController,
	bindings: {
		filter: '<',
	},
};
