const tpl = require('./FileExporter.html');
class FileExporterController {
	constructor($scope, reportHelper, i18n) {
		'ngInject';

		this.$scope = $scope;
		this.reportHelper = reportHelper;
		this.i18n = i18n;

		this.EXPORT_FORMATS = reportHelper.getExportFormats();
		this.exportFormat = !this.EXPORT_FORMATS[0].disabled ? this.EXPORT_FORMATS[0] : null;

		this.modalData = {};
		this.extraClass = '';

		this.dynamicText = '';
	}

	$onInit() {
		if (this.resolve && this.resolve.modalData) {
			this.modalData = this.resolve.modalData;
		}
	}

	downloadAction(format) {
		format = format.format;
		if (this.resolve && this.resolve.download && typeof this.resolve.download === 'function') {
			this.resolve.download(format, this);
		}
	}

	onChangeFormat(format) {
		this.exportFormat = format.disabled ? this.exportFormat : format;
		this.onSettingChange();
	}

	onSettingChange() {
		const settings = this.exportFormat.additionalOptions;

		if (!settings) {
			this.dynamicText = '';
			return;
		}

		const keys = Object.keys(settings);

		let info = '';

		keys.forEach((key) => {
			if (settings[key].checked && settings[key].additionalInfo) {
				info += settings[key].additionalInfo;
			}
		});

		if (info.length) {
			this.dynamicText = '<h3>' + this.i18n.__('Informacja', 'reports') + '</h3>' + info;
		} else {
			this.dynamicText = '';
		}
	}

	getAclFeature(format) {
		return this.$scope.$root.features[`report${format.toUpperCase()}`];
	}

	refreshExportTypes() {
		this.EXPORT_FORMATS = this.reportHelper.getExportFormats();
	}
}

export const fileExporter = {
	template: tpl,
	controller: FileExporterController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
