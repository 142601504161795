import tpl from './SentimentRanking.html';

class SentimentRankingController {
	constructor(i18n) {
		'ngInject';
		this.i18n = i18n;
	}
}

export const SentimentRanking = {
	template: tpl,
	controller: SentimentRankingController,
	bindings: {
		sentimentRankingData: '<',
	},
};
