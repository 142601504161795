// const tpl = require('./QuestionDragAndDrop.html');
const tpl = require('./QuestionMatrix.html');
let { AbstractQuestionMatrixController } = require('./AbstractQuestionMatrixController');

class QuestionDragAndDropController extends AbstractQuestionMatrixController {
	constructor(chartColorHelper, modalService, $filter, questionHelper, i18n, quickFilterGenerator) {
		'ngInject';

		super(chartColorHelper, modalService, $filter, questionHelper, i18n, quickFilterGenerator);
	}

	$onInit() {
		super.$onInit();
	}
}

export const questionDragAndDrop = {
	template: tpl,
	controller: QuestionDragAndDropController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
