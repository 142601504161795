import Template from './cross-table-empty-screen.html';

class CrossTableEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Obserwuj zależności pomiędzy odpowiedziami ankiety lub odpowiedzią i grupą respondentów',
					'user'
				),
				this.i18n.__(
					'Wykonuj pogłębione analizy tabeli krzyżowej zawężając zakres danych za pomocą filtrów',
					'user'
				),
			],
			2: [
				this.i18n.__('Dopracuj wykres zmieniając skalę i układ danych.', 'user'),
				this.i18n.__(
					'Eksportuj tabelę krzyżową do raportu PDF lub prosto do arkusza kalkulacyjnego. Oszczędzisz mnóstwo czasu!',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}

	getTooltipText() {
		return this.i18n.__(
			'Ta funkcja jest dostępna tylko gdy w ankiecie wykorzystujesz: pytanie jednokrotnego wyboru, wielokrotnego wyboru, macierz jednokrotnego wyboru, pytanie NPS, pytanie ocena, pytanie lista rozwijalna, pytanie przeciągnij i upuść.',
			'user'
		);
	}
}
export const CrossTableEmptyScreenComponent = {
	template: Template,
	controller: ['i18n', CrossTableEmptyScreenController],
	bindings: {
		onButtonClick: '<',
		enabled: '<',
	},
};
