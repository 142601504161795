/* eslint no-useless-escape: 0 */

const safeTranslationKey = function (str) {
	str.replace(/\n/g, '\n');
	str = polishToRoman(str);
	str = stripAsterix(str);
	str = jQuery.trim(str);

	return str;
};

const stripAsterix = function (str) {
	str = str.replace(/!/g, '_');
	str = str.replace(/\@/g, '_');
	str = str.replace(/\#/g, '_');
	str = str.replace(/\$/g, '_');
	str = str.replace(/\^/g, '_');
	str = str.replace(/\&/g, '_');
	str = str.replace(/\*/g, '_');
	str = str.replace(/\(/g, '_');
	str = str.replace(/\)/g, '_');
	str = str.replace(/\-/g, '_');
	str = str.replace(/\_/g, '_');
	str = str.replace(/\=/g, '_');
	str = str.replace(/\+/g, '_');
	str = str.replace(/\{/g, '_');
	str = str.replace(/\[/g, '_');
	str = str.replace(/\}/g, '_');
	str = str.replace(/\]/g, '_');
	str = str.replace(/\\/g, '_');
	str = str.replace(/\:/g, '_');
	str = str.replace(/\;/g, '_');
	str = str.replace(/\"/g, '_');
	str = str.replace(/\'/g, '_');
	str = str.replace(/\</g, '_');
	str = str.replace(/\>/g, '_');
	str = str.replace(/\//g, '_');

	return str;
};

const polishToRoman = function (str) {
	str = str.replace(/\ą/g, 'a');
	str = str.replace(/\Ą/g, 'A');
	str = str.replace(/\ę/g, 'e');
	str = str.replace(/\Ę/g, 'E');
	str = str.replace(/\ć/g, 'c');
	str = str.replace(/\Ć/g, 'C');
	str = str.replace(/\ł/g, 'l');
	str = str.replace(/\Ł/g, 'L');
	str = str.replace(/\ó/g, 'o');
	str = str.replace(/\Ó/g, 'O');
	str = str.replace(/\Ś/g, 'S');
	str = str.replace(/\ś/g, 's');
	str = str.replace(/\ż/g, 'z');
	str = str.replace(/\Ż/g, 'Z');
	str = str.replace(/\ź/g, 'z');
	str = str.replace(/\Ź/g, 'Z');
	str = str.replace(/\ń/g, 'n');
	str = str.replace(/\Ń/g, 'N');

	return str;
};

const sprintf = function () {
	if (!arguments || arguments.length < 1 || !RegExp) {
		return;
	}
	var str = arguments[0];
	var re = /([^%]*)%('.|0|\x20)?(-)?(\d+)?(\.\d+)?(%|b|c|d|u|f|o|s|x|X)(.*)/;
	var a,
		b,
		numSubstitutions = 0,
		numMatches = 0;
	a = b = [];
	while ((a = re.exec(str))) {
		var leftpart = a[1],
			pPad = a[2],
			pJustify = a[3],
			pMinLength = a[4];
		var pPrecision = a[5],
			pType = a[6],
			rightPart = a[7];

		numMatches++;
		if (pType == '%') {
			subst = '%';
		} else {
			numSubstitutions++;
			if (numSubstitutions >= arguments.length) {
				alert(
					'Error! Not enough function arguments (' +
						(arguments.length - 1) +
						', excluding the string)\n' +
						'for the number of substitution parameters in string (' +
						numSubstitutions +
						' so far).'
				);
			}
			var param = arguments[numSubstitutions];
			var pad = '';
			if (pPad && pPad.substr(0, 1) == "'") pad = leftpart.substr(1, 1);
			else if (pPad) pad = pPad;
			var justifyRight = true;
			if (pJustify && pJustify === '-') justifyRight = false;
			var minLength = -1;
			if (pMinLength) minLength = parseInt(pMinLength);
			var precision = -1;
			if (pPrecision && pType == 'f') precision = parseInt(pPrecision.substring(1));
			var subst = param;
			switch (pType) {
				case 'b':
					subst = parseInt(param).toString(2);
					break;
				case 'c':
					subst = String.fromCharCode(parseInt(param));
					break;
				case 'd':
					subst = parseInt(param) ? parseInt(param) : 0;
					break;
				case 'u':
					subst = Math.abs(param);
					break;
				case 'f':
					subst =
						precision > -1
							? Math.round(parseFloat(param) * Math.pow(10, precision)) / Math.pow(10, precision)
							: parseFloat(param);
					break;
				case 'o':
					subst = parseInt(param).toString(8);
					break;
				case 's':
					subst = param;
					break;
				case 'x':
					subst = ('' + parseInt(param).toString(16)).toLowerCase();
					break;
				case 'X':
					subst = ('' + parseInt(param).toString(16)).toUpperCase();
					break;
			}

			var padLeft = minLength - subst.toString().length;
			var padding;

			if (padLeft > 0) {
				var arrTmp = new Array(padLeft + 1);
				padding = arrTmp.join(pad ? pad : ' ');
			} else {
				padding = '';
			}
		}
		str = leftpart + padding + subst + rightPart;
	}
	return str;
};

class i18nService {
	constructor(langService) {
		'ngInject';

		this.langService = langService;
		this.i18n = null;
	}

	fetchTranslations() {
		$.ajax({
			url: `/scripts/i18n/${this.langService.getLang()}.js`,
			dataType: 'script',
			success: (data) => {
				eval(data);

				this.i18n = i18n;
			},
			async: false,
		});
	}

	__(key, context) {
		if (!context) {
			context = 'default';
		}

		var safeKey = safeTranslationKey(key);

		if (!this.i18n) {
			this.fetchTranslations();
		}

		if (typeof this.i18n[context] == 'object' && typeof this.i18n[context][safeKey] === 'string') {
			return this.i18n[context][safeKey];
		} else {
			if (process.env.TRANSLATION_ENABLED) {
				$.post(
					'/tools/translator/add',
					{
						context: context,
						key: key,
					},
					function (data) {}
				);
			}

			return key;
		}
	}

	_s() {
		let key = arguments[0];

		const customTagsCount = key.split('%s').length - 1;

		const contextIndex = customTagsCount + 1;
		const context = arguments[contextIndex] || null;

		key = this.__(key, context);

		key = key.replace(/%s/g, '##s');
		key = key.replace(/%/g, '##');
		key = key.replace(/##s/g, '%s');

		const argumentsToReplace = Array.from(arguments).slice(1);

		let str = sprintf(key, ...argumentsToReplace);

		str = str.replace('##', '%');

		return str;
	}
}

export { i18nService };
