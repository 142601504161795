const jquery = require('jquery');
const tpl = require('../templates/ReportPartEditor.html');
const angular = require('angular');

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/save/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/autoresize/plugin';
require.context(
	'!file-loader?name=[path][name].[ext]&context=node_modules/tinymce!tinymce/skins',
	true,
	/.*/
);

/**
 * ReportPartEditorService
 *
 * @class ReportPartEditorService
 */
class ReportPartEditorService {
	constructor($compile, reportHelper, alertsService, i18n, supportCRMService) {
		'ngInject';

		let self = this;
		this.title = '';
		this.text = '';
		this.element = null;
		this.$compile = $compile;
		this.alertsService = alertsService;
		this.i18n = i18n;
		this.supportCRMService = supportCRMService;

		this.placeholders = {
			title: i18n.__(reportHelper.getDefaultValues().partTitle, 'reports'),
			text: i18n.__(reportHelper.getDefaultValues().partText, 'reports'),
		};

		this.newScope = null;
		this.defaultValues = reportHelper.getEmptyPartClone();
		this.isNew = false;
		this.eventCapture = false;

		this.editorSubmit = function editorSubmit(e) {
			e.preventDefault();

			self.partCtrl.update({
				title: self.newScope.title,
				text: self.newScope.text,
			});

			if (self.isNew) {
				self.alertsService.success(i18n.__('Dodano komentarz', 'reports'));

				self.supportCRMService.emitReportTextBlockCreated();
			} else {
				self.alertsService.success(i18n.__('Edytowano komentarz', 'reports'));
			}

			self.hideEditor();
		};

		this.editorCancel = function editorCancel(e) {
			if (e) {
				e.preventDefault();
			}

			if (self.isNew && self.partCtrl) {
				self.partCtrl.deletePart(true);
			} else {
				self.hideEditor();
			}
		};
	}

	attachEditor(ctrl, parent, isNew) {
		let editorWrapper = jquery(parent).find('.reportPart__editorWrapper');

		this.isNew = Boolean(isNew);

		if (editorWrapper && editorWrapper.length && ctrl) {
			this.partCtrl = ctrl;
			this.newScope = this.partCtrl.$scope.$new();

			this.newScope.placeholders = this.placeholders;
			let baseURL = '/dist/new-app';

			this.newScope.tinymceOptions = {
				baseURL,
				placeholder: this.placeholders.text,
				inline: false,
				plugins: 'save lists image link autoresize',
				skin: 'oxide',
				theme: 'silver',
				elementpath: false,
				menubar: '',
				statusbar: false,
				autoresize_bottom_margin: 0,
				toolbar: 'bold italic underline numlist bullist image link',
				content_style:
					'body { margin: 18px; } p { font-family: "Poppins", sans-serif; font-size: 13px; margin-top: 0; margin-bottom: 0; color: #032c3e; color: ' +
					ctrl.getTextColor() +
					'; } p + p { margin-top: 1em; }',
			};

			this.newScope.title = this.partCtrl.part.title || '';
			this.newScope.text = this.partCtrl.part.text || '';
			this.newScope.headlineColor = ctrl.getHeadlineColor();

			this.newScope.editorSubmit = this.editorSubmit;
			this.newScope.editorCancel = this.editorCancel;
			this.element = this.$compile(angular.element(tpl))(this.newScope);

			jquery(this.element).appendTo(editorWrapper).find('.reportPart__editorInput').focus();

			this.partCtrl.builder.editionInProgress = true;
		}
	}

	idEdited() {
		if (this.newScope && this.defaultValues) {
			let edited =
				this.newScope.title !== this.defaultValues.title ||
				this.newScope.text !== this.defaultValues.text;
			return edited;
		}
		return false;
	}

	detach() {
		let editorScope = angular.element(this.element).scope();
		this.partCtrl = null;
		this.element.remove();

		if (editorScope) {
			editorScope.$destroy();
		}

		this.title = '';
		this.text = '';
	}

	hideEditor() {
		if (this.partCtrl) {
			this.partCtrl.builder.editionInProgress = false;
			this.partCtrl.setShowEdit(false);
		}
	}
}

// ReportPartEditorService.$inject = ['$compile', 'reportHelper', 'modalService'];

export default ReportPartEditorService;
