import angular from 'angular';

const tpl = require('./EditReportNameModal.html');

class EditReportNameModalController {
	constructor($scope, i18n) {
		'ngInject';

		this.$scope = $scope;
		this.i18n = i18n;

		this.modalData = {
			title: i18n.__('Edycja tytułu raportu', 'reports'),
			buttonText: i18n.__('Zapisz', 'reports'),
		};

		this.extraClass = 'newReport';
	}

	$onInit() {
		this.$scope.newReportName = this.resolve.reportName;
	}

	saveReportName() {
		this.resolve.saveReportName(this.$scope.newReportName).then((data) => {
			this.close();
		});
	}

	close() {
		this.dismiss();
	}
}

export const editReportNameModal = {
	template: tpl,
	controller: EditReportNameModalController,
	bindings: {
		resolve: '<',
		dismiss: '&',
	},
};
