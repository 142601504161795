import angular from 'angular';

class AbstractChartController {
	constructor(chartColorHelper) {
		'ngInject';
		let ctrl = this;

		this.chartColorHelper = chartColorHelper;

		this.chart = null;
		this.hasChartAnimationEnded = false;

		this.chartConfig = {};

		this.defaultOptions = {};
		this.defaultOptions.credits = {
			enabled: false,
		};
		this.defaultOptions.chart = {
			style: {
				fontFamily: '"Poppins", sans-serif',
				fontSize: '12px',
			},
		};
		this.defaultOptions.tooltip = {
			enabled: false,
		};
		this.defaultOptions.plotOptions = {};
		this.defaultOptions.plotOptions.series = {
			events: {
				afterAnimate: function afterAnimate(e) {
					ctrl.hasChartAnimationEnded = true;
					if (ctrl.questionCtrl) {
						ctrl.questionCtrl.chartAnimationEnded(ctrl.hasChartAnimationEnded);
					}
				},
			},
			point: {
				events: {
					mouseOver: function mouseOver(e) {
						// console.log('mouseOver', ctrl.questionCtrl && ctrl.hasChartAnimationEnded);
						if (ctrl.questionCtrl && ctrl.hasChartAnimationEnded) {
							ctrl.questionCtrl.chartPointMouseOver(e.target);
						}
					},
					mouseOut: function mouseOut(e) {
						// console.log('mouseOut', ctrl.questionCtrl && ctrl.hasChartAnimationEnded);
						if (ctrl.questionCtrl && ctrl.hasChartAnimationEnded) {
							ctrl.questionCtrl.chartPointMouseOut(e.target);
						}
					},
				},
			},
		};
		this.defaultOptions.xAxis = {
			labels: {
				enabled: false,
				style: {
					color: '#aaa',
					fontSize: '12px',
					fontWeight: '600',
				},
			},
			crosshair: false,
			visible: false,
			tickWidth: 0,
			lineWidth: 2,
			lineColor: '#777',
		};
		this.defaultOptions.yAxis = {
			labels: {
				enabled: false,
			},
			title: {
				enabled: false,
			},
			visible: false,
		};
		this.defaultOptions.title = {
			text: undefined,
		};
		this.defaultOptions.legend = {
			enabled: false,
		};
	}

	createSeries(title, answers) {
		let result;
		let data = [];

		answers.forEach((elem, i) => {
			if (this.questionCtrl.isAnswerNeutral(elem)) {
				return;
			}
			data.push({
				id: i,
				name: elem.title,
				y: elem.count,
			});
		});

		result = [
			{
				name: title,
				data,
			},
		];

		return result;
	}

	createConfig(dataSeries) {
		let config = angular.merge({}, this.defaultOptions, this.chartConfig);
		// console.log('createConfig', config, this.chartConfig);
		if (dataSeries) {
			config.series = dataSeries;
		}
		return config;
	}

	getChart() {
		return this.chart;
	}

	updateColor(color) {
		this.chartColorHelper.setSourceColor(color);

		this.chart.update(
			{
				colors: this.chartColorHelper.getColorsTableForChart(),
			},
			true
		);
	}
}

export { AbstractChartController };
