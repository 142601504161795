import tpl from './range-picker.html';
require('./range-picker.scss');

import flatpickr from 'flatpickr';
import { Polish } from 'flatpickr/dist/l10n/pl';
import { Slovak } from 'flatpickr/dist/l10n/sk';
import { Czech } from 'flatpickr/dist/l10n/cs';
require('flatpickr/dist/themes/material_green.css');

class RangePickerController {
	constructor(EventEmitter, $filter, $scope, $element, i18n, langService) {
		'ngInject';

		this.EventEmitter = EventEmitter;
		this.$filter = $filter;
		this.$scope = $scope;
		this.$element = $element;
		this.i18n = i18n;
		this.langService = langService;

		// flatpickr has different date format :/
		this.flatpickrDateFormat = 'm/d/Y';
		this.calendarInstance = null;

		this.range = '';
	}

	$onInit() {
		this.setCalendarConfig(this.langService.getLang());
	}

	setCalendarConfig(lang) {
		const LANGS = {
			pl: Polish,
			cs: Czech,
			sk: Slovak,
		};

		const DATE_FORMATS = {
			pl: 'd/m/Y',
			cs: 'd.m.Y',
			sk: 'd.m.Y',
			en: 'd/m/Y',
		};

		if (['pl', 'cs', 'sk'].includes(lang)) {
			flatpickr.localize(LANGS[lang]);
			this.flatpickrDateFormat = DATE_FORMATS[lang];
		}
	}

	initCalendar() {
		const config = {
			mode: 'range',
			dateFormat: this.flatpickrDateFormat,
			enableTime: true,
			defaultHour: 12,
			defaultMinute: 0,
			time_24hr: true,
			onChange: (selectedDates) => {
				this.from = selectedDates[0];
				this.from.setHours(0);
				this.from.setMinutes(0);
				this.from.setSeconds(0);

				if (selectedDates.length === 2) {
					this.to = selectedDates[1];
					this.to.setHours(23);
					this.to.setMinutes(59);
					this.to.setSeconds(59);

					this.onChange(
						this.EventEmitter({
							from: this.from.getTime(),
							to: this.to.getTime(),
						})
					);
				}
			},
		};

		const CalendarElement = $(this.$element).find('[data-target="range-picker"]');
		this.calendarInstance = flatpickr(CalendarElement, config);
	}

	$onDestroy() {
		this.calendarInstance.destroy();
	}

	$onChanges(changes) {
		if (changes.from && changes.to) {
			this.from = this.from ? new Date(this.from) : null;
			this.to = this.to ? new Date(this.to) : null;

			this.initCalendar();

			if (this.from && this.to) {
				this.range = `${this.formatDate(this.from)} - ${this.formatDate(this.to)}`;
			}
		}
	}

	formatDate(date) {
		return flatpickr.formatDate(date, this.flatpickrDateFormat);
	}
}

export const RangePickerComponent = {
	controller: RangePickerController,
	template: tpl,
	bindings: {
		onChange: '&',
		from: '<',
		to: '<',
		placeholder: '@',
		dateFormat: '@',
	},
};
