const Entities = require('html-entities').AllHtmlEntities;

class RankingsService {
	constructor(i18n, modalService, surveyProviderService, Restangular, apiHelper, questionHelper) {
		'ngInject';

		this.i18n = i18n;
		this.modalService = modalService;
		this.$uibModal = modalService.getUibModal();
		this.surveyProviderService = surveyProviderService;
		this.restangular = Restangular;
		this.apiHelper = apiHelper;
		this.questionHelper = questionHelper;
		this.entities = new Entities();
	}

	openCreator(opts) {
		let onCreateCallback = opts && opts.onCreateCallback ? opts.onCreateCallback : null;
		let onCloseCallback = opts && opts.onCloseCallback ? opts.onCloseCallback : null;
		let rankingId = opts && opts.rankingId ? opts.rankingId : null;

		this.creatorModal = this.$uibModal.open({
			component: 'rankingCreator',
			size: '700',
			resolve: {
				onCreateCallback: () => onCreateCallback,
				onCloseCallback: () => onCloseCallback,
				rankingId: () => rankingId,
			},
			keyboard: false,
		});

		return this.creatorModal;
	}

	closeCreator() {
		this.creatorModal.close();
	}

	// 3 /survey-id/ranking/questions || /survey-id/ranking/metrics GET

	getQuestionsForRankingPromise() {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('ranking')
					.one('questions')
					.get()
					.then((questionsList) => {
						questionsList =
							questionsList && questionsList.plain ? questionsList.plain() : questionsList;
						resolve(questionsList.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	getMetricsForRankingPromise() {
		return new Promise((resolve) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('ranking')
					.one('metrics')
					.get()
					.then((metricsList) => {
						metricsList = metricsList && metricsList.plain ? metricsList.plain() : metricsList;
						resolve(metricsList.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	// 1 survey-id/ranking  GET, POST

	getRankingsListPromise() {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('ranking')
					.get()
					.then((rankingsList) => {
						rankingsList = rankingsList && rankingsList.plain ? rankingsList.plain() : rankingsList;
						resolve(rankingsList.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	createRankingPromise(data) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.all('ranking')
					.post(data)
					.then((rankingData) => {
						rankingData = rankingData && rankingData.plain ? rankingData.plain() : rankingData;
						resolve(rankingData);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	// 5 /ranking/ranking-id/calculate

	getRankingResultPromise(rankingId, filterId = null) {
		let queryData = filterId ? { filter: filterId } : {};

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('ranking', rankingId)
					.one('calculate')
					.get(queryData)
					.then((rankingResult) => {
						rankingResult =
							rankingResult && rankingResult.plain ? rankingResult.plain() : rankingResult;
						resolve(rankingResult);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	// 2 survey-id/ranking/ranking-id  GET, PUT, DELETE

	getRankingDataPromise(rankingId) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('ranking', rankingId)
					.get()
					.then((rankingData) => {
						rankingData = rankingData && rankingData.plain ? rankingData.plain() : rankingData;
						resolve(rankingData);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	updateRankingDataPromise(rankingId, data) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('ranking', rankingId)
					.customPUT(data)
					.then((rankingData) => {
						rankingData = rankingData && rankingData.plain ? rankingData.plain() : rankingData;
						resolve(rankingData);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	removeRankingPromise(rankingId) {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('ranking', rankingId)
					.remove()
					.then(
						() => {
							resolve();
						},
						(errData) => {
							if (errData && errData.status === 409) {
								reject();
							} else {
								this.apiHelper.handleDataError(errData);
							}
						}
					);
			}, this.apiHelper.handleDataError);
		});
	}

	parsePreparedData(rankingData) {
		const sets = rankingData.sets;

		if (!rankingData.title) {
			rankingData.title = this.prepareRankingTitle(sets);
		}

		let data = {
			title: rankingData.title,
			sets: [],
		};

		sets.forEach((set) => {
			let setObject = {
				type: set.type,
			};

			if (Object.prototype.hasOwnProperty.call(set, 'question')) {
				setObject.question = set.question.id;
				setObject.rowIndexes = set.question.rowIndex ? [set.question.rowIndex] : [];
			} else if (Object.prototype.hasOwnProperty.call(set, 'metric')) {
				setObject.question = set.metric.id;
				setObject.rowIndexes = set.metric.rowIndex ? [set.metric.rowIndex] : [];
				setObject.type = set.metric.type;
			} else if (Object.prototype.hasOwnProperty.call(set, 'segment')) {
				setObject.rowIndexes = [set.segment.rowIndex];
			} else if (Object.prototype.hasOwnProperty.call(set, 'tags')) {
				setObject.rowIndexes = set.tags.map((tag) => {
					return Number(tag.id);
				});
			}

			data.sets.push(setObject);
		});

		return data;
	}

	handleTranslation(rankingResult) {
		if (this.isNpsSet(rankingResult.sets[1].questionType)) {
			rankingResult.columns.map((column) => {
				column.title = this.i18n.__(column.title, 'reports');
				return column;
			});
		}

		return rankingResult;
	}

	isNpsSet(questionType) {
		return Number(questionType) === Number(this.questionHelper.getTypes().nps);
	}

	openAddRankingToReportModal(opts) {
		this.addRankingToReportModal = this.$uibModal.open({
			component: 'addRankingToReportModal',
			size: '400',
			resolve: {
				rankingData: () => opts.rankingData,
				additionalSettings: () => opts.additionalSettings,
				filterId: () => opts.filterId,
				successCallback: () => opts.successCallback,
			},
		});
		this.addRankingToReportModal.result.then(
			() => {},
			() => {}
		);
	}

	prepareRankingTitle(rankingSets) {
		const sets = rankingSets;
		let rowTitle = '';
		let columnTitle = this.entities.decode(sets[1].metric.title.replace(/(\r\n|\n|\r)/gm, ''));

		if (Object.prototype.hasOwnProperty.call(sets[0], 'question')) {
			rowTitle = this.entities.decode(sets[0].question.title.replace(/(\r\n|\n|\r)/gm, ''));
		} else if (Object.prototype.hasOwnProperty.call(sets[0], 'segment')) {
			rowTitle = this.entities.decode(sets[0].segment.title.replace(/(\r\n|\n|\r)/gm, ''));
		} else {
			rowTitle = this.i18n.__('Tagi', 'reports');
		}

		return this.i18n._s('[Wiersz] %s [Kolumna] %s', rowTitle, columnTitle, 'reports');
	}
}

export default RankingsService;
