import styled from 'styled-components';

const PaginationText = styled.p`
	font-size: 13px;
	font-weight: 600;
	color: #888888;
	margin: 0 10px;
`;

export default PaginationText;
