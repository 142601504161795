const tpl = require('./DeleteCompletionsModal.html');

class DeleteCompletionsModalController {
	constructor(i18n, $rootScope, modalService) {
		'ngInject';

		this.i18n = i18n;
		this.$rootScope = $rootScope;
		this.modalService = modalService;
	}

	deleteFinished() {
		this.showConfirmModal();
	}

	deleteAll() {
		this.showConfirmModal(true);
	}

	showConfirmModal(unfinished = false) {
		let text;
		let title;

		if (unfinished && this.resolve.activeCompletionsCount > 0) {
			text = this.i18n._s(
				'%s Jeżeli usuniesz wszystkie wypełnienia respondenci nie będą mieli możliwości dokończenia wypełniania ankiety.%sCzy na pewno chcesz usunąć wszystkie (w tym trwające) wypełnienia?',
				this.getStringFormForNumber(this.resolve.activeCompletionsCount),
				'<br><br>',
				'reports'
			);
			title = this.i18n.__('Ostrzeżenie', 'reports');
		} else {
			text = this.i18n.__('Czy na pewno chcesz usunąć wypełnienia?', 'reports');
			title = this.i18n.__('Potwierdzenie', 'reports');
		}

		this.modalService
			.showConfirm({
				text,
				title,
				confirmText: this.i18n.__('Usuń', 'reports'),
				cancelText: this.i18n.__('Anuluj', 'reports'),
			})
			.then(
				() => {
					this.close({ $value: unfinished });
				},
				() => {}
			);
	}

	getStringFormForNumber(number) {
		let content;

		if (number === 1) {
			content =
				'W tej ankiecie znajduje się %s niedokończone wypełnienie, rozpoczęte w dniu dzisiejszym.';
		} else if (
			number < 5 ||
			(number > 20 && [2, 3, 4].findIndex((element) => element === number % 10) !== -1)
		) {
			content =
				'W tej ankiecie znajdują się %s niedokończone wypełnienia, rozpoczęte w dniu dzisiejszym.';
		} else {
			content =
				'W tej ankiecie znajduje się %s niedokończonych wypełnień, rozpoczętych w dniu dzisiejszym.';
		}

		return this.i18n._s(content, `<strong>${number}</strong>`, 'reports');
	}
}

export const deleteCompletionsModal = {
	template: tpl,
	controller: DeleteCompletionsModalController,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&',
	},
};
