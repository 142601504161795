import tpl from './trend-creator.html';
import angular from 'angular';
import { AbstractCreator } from '../../creator/abstract-creator';

class TrendCreatorController extends AbstractCreator {
	constructor(
		TRENDS_CONFIG,
		$scope,
		i18n,
		trendsService,
		modalService,
		analyzeSetStorage,
		questionHelper,
		segmentService
	) {
		'ngInject';

		super($scope, i18n, modalService, analyzeSetStorage);

		this.TRENDS_CONFIG = TRENDS_CONFIG;
		this.$scope = $scope;
		this.i18n = i18n;
		this.trendsService = trendsService;
		this.modalService = modalService;
		this.segmentService = segmentService;
		this.questionTypes = questionHelper.getTypes();

		this.TYPES_OF_DATA_HELPER = [
			{
				type: TRENDS_CONFIG.setType.question,
				text: i18n.__('oceną z pytania', 'reports'),
				icon: 'analyze-set-question',
				disabled: false,
			},
			{
				type: TRENDS_CONFIG.setType.tag,
				text: i18n.__('tagami', 'reports'),
				icon: 'analyze-set-tag',
				disabled: false,
			},
		];

		this.trendData = {
			sets: [
				{
					type: TRENDS_CONFIG.setType.period,
					periods: {},
				},
				{
					type: TRENDS_CONFIG.setType.question,
					details: true,
				},
			],
		};

		this.periodGroups = null;
		this.trendBase = null;
	}

	async $onInit() {
		super.$onInit();

		if (this.resolve.trendId) {
			this.getTrendData();
		} else {
			$$await(await this.analyzeSetStorage.fetchQuestionsForTrend());
			this.isPrefetchingCompleted = true;
		}
	}

	validate() {
		const periodSet = this.trendData.sets[0];

		let periodSetStatement = periodSet.period && periodSet.trendRange;

		if (
			periodSetStatement &&
			periodSet.trendRange.type === this.TRENDS_CONFIG.trendRange.specified
		) {
			periodSetStatement =
				periodSetStatement &&
				periodSet.periods &&
				Object.keys(periodSet.periods).some((year) => {
					return periodSet.periods[year].length > 0;
				});
		}

		const dataSet = this.trendData.sets[1];

		let dataSetStatement;

		if (dataSet.type === this.TRENDS_CONFIG.setType.question) {
			dataSetStatement = dataSet.question;

			if (
				dataSetStatement &&
				(dataSet.question.type === this.questionTypes.matrixSingle ||
					dataSet.question.totalRows > 1)
			) {
				dataSetStatement = dataSetStatement && dataSet.rowIndexes && dataSet.rowIndexes.length > 0;
			}
		} else if (dataSet.type === this.TRENDS_CONFIG.setType.tag) {
			dataSetStatement = dataSet.tags && dataSet.tags.length > 0;
		}

		if (periodSetStatement && dataSetStatement && this.isAnyChanges()) {
			return true;
		}

		return false;
	}

	async getTrendData() {
		const trendData = await this.trendsService.getTrendDataPromise(this.resolve.trendId);
		this.parseFetchedData(trendData);
	}

	async save() {
		this.generating = true;

		const data = this.trendsService.parsePreparedData(this.trendData);

		let trendResult;

		if (this.resolve.trendId) {
			trendResult = await this.trendsService.updateTrendDataPromise(this.resolve.trendId, data);
		} else {
			trendResult = await this.trendsService.createTrendPromise(data);
			this.segmentService.track('Dodano nowy trend');
		}

		this.resolve.onCreateCallback(trendResult);
	}

	async parseFetchedData(trendData) {
		this.trendData.title = trendData.title;

		const [questions, tags, periods, trendRanges] = $$await(
			await Promise.all([
				this.analyzeSetStorage.fetchQuestionsForTrend(),
				this.analyzeSetStorage.fetchTags(),
				this.analyzeSetStorage.fetchPeriods(),
				this.analyzeSetStorage.fetchTrendRanges(),
			])
		);

		trendData.sets.forEach(async (set, index) => {
			this.trendData.sets[index].type = set.type;

			if (set.type === this.TRENDS_CONFIG.setType.question) {
				this.trendData.sets[index].question = questions.find(
					(question) => Number(question.id) === Number(set.question)
				);

				if (set.rowIndexes) {
					this.trendData.sets[index].rowIndexes = set.rowIndexes;
				}
			} else if (set.type === this.TRENDS_CONFIG.setType.period) {
				this.trendData.sets[index].period = periods.find(
					(period) => Number(period.type) === Number(set.period)
				);
				this.trendData.sets[index].trendRange = trendRanges.find(
					(trendRange) => Number(trendRange.type) === Number(set.trendRange)
				);

				if (this.trendData.sets[index].trendRange.type === 2) {
					this.periodGroups = $$await(await this.analyzeSetStorage.fetchAllPeriods(set.period));
					const periods = {};

					set.periods.forEach((item) => {
						periods[item.year] = [];

						this.periodGroups[item.year].forEach((period) => {
							if (item.indexes.indexOf(Number(period.rowIndex)) !== -1) {
								periods[item.year].push(period);
							}
						});
					});

					this.trendData.sets[index].periods = periods;
				}
			} else if (set.type === this.TRENDS_CONFIG.setType.tag) {
				this.trendData.sets[index].tags = tags.filter((tag) => {
					return set.rowIndexes.indexOf(Number(tag.id)) !== -1;
				});
			}
		});

		this.trendBase = angular.copy(this.trendData);

		this.isPrefetchingCompleted = true;
	}

	async onPeriodChange($event) {
		if (this.trendData.sets[0].trendRange && this.trendData.sets[0].trendRange.type === 2) {
			this.periodGroups = $$await(await this.analyzeSetStorage.fetchAllPeriods($event.item.type));
		}
	}

	async onTrendRangeChange($event) {
		if ($event.item.type === 2) {
			this.periodGroups = $$await(
				await this.analyzeSetStorage.fetchAllPeriods(this.trendData.sets[0].period.type)
			);
		}
	}

	isAnyChanges() {
		if (this.trendBase && angular.toJson(this.trendData) !== angular.toJson(this.trendBase)) {
			return true;
		} else if (
			!this.trendBase &&
			(this.trendData.sets[1].question ||
				this.trendData.title ||
				this.trendData.sets[1].tags ||
				this.trendData.sets[0].period)
		) {
			return true;
		}

		return false;
	}

	closeWithConfirmation() {
		if (!this.isAnyChanges()) {
			this.close();
		} else {
			this.confirmClosing(this.i18n.__('Trend nie został zapisany', 'reports'));
		}
	}
}

TrendCreatorController.$inject = [
	'TRENDS_CONFIG',
	'$scope',
	'i18n',
	'trendsService',
	'modalService',
	'analyzeSetStorage',
	'questionHelper',
	'segmentService',
];

export const TrendCreatorComponent = {
	template: tpl,
	controller: TrendCreatorController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
