const tpl = require('./QuestionStaticText.html');

class QuestionStaticTextController {
	constructor(i18n) {
		'ngInject';

		this.i18n = i18n;
	}

	$onInit() {
		this.questionCtrl.setChild(this);
	}
	isReady() {
		return true;
	}
}

export const questionStaticText = {
	template: tpl,
	controller: QuestionStaticTextController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
	},
};
