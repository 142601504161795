import angular from 'angular';
import $ from 'jquery';

class ProjectsService {
	constructor() {}

	fetchFoldersWithProjects() {
		return new Promise(function (resolve, reject) {
			$.ajax({
				url: '/user/folders/projects',
				success: function (response) {
					resolve(response.data);
				},
				error: function (response) {
					reject(JSON.parse(response.responseText));
				},
			});
		});
	}
}

export default ProjectsService;
