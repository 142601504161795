const tpl = require('./trend-chart.html');

import jquery from 'jquery';
import highcharts from 'highcharts';
import { TrendsConfig } from '../trends.config';

const Entities = require('html-entities').AllHtmlEntities;

class TrendChartController {
	constructor($scope, $element, chartColorHelper, i18n, $filter, questionHelper) {
		'ngInject';

		this.$element = $element;
		this.chartColorHelper = chartColorHelper;
		this.questionHelper = questionHelper;
		this.$scope = $scope;
		this.colorFilter = $filter('colorFilter');
		this.i18n = i18n;

		const self = this;

		this.questionData = null;

		this.chartConfig = {
			chart: {
				type: 'column',
				animation: false,
				height: 396,
				spacingBottom: 40,
				spacingRight: 25,
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
			},
			title: {
				text: '',
			},
			credits: {
				enabled: false,
			},
			xAxis: {
				title: {
					text: '',
				},
				labels: {
					style: {
						color: '#032c3e',
						fontSize: '12px',
					},
				},
				crosshair: false,
				tickWidth: 0,
				lineWidth: 0,
				lineColor: '#777',
				categories: [],
			},
			yAxis: [
				{
					title: {
						text: '',
					},
					lineWidth: 2,
					lineColor: '#777',
					gridLineWidth: 1,
					plotLines: [
						{
							color: '#777',
							value: 0,
							width: 3,
							zIndex: 5,
						},
						{
							color: '#e6e6e6',
							value: -1,
							width: 1,
						},
						{
							color: '#e6e6e6',
							value: 1,
							width: 1,
						},
					],
				},
			],
			legend: {
				enabled: true,
				symbolRadius: 2,
				margin: 30,
				itemStyle: {
					width: '120px',
				},
			},
			plotOptions: {
				line: {
					color: 'rgb(135, 121, 255)',
					marker: {
						radius: 5,
						fillColor: 'rgb(135, 121, 255)',
						states: {
							hover: {
								lineWidthPlus: 0,
								radiusPlus: 2,
							},
						},
					},
					lineWidth: 1,
					threshold: null,
				},
				column: {
					stacking: 'percent',
					borderWidth: 0,
					dataLabels: {
						enabled: false,
					},
				},
				area: {
					stacking: 'normal',
				},
				series: {
					animation: !this.external,
				},
			},
			tooltip: {
				useHTML: true,
			},
		};

		this.chartConfigRatings = {
			chart: {
				animation: false,
				height: 396,
				spacingBottom: 40,
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
			},
			title: {
				text: '',
			},
			yAxis: {
				min: 0,
				title: {
					text: '',
				},
				lineWidth: 2,
				lineColor: '#777',
			},
			legend: {
				enabled: false,
			},
			xAxis: {
				title: {
					text: '',
				},
				labels: {
					style: {
						color: '#032c3e',
						fontSize: '12px',
					},
					autoRotationLimit: 130,
				},
				tickWidth: 0,
				crosshair: false,
				lineWidth: 2,
				lineColor: '#777',
				categories: [],
			},
			plotOptions: {
				line: {
					color: '#97b1bd',
					marker: {
						radius: 5,
						symbol: 'circle',
						states: {
							hover: {
								lineWidthPlus: 0,
								radiusPlus: 2,
							},
						},
					},
					lineWidth: 2,
					threshold: null,
				},
				series: {
					animation: true,
				},
			},
			series: [
				{
					data: [],
				},
			],
			credits: {
				enabled: false,
			},
			tooltip: {
				borderWidth: 0,
				borderRadius: 8,
				shadow: false,
				backgroundColor: '#032c3e',
				useHTML: true,
				style: {
					color: '#ffffff',
					fontSize: '11px',
					width: '140px',
				},
				formatter() {
					const index = self.trendResult.rows.findIndex((row) => {
						return row.title === this.x;
					});
					let res = `${self.trendResult.rows[index].range}

                               <br><br><b style="font-size: 14px">${i18n.__(
																	'Średnia ocena',
																	'reports'
																)}: ${this.y}</b>`;
					return res;
				},
			},
		};
	}

	$onInit() {
		this.$scope.$parent.$ctrl.chartCtrl = this;
	}

	$onChanges(changes) {
		if (changes && this.trendResult) {
			this.drawChart(this.trendResult);
		}
	}

	drawChart(trendResult) {
		this.trendResult = trendResult;

		const questionTypes = this.questionHelper.getTypes();
		const customSet = this.trendResult.sets[1];

		if (customSet.type === TrendsConfig.setType.question) {
			if (customSet.questionType === questionTypes.nps) {
				this.updateBarColors();
				this.createNpsChart();
			} else if (customSet.trendRange) {
				this.createAggregated();
			} else {
				this.createRatingsChart();
			}
		} else if (customSet.type === TrendsConfig.setType.tag) {
			this.createAggregated();
		}
	}

	createNpsChart() {
		let series = [];
		const entities = new Entities();

		this.chartConfig.xAxis.categories = [];

		this.chartConfig.chart.type = this.type.chart;
		this.chartConfig.plotOptions[this.type.chart].stacking = this.format.stacking;

		for (let j = 0; j < this.trendResult.rows.length; j++) {
			let rowTitle = entities.decode(this.trendResult.rows[j].title);
			rowTitle = this.trendResult.rows[j].shouldBeTranslated
				? this.i18n.__(rowTitle, 'reports')
				: rowTitle;

			this.chartConfig.xAxis.categories.push(rowTitle);
		}

		for (let i = 0; i < this.trendResult.columns.length; i++) {
			let title = entities.decode(this.trendResult.columns[i].title);
			title = this.trendResult.columns[i].shouldBeTranslated
				? this.i18n.__(title, 'reports')
				: title;

			let single = {
				name: title,
				data: [],
				color: this.colorFilter(this.barColors[i]),
			};

			series.unshift(single);

			for (let j = 0; j < this.trendResult.rows.length; j++) {
				single.data.push(Number(this.trendResult.answers[j][i].count));
			}
		}

		if (this.format.stacking === 'percent') {
			let rightAxis = {
				name: 'NPS',
				type: 'line',
				data: this.trendResult.nps.map((val) => parseFloat(val, 10)),
			};
			series.push(rightAxis);
		}

		this.chartConfig.series = series;
		this.chartConfig.tooltip.formatter = this.getNpsFormatter();
		this.chart = highcharts.chart(jquery(this.$element).find('.chart__main')[0], this.chartConfig);
	}

	createAggregated() {
		let series = [];
		const entities = new Entities();

		this.chartConfig.xAxis.categories = [];

		this.chartConfig.chart.type = this.type.chart;
		this.chartConfig.plotOptions[this.type.chart].stacking = this.format.stacking;

		for (let j = 0; j < this.trendResult.rows.length; j++) {
			let rowTitle = entities.decode(this.trendResult.rows[j].title);
			rowTitle = this.trendResult.rows[j].shouldBeTranslated
				? this.i18n.__(rowTitle, 'reports')
				: rowTitle;

			this.chartConfig.xAxis.categories.push(rowTitle);
		}

		for (let i = 0; i < this.trendResult.columns.length; i++) {
			let title = entities.decode(this.trendResult.columns[i].title);
			title = this.trendResult.columns[i].shouldBeTranslated
				? this.i18n.__(title, 'reports')
				: title;

			let single = {
				name: title,
				data: [],
			};

			series.push(single);

			for (let j = 0; j < this.trendResult.rows.length; j++) {
				single.data.push(Number(this.trendResult.answers[j][i]));
			}
		}

		this.chartConfig.series = series;

		this.chartConfig.tooltip.pointFormat =
			'<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>';
		this.chart = highcharts.chart(jquery(this.$element).find('.chart__main')[0], this.chartConfig);
	}

	createRatingsChart() {
		let series = [];
		const entities = new Entities();

		this.chartConfigRatings.xAxis.categories = [];

		for (let j = 0; j < this.trendResult.rows.length; j++) {
			let rowTitle = entities.decode(this.trendResult.rows[j].title);

			rowTitle = this.trendResult.rows[j].shouldBeTranslated
				? this.i18n.__(rowTitle, 'reports')
				: rowTitle;

			this.chartConfigRatings.xAxis.categories.push(rowTitle);
		}

		if (this.trendResult.average) {
			this.chartConfigRatings.legend.enabled = false;
			this.chartConfigRatings.yAxis.max = null;
			this.chartConfigRatings.yAxis.tickInterval = null;
			series = [{ data: [], color: 'rgb(135, 121, 255)' }];
			for (let i = 0; i < this.trendResult.average.length; i++) {
				const averageValue = Number(this.trendResult.average[i]);

				if (averageValue > 0) {
					series[0].data.push(averageValue);
				} else {
					series[0].data.push(null);
				}
			}
		} else {
			const legend = {
				enabled: true,
				symbolRadius: 2,
				margin: 40,
				itemStyle: {
					width: '120px',
				},
			};

			this.chartConfigRatings.yAxis.tickInterval = 1;
			this.chartConfigRatings.yAxis.max = this.getMaxValue(this.trendResult.answers);
			this.chartConfigRatings.yAxis.min = this.getMinValue(this.trendResult.answers);

			this.chartConfigRatings.legend = legend;

			for (let i = 0; i < this.trendResult.columns.length; i++) {
				series[i] = { data: [], color: null, name: this.trendResult.columns[i].title };
				for (let j = 0; j < this.trendResult.answers.length; j++) {
					const averageValue = Number(this.trendResult.answers[j][i]);

					if (averageValue > 0) {
						series[i].data.push(averageValue);
					} else {
						series[i].data.push(null);
					}

					series[i].color = this.chartColorHelper.getColorsTableForChart()[i];
				}
			}
		}

		this.chartConfigRatings.series = series;
		this.chart = highcharts.chart(
			jquery(this.$element).find('.chart__main')[0],
			this.chartConfigRatings
		);
	}

	updateBarColors() {
		this.barColors = jquery.map(this.chartColorHelper.getNPSColors(), (value, index) => {
			return [value];
		});
	}

	getAllResultsFromAnswers(array) {
		const results = [];
		array.forEach((el) => {
			el.forEach((result) => {
				results.push(result);
			});
		});

		return results;
	}

	getMaxValue(array) {
		const results = this.getAllResultsFromAnswers(array);
		return Math.max(...results);
	}

	getMinValue(array) {
		const results = this.getAllResultsFromAnswers(array);
		return Math.min(...results);
	}

	updateThemeColor(newTheme) {
		this.chartColorHelper.setSourceColor(newTheme.chartColor);
	}

	getNpsFormatter() {
		const self = this;

		return function () {
			const index = self.trendResult.rows.findIndex((row) => {
				return row.title === this.x;
			});

			let totalCompletions = 0;

			self.trendResult.answers[index].forEach((answer) => {
				totalCompletions += answer.count;
			});

			return `${self.trendResult.rows[index].range}
                       <br><br><b style="font-size: 14px">NPS: ${self.trendResult.nps[index]}</b>
                       <br><br>${self.i18n.__('Liczba wypełnień', 'reports')}: ${totalCompletions}
                       <br><br>${self.i18n.__('Krytycy', 'reports')}: <b style="color: #ff6868">${
				self.trendResult.answers[index][0].count
			} (${self.trendResult.answers[index][0].percent}%)</b>
                       <br>${self.i18n.__('Neutralni', 'reports')}: <b style="color: #ffc970">${
				self.trendResult.answers[index][1].count
			} (${self.trendResult.answers[index][1].percent}%)</b>
                       <br>${self.i18n.__('Promotorzy', 'reports')}: <b style="color: #b8e986">${
				self.trendResult.answers[index][2].count
			} (${self.trendResult.answers[index][2].percent}%)</b>`;
		};
	}
}

export const TrendChartComponent = {
	template: tpl,
	controller: TrendChartController,
	bindings: {
		type: '<',
		format: '<',
	},
};
