const { DynamicLoader } = require('./DynamicLoader');

class RefreshDynamicLoader extends DynamicLoader {
	constructor(config) {
		super(config);

		this.interval = config.interval ? config.interval : 10000;
		this.invervalId = null;
		this.refreshing = false;

		this.initRefresh();
	}

	initRefresh() {
		this.intervalId = setInterval(() => {
			if (this.itemsList.length > 0) {
				this.refreshList();
			}
		}, this.interval);
	}

	refreshList() {
		let currentCount = this.itemsList.length;
		let fullPages = Math.floor(currentCount / 10);
		let lastPageReportsCount = currentCount % 10;
		let toLoad = 1;

		this.itemsList = [];
		this.pageLoaded = 0;
		this.refreshing = true;

		let refresh = () => {
			if (toLoad <= fullPages && !this.fetching) {
				this.loadForRefresh();
				toLoad++;
				this.timeout(refresh);
			} else if (toLoad === fullPages + 1 && lastPageReportsCount > 0 && !this.fetching) {
				this.loadForRefresh(lastPageReportsCount);
				toLoad++;
			} else if (toLoad <= fullPages) {
				this.timeout(refresh);
			} else {
				this.refreshing = false;
			}
		};

		refresh();
	}

	loadForRefresh(lastPageItems = 0) {
		if (lastPageItems > 0) {
			this.fetch(lastPageItems);
		} else {
			this.fetch();
		}
	}

	timeout(func) {
		setTimeout(() => {
			func();
		}, 500);
	}

	onDestroy() {
		clearInterval(this.intervalId);
	}
}

export { RefreshDynamicLoader };
