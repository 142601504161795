import tpl from './cross-table-result.html';
import angular from 'angular';

class CrossTableResultController {
	constructor(i18n, $scope, $rootScope, crossTablesService, $timeout, reportHelper) {
		'ngInject';

		this.crossTablesService = crossTablesService;
		this.reportHelper = reportHelper;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$scope = $scope;
		this.i18n = i18n;

		this.DISPLAY_MODE_SETUP = {
			nonEmpty: 0,
			all: 1,
		};

		this.SERIES_TYPE_SETUP = {
			bar: 0,
			column: 1,
		};

		this.FORMAT_TYPE_SETUP = {
			percent: 0,
			count: 1,
		};

		this.additionalSettings = {
			crossTableRowPercent: 0,
			crossTableColumnPercent: 0,
			crossTableRotated: 0,
			crossTableDisplayMode: this.DISPLAY_MODE_SETUP.nonEmpty,
			crossTableChartSeries: this.SERIES_TYPE_SETUP.bar,
			crossTableChartFormat: this.FORMAT_TYPE_SETUP.percent,
		};

		this.DISPLAY_MODE = [
			{
				type: this.DISPLAY_MODE_SETUP.nonEmpty,
				title: i18n.__('tylko niezerowe', 'reports'),
			},
			{
				type: this.DISPLAY_MODE_SETUP.all,
				title: i18n.__('wszystkie', 'reports'),
			},
		];

		this.SERIES_MODE = [
			{
				title: `<i class="fa fa-bar-chart" aria-hidden="true" style="transform: rotate(90deg) scaleX(-1)"></i>`,
				type: this.SERIES_TYPE_SETUP.bar,
				chart: 'bar',
			},
			{
				title: `<i class="fa fa-bar-chart" aria-hidden="true"></i>`,
				type: this.SERIES_TYPE_SETUP.column,
				chart: 'column',
			},
		];

		this.FORMAT_MODE = [
			{
				title: `<i class="fa fa-percent" aria-hidden="true"></i>`,
				type: this.FORMAT_TYPE_SETUP.percent,
				stacking: 'percent',
			},
			{
				title: `123`,
				type: this.FORMAT_TYPE_SETUP.count,
				stacking: 'normal',
			},
		];

		this.selectedSeriesMode = this.SERIES_MODE[0];
		this.selectedFormatMode = this.FORMAT_MODE[0];
	}

	$onInit() {
		this.bindUIActions();

		if (this.partCtrl) {
			this.initForPart();
		}
	}

	$onChanges() {
		this.updateChart(this.crossTableResult);
	}

	bindUIActions() {
		let ctrl = this;

		this.$scope.$on('addToReport', (event, data) => {
			ctrl.addToReport(data.crossTableData, data.filterId, data.successCallback);
		});

		this.$scope.$watch(
			() => this.crossTableResult,
			(newValue, oldValue) => {
				this.crossTableResultWatcher(newValue, oldValue);
			}
		);

		if (this.partCtrl) {
			for (let key in this.additionalSettings) {
				this.$scope.$watch(
					() => this.additionalSettings[key],
					(newValue, oldValue) => {
						if (newValue === oldValue) {
							return;
						}

						this.updateAdditionalSetting(this.reportHelper.additionalSettingsTypes[key], newValue);
					}
				);
			}
		}
	}

	// Bind Functions

	crossTableResultWatcher(newValue, oldValue) {
		if (this.partCtrl && newValue && !oldValue) {
			this.chartCtrl.updateThemeColor(this.reportBuilderCtrl.getColors());

			if (this.additionalSettings.crossTableRotated === 1) {
				this.rotateTable(false);
			} else {
				this.updateChart(newValue);
			}

			this.reportBuilderCtrl.reportFullyLoaded();
		} else if (newValue) {
			this.updateChart(newValue);
		}
	}

	updateChart(crossTableResult) {
		if (crossTableResult) {
			if (this.additionalSettings.crossTableDisplayMode) {
				this.chartCtrl.drawChart(crossTableResult);
			} else {
				this.chartCtrl.drawChart(this.removeEmptyRows(crossTableResult));
			}
		}
	}

	updateAdditionalSetting(key, value) {
		let chapterId = this.partCtrl.chapterCtrl.chapter.id;
		let partId = this.partCtrl.id;
		let setting = this.partCtrl.part.additionalSettings.find((setting) => setting.type === key);
		let data = {
			type: key,
			setup: value,
		};

		if (!setting) {
			this.reportBuilderCtrl.reportsService.addAdditionalSettingsPromise(
				null,
				chapterId,
				partId,
				data
			);
		} else {
			this.reportBuilderCtrl.reportsService.updateAdditionalSettingsPromise(
				null,
				chapterId,
				partId,
				setting.id,
				data
			);
		}
	}

	// UI Actions

	onFormatModeChange($event) {
		this.additionalSettings.crossTableChartFormat = $event.item.type;
	}

	onSeriesModeChange($event) {
		this.additionalSettings.crossTableChartSeries = $event.item.type;
	}

	rowPercentToggle() {
		this.additionalSettings.crossTableRowPercent =
			this.additionalSettings.crossTableRowPercent === 0 ? 1 : 0;
	}

	columnPercentToggle() {
		this.additionalSettings.crossTableColumnPercent =
			this.additionalSettings.crossTableColumnPercent === 0 ? 1 : 0;
	}

	getPercentSpan(value, column = false) {
		if (column) {
			return this.i18n.__('% z kolumny', 'reports') + ': ' + value + '%';
		}

		return this.i18n.__('% z wiersza', 'reports') + ': ' + value + '%';
	}

	rotateTable(changeAdditional = true) {
		let ctrl = this;
		let rotatedData = {};
		let rotatedSets = {};

		rotatedData.answers = [];

		for (let i = 0; i < ctrl.crossTableResult.answers.length; i++) {
			for (let j = 0; j < ctrl.crossTableResult.answers[i].length; j++) {
				if (!rotatedData.answers[j]) {
					rotatedData.answers[j] = [];
				}

				rotatedData.answers[j][i] = {};
				rotatedData.answers[j][i].count = ctrl.crossTableResult.answers[i][j].count;
				rotatedData.answers[j][i].rowPercent = ctrl.crossTableResult.answers[i][j].columnPercent;
				rotatedData.answers[j][i].columnPercent = ctrl.crossTableResult.answers[i][j].rowPercent;
			}
		}

		rotatedData.columns = ctrl.crossTableResult.rows;
		rotatedData.rows = ctrl.crossTableResult.columns;
		rotatedData.totalColumns = ctrl.crossTableResult.totalRows;
		rotatedData.totalRows = ctrl.crossTableResult.totalColumns;

		rotatedData.total = {
			rowCount: ctrl.crossTableResult.total.columnCount,
			rowPercent: ctrl.crossTableResult.total.columnPercent,
			columnCount: ctrl.crossTableResult.total.rowCount,
			columnPercent: ctrl.crossTableResult.total.rowPercent,
		};

		rotatedData.sets = [this.crossTableResult.sets[1], this.crossTableResult.sets[0]];
		rotatedData.id = this.crossTableResult.id;

		if (changeAdditional) {
			this.additionalSettings.crossTableRotated =
				this.additionalSettings.crossTableRotated === 0 ? 1 : 0;
		}

		// Przy przechodzeniu do utworzonego przy dodawaniu partu raportu
		// Nie ogarniało cyklu - stąd $timeout.
		this.$timeout(() => {
			this.crossTableResult = rotatedData;
		});
	}

	onDisplayModeChange() {
		this.updateChart(this.crossTableResult);
	}

	addToReport(crossTableData, filterId, successCallback) {
		this.crossTablesService.openAddCrossTableToReportModal({
			crossTableData,
			additionalSettings: this.additionalSettings,
			filterId,
			successCallback,
		});
	}

	isTableWithChart() {
		return (
			!this.partCtrl ||
			(this.partCtrl &&
				this.partCtrl.part.type === this.reportHelper.reportPartTypes.CROSS_TABLES_TABLE_CHART)
		);
	}

	isOnlyTable() {
		return (
			this.partCtrl &&
			this.partCtrl.part.type === this.reportHelper.reportPartTypes.CROSS_TABLES_TABLE
		);
	}

	isOnlyChart() {
		return (
			this.partCtrl &&
			this.partCtrl.part.type === this.reportHelper.reportPartTypes.CROSS_TABLES_CHART
		);
	}

	isAnyEmptyRow() {
		if (
			!this.crossTableResult ||
			!this.crossTableResult.totalRows ||
			this.crossTableResult.totalRows.length === 0
		) {
			return false;
		}

		const emptyRowIndex = this.crossTableResult.totalRows.findIndex((rowTotal) => {
			return rowTotal.count === 0;
		});

		return emptyRowIndex !== -1;
	}

	areAllEmptyRows() {
		if (
			!this.crossTableResult ||
			!this.crossTableResult.totalRows ||
			this.crossTableResult.totalRows.length === 0
		) {
			return false;
		}

		return this.crossTableResult.totalRows.every((value) => value.count === 0);
	}

	removeEmptyRows(results) {
		let filteredResults = angular.copy(results);
		results.rows.forEach((val, index) => {
			if (val.isEmpty) {
				filteredResults.rows[index] = null;
				filteredResults.answers[index] = null;
				filteredResults.totalRows[index] = null;
			}
		});
		return filteredResults;
	}

	// Methods for reports

	initForPart() {
		this.partCtrl.getAdditionalSettingsAsObject(this.additionalSettings);

		this.selectedSeriesMode = this.SERIES_MODE.find((mode) => {
			return mode.type === this.additionalSettings.crossTableChartSeries;
		});

		this.selectedFormatMode = this.FORMAT_MODE.find((mode) => {
			return mode.type === this.additionalSettings.crossTableChartFormat;
		});

		this.reportBuilderCtrl.setCrossTableReference(this);
	}

	themeUpdate(newTheme) {
		this.chartCtrl.updateThemeColor(newTheme);
		this.updateChart(this.crossTableResult);
	}

	isReady() {
		return !!this.crossTableResult;
	}
}

export const CrossTableResultComponent = {
	template: tpl,
	controller: CrossTableResultController,
	require: {
		partCtrl: '?^reportPart',
		reportBuilderCtrl: '?^reportBuilder',
	},
	bindings: {
		crossTableResult: '<',
	},
};
