import tpl from './AnalyzeWrapper.html';
import '../../scss/analyze.scss';

class AnalyzeWrapperController {
	constructor($log, surveyProviderService, i18n) {
		'ngInject';
		let ctrl = this;
		this.text = 'Analyze';
		this.name = 'analyze';
		this.i18n = i18n;
	}
}

export const analyzeWrapper = {
	template: tpl,
	controller: AnalyzeWrapperController,
	bindings: {
		subComponent: '@',
	},
};
