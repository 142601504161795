import tpl from './TopBar.html';
import '../../scss/topBar.scss';

class TopBarController {
	constructor(surveyProviderService, $rootScope, $state) {
		'ngInject';

		this.surveyProviderService = surveyProviderService;
		this.$rootScope = $rootScope;
		this.$state = $state;

		this.faceboxLoading = false;

		surveyProviderService.getCurrentSurveyIdPromise().then(
			(currentId) => {
				this.handleSurvey(currentId);
			},
			(data) => {
				console.error(data);
			}
		);

		$rootScope.$watch('xhr', (val) => {
			this.loading = val;

			if (!val) {
				document.body.classList.remove('bodyLoading');
			}
		});

		$rootScope.$on('loadingOn', () => {
			this.loading = true;
		});

		$rootScope.$on('loadingOff', () => {
			this.loading = false;
		});

		$rootScope.$on('faceboxLoadingOn', () => {
			this.faceboxLoading = true;
		});

		$rootScope.$on('faceboxLoadingOff', () => {
			this.faceboxLoading = false;
		});
	}

	readOnly() {
		return this.$rootScope.surveyAccess?.readOnly;
	}

	handleSurvey(surveyId, sendEvent) {
		return this.surveyProviderService.getSurveyPromise(surveyId).then(
			(data) => {
				this.$rootScope.currentSurveyData = data;

				this.checkAccessForSurveyReadOnlySharing();

				this.$rootScope.$broadcast('loadingOff');

				if (sendEvent) {
					this.$rootScope.$broadcast('SurveyChanged');
				}
			},
			(errorData) => {}
		);
	}

	checkAccessForSurveyReadOnlySharing() {
		if (!this.readOnly()) {
			return;
		}

		const LONG_STATE_NAME_INDEX = 2;
		const currentStateName = this.$state.getCurrentPath()[LONG_STATE_NAME_INDEX].state.name;

		const configList = [
			{
				forbiddenStateNames: ['settings.tags'],
				redirectUrl: '/user#settings/index',
			},
			{
				forbiddenStateNames: ['analyze.verbatim'],
				redirectUrl: '/user#summary',
			},
		];

		configList.forEach((config) => {
			if (config.forbiddenStateNames.includes(currentStateName)) {
				parent.window.location.replace(config.redirectUrl);
			}
		});
	}
}
export const topBar = {
	template: tpl,
	controller: TopBarController,
};
