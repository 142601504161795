import tpl from './FilterComponent.html';

class FilterComponentController {
	constructor(
		i18n,
		$scope,
		$state,
		filtersService,
		surveyProviderService,
		modalService,
		alertsService,
		reportHelper,
		$filter,
		$rootScope,
		aclManager
	) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.$state = $state;
		this.filtersService = filtersService;
		this.surveyProviderService = surveyProviderService;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.reportHelper = reportHelper;
		this.numeralFilter = $filter('numeralFilter');
		this.$rootScope = $rootScope;
		this.aclManager = aclManager;

		this.fetchingCompletions = false;
		this.completionsFetched = false;
		this.actionText = this.i18n.__('Sprawdź', 'reports');
		this.resultsCount = -1;

		this.author = this.i18n.__('własny', 'reports');

		this.rules = {
			questions: false,
			time: false,
			respondents: false,
		};

		this.controls = {
			copy: {
				state: true,
			},
			delete: {
				state: true,
				disabled: true,
				tooltipMessage: '',
			},
		};
	}

	$onInit() {
		this.checkRules();
		this.checkControls();
		this.checkOwner();
	}

	checkRules() {
		if (this.filter.questionRules && this.filter.questionRules.length) {
			this.rules.questions = true;
		}

		if (
			this.filter.period ||
			(!this.filter.period &&
				!(this.filter.questionRules && this.filter.questionRules.length) &&
				!(this.filter.respondentRules && this.filter.respondentRules.length))
		) {
			this.rules.time = true;
		}

		if (this.filter.respondentRules && this.filter.respondentRules.length) {
			this.rules.respondents = true;
		}
	}

	checkControls() {
		if (!this.filter.isEditable) {
			this.controls.delete.state = false;
			this.controls.copy.state = false;
			return;
		}

		if (!this.filter.own) {
			this.controls.delete.state = false;
			this.controls.copy.state = false;
		}

		if (this.filter.isReportConnected) {
			this.controls.delete.tooltipMessage = this.i18n._s(
				'Ten filtr jest użyty w %s raportach, np.: %s',
				this.filter.connectedReportsCount,
				this.filter.connectedReportTitle,
				'reports'
			);
		} else if (this.filter.isMailingReportConnected) {
			this.controls.delete.tooltipMessage = this.i18n.__(
				'Ten filtr jest użyty w raporcie cyklicznym',
				'reports'
			);
		} else if (this.filter.isAlertConnected) {
			this.controls.delete.tooltipMessage = this.i18n.__(
				'Ten filtr jest użyty w alercie',
				'reports'
			);
		} else {
			this.controls.delete.tooltipMessage = this.i18n.__('Usuń filtr', 'reports');
			this.controls.delete.disabled = false;
		}
	}

	checkOwner() {
		if (!this.filter.own) {
			this.author = this.i18n.__('inny użytkownik', 'reports');
		}
	}

	edit(event) {
		event.stopPropagation();
		const ctrl = this;

		this.filtersService
			.openCreator({
				id: this.filter.id,
				isEditable: this.filter.isEditable,
				onCreateCallback: null,
				onCloseCallback: (revert, currentData) => {
					if (!revert) {
						ctrl.filter = currentData;
						ctrl.filtersService.replaceFilterData(currentData);
						ctrl.handleEdition();
					}
				},
			})
			.closed.then(() => {
				this.surveyProviderService.interrupt();
				this.surveyProviderService.interruptQuestions();
			});
	}

	copy(event) {
		event.stopPropagation();
		this.filtersService.openCreator({
			id: this.filter.id,
			isEditable: true,
			isCopy: true,
			onCreateCallback: (filterData) => {
				this.$scope.$emit('filterCreated', {
					filterData,
				});
			},
			onCloseCallback: null,
		});
	}

	delete(event) {
		event.stopPropagation();
		if (this.controls.delete.disabled) {
			return;
		}

		const config = {
			size: '400',
			title: this.i18n.__('Usuwanie filtra', 'reports'),
			text: this.i18n.__('Czy na pewno chcesz usunąć?', 'reports'),
			icon: {
				font: 'fa-trash',
				color: 'gray',
			},
			button: {
				text: this.i18n.__('Anuluj', 'reports'),
				type: 'button--big button--gray',
			},
			button2: {
				text: this.i18n.__('Tak, usuń', 'reports'),
				type: 'button--big',
			},
		};

		this.modalService.show(config).then((reason) => {
			if (reason === 'button1') {
				this.modalService.close();
			}

			if (reason === 'button2') {
				this.modalService.loading(this.i18n.__('Usuwanie filtra...', 'reports'));

				this.filtersService.removeFilterPromise(this.filter.id).then(() => {
					this.$scope.$emit('filterDeleted', {
						filterData: this.filter,
					});
					this.modalService.close();
				});
			}
		});
	}

	filterResults(event) {
		event.stopPropagation();
		this.filtersService.quickActivateFilter(this.filter.id);
		parent.location.href = `/user#angular?r=/analyze/aggregatedResults`;
	}

	createReport(event) {
		event.stopPropagation();
		this.reportHelper.openAddToReportModal({
			questionCtrl: null,
			addWholeSurvey: true,
			filterId: this.filter.id,
			onlyNew: false,
		});
	}

	handleEdition() {
		if (this.resultsCount === -1) {
			return;
		}

		this.fetchCompletions();
	}

	fetchCompletions() {
		this.fetchingCompletions = true;
		this.actionText = this.i18n.__('Pobieranie', 'reports');

		return new Promise((resolve) => {
			this.filtersService.getFilterResultCountByFilterId(this.filter.id).then(
				(data) => {
					this.resultsCount = data.plain().count;
					this.fetchingCompletions = false;
					this.completionsFetched = true;
					this.$scope.$apply();
					resolve();
				},
				() => {
					this.alertsService.error(this.i18n.__('Błąd podczas filtrowania wyników', 'reports'));
					this.resultsCount = -1;
					this.fetchingCompletions = false;
					resolve();
				}
			);
		});
	}

	canCopyFilter() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}

	canCreateReport() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}
}

export const filterComponent = {
	template: tpl,
	controller: FilterComponentController,
	bindings: {
		filter: '<',
		isDefaultApplied: '<',
	},
};
