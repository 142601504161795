let types = {};

// let questionTypes = {
types.all = {
	single: 0,
	matrixSingle: 1,
	text: 2,
	points: 3,
	form: 4,
	ranking: 5,
	multiple: 6,
	matrixMulti: 7,
	slider: 8,
	staticText: 9,
	number: 10,
	email: 11,
	date: 12,
	dropdown: 13,
	attachment: 15,
	nps: 16,
	matrixOpen: 17,
	dragAndDrop: 19,
	rating: 20,
};

// let filterableTypes = {
types.filterable = {
	single: 0,
	matrixSingle: 1,
	text: 2,
	points: 3,
	form: 4,
	ranking: 5,
	multiple: 6,
	matrixMulti: 7,
	slider: 8,
	number: 10,
	email: 11,
	date: 12,
	dropdown: 13,
	nps: 16,
	dragAndDrop: 19,
	rating: 20,
};

// let unfilterableTypes = [
types.unfilterable = [
	types.all.staticText, // 9
	types.all.attachment, // 15
	types.all.matrixOpen, // 17
];

// let typesWithReasons = [
types.withReasons = [types.all.matrixSingle, types.all.nps, types.all.rating];
// let typesWithOthers = [
types.withOthers = [
	types.all.matrixSingle,
	types.all.multiple,
	types.all.matrixMulti,
	types.all.matrixOpen,
	types.all.single,
	types.all.rating,
];
// let matrixTypes = [
//     questionTypes.matrixSingle,
//     questionTypes.matrixMulti,
//
// ]

types.withAdditionalCompletions = [
	types.all.text,
	types.all.email,
	types.all.date,
	types.all.form,
	types.all.number,
	types.all.attachment,
	types.all.ranking,
];

types.hasNeutral = [
	types.all.single,
	types.all.multiple,
	types.all.dropdown,
	types.all.matrixSingle,
	types.all.matrixMulti,
];

let defaultDisplayLimit = 5;
let paginationDefaultDisplayLimit = 10;

let timelineModes = {
	day: 1,
	week: 2,
	month: 3,
	quarter: 4,
};

class QuestionHelper {
	getTypes() {
		return types.all;
	}

	isFilterable(questionData) {
		// return unfilterableTypes.indexOf(questionData.type) === -1;
		return types.unfilterable.indexOf(questionData.type) === -1;
	}

	typeHasReasons(questionData) {
		return types.withReasons.indexOf(questionData.type) !== -1;
	}
	typeHasOthers(questionData) {
		return types.withOthers.indexOf(questionData.type) !== -1;
	}
	typeHasAdditionalCompletions(questionData) {
		return types.withAdditionalCompletions.indexOf(questionData.type) !== -1;
	}
	typeHasNeutral(questionData) {
		return types.hasNeutral.indexOf(questionData.type) !== -1;
	}

	questionHasAdditionalCompletions(questionData) {
		// console.log('questionHasAdditionalCompletions', this.typeHasAdditionalCompletions(questionData));
		if (this.typeHasAdditionalCompletions(questionData)) {
			let limit = questionData.paginationPageCount || defaultDisplayLimit;
			if (questionData.type === types.all.ranking || questionData.type === types.all.number) {
				return questionData.fillCount > limit;
			}

			return questionData.paginationTotalCount > defaultDisplayLimit;
		}
		return false;
	}

	getQuestionIndex(question, list, source) {
		// console.log('getQuestionIndex', list.length);
		let index = 0;
		if (!question) {
			return null;
		}
		list.forEach((item) => {
			if (item.type !== types.all.staticText) {
				if (item.pageNumber < question.pageNumber) {
					index++;
				} else if (item.pageNumber === question.pageNumber) {
					if (item.order <= question.order) {
						index++;
					}
				}
			}
		});

		return index;
	}

	questionHasOthers(questionData) {
		if (questionData && questionData.answers) {
			if (this.isMatrixOpen(questionData)) {
				return questionData.answers.some((item) => item.some((inner) => !!inner.count));
			} else if (this.isMatrix(questionData) || this.isRating(questionData)) {
				return (
					(this.typeHasReasons(questionData) || this.typeHasOthers(questionData)) &&
					(questionData.rows.some((item) => !!item.hasOthers) ||
						questionData.columns.some((item) => !!item.hasOthers))
				);
			}
			return (
				questionData.answers.some((item) => item.hasOthers) &&
				(this.typeHasReasons(questionData) || this.typeHasOthers(questionData))
			);
		}
		return false;
	}

	getDisplayLimit() {
		return defaultDisplayLimit;
	}

	getTimelineModes() {
		return timelineModes;
	}

	getMostPopularAnswer(questionData) {
		let answers = questionData.answers;
		let mostPopular = {};

		if (!(this.isText(questionData) || this.isDate(questionData))) {
			return mostPopular;
		}

		if (answers.length > 1) {
			let freq = [];

			for (var i = 0, len = answers.length; i < len; i++) {
				let answer = answers[i];
				let c = parseInt(answer.count, 10);

				if (c > 1) {
					if (freq.indexOf(c) === -1) {
						if ((!isNaN(mostPopular.count) && mostPopular.count <= c) || isNaN(mostPopular.count)) {
							mostPopular = answer;
							mostPopular.count = c;
						}

						freq.push(answer.count);
					} else if (mostPopular && mostPopular.count === c) {
						mostPopular = {};
						break;
					}
				}
			}
		}

		return mostPopular;
	}

	isSingle(questionData) {
		return questionData.type === types.all.single;
	}
	isMultiple(questionData) {
		return questionData.type === types.all.multiple;
	}
	isMatrix(questionData) {
		return (
			questionData.type === types.all.matrixSingle ||
			questionData.type === types.all.matrixMulti ||
			questionData.type === types.all.matrixOpen
		);
	}
	isMatrixSingle(questionData) {
		return questionData.type === types.all.matrixSingle;
	}
	isMatrixMultiple(questionData) {
		return questionData.type === types.all.matrixMulti;
	}
	isMatrixOpen(questionData) {
		return questionData.type === types.all.matrixOpen;
	}
	isForm(questionData) {
		return questionData.type === types.all.form;
	}
	isRanking(questionData) {
		return questionData.type === types.all.ranking;
	}
	isSlider(questionData) {
		return questionData.type === types.all.slider;
	}
	isPoints(questionData) {
		return questionData.type === types.all.points;
	}
	isNumber(questionData) {
		return questionData.type === types.all.number;
	}
	isNPS(questionData) {
		return questionData.type === types.all.nps;
	}
	isText(questionData) {
		return questionData.type === types.all.text;
	}
	isStaticText(questionData) {
		return questionData.type === types.all.staticText;
	}
	isRating(questionData) {
		return questionData.type === types.all.rating;
	}
	isDate(questionData) {
		return questionData.type === types.all.date;
	}
	isEmail(questionData) {
		return questionData.type === types.all.email;
	}

	filterDotForNumeric(questionData) {
		questionData.sum = this.replaceDot(questionData.sum);
		questionData.average = this.replaceDot(questionData.average);
		questionData.mode = this.replaceDot(questionData.mode);
		questionData.median = this.replaceDot(questionData.median);

		questionData.answers.forEach((answer) => {
			answer.title = this.replaceDot(answer.title);
		});

		return questionData;
	}

	replaceDot(number) {
		return number.toString().replace(/\./g, ',');
	}
}

export default QuestionHelper;
