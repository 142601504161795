import angular from 'angular';
import { AbstractStorage } from './AbstractStorage';

class TagsStorage extends AbstractStorage {
	constructor(sentimentAPIService, Observer) {
		'ngInject';

		super(sentimentAPIService, Observer);

		this.data = null;
	}

	async fetchTags(from, to) {
		let tags;

		if (this.data) {
			tags = angular.copy(this.data);

			this.observer.notify(tags);
			return tags;
		}

		this.data = $$await(
			await this.sentimentAPIService.getTags(Math.floor(from / 1000), Math.floor(to / 1000))
		);

		tags = angular.copy(this.data);

		this.observer.notify(tags);
		return tags;
	}

	clear() {
		this.data = null;
	}
}

TagsStorage.$inject = ['sentimentAPIService', 'Observer'];

export { TagsStorage };
