import tpl from './ARQuestion.html';
import '../../scss/question.scss';
import jquery from 'jquery';

window.jquery = jquery;

class ARQuestionController {
	constructor(
		chartColorHelper,
		$element,
		modalService,
		alertsService,
		reportHelper,
		questionHelper,
		$scope,
		$sce,
		i18n,
		surveyProviderService,
		$sanitize,
		reportsService,
		aggregatedModeService,
		aclManager
	) {
		'ngInject';

		this.modalService = modalService;
		this.alertsService = alertsService;
		this.reportHelper = reportHelper;
		this.chartColorHelper = chartColorHelper;
		this.questionHelper = questionHelper;
		this.$scope = $scope;
		this.i18n = i18n;
		this.surveyProviderService = surveyProviderService;
		this.reportsService = reportsService;
		this.aggregatedModeService = aggregatedModeService;
		this.aclManager = aclManager;

		this.dom = jquery($element);
		this.totalCompletions = null;
		this.barColors = [];
		this.chart = null;
		this.chartCtrl = null;
		this.hasChartAnimationEnded = false;

		this.childQuestionCtrl = null;
		// this.extraComponents

		this.id = null;

		this.minRows = 3;
		this.fillers = [];
		this.answersReasonsObj = {};
		this.additionalSettings = {};
		this.answersWithoutNeutral = [];

		this.questionTypes = this.questionHelper.getTypes();
		this.isStaticText = false;
		this.isNPS = false;
		this.showQuestionNumbers = true;
		this.chartReady = false;
		this.noResults = false;

		this.displayLimit = 5;

		this.pageLimit = 10;
		this.isPagerRequired = true;

		// resetuje liczniki
		chartColorHelper.reset();
	}

	/**
	 * onInit - description
	 *
	 * @return {type}  description
	 */
	$onInit() {
		this.bindPager();
		// console.log('aRQuestion init', this.questionData, this.questionId, this.filterId, this.paginationManager);

		this.id = this.questionData.id;
		if (this.questionData.error) {
			console.log('ERROR', this.questionData);

			if (
				!this.questionData.rejectData ||
				parseInt(this.questionData.rejectData.status, 10) !== -1
			) {
				this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem', 'reports'));
			}
			return;
		}

		this.noResults = this.getTotalCompletions() === 0;
		if (this.noResults && this.reportBuilder) {
			this.reportBuilder.reportFullyLoaded();
		}

		this.last = Boolean(this.last);
		this.isStaticText = this.questionData.type === this.questionTypes.staticText;
		this.isNPS = this.questionData.type === this.questionTypes.nps;

		if (this.chartColor) {
			this.chartColorHelper.setSourceColor(this.chartColor);
		}

		if (this.reportBuilder && !this.reportAttachment) {
			this.reportBuilder.setQuestionReference(this);
		}

		this.updateBarColors();

		if (
			this.questionData &&
			this.questionData.answers &&
			this.questionData.answers.length &&
			this.questionData.answers.length < this.minRows
		) {
			this.fillers = new Array(this.minRows - this.questionData.answers.length);
			this.fillers.map(() => 1);
		}

		if (this.showAll) {
			this.displayLimit = undefined;
		}

		if (this.pageWrapper && this.pageWrapper.surveyWrapper) {
			this.pageWrapper.surveyWrapper.addQuestionController(this);
		}
	}

	$postLink() {
		if (this.$scope.$root.currentSurveyData) {
			this.showQuestionNumbers = this.$scope.$root.currentSurveyData.questionNumbers;
		}
	}

	/**
	 *
	 */
	getNextColor() {
		return this.chartColorHelper.getNextColor();
	}

	setChild(child) {
		this.childQuestionCtrl = child;
		if (this.reportBuilder) {
			this.reportBuilder.reportFullyLoaded();
		}
	}

	getChild() {
		return this.childQuestionCtrl;
	}

	/**
	 *
	 */
	getId() {
		return this.questionData.id;
	}

	updateBarColors(newTheme) {
		if (this.isStaticText) {
			return;
		}
		if (this.childQuestionCtrl && this.childQuestionCtrl.updateBarColors) {
			this.childQuestionCtrl.updateBarColors(newTheme);

			return;
		}

		this.barColors.length = 0;
		if (this.questionData.answers && this.questionData.answers.length) {
			this.answersWithoutNeutral = this.questionData.answers;
			if (this.hasNeutral()) {
				this.answersWithoutNeutral = [];
				this.questionData.answers.forEach((item) => {
					if (!this.isAnswerNeutral(item)) {
						this.answersWithoutNeutral.push(item);
					}
				});
			}

			this.barColors = this.chartColorHelper.getColorsTable(
				false,
				this.answersWithoutNeutral.length
			);
		}
	}

	update(newTheme) {
		if (newTheme) {
			this.updateBarColors(newTheme);

			if (this.childQuestionCtrl && this.childQuestionCtrl.update) {
				this.childQuestionCtrl.update(newTheme);
			} else if (this.chart) {
				this.chartCtrl.updateColor(newTheme.chartColor);
			}
			this.$scope.$apply();
		}

		if (this.$scope.$root.currentSurveyData) {
			this.showQuestionNumbers = this.$scope.$root.currentSurveyData.questionNumbers;
		}
		this.questionIndex = this.getQuestionIndex();
	}

	addToReport() {
		this.reportHelper.openAddToReportModal({
			questionCtrl: this,
			addWholeSurvey: false,
			filterId: this.surveyProviderService.filterId,
		});

		return false;
	}

	/**
	 * getTotalCompletions - description
	 *
	 * @return {type}  description
	 */
	getTotalCompletions() {
		if (this.isStaticText) {
			return 0;
		}
		let ctrl = this;

		if (this.questionData.fillCount) {
			this.totalCompletions = this.questionData.fillCount;
		} else if (this.questionData && this.questionData.answers && this.questionData.answers.length) {
			this.totalCompletions = 0;
			this.questionData.answers.forEach(function (elem, i) {
				ctrl.totalCompletions += elem.count || 0;
			});
			return this.totalCompletions;
		} else {
			this.totalCompletions = 0;
		}
		return this.totalCompletions;
	}

	/**
	 * getBarWidth - description
	 *
	 * @param  {type} answerIndex description
	 * @return {type}             description
	 */
	getBarWidth(answerIndex) {
		const percent = this.questionData.answers[answerIndex].percent;

		if (percent !== undefined) {
			return percent >= 100 ? 100 : percent;
		}

		let max = this.getTotalCompletions();
		return max === 0 ? 0 : (this.questionData.answers[answerIndex].count / max) * 100;
	}

	setChartReference(chartCtrl) {
		this.chartCtrl = chartCtrl;
		this.chart = chartCtrl.chart;
	}

	getChart() {
		return this.chart;
	}
	getChartController() {
		return this.chartCtrl;
	}

	tableItemMouseOver(i) {
		let item;
		if (this.chart && this.hasChartAnimationEnded) {
			item = this.chart.get(i);

			if (item) {
				if (item.points && item.points.length) {
					// pierwszy element z serii
					item.points[0].select();
				} else {
					item.select();
				}
			}
		}
	}

	tableItemLeave(i) {
		let item;
		if (this.chart && this.hasChartAnimationEnded) {
			item = this.chart.get(i);

			if (item) {
				if (item.points && item.points.length) {
					// pierwszy element z serii
					item.points[0].select(false);
				} else {
					item.select(false);
				}
			}
		}
	}

	chartPointMouseOver(target) {
		let index;
		// window.console.log('tu');
		if (target.id !== undefined) {
			index = target.id;
		} else if (target.series.index !== undefined) {
			index = target.series.index;
		}

		this.dom
			.find('tbody tr')
			.removeClass('question__answerTableRow--highlighted')
			.eq(index)
			.addClass('question__answerTableRow--highlighted');
	}

	chartPointMouseOut(target) {
		let index;
		// window.console.log('tu');
		if (target.id !== undefined) {
			index = target.id;
		} else if (target.series.index !== undefined) {
			index = target.series.index;
		}

		this.dom.find('tbody tr').eq(index).removeClass('question__answerTableRow--highlighted');
	}

	chartAnimationEnded(val) {
		this.hasChartAnimationEnded = val;
	}

	showOthersClick(answerIndex) {
		this.modalService.openShowOtherAnswersModal(this.questionIndex, answerIndex, this.questionData);
	}

	showCompletionsClick() {
		this.modalService.openShowOtherAnswersModal(this.questionIndex, null, this.questionData);
	}

	hasReasons(column) {
		let type = this.questionData.type;
		// console.log('hasReasons', column, type);

		if (type !== this.questionTypes.nps && type !== this.questionTypes.matrixSingle) {
			return false;
		}

		if (!column) {
			for (let i = 0; i < this.questionData.answers.length; i++) {
				if (this.questionData.answers[i].hasOthers) {
					return true;
				}
			}
		} else {
			// matrix
			let index = this.questionData.answers[0].answers.indexOf(column);

			if (column.hasOthers) {
				return true;
			}
			for (let i = 1; i < this.questionData.answers.length; i++) {
				let item = this.questionData.answers[i];
				if (
					item.answers &&
					item.answers.length &&
					item.answers[index] &&
					item.answers[index].hasOthers
				) {
					return true;
				}
			}
		}

		return false;
	}

	showReasons(answer, forAttachment) {
		let reasons = [];

		if (!answer) {
			// nps
			for (let i = 0; i < this.questionData.answers.length; i++) {
				if (this.questionData.answers[i].hasOthers) {
					reasons.push({
						title: this.questionData.answers[i].title,
						reasons: [],
						index: this.questionData.answers[i].index,
					});
				}
			}
		} else {
			// console.log('MACIERZ!');
			let column = answer;
			if (column.hasOthers) {
				column.otherIndexes.forEach((index) => {
					let row = this.questionData.rows[index - 1];
					reasons.push({
						title: row.title,
						index,
						reasons: [],
						matrixData: {
							column,
							row,
						},
					});
				});
			}
		}

		if (reasons.length && !forAttachment) {
			this.modalService.openExtendedAnswersModal(this.questionData, {
				answer,
				reasons,
				isNPS: this.isNPS,
			});
		} else if (reasons.length && forAttachment) {
			return {
				answer,
				reasons,
				isNPS: this.isNPS,
			};
		}
	}

	getAdditionalSettings() {
		return this.additionalSettings;
	}

	updateExtraCharts(opts) {
		if (opts && this.childQuestionCtrl) {
			if (opts.timeline) {
				this.additionalSettings.timelineMode = opts.mode;
			}
			if (opts.dynamicY) {
				this.additionalSettings.dynamicY = opts.mode;
			}

			if (this.surveyProviderService.filterId) {
				opts.filterId = this.surveyProviderService.filterId;
			}

			this.childQuestionCtrl.updateTimelineOptions(opts);
		}
	}

	hasNeutral() {
		return Boolean(this.questionData.neutral);
	}

	isAnswerNeutral(answerData) {
		// console.log('-isAnswerNeutral', this.questionHelper.typeHasNeutral(this.questionData), this.questionData.neutral, this.questionData.neutral && Number(this.questionData.neutral) === Number(answerData.index));
		if (!this.questionHelper.typeHasNeutral(this.questionData)) {
			return false;
		}
		return (
			this.questionData.neutral && Number(this.questionData.neutral) === Number(answerData.index)
		);
	}

	chartInitialized() {
		this.chartReady = true;
		if (this.childQuestionCtrl && this.childQuestionCtrl.chartInitialized) {
			this.childQuestionCtrl.chartInitialized();
			// console.log('ARQ.chartInitialized');
			if (this.reportBuilder) {
				this.reportBuilder.reportFullyLoaded();
			}
		}
	}
	isChartReady() {
		return this.chartReady;
	}

	isReady() {
		let ready = true;

		if (this.noResults) {
			return true;
		}

		if (!this.childQuestionCtrl) {
			ready = false;
		} else {
			ready = this.childQuestionCtrl.isReady();
		}
		// console.log('arq isReady', ready, this.childQuestionCtrl);

		return ready;
	}

	getQuestionIndex() {
		if (this.pageWrapper) {
			return this.pageWrapper.surveyWrapper.getQuestionIndex(this.questionData);
		}
		return this.surveyProviderService.getQuestionIndex(this.questionData);
	}

	getFilterId() {
		return this.filterId;
	}

	getPartController() {
		return this.reportPart;
	}

	getQuestionData() {
		return this.questionData;
	}

	scrollTo() {
		$('html, body').animate(
			{ scrollTop: $(`div[data-id="${this.questionData.id}"]`).offset().top - 100 },
			500
		);
	}

	bindPager() {
		this.$scope.$on('pageLimitChange', (event, data) => {
			this.pageLimit = data.pageLimit;

			this.surveyProviderService
				.getQuestion(this.questionData.id, data.pageLimit, 1)
				.then((questionData) => {
					this.questionData = questionData;

					if (!data.isFirst) {
						this.scrollTo();
					}
				});
		});

		this.$scope.$on('pagerInitialized', (event, data) => {
			this.isPagerRequired = data.visibility;
		});
	}

	isAllAggregatedMode() {
		return this.aggregatedModeService.isAllAggregatedMode();
	}

	canCreateReport() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}
}

export const aRQuestion = {
	template: tpl,
	controller: ARQuestionController,
	require: {
		pageWrapper: '?^surveyPageWrapper',
		reportBuilder: '?^reportBuilder',
		reportPart: '?^reportPart',
		reportAttachment: '?^reportAttachment',
	},
	bindings: {
		questionId: '<',
		questionData: '<',
		reportType: '<',
		reportData: '<',
		chartColor: '<',
		filterId: '<',
		showAll: '@',
		showPagination: '<',
		simple: '@',
		last: '<',
		paginationManager: '<',
	},
};
