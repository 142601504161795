/**
 * Serwis do logiki związanej z raportami
 *
 * @class ReportsService
 * @todo dodać komunikacje z serwerem
 */
class ReportsService {
	constructor(
		apiHelper,
		Restangular,
		reportHelper,
		surveyProviderService,
		crossTablesService,
		i18n,
		supportCRMService,
		UserRestangular
	) {
		'ngInject';

		const PARTS_POSITIONS = {
			START: 'start',
			END: 'end',
		};
		const EXPORT_FORMATS = reportHelper.getExportFormats();
		const PART_TYPES = reportHelper.getReportPartTypes();

		let self = this;
		this.apiHelper = apiHelper;
		this.surveyProviderService = surveyProviderService;
		this.i18n = i18n;
		this.crossTablesService = crossTablesService;
		let editStartedTimestamp = null;

		this.dataSynchronized = true;
		this.totalCount = 0;
		this.cache = {
			addToReport: {
				report: null,
				reportId: null,
				list: null,
				chapterIndex: null,
			},
		};

		this.interruptQueries = false;
		this.state = {};
		this.state.loadingProcesses = {};
		this.state.loadingProcesses = {
			list: false,
		};

		let reportIDs = {};
		let reportsData = [];
		let filesData = [];

		let now = new Date().getTime();

		let errorHandlerFactory = function errorHandlerFactory(reject) {
			return function handleError(data) {
				reject(data);
				self.apiHelper.handleDataError(data);
				return data;
			};
		};

		/**
		 * mapReportsIDs - mapuje indexy z tablicy raportow na ich ID
		 *
		 * @private
		 * @return {type}  description
		 */
		let mapReportsIDs = function mapReportsIDs() {
			reportIDs.length = 0;
			reportsData.forEach(function (elem, i) {
				reportIDs[elem.id] = i;
			});
		};

		/**
		 * fetchAllReports - pobiera z serwera
		 *
		 * @private
		 * @return {type}  description
		 */
		let fetchAllReports = (page) =>
			new Promise(function (resolve, reject) {
				page = page || 1;
				surveyProviderService.getCurrentSurveyIdPromise().then(
					function done(id) {
						Restangular.one(String(id))
							.one('reports')
							.get({ page })
							.then(function done(data) {
								data = data && data.plain ? data.plain() : data;
								reportsData = data.items;
								self.totalCount = data.total;
								mapReportsIDs();

								resolve(reportsData);
							}, errorHandlerFactory(reject));
					},
					function error() {
						apiHelper.handleNoToken();
						reject();
					}
				);
			});

		let cleanUpPartData = (partData, noArray = false) => {
			if (Array.isArray(partData)) {
				partData.map((elem) => {
					delete elem._builderData;
					return elem;
				});
				return partData;
			}

			delete partData._builderData;

			if (noArray) {
				return partData;
			}
			return [partData];
		};

		this.invalidate = () => {
			// console.log('reports invalidate');
			this.interrupt();
			reportIDs = {};
			reportsData = [];
			filesData = [];
			this.totalCount = 0;
			this.currentReportId = null;
		};
		this.interrupt = () => {
			if (this.state.loadingProcesses.list) {
				this.interruptQueries = true;
			} else {
				this.interruptQueries = false;
			}
		};
		this.finishInterrupt = () => {
			if (!this.state.loadingProcesses.list) {
				this.interruptQueries = false;
			}
		};

		this.clearCache = (key) => {
			if (key && this.cache[key]) {
				this.cache[key] = {};
			} else {
				for (var cache in this.cache) {
					if (Object.prototype.hasOwnProperty.call(this.cache, cache)) {
						this.cache[cache] = {};
					}
				}
			}
		};
		this.getCacheObject = (key) => {
			if (key) {
				return this.cache[key];
			}
			return this.cache;
		};

		/**
		 * getReportsListPromise -
		 *
		 * @public
		 * @return {type}  description
		 */
		this.getReportsListPromise = function getReportsListPromise(forceFetch, pageNumber) {
			return fetchAllReports(pageNumber).then(function done(data) {
				return data;
			});
		};

		this.continouslyLoadReports = (
			reportsListData,
			successCallback,
			finishCallback,
			paginationPageNumber
		) => {
			let total = this.getTotalCount();
			let count = reportsListData.length;
			let pageNumber = paginationPageNumber ? paginationPageNumber : 1;
			let nextPageNumber = pageNumber + 1;

			this.state.loadingProcesses.list = true;
			if (this.interruptQueries) {
				this.state.loadingProcesses.list = false;
				this.finishInterrupt();
				return;
			}

			if (count < total) {
				this.getReportsListPromise(false, nextPageNumber).then(
					(data) => {
						reportsListData = reportsListData.concat(data);
						if (successCallback) {
							successCallback(reportsListData, nextPageNumber);
						}
						this.continouslyLoadReports(
							reportsListData,
							successCallback,
							finishCallback,
							nextPageNumber
						);
					},
					(errData) => {
						this.state.loadingProcesses.list = false;
						console.error(errData);
					}
				);
			} else if (finishCallback) {
				this.state.loadingProcesses.list = false;
				finishCallback(count);
			}
		};

		this.copyReportPromise = (reportId, title) =>
			new Promise((resolve, reject) => {
				this.surveyProviderService.getCurrentSurveyIdPromise().then((id) => {
					Restangular.one(String(id))
						.one('reports', reportId)
						.post('copy', { title })
						.then((data) => {
							supportCRMService.emitReportCreated();
							resolve(data.id);
						}, reject);
				}, reject);
			});

		/**
		 * deleteReport -
		 *
		 * @public
		 * @return {type}  description
		 */
		this.deleteReport = (reportId) =>
			new Promise((resolve, reject) => {
				surveyProviderService.getCurrentSurveyIdPromise().then(
					(id) => {
						Restangular.one(String(id))
							.one('reports', reportId)
							.remove()
							.then(
								(data) => {
									var i = reportsData.findIndex((report) => report.id === reportId);
									reportsData.splice(i, 1);
									if (!data) {
										data = {
											id: reportId,
										};
									}
									resolve(data);
								},
								(data) => {
									reject(data);
									return data;
								}
							);
					},
					function error() {
						apiHelper.handleNoToken();
						reject();
					}
				);
			});

		/**
		 * exportReport -
		 *
		 * @public
		 * @return {type}  description
		 */
		this.exportReport = function exportReport(reportId, exportData) {
			return new Promise(function (resolve, reject) {
				surveyProviderService.getCurrentSurveyIdPromise().then(
					function done(id) {
						UserRestangular.one('old-reports')
							.post('export', exportData)
							.then(function done(data) {
								resolve(data);
							}, errorHandlerFactory(reject));
					},
					function error() {
						apiHelper.handleNoToken();
						reject();
					}
				);
			});
		};

		/**
		 * getReport - description
		 *
		 * @public
		 * @param  {Number} id Report ID
		 * @return {Object}    Single report data
		 */
		this.getReport = function getReport(id) {
			return reportsData[reportIDs[id]];
		};

		/**
		 * getReportPromise - description
		 *
		 * @public
		 * @param  {Number} id Report ID
		 * @return {Promise}    Single report data
		 */
		this.getReportPromise = (id) =>
			new Promise(function (resolve, reject) {
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', id)
						.get()
						.then(function done(data) {
							if (data) {
								data = data && data.plain ? data.plain() : data;
								if (reportIDs[data.id] === undefined) {
									reportsData.push(data);
								} else {
									reportsData[reportIDs[data.id]] = data;
								}
								mapReportsIDs();
								resolve(data);
								return data;
							}
							reject(data);
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.addPartDataToReportPromise = (conf, customizeNewPart) =>
			new Promise((resolve, reject) => {
				if (conf.newReportName) {
					self.addNewReportWithPartPromise(conf, customizeNewPart).then(resolve, reject);
				} else if (
					(conf.reportIndex !== undefined || conf.reportId !== undefined) &&
					conf.chapterIndex !== undefined
				) {
					self.addNewPartToReportPromise(conf, customizeNewPart).then(resolve, reject);
				}
			});

		this.addNewReportWithPartPromise = (conf, customizeNewPart) =>
			new Promise((resolve, reject) => {
				let newPart = reportHelper.getEmptyPartClone();

				reportHelper.getEmptyReportClonePromise().then((report) => {
					report.title = conf.newReportName;

					newPart.position = -1;
					newPart = customizeNewPart(newPart);
					report.chapters[1].parts.push(newPart);
					self.postReport(report, resolve, reject);
				});
			});

		this.addNewPartToReportPromise = (conf, customizeNewPart) =>
			new Promise((resolve, reject) => {
				let report;
				if (conf.reportIndex) {
					report = reportsData[conf.reportIndex];
				} else if (conf.reportId) {
					report = self.getReport(conf.reportId);
				}

				let position = conf.position ? conf.position : PARTS_POSITIONS.START;
				let chapter = report.chapters[conf.chapterIndex];

				let newPart = reportHelper.getEmptyPartClone();
				newPart.position = self.getPositionForNewPart(position);
				newPart = customizeNewPart(newPart);

				if (conf.newChapterName) {
					let chapter = {
						title: conf.newChapterName,
						position: -1,
					};

					self.addChapterToReportPromise(report.id, chapter).then((chapterData) => {
						self.addPartPromise(report, chapterData, newPart).then(resolve, reject);
					});
				} else {
					self.addPartPromise(report, chapter, newPart).then(resolve, reject);
				}
			});

		this.addPartPromise = (report, chapter, newPart) =>
			new Promise((resolve, reject) => {
				let reportId = report.id;

				self.addPartToReportPromise(reportId, chapter.id, newPart).then(
					(data) => {
						chapter.parts.splice(data.position, 0, data.parts[0]);

						if (newPart.type === PART_TYPES.TABLE_OF_CONTENTS) {
							self
								.updateReportPromise(self.currentReportId, { showTableOfContents: true })
								.then(() => {
									self.getReportPromise(reportId).then(resolve, reject);
									return self.getReport(reportId);
								});
						} else if (newPart.partType === PART_TYPES.SUMMARY) {
							self.updateReportPromise(self.currentReportId, { showSummary: true }).then(() => {
								self.getReportPromise(reportId).then(resolve, reject);
								return self.getReport(reportId);
							});
						} else {
							self.getReportPromise(reportId).then(resolve, reject);
							return self.getReport(reportId);
						}
					},
					function error(data) {
						reject(data);
						return data;
					}
				);
			});

		/**
		 * Creating array with additional settings while adding report part
		 * @param additionalSettings
		 * @returns {Array}
		 */
		this.getAdditionalSettingsParsed = (additionalSettings) => {
			if (!additionalSettings) {
				return [];
			}

			let additionalSettingsTypes = reportHelper.getAdditionalSettingsTypes();
			let additionalSettingsParsed = [];
			Object.keys(additionalSettingsTypes).forEach((key) => {
				if (additionalSettings[key]) {
					additionalSettingsParsed.push({
						type: additionalSettingsTypes[key],
						setup: additionalSettings[key],
					});
				}
			});
			return additionalSettingsParsed;
		};

		this.getPositionForNewPart = (position) => {
			if (position === PARTS_POSITIONS.START) {
				return 0;
			}

			if (position === PARTS_POSITIONS.END) {
				return -1;
			}

			return Number(position);
		};

		this.addToReportPromise = (opts) =>
			new Promise(function (resolve, reject) {
				let questionPromise;
				let report;
				let chapter;
				let position;
				let newPart;
				let additionalSettings = [];

				if (opts.questionId !== undefined) {
					questionPromise = surveyProviderService.getQuestionPromiseById(opts.questionId);
				}

				if (opts.additionalSettings && opts.additionalSettings.timelineMode) {
					additionalSettings.push({
						type: 1,
						setup: opts.additionalSettings.timelineMode,
					});
				}

				if (opts.additionalSettings && opts.additionalSettings.isDynamicAxisY) {
					additionalSettings.push({
						type: 2,
						setup: opts.additionalSettings.isDynamicAxisY,
					});
				}

				if (opts.newReportName) {
					self
						.addNewReportWithQuestion(
							opts.newReportName,
							opts.partType,
							questionPromise,
							opts.filterId,
							additionalSettings
						)
						.then(resolve, reject);
				} else {
					position = opts.position !== undefined ? opts.position : PARTS_POSITIONS.START;
					if (
						(opts.reportIndex !== undefined || opts.reportId !== undefined) &&
						opts.chapterIndex !== undefined
					) {
						if (opts.reportIndex !== undefined) {
							report = reportsData[opts.reportIndex];
						} else if (opts.reportId !== undefined) {
							report = self.getReport(opts.reportId);
						}
						chapter = report.chapters[opts.chapterIndex];

						if (opts.partType === PART_TYPES.TABLE_OF_CONTENTS) {
							newPart = reportHelper.getTableOfContentsClone();
							position = PARTS_POSITIONS.START;
						} else if (opts.partType === PART_TYPES.SUMMARY) {
							newPart = reportHelper.getSummaryClone();
							position = PARTS_POSITIONS.START;
						} else {
							newPart = reportHelper.getEmptyPartClone(opts.partType);
							newPart.question = opts.questionId;
							// newPart.type = Number(opts.partType);
							newPart.text = '';
							newPart.title = '';
						}

						if (position === PARTS_POSITIONS.START) {
							newPart.position = 0;
						} else if (position === PARTS_POSITIONS.END) {
							newPart.position = -1;
						} else {
							position = Number(position);
							newPart.position = position;
						}

						// filtr
						if (opts.filterId !== undefined) {
							newPart.filter = opts.filterId;
						}

						if (opts.additionalSettings && opts.additionalSettings.timelineMode) {
							newPart.additionalSettings = additionalSettings;
						}

						// nowy rozdział
						if (opts.newChapterName) {
							chapter = {
								title: opts.newChapterName,
								position: -1,
							};
							self.addChapterToReportPromise(report.id, chapter).then((data) => {
								self.addPartWithQuestion(report.id, data, newPart, resolve, reject, opts);
							}, reject);
						} else {
							self.addPartWithQuestion(report.id, chapter, newPart, resolve, reject, opts);
						}
					}
				}
			});

		this.addPartWithQuestion = (reportId, chapter, newPart, resolve, reject, opts) => {
			this.addPartToReportPromise(reportId, chapter.id, newPart).then(
				(data) => {
					chapter.parts.splice(data.position, 0, data.parts[0]);

					if (opts.partType === PART_TYPES.TABLE_OF_CONTENTS) {
						this.updateReportPromise(self.currentReportId, { showTableOfContents: true }).then(
							(data) => {
								// console.log('showTableOfContents', data);
								this.getReportPromise(reportId).then(resolve, reject);
								return this.getReport(reportId);
							}
						);
					} else if (opts.partType === PART_TYPES.SUMMARY) {
						self.updateReportPromise(self.currentReportId, { showSummary: true }).then((data) => {
							// console.log('showSummary', data);
							this.getReportPromise(reportId).then(resolve, reject);
							return this.getReport(reportId);
						});
					} else {
						this.getReportPromise(reportId).then(resolve, reject);
						return this.getReport(reportId);
					}
				},
				function error(data) {
					reject(data);
					return data;
				}
			);
		};
		this.postShowTableOfContents = (reportId, value) =>
			new Promise((resolve, reject) => {
				this.updateReportPromise(reportId || this.currentReportId, {
					showTableOfContents: value,
				}).then((data) => {
					// console.log('showTableOfContents', data);
					this.getReportPromise(reportId || this.currentReportId).then(resolve, reject);
					// return this.getReport(reportId || this.currentReportId);
				});
			});

		/**
		 * addNewReportWithQuestion - description
		 *
		 * @param  {String} name       description
		 * @param  {Number} partType   description
		 * @param  {Promise} questionPromise   description
		 * @return {Promise}           description
		 */
		this.addNewReportWithQuestion = (
			name,
			partType,
			questionPromise,
			filterId,
			additionalSettings
		) =>
			new Promise(function addNewReportWithQuestionPromise(resolve, reject) {
				// let report = reportHelper.getEmptyReportClone();
				let newPart = reportHelper.getEmptyPartClone();
				let reportPromise = reportHelper.getEmptyReportClonePromise();
				newPart.position = -1;
				newPart.additionalSettings = additionalSettings;

				reportPromise.then((report) => {
					report.title = name;

					questionPromise.then(
						function done(data) {
							// console.log('questionPromise.then', data);
							newPart.title = data.title;
							newPart.type = partType;
							newPart.text = '';
							newPart.filter = filterId;
							newPart.question = data.id;
							// report.chapters[0].parts.push(newPart);
							report.chapters[1].parts.push(newPart);

							self.postReport(report, resolve, reject);
						},
						function error(data) {}
					);
				});

				// nie dodano
				// reject();
			});

		// this.addWholeSurveyToReportPromise = (newReportName, filterId, partType, reportId) => new Promise(function addWholeSurveyToReportPromise(resolve, reject) {
		this.addWholeSurveyToReportPromise = (opts) =>
			new Promise((resolve, reject) => {
				let newReportName = opts.newReportName || null;
				let filterId = opts.filterId || null;
				// let partType = opts.partType || null;
				let partType = null;
				let reportId = opts.reportId || null;
				let chapterIndex = opts.chapterIndex || null;
				let newChapterName = opts.newChapterName || null;
				let report = null;
				let chapter = null;
				let parts = [];
				// console.log('addWholeSurveyToReportPromise', opts, opts.questionControllers, partType);
				let go = function (report, reportId, newChapterName, chapter, resolve, reject) {
					surveyProviderService.loadAllQuestions({ addQuestion: () => {} }, (list) => {
						if (newReportName) {
							report.title = newReportName;
						}

						list.sort((a, b) => {
							if (a.pageNumber === b.pageNumber) {
								return a.order - b.order;
							}
							return a.pageNumber - b.pageNumber;
						});

						for (let i = 0, max = list.length; i < max; i++) {
							let newPart = reportHelper.getEmptyPartClone(partType, list[i]);
							newPart.title = list[i].title;
							// newPart.type = Number(partType);
							newPart.text = '';
							newPart.question = Number(list[i].id);

							if (
								reportHelper.questionHasAdditionalSettings(list[i]) &&
								opts.questionControllers &&
								opts.questionControllers.length
							) {
								for (let j = 0; j < opts.questionControllers.length; j++) {
									if (
										opts.questionControllers[j].questionData.id === list[i].id &&
										opts.questionControllers[j].getChild()
									) {
										newPart.additionalSettings[0].setup = opts.questionControllers[j]
											.getChild()
											.getTimelineMode();
										// console.log('znalazłem!', opts.questionControllers[j], newPart);
									}
								}
							}

							newPart.filter = filterId;
							newPart.position = -1;
							parts.push(newPart);
						}
						if (reportId) {
							if (newChapterName) {
								self.addChapterToReportPromise(reportId, chapter).then((data) => {
									self.addPartToReportPromise(reportId, data.id, parts).then(resolve, reject);
								}, reject);
							} else {
								self.addPartToReportPromise(reportId, chapter.id, parts).then(resolve, reject);
							}
						} else {
							chapter.parts = chapter.parts.concat(parts);
							self.postReport(report, resolve, reject);
						}
					});
				};

				if (reportId) {
					// console.log('reportId', reportId);
					report = self.getReport(reportId);

					if (newChapterName) {
						chapter = {
							title: newChapterName,
							position: -1,
						};
					} else if (chapterIndex !== null) {
						chapter = report.chapters[chapterIndex];
					} else {
						// dodaj do ostatniego
						chapter = report.chapters[report.chapters.length - 1];
					}

					go(report, reportId, newChapterName, chapter, resolve, reject);
				} else {
					reportHelper.getEmptyReportClonePromise().then((rep) => {
						chapter = rep.chapters[1];
						go(rep, null, newChapterName, chapter, resolve, reject);
					});
				}
			});

		/**
		 * createEmptyReportPromise - description
		 * @public
		 * @function createEmptyReportPromise
		 * @return {type}  description
		 */
		this.createEmptyReportPromise = (title) =>
			new Promise((resolve, reject) => {
				reportHelper.getEmptyReportClonePromise().then((report) => {
					let now = new Date();
					if (title) {
						report.title = title;
					} else {
						report.title = this.i18n._s(
							'Szkic raportu z dnia %s z godziny %s',
							now.toLocaleDateString(),
							now.getHours() + ':' + now.getMinutes(),
							'reports'
						);
					}
					this.postReport(report, resolve, reject);
				});
			});

		this.postReport = (report, resolve, reject) => {
			self.dataSynchronized = false;

			surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
				Restangular.one(String(surveyId))
					.all('reports')
					.post(report)
					.then((data) => {
						data = data && data.plain ? data.plain() : data;

						reportsData.push(data);
						mapReportsIDs();
						self.dataSynchronized = true;

						resolve(data);

						supportCRMService.emitReportCreated();

						return data;
					}, errorHandlerFactory(reject));
			}, errorHandlerFactory(reject));
		};

		this.updateReportPromise = (reportId, updateData) =>
			new Promise(function (resolve, reject) {
				// console.log('updateReportPromise');
				self.dataSynchronized = false;
				if (updateData.title !== undefined && updateData.title.length === 0) {
					updateData.title = reportHelper.getDefaultValues().reportTitle;
				}
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.patch(updateData)
						.then(function done(data) {
							mapReportsIDs();
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.postThemePromise = (id, theme) =>
			new Promise(function (resolve, reject) {
				self.dataSynchronized = false;
				Restangular.one('report-theme')
					.all(id)
					.patch(theme)
					.then(function done(data) {
						self.dataSynchronized = true;
						resolve(data);
						return data;
					}, errorHandlerFactory(reject));
			});

		this.addChapterToReportPromise = (reportId, chapter) =>
			new Promise(function (resolve, reject) {
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.all('chapters')
						.post(chapter)
						.then(function done(data) {
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.deleteChapterPromise = (reportId, chapterId) =>
			new Promise(function (resolve, reject) {
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.remove()
						.then(function done(data) {
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.updateChapterPromise = (reportId, chapterId, updateData) =>
			new Promise(function (resolve, reject) {
				// console.log('updateChapterPromise', updateData);
				if (updateData.title !== undefined && updateData.title.length === 0) {
					updateData.title = reportHelper.getDefaultValues().chapterTitle;
				}
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.patch(updateData)
						.then(function done(data) {
							data = data && data.plain ? data.plain() : data;
							self.dataSynchronized = true;

							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.addPartToReportPromise = (reportId, chapterId, parts) =>
			new Promise((resolve, reject) => {
				// console.log('addPartToReportPromise', reportId, chapterId, parts, parts.length ? cleanUpPartData(parts.slice(0)) : '');
				// console.log('addPartToReportPromise', reportId, chapterId, parts);
				this.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.all('parts')
						.post({ parts: cleanUpPartData(parts) })
						.then((data) => {
							data = data && data.plain ? data.plain() : data;
							// console.log('addPartToReportPromise data', data);
							this.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.deletePartPromise = (reportId, chapterId, partId) =>
			new Promise((resolve, reject) => {
				// console.log('deletePartPromise');
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.one('parts', partId)
						.remove()
						.then(function done(data) {
							data = data && data.plain ? data.plain() : data;
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.updatePartPromise = (reportId, chapterId, partId, partData) =>
			new Promise(function (resolve, reject) {
				// console.log('updatePartPromise');
				reportId = reportId || self.currentReportId;
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					// Restangular.one(surveyId).one('reports', reportId).one('chapters', chapterId).one('parts', partId).patch({parts: cleanUpPartData(partData)}).then(function done(data) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.one('parts', partId)
						.patch(cleanUpPartData(partData, true))
						.then(function done(data) {
							data = data && data.plain ? data.plain() : data;
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.addAdditionalSettingsPromise = (reportId, chapterId, partId, settingsData) =>
			new Promise(function (resolve, reject) {
				reportId = reportId || self.currentReportId;
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.one('parts', partId)
						.all('settings')
						.post(cleanUpPartData(settingsData, true))
						.then(function done(data) {
							data = data && data.plain ? data.plain() : data;
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.updateAdditionalSettingsPromise = (reportId, chapterId, partId, settingId, settingsData) =>
			new Promise(function (resolve, reject) {
				reportId = reportId || self.currentReportId;
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.one('parts', partId)
						.one('settings', settingId)
						.patch(cleanUpPartData(settingsData, true))
						.then(function done(data) {
							data = data && data.plain ? data.plain() : data;
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.updateChaptersPositionPromise = (reportId, positionData) =>
			new Promise(function (resolve, reject) {
				reportId = reportId || self.currentReportId;
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					// Restangular.one(surveyId).one('reports', reportId).one('chapters').one('position').post(positionData).then(function done(data) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters')
						.one('position')
						.customPOST(positionData, '')
						.then(function done(data) {
							data = data && data.plain ? data.plain() : data;
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.updatePartsPositionPromise = (reportId, chapterId, positionData) =>
			new Promise(function (resolve, reject) {
				reportId = reportId || self.currentReportId;
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					// Restangular.one(surveyId).one('reports', reportId).one('chapters').one('position').post(positionData).then(function done(data) {
					Restangular.one(String(surveyId))
						.one('reports', reportId)
						.one('chapters', chapterId)
						.one('parts')
						.one('position')
						.customPOST(positionData, '')
						.then(function done(data) {
							data = data && data.plain ? data.plain() : data;
							self.dataSynchronized = true;
							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		this.getLogoUploadUrl = () => {
			// w tym miejscu id musi już być pobrane i ustawione
			let surveyId = surveyProviderService.getCurrentSurveyId();
			let url = apiHelper.getApiPrefix();

			url += surveyId + '/reports/' + self.currentReportId + '/logo/';

			return url;
		};

		this.uploadLogo = (formData) =>
			new Promise(function (resolve, reject) {
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					// if (self.currentReportId) {}
					Restangular.one(String(surveyId))
						.one('reports', self.currentReportId)
						.one('logo')
						.customPOST(formData, '', {}, { 'Content-Type': undefined })
						.then(
							function done(data) {
								// console.log('uploadLogo', data);

								self.dataSynchronized = true;
								self.getCurrentReport().logo = data;

								resolve(data);
								return data;
							},
							(errData) => {
								// console.log('uploadLogo error', errData);
								self.dataSynchronized = true;
								reject(errData);
								return errData;
							}
						);
				}, errorHandlerFactory(reject));
			});

		this.deleteLogo = (formData) =>
			new Promise(function (resolve, reject) {
				self.dataSynchronized = false;
				surveyProviderService.getCurrentSurveyIdPromise().then(function done(surveyId) {
					Restangular.one(String(surveyId))
						.one('reports', self.currentReportId)
						.one('logo')
						.remove()
						.then(function done(data) {
							self.dataSynchronized = true;
							self.getCurrentReport().logo = null;

							resolve(data);
							return data;
						}, errorHandlerFactory(reject));
				}, errorHandlerFactory(reject));
			});

		/**
		 * getConst - zwraca stałe pomocnicze
		 *
		 * @public
		 * @function getConst
		 * @return {Object}  stałe przydatne w innych komponentach i serwisach
		 */
		this.getConst = function getConst() {
			return {
				PARTS_POSITIONS,
				EXPORT_FORMATS,
			};
		};

		/**
		 * markReportUpdated - ustaiwa pole updated na aktualny timestamp
		 *
		 * @param  {Object} opts {report: Object} lub {id: Number}
		 * @return {type}      description
		 */
		this.markReportUpdated = function markReportUpdated(opts) {};

		this.setCurrentReport = function setCurrentReport(idOrReport) {
			if (typeof idOrReport === 'object') {
				this.currentReportId = idOrReport.id;
			} else {
				this.currentReportId = idOrReport;
			}
		};

		this.getCurrentReport = () => this.getReport(this.currentReportId);

		this.isDataSynchronized = () => this.dataSynchronized;
		this.setDataSynchronized = (val) => {
			this.dataSynchronized = val;
		};
		this.startEdit = (idOrReport) => {
			this.reportDownloaded = false;
			this.setCurrentReport(idOrReport);
			document.body.classList.remove('reportDownloaded');
		};
		this.endEdit = () => {
			document.body.classList.remove('reportDownloaded');
		};
		this.reportFullyLoaded = () => {
			this.reportDownloaded = true;
			document.body.classList.add('reportDownloaded');
		};
		this.isReportDownloaded = () => this.reportDownloaded;
		this.getTotalCount = () => this.totalCount;

		apiHelper.setDataService('reports', this);
	}
}

export default ReportsService;
