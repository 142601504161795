const tpl = require('./FakeFileInput.html');
const $ = require('jquery');

class FakeFileInputController {
	constructor(
		$element,
		reportsService,
		$scope,
		modalService,
		alertsService,
		apiHelper,
		i18n,
		supportCRMService
	) {
		'ngInject';

		let ctrl = this;
		let hasAdvancedUpload;
		let dndEnabledClass = 'fakeFileInput--dndEnabled';
		let fileSelectedClass = 'fakeFileInput--fileSelected';
		let windowOnDrag;
		let windowOnDragEnd;
		let fileSelected = false;
		let displayFileName;
		let addFile;
		let droppedFiles;
		let previewMouseIn;
		let hidePreview;

		this.modalService = modalService;
		this.alertsService = alertsService;
		this.reportsService = reportsService;
		this.apiHelper = apiHelper;
		this.i18n = i18n;
		this.supportCRMService = supportCRMService;

		let imgPrefix = '';

		this.dom = $($element);
		this.rawDom = this.dom[0];
		this.formDom = $('form', this.dom);
		this.formData = null;
		this.fieldName = 'fileName';
		this.isDndEnabled = false;
		this.isFileUploaded = false;
		this.preview = null;
		this.previewInBody = null;
		this.previewOffset = null;
		this.previewTimeout = null;

		hasAdvancedUpload =
			('draggable' in this.rawDom || ('ondragstart' in this.rawDom && 'ondrop' in this.rawDom)) &&
			'FormData' in window &&
			'FileReader' in window;

		previewMouseIn = function previewMouseIn(e) {
			// console.log('previewMouseIn', ctrl.filePath);
			if (ctrl.filePath) {
				if (!ctrl.previewOffset) {
					ctrl.previewOffset = this.getBoundingClientRect();
					ctrl.previewInBody[0].style.top = ctrl.previewOffset.top + 'px';
					ctrl.previewInBody[0].style.left =
						ctrl.previewOffset.left + ctrl.previewOffset.width - 10 + 'px';
				}
				clearTimeout(ctrl.previewTimeout);
				ctrl.previewInBody.show();
			}
		};
		hidePreview = function functionName(e) {
			if (ctrl.filePath) {
				clearTimeout(ctrl.previewTimeout);
				ctrl.previewTimeout = setTimeout(function () {
					ctrl.previewInBody.hide();
				}, 100);
			}
		};

		this.$onInit = function onInit() {
			this.previewInBody = this.preview.clone();
			this.previewInBody.addClass('fakeFileInput__preview--inBody').appendTo('body');

			this.previewInBody.on('mousemove', previewMouseIn);
			this.previewInBody.on('mouseout', hidePreview);

			this.dom.hover(previewMouseIn, hidePreview);
			this.updatePreview();
		};

		this.$postLink = function postLink() {
			if (this.handleMethod) {
				this.handleMethod(this);
			}
		};

		this.$onDestroy = function onDestroy() {
			$(window).off('dragover dragenter', windowOnDrag);
			$(window).off('dragleave dragend drop', windowOnDragEnd);

			this.previewInBody.remove();
		};

		this.$onChanges = function $onChanges() {
			if (this.filePath) {
				this.filePath = this.filePath.replace(/http[s]?:\/\//, '');
				displayFileName(this.filePath);
				this.formDom.addClass(fileSelectedClass);
				this.isFileUploaded = true;
				this.updatePreview();
			}
		};

		this.updatePreview = function updatePreview() {
			if (this.previewInBody && this.previewInBody.length && this.filePath) {
				this.previewInBody.find('img').attr('src', '//' + this.filePath);
			}
		};

		this.input = $('.fakeFileInput__input', this.dom);
		this.status = $('.fakeFileInput__status', this.dom);
		this.text = $('.text', this.status);
		this.cancel = $('.fa', this.status);
		this.dndOverlay = $('.fakeFileInput__dndOverlay', this.dom);
		this.preview = $('.fakeFileInput__preview', this.dom);
		this.previewImg = $('img', this.preview);

		this.input
			.on('change', function (e) {
				let path = this.value;

				if (path) {
					droppedFiles = null;
					addFile(path);
				}
			})
			.on('click', function functionName(e) {
				if (ctrl.disabled) {
					e.preventDefault();
					if (ctrl.disabledHandler) {
						ctrl.disabledHandler();
					}
					return false;
				}
			});

		this.cancelFunction = function cancelFunction() {
			if (ctrl.isFileUploaded) {
				reportsService.deleteLogo().then(
					function done(data) {
						ctrl.formDom.removeClass(fileSelectedClass);
						displayFileName('');
						ctrl.input.val('');
						ctrl.formDom[0].reset();
						ctrl.formData = null;
						ctrl.filePath = '';
						$scope.$digest();
					},
					function error(data) {
						console.error(data);
					}
				);
			} else {
				ctrl.formDom.removeClass(fileSelectedClass);
				displayFileName('');
				ctrl.input.val('');
				ctrl.formData = null;
			}

			hidePreview();
		};

		this.cancel.on('click', this.cancelFunction);

		this.upload = () =>
			new Promise((resolve, reject) => {
				ctrl.isFileUploaded = false;

				reportsService.uploadLogo(ctrl.formData).then(
					function done(data) {
						if (data) {
							data = data.replace(/http[s]?:\/\//, '');
							ctrl.filePath = imgPrefix + data;
							ctrl.isFileUploaded = true;
							ctrl.updatePreview();
							resolve(data);
							return data;
						}
					},
					(errData) => {
						if (errData && errData.status === 400) {
							this.alertsService.error(
								this.i18n.__('Nie udało się zaimportować pliku.', 'reports')
							);
						} else {
							this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem.', 'reports'));
						}
					}
				);
			});

		displayFileName = function displayFileName(path) {
			path = path.replace(/\\/g, '/');

			path = path.substr(path.lastIndexOf('/') + 1);
			ctrl.text.text(path);
		};

		addFile = function addFile(path) {
			displayFileName(path);

			ctrl.formData = new FormData();
			if (ctrl.input[0].files[0]) {
				ctrl.formData.append('logo', ctrl.input[0].files[0]);
			}

			if (droppedFiles && droppedFiles[0]) {
				ctrl.formData.append('logo', droppedFiles[0].getAsFile());
			}

			fileSelected = Boolean(ctrl.formData.getAll('logo') && ctrl.formData.getAll('logo').length);

			if (fileSelected) {
				ctrl.upload().then(
					() => {
						ctrl.modalService
							.show({
								size: '400',
								title: i18n.__('Logo zaimportowane', 'reports'),
								text: i18n.__('Logo zostało poprawnie dodane do raportu', 'reports'),
								icon: {
									color: 'green',
									svg: '',
									font: 'fa-check',
								},
								button: {
									id: 'upload',
									text: 'OK',
									type: 'button--wide',
									closeOnClick: true,
								},
							})
							.then((action) => {
								if (action === 'upload') {
									ctrl.supportCRMService.emitReportLogoUploaded();
									ctrl.formDom.addClass(fileSelectedClass);
								}
							});
					},
					() => {
						ctrl.alertsService.error(ctrl.i18n.__('Nie udało się zaimportować pliku.', 'reports'));
					}
				);
			} else {
				ctrl.formDom.removeClass(fileSelectedClass);
			}
			ctrl.formDom.removeClass(dndEnabledClass);
		};

		windowOnDrag = function windowOnDrag(e) {
			e.preventDefault();

			try {
				if (e.originalEvent.dataTransfer.types && e.originalEvent.dataTransfer.types.length) {
					for (let i = 0; i < e.originalEvent.dataTransfer.types.length; i++) {
						let item = e.originalEvent.dataTransfer.types[i];
						if (item.toLowerCase() === 'files') {
							ctrl.formDom.addClass(dndEnabledClass);
							break;
						}
					}
				}
			} catch (error) {
				console.error(error);
			}
		};

		windowOnDragEnd = function windowOnDragEnd() {
			ctrl.formDom.removeClass(dndEnabledClass);
		};

		if (hasAdvancedUpload) {
			$(window).on('dragover dragenter', windowOnDrag);
			$(window).on('dragleave dragend drop', windowOnDragEnd);

			this.formDom
				.on('drag dragstart dragend dragover dragenter dragleave drop', function (e) {
					e.preventDefault();
					e.stopPropagation();
				})
				.on('dragover dragenter', function () {
					ctrl.formDom.addClass(dndEnabledClass);
				})
				.on('dragleave dragend drop', function () {
					ctrl.formDom.removeClass(dndEnabledClass);
				})
				.on('drop', function (e) {
					droppedFiles = e.originalEvent.dataTransfer.items;

					fileSelected = true;
					addFile(droppedFiles[0].getAsFile().name);
				});
		}

		this.formDom.on('submit', function submit(e) {
			e.preventDefault();
			return false;
		});
	}
}

export const fakeFileInput = {
	template: tpl,
	controller: FakeFileInputController,
	bindings: {
		handleMethod: '<',
		disabledHandler: '<',
		filePath: '<',
		disabled: '<',
	},
};
