const tpl = require('./ReportBuilder.html');
require('../../scss/reportBuilder.scss');
const jquery = require('jquery');
let highcharts = require('highcharts');
const angular = require('angular');

/**
 *
 * @class ReportBuilderController
 *
 */
class ReportBuilderController {
	constructor(
		reportsService,
		$scope,
		$rootScope,
		$element,
		reportPartEditorService,
		reportHelper,
		reportBuilderHelper,
		$state,
		modalService,
		userService,
		alertsService,
		i18n,
		supportCRMService,
		questionHelper,
		$timeout,
		aclManager
	) {
		'ngInject';
		let ctrl = this;

		this.reportsService = reportsService;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$element = $element;
		this.reportPartEditorService = reportPartEditorService;
		this.reportHelper = reportHelper;
		this.reportBuilderHelper = reportBuilderHelper;
		this.$state = $state;
		this.modalService = modalService;
		this.userService = userService;
		this.alertsService = alertsService;
		this.i18n = i18n;
		this.questionHelper = questionHelper;
		this.supportCRMService = supportCRMService;
		this.$timeout = $timeout;
		this.aclManager = aclManager;

		this.isLoading = true;
		this.editionInProgress = false;

		this.chapterDraggingDisabled = true;
		this.partsDraggingDisabled = true;
		this.currentReport = null;

		this.questionControllers = [];
		this.crossTableControllers = [];
		this.rankingControllers = [];
		this.trendControllers = [];
		this.attachmentControllers = [];

		this.questionCount = 0;
		this.crossTableCount = 0;
		this.staticTextQuestionCount = 0;

		this.nameEditorWrapper = null;
		this.nameEditor = null;
		this.nameEditorInput = null;
		this.nameEditorVisible = false;

		this.partDroppedIndex = null;

		this.questions = [];
		this.crossTables = [];
		this.rankings = [];
		this.trends = [];
		this.attachments = [];

		this.disabled = false;
		this.EXPORT_FORMATS = reportHelper.getExportFormats();
		this.logoPath = null;
		this.sortableChapters = [];
		this.headChapter = null;

		this.drag = {
			// helper do DND
			parts: {},
			chapters: {},
		};

		this.themeLoaded = false;

		this.locked = false;

		this.dom = jquery($element);
		this.DATA_INFO = window.DATA_INFO;

		this.reportBuilderHelper.setBuilder(this);

		this.fetchUpdatedReport = () => {
			if (ctrl.editMode) {
				this.drag = {
					parts: {},
					chapters: {},
				};

				this.reportLoaded = false;

				this.attachments = [];
				this.attachmentControllers = [];
				this.questions = [];
				this.questionControllers = [];
				this.crossTables = [];
				this.crossTableControllers = [];
				this.rankings = [];
				this.rankingControllers = [];
				this.trends = [];
				this.trendControllers = [];

				this.locked = true;

				reportsService.getReportPromise(ctrl.editId).then(
					(data) => {
						this.locked = false;
						this.currentReport = data;

						this.$scope.reportTitle = data.title;
						this.parseReportData();

						this.$scope.$digest();
					},
					() => {}
				);
			}
		};

		this.$onDestroy = () => {
			this.reportsService.endEdit();
		};

		this.parseReportData = (quick) => {
			// console.log('**** parseReportData', quick, this.currentReport.chapters);
			let fieldName = reportBuilderHelper.builderDataName;
			let chapterIndex;
			let partIndex;
			let chapter;
			let part;
			let questionCounter = 0;
			let crossTableCounter = 0;
			let rankingCounter = 0;
			let trendCounter = 0;
			let partCounter = 0;
			let staticTextQuestionCounter = 0;
			let summaryCount = 0;
			let tocCount = 0;
			this.questions = [];
			this.sortedPartsIds = [];
			let partsToRemove = [];

			if (!quick) {
				this.staticTextQuestionCount = 0;
				this.sortableChapters = [];
				this.headChapter = null;
			}

			for (let j = 0; j < this.currentReport.chapters.length; j++) {
				let chapter = this.currentReport.chapters[j];

				for (let i = 0; i < chapter.parts.length; i++) {
					let part = chapter.parts[i];
					this.sortedPartsIds.push(part.id);

					if (part.question) {
						this.questions.push(part.question);
						this.isEmpty = false;

						if (part.isNumeric) {
							questionCounter++;
							partCounter++;
							part[fieldName] = {
								questionIndexInReport: partCounter,
							};
						} else {
							staticTextQuestionCounter++;
						}
					} else if (part.type === reportHelper.reportPartTypes.TABLE_OF_CONTENTS) {
						tocCount++;
						if (tocCount > 1) {
							partsToRemove.push({
								chapter: j,
								part: i,
							});
						}
					} else if (part.type === reportHelper.reportPartTypes.SUMMARY) {
						summaryCount++;
						if (summaryCount > 1) {
							partsToRemove.push({
								chapter: j,
								part: i,
							});
						}
					} else if (reportHelper.isCrossTable(part.type)) {
						crossTableCounter++;
						partCounter++;
						this.isEmpty = false;
						this.crossTables.push(part.crossTable);

						part[fieldName] = {
							questionIndexInReport: partCounter,
						};
					} else if (reportHelper.isRanking(part.type)) {
						rankingCounter++;
						partCounter++;
						this.isEmpty = false;
						this.rankings.push(part.ranking);

						part[fieldName] = {
							questionIndexInReport: partCounter,
						};
					} else if (reportHelper.isTrend(part.type)) {
						trendCounter++;
						partCounter++;
						this.isEmpty = false;
						this.trends.push(part.trend);

						part[fieldName] = {
							questionIndexInReport: partCounter,
						};
					}
				}
			}

			if (partsToRemove.length > 0) {
				partsToRemove.forEach((data) => {
					this.currentReport.chapters[data.chapter].parts.splice(data.part, 1);
				});
			}

			if (!quick) {
				this.sortableChapters = angular.copy(this.currentReport.chapters).splice(1);
				this.headChapter = angular.copy(this.currentReport.chapters[0]);
			}

			if (!this.currentReport.locked) {
				this.enableEdition();

				if (this.currentReport.logo) {
					this.logoPath = this.currentReport.logo.replace(/http[s]?:\/\//, '');
				}
			}

			this.questionCount = questionCounter;
			this.crossTableCounter = crossTableCounter;
			this.rankingCounter = rankingCounter;
			this.trendCounter = trendCounter;
			this.staticTextQuestionCount = staticTextQuestionCounter;
			this.isEmpty =
				!this.questions.length &&
				!this.crossTables.length &&
				!this.rankings.length &&
				!this.trends.length;
			this.reportFetched = true;

			if (this.isEmpty) {
				this.reportsService.reportFullyLoaded();
			}
		};

		this.addChapter = function addChapter() {
			// console.log('addChapter');
			if (this.isDisabled()) {
				this.showDisabledModal();
				return;
			}
			if (this.locked) {
				return;
			}
			this.locked = true;
			let empty = reportHelper.getEmptyChapterClone();
			empty.position = -1;

			reportsService.addChapterToReportPromise(this.currentReport.id, empty).then(
				(data) => {
					// data._showEdit = true;
					this.locked = false;
					this.currentReport.chapters.push(data);
					this.sortableChapters.push(data);
					$scope.$digest();

					this.supportCRMService.emitReportChapterCreated();

					if (!this.chapterDraggingDisabled) {
						this.onChapterInsert();
					}
				},
				() => {
					this.locked = false;
				}
			);
		};

		this.deleteChapter = function deleteChapter(index) {
			// console.log('builder.deleteChapter', index);
			if (this.isDisabled()) {
				this.showDisabledModal();
				return;
			}
			if (this.locked) {
				return;
			}
			this.locked = true;

			this.modalService
				.showConfirm({
					text: this.i18n.__('Czy na pewno usunąć ten rozdział?', 'reports'),
					title: this.i18n.__('Potwierdź usuwanie', 'reports'),
					confirmText: this.i18n.__('Usuń', 'reports'),
					cancelText: this.i18n.__('Anuluj', 'reports'),
				})
				.then(
					() => {
						// console.log('OK', index, this.currentReport.chapters[index]);
						reportsService
							.deleteChapterPromise(this.currentReport.id, this.currentReport.chapters[index].id)
							.then(
								(data) => {
									this.locked = false;

									this.alertsService.success(this.i18n.__('Usunięto rozdział', 'reports'));
									this.fetchUpdatedReport();
								},
								(data) => {
									this.locked = false;
									console.error(data);
								}
							);
					},
					() => {
						this.locked = false;
					}
				);
		};

		this.setQuestionReference = function setQuestionReference(questionCtrl) {
			this.questionControllers.push(questionCtrl);

			if (this.questionControllers.length === this.questionCount + this.staticTextQuestionCount) {
				this.reportFullyLoaded();
			}
		};

		this.updateQuestions = function updateQuestions(newTheme) {
			this.questionControllers.forEach(function (elem) {
				elem.update(newTheme);
			});
		};

		this.setCrossTableReference = (crossTableCtrl) => {
			this.crossTableControllers.push(crossTableCtrl);
		};

		this.updateCrossTables = (newTheme) => {
			this.crossTableControllers.forEach((crossTableCtrl) => {
				crossTableCtrl.themeUpdate(newTheme);
			});
		};

		this.updateTrends = (newTheme) => {
			this.trendControllers.forEach((trendCtrl) => {
				trendCtrl.themeUpdate(newTheme);
			});
		};

		this.setRankingReference = (rankingCtrl) => {
			this.rankingControllers.push(rankingCtrl);
		};

		this.setTrendReference = (trendCtrl) => {
			this.trendControllers.push(trendCtrl);
		};

		this.addAnswersAttachmentController = (attController) => {
			this.attachmentControllers.push(attController);

			this.reportFullyLoaded();
		};

		this.removeAnswersAttachmentController = (attController) => {
			this.attachmentControllers.splice(this.attachmentControllers.indexOf(attController), 1);
		};

		this.reportFullyLoaded = () => {
			if (
				this.reportFetched &&
				!this.reportLoaded &&
				// this.questionsDataArray.length === this.questions.length &&
				this.questionControllers.length +
					this.crossTableControllers.length +
					this.rankingControllers.length +
					this.trendControllers.length ===
					this.questionCount +
						this.staticTextQuestionCount +
						this.crossTableCounter +
						this.rankingCounter +
						this.trendCounter
			) {
				let attCount = 0;
				for (let i = 0; i < this.questionControllers.length; i++) {
					let elem = this.questionControllers[i];

					if (
						this.questionHelper.questionHasOthers(elem.questionData) ||
						this.questionHelper.questionHasAdditionalCompletions(elem.questionData)
					) {
						attCount++;
					}
				}
				if (this.ext) {
					let ready = true;
					for (let i = 0; i < this.questionControllers.length; i++) {
						// console.log('Q.isReady()', this.questionControllers[i], this.questionControllers[i].isReady());
						if (!this.questionControllers[i].isReady()) {
							// console.log('false', this.questionControllers[i]);
							ready = false;
						}
					}

					for (let i = 0; i < this.crossTableControllers.length; i++) {
						if (!this.crossTableControllers[i].isReady()) {
							ready = false;
						}
					}

					for (let i = 0; i < this.rankingControllers.length; i++) {
						if (!this.rankingControllers[i].isReady()) {
							ready = false;
						}
					}

					for (let i = 0; i < this.trendControllers.length; i++) {
						if (!this.trendControllers[i].isReady()) {
							ready = false;
						}
					}

					if (
						ready &&
						attCount === this.attachments.length &&
						attCount === this.attachmentControllers.length
					) {
						for (let i = 0; i < attCount; i++) {
							if (!this.attachmentControllers[i].isReady()) {
								ready = false;
							}
						}

						if (ready) {
							this.reportLoaded = true;
							this.reportsService.reportFullyLoaded();
						}
					}
				} else if (attCount === this.attachments.length) {
					this.reportLoaded = true;
					this.reportsService.reportFullyLoaded();
				}

				this.$timeout(() => {
					this.$scope.$apply();
				});
			}
		};

		this.setThemeLoaded = (val) => {
			this.themeLoaded = val;
		};

		this.getEditId = () => this.editId;

		this.addCommentPart = function addCommentPart(chapterIndex, partIndex, scrollPosition) {
			let commentPosition = scrollPosition;
			if (this.isDisabled()) {
				this.showDisabledModal();
				return;
			}
			if (this.locked) {
				return;
			}

			this.hideAllPartsEditors();
			this.locked = true;
			let part = reportHelper.getEmptyPartClone();
			let chapters = this.currentReport.chapters;
			part.position = partIndex + 1;

			let thisChapter = this.dom.find('.reportChapter__partsList:eq(' + chapterIndex + ')');

			let thisPart = thisChapter.find(
				'.reportChapter__partWrapper:eq(' + (part.position - 1) + ')'
			);

			if (!scrollPosition) {
				if (!thisPart.length) {
					//if the chapter is empty

					commentPosition = thisChapter.offset().top - 70;
				} else if (thisPart.length && part.position === 0) {
					// if the chapter isn't empty but there is no question inside
					// and user want to add new part on the first position

					commentPosition = thisPart.offset().top - 70;
				} else {
					// if the chapter isn't empty but there is no question inside
					// and user want to add new part on the last position

					commentPosition = thisPart.offset().top + 40 + thisPart.outerHeight() - 70;
				}
			} else {
				// if there are questions inside the chapter
				commentPosition = commentPosition + thisPart.outerHeight() - 70;
			}

			part._showEdit = true;
			part._isNew = true;
			chapters[chapterIndex].parts.splice(part.position, 0, part);
			this.locked = false;

			this.parseReportData();
			this.$timeout(() => {
				this.$scope.$apply();
				this.waitForPanelReloading(() => {
					setTimeout(() => {
						window.scrollTo(0, commentPosition);
					}, 200);
				});
			});
		};

		this.waitForPanelReloading = (fn) => {
			let listener = this.$rootScope.$watch('xhr', (val) => {
				if (!val) {
					fn();
					listener();
				}
			});
		};

		this.saveCommentPart = (chapter, part) => {
			reportsService.addPartToReportPromise(this.currentReport.id, chapter.id, part).then(
				(data) => {
					this.fetchUpdatedReport();
				},
				() => {
					this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem.', 'reports'));
				}
			);
		};

		this.removePart = function removePart(chapterIndex, partIndex, isPlaceholder, partData) {
			if (this.isDisabled()) {
				this.showDisabledModal();
				return;
			}
			if (this.locked) {
				return;
			}
			this.locked = true;
			if (
				this.currentReport &&
				this.currentReport.chapters[chapterIndex] &&
				this.currentReport.chapters[chapterIndex].parts[partIndex] &&
				(isPlaceholder || this.currentReport.chapters[chapterIndex].parts[partIndex].id)
			) {
				let chapter = this.currentReport.chapters[chapterIndex];
				let reportId = this.currentReport.id;
				let part = chapter.parts[partIndex];

				if (isPlaceholder) {
					// console.log('placeholder', chapterIndex, partIndex, this.currentReport.chapters[chapterIndex].parts[partIndex]);
					this.currentReport.chapters[chapterIndex].parts.splice(partIndex, 1);
					this.locked = false;
					this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
					if (chapterIndex) {
						this.parseReportData();
					} else {
						this.fetchUpdatedReport();
					}

					// console.log('placeholder2', chapterIndex, partIndex, this.currentReport.chapters[chapterIndex].parts[partIndex]);
					this.$timeout(() => this.$scope.$apply());
				} else {
					this.reportsService.deletePartPromise(reportId, chapter.id, part.id).then(
						(data) => {
							this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();

							this.currentReport.chapters[chapterIndex].parts.splice(partIndex, 1);
							this.fetchUpdatedReport();
							this.locked = false;
						},
						(data) => {
							console.error(data);
						}
					);
				}
			}
		};

		this.updatePart = function updatePart(chapterId, partId, partData) {
			// console.log('builder.updatePart');
			if (this.isDisabled()) {
				this.showDisabledModal();
				return;
			}
			this.reportsService
				.updatePartPromise(this.editId, chapterId, partId, partData)
				.then(function done(data) {});
		};

		this.getExportFormats = () => reportsService.getConst().EXPORT_FORMATS;

		this.toggleChapterDragging = function toggleChapterDragging() {
			// console.log('builder.toggleChapterDragging');
			if (this.isDisabled()) {
				this.showDisabledModal();
				return;
			}
			if (this.chapterDraggingDisabled && !this.partsDraggingDisabled) {
				this.togglePartsDragging();
			}

			this.chapterDraggingDisabled = !this.chapterDraggingDisabled;

			this.hideAllPartsEditors();
		};

		this.hideAllPartsEditors = function hideAllPartsEditors() {
			reportPartEditorService.editorCancel();
		};

		// NOWE METODY DO SORTOWANIA PARTÓW

		this.chapterDragInsert = function onChapterInsert(index) {
			if (index !== undefined) {
				jquery($element)
					.find('.reportChapter:not(.reportChapter--first)')
					.eq(index)
					.addClass('reportChapter--dragEnabled');
			} else {
				jquery($element)
					.find('.reportChapter:not(.reportChapter--first)')
					.addClass('reportChapter--dragEnabled');
			}

			return true;
		};

		this.chapterDragStart = (startIndex, chapter) => {
			this.drag.chapters[chapter.id] = {
				startIndex,
			};
		};

		this.chapterDragDrop = (destIndex, chapter) => {
			if (this.locked) {
				return;
			}

			this.locked = true;
			let ids = [];
			let startIndex = this.drag.chapters[chapter.id].startIndex;

			this.sortableChapters.splice(destIndex, 0, chapter);

			if (destIndex < startIndex) {
				this.sortableChapters.splice(startIndex + 1, 1);
			} else {
				this.sortableChapters.splice(startIndex, 1);
			}

			this.sortableChapters.forEach(function (item) {
				ids.push(item.id);
			});

			this.reportsService.updateChaptersPositionPromise(this.editId, ids).then(
				(data) => {
					this.locked = false;

					this.fetchUpdatedReport();
				},
				() => {
					this.locked = false;
				}
			);
		};

		// *********************

		this.onPartMoved = (chapterId, ids, part) => {
			let reportId = this.currentReport.id;

			if (this.locked || !part) {
				return;
			}

			this.locked = true;

			let callback = () => {
				this.fetchUpdatedReport();
			};

			if (
				this.drag.parts[part.id] &&
				this.drag.parts[part.id].source &&
				this.drag.parts[part.id].source === this.drag.parts[part.id].target
			) {
				this.reportsService.updatePartsPositionPromise(null, chapterId, ids).then(
					(data) => {
						this.locked = false;

						callback();
						return data;
					},
					() => {
						this.locked = false;
					}
				);
			} else {
				this.reportsService
					.deletePartPromise(reportId, this.drag.parts[part.id].source, part.id)
					.then(
						(data) => {
							if (!part.title) {
								part.title = '';
							}
							if (!part.text) {
								part.text = '';
							}

							let chapterId = this.drag.parts[part.id].target;
							let partIndex = this.drag.parts[part.id].index;

							if (partIndex || partIndex === 0) {
								part.position = partIndex;
							}

							delete part.id;

							this.reportsService.addPartToReportPromise(reportId, chapterId, part).then(
								(data) => {
									this.locked = false;

									delete this.drag.parts[part.id];
									callback();
								},
								() => {
									this.locked = false;
								}
							);
						},
						(errData) => {
							console.log('delete error', errData);
							this.locked = false;
						}
					);
			}
		};

		this.togglePartsDragging = function togglePartsDragging() {
			if (this.isDisabled()) {
				this.showDisabledModal();
				return;
			}
			if (!this.chapterDraggingDisabled) {
				return;
			}

			this.partsDraggingDisabled = !this.partsDraggingDisabled;

			if (this.partsDraggingDisabled) {
				for (let i = 0; i < highcharts.charts.length; i++) {
					highcharts.charts[i].reflow();
				}
			}

			this.hideAllPartsEditors();
		};

		this.getCurrentReport = () => this.currentReport;

		this.setCurrentReport = function setCurrentReport(report) {
			this.currentReport = report;
		};

		this.isDisabled = () => this.disabled || this.locked;
	}

	$onInit() {
		this.editId = this.wrapperCtrl.editId;
		this.editMode = this.editId !== null;

		if (this.editMode) {
			// istniejacy raport
			this.reportsService.getReportPromise(this.editId).then(
				(data) => {
					if (this.$rootScope.userInfo.external) {
						this.ext = true;
					}
					// this.isEmpty = true;
					this.currentReport = data;

					this.$scope.reportTitle = data.title;
					this.reportsService.startEdit(this.editId);

					if (data.locked) {
						this.disableEdition();
					}

					this.parseReportData();

					this.isLoading = false;
					this.$timeout(() => {
						this.$scope.$apply();
					});
					this.reportBuilderHelper.getThemeEditor().init(data);
				},
				(data) => {
					parent.location.href = `/user#angular?r=/reports/list`;
				}
			);
		} else {
			// nowy pusty
			this.reportsService.createEmptyReportPromise().then(
				(data) => {
					this.currentReport = data;
					this.isEmpty = true;

					this.editId = this.currentReport.id;
					this.$scope.reportTitle = this.currentReport.title;
					this.reportsService.setCurrentReport(this.currentReport.id);

					// console.log('nowy done', this.currentReport);
					window.history.replaceState({}, '', document.location.href + '/' + this.currentReport.id);

					this.reportsService.startEdit(this.editId);
					this.isLoading = false;
					this.parseReportData();
					this.$scope.$digest();
					this.reportBuilderHelper.getThemeEditor().init(data);
				},
				(data) => {}
			);
		}

		if (this.wrapperCtrl) {
			this.wrapperCtrl.setBuilder(this);
		}

		this.nameEditorWrapper = jquery('.reportBuilder__nameEditorWrapper');
		this.nameEditor = jquery('.reportBuilder__nameEditor');
		this.nameEditorInput = jquery('input', this.nameEditor);
	}

	getColors() {
		return this.currentReport.theme;
	}

	/**
	 * @function getSummaryPart - szuka partu typu "podsumowanie". Aktualnie nie używane
	 *
	 * @return {type}  {Object}
	 */
	getSummaryPart(getIndex) {
		let types = this.reportHelper.getReportPartTypes();
		let result = this.getPartByType(types.SUMMARY, getIndex);

		if (getIndex) {
			return result;
		}
		result = result[0] || null;
		return result;
	}
	/**
	 * @function getTableOfContentsPart - szuka partu typu "spis treści".
	 *
	 * @return {type}  {Object}
	 */
	getTableOfContentsPart(getIndex) {
		let types = this.reportHelper.getReportPartTypes();
		let result = this.getPartByType(types.TABLE_OF_CONTENTS, getIndex);

		if (getIndex) {
			return result;
		}
		result = result[0] || null;
		return result;
	}

	getPartByType(type, getIndexes) {
		let chapters = this.currentReport.chapters;
		let parts;
		let result = [];
		let indexes = {
			chapter: [],
			part: [],
		};

		for (let i = 0; i < chapters.length; i++) {
			parts = chapters[i].parts;
			for (let j = 0; j < parts.length; j++) {
				if (parts[j].type === type) {
					result.push(parts[j]);
					indexes.chapter.push(i);
					indexes.part.push(j);
				}
			}
		}

		if (getIndexes) {
			return indexes;
		}
		return result;
	}

	toggleTableOfContents(val) {
		if (this.isDisabled()) {
			return;
		}
		let indexes = this.getTableOfContentsPart(true);

		this.reportBuilderHelper.getThemeEditor().lockCheckboxes();

		if (val) {
			this.reportsService
				.addToReportPromise({
					reportId: this.editId,
					chapterIndex: 0,
					partType: this.reportHelper.getReportPartTypes().TABLE_OF_CONTENTS,
				})
				.then(
					(data) => {
						this.currentReport = data;
						this.currentReport.showTableOfContents = true;
						this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
						// $scope.$digest();
						this.parseReportData();
						this.$scope.$apply();
					},
					(data) => {
						this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
						console.error(data);
					}
				);
		} else if (indexes.chapter.length && indexes.part.length) {
			this.reportBuilderHelper.getThemeEditor().lockCheckboxes();
			this.removePart(indexes.chapter[0], indexes.part[0]);
			this.reportsService.postShowTableOfContents(this.editId, false).then((data) => {
				this.currentReport = data;
				this.parseReportData();
				this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
				this.$scope.$apply();
			});
		} else {
			this.reportsService
				.updateReportPromise(this.reportsService.currentReportId, { showTableOfContents: false })
				.then((data) => {
					this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
					this.$scope.$digest();
				});
		}
	}

	toggleSummary(val) {
		let indexes = this.getSummaryPart(true);

		this.reportBuilderHelper.getThemeEditor().lockCheckboxes();

		if (val) {
			this.reportsService
				.addToReportPromise({
					reportId: this.editId,
					chapterIndex: 0,
					partType: this.reportHelper.getReportPartTypes().SUMMARY,
				})
				.then(
					(data) => {
						this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
						this.$scope.$digest();
					},
					(data) => {
						this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
						console.error(data);
					}
				);
		} else if (indexes.chapter.length && indexes.part.length) {
			this.removePart(indexes.chapter[0], indexes.part[0]);
		}
	}

	togglePageNumbers() {
		let val = this.currentReport.showPageNumbers;

		this.reportBuilderHelper.getThemeEditor().lockCheckboxes();

		this.reportsService
			.updateReportPromise(this.currentReport.id, {
				showPageNumbers: val,
			})
			.then(
				(data) => {
					this.currentReport.showPageNumbers = val;
					this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
					this.$scope.$digest();
				},
				(data) => {
					this.reportBuilderHelper.getThemeEditor().unlockCheckboxes();
					this.$scope.$digest();
				}
			);
	}

	toggleTitleEditor() {
		if (this.isDisabled()) {
			this.showDisabledModal();
			return;
		}
		this.nameEditorVisible = !this.nameEditorVisible;
		this.nameEditorWrapper.toggleClass('reportBuilder__nameEditorWrapper--active');
		if (this.nameEditorVisible) {
			this.editionInProgress = true;
			this.nameEditorInput.focus();
		} else {
			this.editionInProgress = false;
		}
	}

	/**
	 * nameEditorSubmit - zapis zmienionego tytułu na serwer i walidacja
	 *
	 * @param  {type} e description
	 * @return {type}   description
	 */
	nameEditorSubmit(e) {
		let val = this.nameEditorInput.val();
		let nameRegex = /['"`.]/g;
		val = val.replace(nameRegex, '');
		val = val.trim();
		e.preventDefault();

		if (val !== this.currentReport.title && val.length) {
			this.nameEditorInput[0].value = val;
			this.reportsService
				.updateReportPromise(this.currentReport.id, {
					title: val,
				})
				.then(
					(data) => {
						this.currentReport.title = data.title;
						this.$scope.reportTitle = data.title;
						this.$scope.$digest();
					},
					(data) => {
						this.nameEditorInput[0].value = this.currentReport.title;
						this.$scope.reportTitle = this.currentReport.title;
						this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem ', 'reports'));
					}
				);
		} else {
			this.nameEditorInput[0].value = this.currentReport.title;
			this.$scope.reportTitle = this.currentReport.title;
		}
		if (!val.length) {
			this.alertsService.warning(this.i18n.__('Podaj poprawny tytuł raportu', 'reports'));
		}
		this.toggleTitleEditor();
	}

	nameEditorCancel(e) {
		this.toggleTitleEditor();
		this.$scope.reportTitle = this.currentReport.title;
	}

	exportClick(val) {
		if (this.isEmpty) {
			this.showDisabledModal();
			return;
		}

		this.modalService.openExportReportModal(this.currentReport, () => {
			this.locked = true;
			this.$timeout(() => {
				this.$scope.$apply();
			});
		});
	}

	addAnswersAttachment(attachmentData) {
		const tmpAttachments = [];
		this.attachments.push(attachmentData);

		this.attachments.forEach((attachmentData) => {
			const index = this.sortedPartsIds.indexOf(attachmentData.id);
			tmpAttachments[index] = attachmentData;
		});

		let i = 1;

		this.attachments = tmpAttachments.filter((attachmentData) => {
			if (!attachmentData) {
				return false;
			}

			attachmentData.partCtrl.setAttachmentIndex(i++);
			return true;
		});

		this.reportFullyLoaded();
	}

	removeAnswersAttachment(id, index) {
		let i = 0;

		for (i = 0; i < this.attachments.length; i++) {
			let att = this.attachments[i];

			if (att.id === id) {
				this.attachments.splice(i, 1);
				break;
			}
		}
	}

	setLogoPath(val) {
		this.logoPath = val;
	}

	disableEdition() {
		this.disabled = true;
	}

	enableEdition() {
		this.disabled = false;
	}

	isTrial() {
		return this.userService.userInfo.trial;
	}

	isReportsCreatorEnabled() {
		return this.aclManager.isFeatureEnabled(this.$rootScope.features.moduleReportsCreator);
	}

	showDisabledModal() {
		// if (!(this.questions && this.questions.length)) {
		if (this.isEmpty) {
			this.modalService.show({
				size: '400',
				title: this.i18n.__('Twój raport jest pusty', 'reports'),
				text: this.i18n.__(
					'Dodaj odpowiedzi z zakładki <b>Wyniki zbiorcze</b> by móc edytować raport.',
					'reports'
				),
				icon: {
					font: 'fa-exclamation-triangle',
					color: 'gray',
				},
				button: {
					text: this.i18n.__('Zamknij', 'reports'),
					type: 'button--big button--gray',
					closeOnClick: true,
				},
			});
		} else {
			this.modalService.show({
				size: '400',
				title: this.i18n.__('Trwa generowanie raportu...', 'reports'),
				text: this.i18n.__('Edytowanie będzie możliwe po zakończeniu generowania.', 'reports'),
				icon: {
					font: 'fa-exclamation-triangle',
					color: 'gray',
				},
				button: {
					text: this.i18n.__('Zamknij', 'reports'),
					type: 'button--big button--gray',
					closeOnClick: true,
				},
			});
		}
	}

	editNameModal() {
		this.modalService.getUibModal().open({
			component: 'editReportNameModal',
			size: '500',
			resolve: {
				reportName: () => this.$scope.reportTitle,
				saveReportName: () => (value) =>
					new Promise((resolve, reject) => {
						this.reportsService
							.updateReportPromise(this.currentReport.id, {
								title: value,
							})
							.then(
								(data) => {
									this.currentReport.title = data.title;
									this.$scope.reportTitle = data.title;
									this.$scope.$digest();
									resolve();
								},
								(data) => {
									this.$scope.reportTitle = this.currentReport.title;
									this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem ', 'reports'));
									reject();
								}
							);
					}),
			},
		});
	}
}

export const reportBuilder = {
	template: tpl,
	controller: ReportBuilderController,
	require: {
		wrapperCtrl: '^reportsBuilderWrapper',
	},
};
