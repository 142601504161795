const authUser = ($transitions, authService, $q, $rootScope, userService, aclManager) => {
	'ngInject';

	$transitions.onStart({}, (transition) => {
		if (transition.from().name === '') {
			const deferred = $q.defer();

			authService
				.signIn()
				.then(() => {
					return $q.all([
						userService.fetchAccount(),
						userService.fetchCachedSurveyInfoForUser(),
						aclManager.initOffer(),
					]);
				})
				.then(() => {
					deferred.resolve();
					$rootScope.userInitialized = true;
				})
				.catch((error) => {
					console.log(error);
					parent.window.location.href = '/info/link-login';
				});

			return deferred.promise;
		}
	});
};

export { authUser };
