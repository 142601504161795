class AggregatedModeService {
	constructor(i18n, $rootScope) {
		'ngInject';

		this.LOCAL_STORAGE_KEY = 'aggregatedMode';
		this.$rootScope = $rootScope;

		this.aggregatedModes = {
			finished: {
				mode: 1,
				label: i18n.__('Tylko dokończone', 'reports'),
			},
			all: {
				mode: 2,
				label: i18n.__('Wszystkie wypełnienia', 'reports'),
			},
		};

		this.aggregatedMode = null;

		$rootScope.$on('SurveyChanged', () => {
			$rootScope.$broadcast('EnableFiltering');
		});

		$rootScope.$on('SurveyReady', () => {
			this.getMode();
		});
	}

	isAllAggregatedMode() {
		return this.aggregatedMode === this.aggregatedModes.all.mode;
	}

	getMode() {
		if (!this.aggregatedMode) {
			this.getFromLocalStorage();
		}

		return this.aggregatedMode;
	}

	getLabel() {
		const currentKey = Object.keys(this.aggregatedModes).find((key) => {
			const element = this.aggregatedModes[key];

			return element.mode === this.aggregatedMode;
		});

		return this.aggregatedModes[currentKey].label;
	}

	changeMode(aggregatedMode) {
		this.aggregatedMode = aggregatedMode;
		this.saveToLocalStorage();
	}

	getModes() {
		return this.aggregatedModes;
	}

	saveToLocalStorage() {
		window.localStorage.setItem(
			`${this.LOCAL_STORAGE_KEY}_${this.$rootScope.currentSurveyData.id}`,
			this.aggregatedMode
		);
	}

	getFromLocalStorage() {
		const aggregatedMode = Number(
			window.localStorage.getItem(
				`${this.LOCAL_STORAGE_KEY}_${this.$rootScope.currentSurveyData.id}`
			)
		);
		this.aggregatedMode = aggregatedMode ? aggregatedMode : this.aggregatedModes.finished.mode;
	}
}

export default AggregatedModeService;
