/* eslint-disable */

//https://github.com/yaru22/angular-timeago/blob/master/dist/angular-timeago.js
//https://github.com/rmm5t/jquery-timeago/blob/master/locales/jquery.timeago.pl.js

angular.module('yaru22.angular-timeago').config(function (timeAgoSettings) {
	var strings = {
		prefixAgo: 'Před',
		prefixFromNow: null,
		suffixAgo: null,
		suffixFromNow: 'Od této chvíle',
		seconds: 'Méně než minuta',
		minute: 'přibližně minutou',
		minutes: '%d minutami',
		hour: 'přibližně hodinou',
		hours: '%d hodinami',
		day: 'dnem',
		days: '%d dny',
		month: 'přibližně měsícem',
		months: '%d měsíci',
		year: 'přibližně rokem',
		years: '%d roky',
		numbers: [],
	};

	timeAgoSettings.strings['cs'] = strings;
	timeAgoSettings.strings['sk'] = strings;
});

angular.module('yaru22.angular-timeago').config(function (timeAgoSettings) {
	function numpf(n, s, t) {
		// s - 2-4, 22-24, 32-34 ...
		// t - 5-21, 25-31, ...
		var n10 = n % 10;
		if (n10 > 1 && n10 < 5 && (n > 20 || n < 10)) {
			return s;
		} else {
			return t;
		}
	}

	var strings = {
		prefixAgo: null,
		prefixFromNow: 'za',
		suffixAgo: 'temu',
		suffixFromNow: null,
		seconds: 'mniej niż minutę',
		minute: 'minutę',
		minutes: function (value) {
			return numpf(value, '%d minuty', '%d minut');
		},
		hour: 'godzinę',
		hours: function (value) {
			return numpf(value, '%d godziny', '%d godzin');
		},
		day: 'dzień',
		days: '%d dni',
		month: 'miesiąc',
		months: function (value) {
			return numpf(value, '%d miesiące', '%d miesięcy');
		},
		year: 'rok',
		years: function (value) {
			return numpf(value, '%d lata', '%d lat');
		},
	};

	timeAgoSettings.strings['pl_PL'] = strings;
});
