class FiltersHelper {
	constructor(i18n) {
		'ngInject';

		this.i18n = i18n;

		this.standardConditions = {
			notChecked: {
				type: 0,
				text: this.i18n.__('niezaznaczona', 'reports'),
			},
			checked: {
				type: 1,
				text: this.i18n.__('zaznaczona', 'reports'),
			},
		};

		this.openConditions = {
			notEqual: {
				type: 0,
				text: this.i18n.__('jest różna', 'reports'),
			},
			equal: {
				type: 1,
				text: this.i18n.__('jest równa', 'reports'),
			},
			notContain: {
				type: 2,
				text: this.i18n.__('nie zawiera', 'reports'),
			},
			contain: {
				type: 3,
				text: this.i18n.__('zawiera', 'reports'),
			},
			notFilled: {
				type: 4,
				text: this.i18n.__('jest niewypełniona', 'reports'),
			},
			filled: {
				type: 5,
				text: this.i18n.__('jest wypełniona', 'reports'),
			},
		};

		this.formConditions = {
			notEqual: {
				type: 0,
				text: this.i18n.__('różne', 'reports'),
			},
			equal: {
				type: 1,
				text: this.i18n.__('równe', 'reports'),
			},
			notContain: {
				type: 2,
				text: this.i18n.__('nie zawiera', 'reports'),
			},
			contain: {
				type: 3,
				text: this.i18n.__('zawiera', 'reports'),
			},
			notFilled: {
				type: 4,
				text: this.i18n.__('niewypełnione', 'reports'),
			},
			filled: {
				type: 5,
				text: this.i18n.__('wypełnione', 'reports'),
			},
		};

		this.numberConditions = {
			notEqual: {
				type: 0,
				text: this.i18n.__('jest różna', 'reports'),
			},
			equal: {
				type: 1,
				text: this.i18n.__('jest równa', 'reports'),
			},
			smaller: {
				type: 6,
				text: this.i18n.__('mniejsza niż', 'reports'),
			},
			bigger: {
				type: 7,
				text: this.i18n.__('większa niż', 'reports'),
			},
			smallerOrEqual: {
				type: 8,
				text: this.i18n.__('mniejsza lub równa', 'reports'),
			},
			biggerOrEqual: {
				type: 9,
				text: this.i18n.__('większa lub równa', 'reports'),
			},
		};

		this.pointsConditions = {
			notEqual: {
				type: 0,
				text: this.i18n.__('różna od', 'reports'),
			},
			equal: {
				type: 1,
				text: this.i18n.__('równa', 'reports'),
			},
			smaller: {
				type: 6,
				text: this.i18n.__('mniejsza niż', 'reports'),
			},
			bigger: {
				type: 7,
				text: this.i18n.__('większa niż', 'reports'),
			},
		};

		this.rankingConditions = {
			notChecked: {
				text: this.i18n.__('nieustawiona', 'reports'),
				type: 0,
			},
			checked: {
				text: this.i18n.__('ustawiona', 'reports'),
				type: 1,
			},
		};

		this.sliderConditions = {
			checked: {
				text: this.i18n.__('ustawiona', 'reports'),
				type: 1,
			},
		};

		this.conditionTypeForQuickFilter = 1;
	}

	getQuestionRulesConditionsAsArray(conditions) {
		const array = [];

		Object.keys(conditions).forEach((key) => {
			array.push(conditions[key]);
		});

		return array;
	}

	getStandardConditions(isArray = true) {
		if (isArray) {
			return this.getQuestionRulesConditionsAsArray(this.standardConditions);
		}

		return this.standardConditions;
	}

	getConditionsForOpen(isArray = true) {
		if (isArray) {
			return this.getQuestionRulesConditionsAsArray(this.openConditions);
		}

		return this.openConditions;
	}

	getConditionsForForm(isArray = true) {
		if (isArray) {
			return this.getQuestionRulesConditionsAsArray(this.formConditions);
		}

		return this.formConditions;
	}

	getConditionsForNumber(isArray = true) {
		if (isArray) {
			return this.getQuestionRulesConditionsAsArray(this.numberConditions);
		}

		return this.numberConditions;
	}

	getConditionsForPoints(isArray = true) {
		if (isArray) {
			return this.getQuestionRulesConditionsAsArray(this.pointsConditions);
		}

		return this.pointsConditions;
	}

	getConditionsForRanking(isArray = true) {
		if (isArray) {
			return this.getQuestionRulesConditionsAsArray(this.rankingConditions);
		}

		return this.rankingConditions;
	}

	getConditionsForSlider(isArray = true) {
		if (isArray) {
			return this.getQuestionRulesConditionsAsArray(this.sliderConditions);
		}

		return this.sliderConditions;
	}

	getConditionTypeForQuickFilter() {
		return this.conditionTypeForQuickFilter;
	}
}

export default FiltersHelper;
