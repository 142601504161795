class ReportBuilderHelper {
	constructor() {
		'ngInject';

		this.builderDataName = '_builderData';

		this.builder = null;
		this.themeEditor = null;

		this.setBuilder = function setBuilder(ctrl) {
			// console.log('setBuilder');
			this.builder = ctrl;
		};

		this.setThemeEditor = function setThemeEditor(ctrl) {
			this.themeEditor = ctrl;
		};

		this.getBuilder = () => this.builder;
		this.getThemeEditor = () => this.themeEditor;
	}
}

export default ReportBuilderHelper;
