const tpl = require('./TimelineChart.html');
import jquery from 'jquery';
import highcharts from 'highcharts';
import dayjs from 'dayjs';

let priv = {};

class TimelineChartController {
	constructor($scope, $element, chartColorHelper, i18n, $timeout, questionHelper, langService) {
		'ngInject';

		this.$element = $element;
		this.chartColorHelper = chartColorHelper;
		this.i18n = i18n;
		this.langService = langService;
		// this.$scope = $scope;

		priv.$timeout = $timeout;
		priv.questionHelper = questionHelper;

		this.modeSwitcherOptions = questionHelper.getTimelineModes();
		this.external = $scope.$root.userInfo.external;

		this.modeSwitcherOptionsArray = [
			{
				id: this.modeSwitcherOptions.day,
				text: i18n.__('Dziennie', 'reports'),
			},
			{
				id: this.modeSwitcherOptions.week,
				text: i18n.__('Tygodniowo', 'reports'),
			},
			{
				id: this.modeSwitcherOptions.month,
				text: i18n.__('Miesięcznie', 'reports'),
			},
			{
				id: this.modeSwitcherOptions.quarter,
				text: i18n.__('Kwartalnie', 'reports'),
			},
		];
		this.selectedMode = this.modeSwitcherOptions.week;

		let self = this;
		priv.modeSwitcherOptions = this.modeSwitcherOptions;

		this.loading = true;
		this.inReport = false;

		this.chartConfig = {
			chart: {
				animation: false,
				marginLeft: 65,
				height: 208,
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
			},
			title: {
				text: undefined,
			},
			credits: {
				enabled: false,
			},
			xAxis: {
				type: 'category',
				title: {
					text: '',
				},
				labels: {
					style: {
						color: '#aaa',
						fontSize: '11px',
						fontWeight: '600',
					},
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					maxStaggerLines: 1,
				},
				crosshair: false,
				tickWidth: 0,
				lineWidth: 2,
				lineColor: '#777',
			},
			yAxis: {
				title: {
					text: '',
				},
				min: -100,
				max: 100,
				floor: -100,
				ceiling: 100,
				tickAmount: 5,
			},
			legend: {
				enabled: false,
			},
			plotOptions: {
				line: {
					color: 'rgb(135, 121, 255)',
					marker: {
						radius: 5,
						fillColor: 'rgb(135, 121, 255)',
						states: {
							hover: {
								lineWidthPlus: 0,
								radiusPlus: 2,
							},
						},
					},
					lineWidth: 1,
					threshold: null,
				},
				series: {
					animation: !this.external,
				},
			},
			tooltip: {
				xDateFormat: self.langService.getLang() === 'en' ? '%m.%d.%Y' : '%d.%m.%Y',
				borderWidth: 0,
				borderRadius: 8,
				shadow: false,
				backgroundColor: '#032c3e',
				useHTML: true,
				style: {
					color: '#ffffff',
					fontSize: '11px',
				},
				formatter() {
					const timestampIndex = this.point.x;
					const timestamp = Object.keys(self.timelineData.timestamps)[timestampIndex];
					const dataForTimestamp = self.timelineData.timestamps[timestamp];

					let res = self.calculateFormattedRange(self.selectedMode, timestamp);

					res += '<br><br><b style="font-size: 14px">NPS: ' + this.y + '</b>';
					res +=
						'<br><br>' + i18n.__('Liczba wypełnień', 'reports') + ': ' + dataForTimestamp.fillCount;
					res +=
						'<br><br>' +
						i18n.__('Krytycy', 'reports') +
						': <b class="red">' +
						dataForTimestamp.detractorsCount +
						' (' +
						dataForTimestamp.detractorsPercent +
						'%)</b>';
					res +=
						'<br>' +
						i18n.__('Neutralni', 'reports') +
						': <b class="yellow">' +
						dataForTimestamp.neutralsCount +
						' (' +
						dataForTimestamp.neutralsPercent +
						'%)</b>';
					res +=
						'<br>' +
						i18n.__('Promotorzy', 'reports') +
						': <b class="green">' +
						dataForTimestamp.promotersCount +
						' (' +
						dataForTimestamp.promotersPercent +
						'%)</b>';

					return res;
				},
			},
		};
	}

	$onInit() {
		this.inReport = !!this.reportPart;
		if (this.questionCtrl && this.questionCtrl.getChild()) {
			this.questionCtrl.getChild().setExtraChart(this);
		}
	}

	$onChanges(changes) {
		if (this.period) {
			this.selectedMode = this.period;
		}
		if (this.timelineData) {
			const { values, categories } = this.createSeries(this.timelineData);
			const config = this.createConfig(values, categories);

			this.chart = highcharts.chart(jquery(this.$element).find('.chart__main')[0], config);
			this.loading = false;

			if (this.questionCtrl) {
				this.questionCtrl.getAdditionalSettings().timelineMode = this.selectedMode;
			}
		}

		if (this.chart && this.isDynamicY) {
			this.handleDynamicY(true);
		}
	}

	$postLink() {
		if (this.reportPart) {
			this.reportPart.builder.reportFullyLoaded();
		}
	}

	createSeries() {
		if (!this.timelineData || !this.timelineData.timestamps) {
			return;
		}

		const timestamps = this.timelineData.timestamps;
		const format = this.langService.getLang() === 'en' ? 'MM.DD' : 'DD.MM';

		let categories = [];
		let values = [];

		for (var timestamp in timestamps) {
			values.push(timestamps[timestamp].nps);
			categories.push(this.calculateFormattedRange(this.selectedMode, Number(timestamp), format));
		}

		return { values, categories };
	}

	createConfig(dataSeries, categories) {
		this.chartConfig.series = [
			{
				type: 'line',
				data: dataSeries,
			},
		];

		this.chartConfig.xAxis.categories = categories;

		return this.chartConfig;
	}

	switchMode(mode) {
		// console.log('switchMode', mode, this);
		this.selectedMode = mode;
		this.loading = true;

		if (this.questionCtrl) {
			this.questionCtrl.updateExtraCharts({
				timeline: true,
				mode,
				successCallback: () => {
					this.loading = false;
				},
				errorCallback: () => {},
			});
		}
	}

	isReady() {
		return !this.isLoading;
	}

	calculateFormattedRange(mode, endTimestamp, format = null) {
		if (!format) {
			format = this.langService.getLang() === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY';
		}

		const endPoint = dayjs(endTimestamp * 1000);
		const endPointFormatted = endPoint.format(format);

		if (mode === this.modeSwitcherOptions.week) {
			return `${endPoint.subtract(6, 'day').format(format)} - ${endPointFormatted}`;
		} else if (mode === this.modeSwitcherOptions.month) {
			return `${endPoint.startOf('month').format(format)} - ${endPointFormatted}`;
		} else if (mode === this.modeSwitcherOptions.quarter) {
			return `${endPoint
				.subtract(2, 'month')
				.startOf('month')
				.format(format)} - ${endPointFormatted}`;
		}

		return endPointFormatted;
	}

	handleDynamicY($onChanges) {
		let mode = false;

		if (this.isDynamicY) {
			let Ymin = null;
			let Ymax = null;
			let dataMin = this.chart.yAxis[0].dataMin;
			let dataMax = this.chart.yAxis[0].dataMax;

			Ymin = Math.abs(dataMin) * 1.2 > 100 ? -100 : Ymin;
			Ymax = Math.abs(dataMax) * 1.2 > 100 ? 100 : Ymax;
			Ymin = dataMin === 0 ? -5 : Ymin;
			Ymax = dataMax === 0 ? 5 : Ymax;

			this.chart.yAxis[0].update({
				min: Ymin,
				max: Ymax,
				tickAmount: undefined,
				tickInterval: 10,
			});
			this.questionCtrl.getAdditionalSettings().isDynamicAxisY = this.isDynamicY;
			mode = true;
		} else {
			this.chart.yAxis[0].update({
				min: -100,
				max: 100,
				tickAmount: 5,
				tickInterval: null,
			});
			this.questionCtrl.getAdditionalSettings().isDynamicAxisY = null;
			mode = false;
		}

		if (!$onChanges) {
			this.questionCtrl.updateExtraCharts({
				dynamicY: true,
				mode,
				successCallback: () => {
					this.loading = false;
				},
				errorCallback: () => {},
			});
		}
	}
}

export const timelineChart = {
	template: tpl,
	controller: TimelineChartController,
	require: {
		questionCtrl: '?^aRQuestion',
		reportPart: '?^reportPart',
	},
	bindings: {
		timelineData: '<',
		period: '<',
		isDynamicY: '<',
	},
};
