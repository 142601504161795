import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import { i18n } from '../../../utils/i18n';

import PaginationText from './PaginationText';
import PaginationControls from './PaginationControls';
import PaginationInput from './PaginationInput';

const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&.loading {
		opacity: 0.5;
		pointer-events: none;
	}
`;

const Pagination = ({ activePage = 1, limit = 20, total = 1, loading, onPageChange }) => {
	const MINIMAL_PAGE = 1;
	const DECREASE_FACTOR = -1;
	const INCREASE_FACTOR = 1;
	const DEBOUNCE_TIME = 500;

	const totalPages = Math.ceil((total || 1) / (limit || 1));
	const [value, setValue] = useState(1);

	useEffect(() => {
		setValue(activePage);
	}, [activePage]);

	useEffect(() => {
		const numberOfPages = Math.ceil((total || 1) / (limit || 1));

		if (activePage > numberOfPages) {
			validateAndChangePage(numberOfPages);
		}
	}, [limit]);

	const setValueAndChangePage = (value) => {
		setValue(value);
		onPageChange(value);
	};

	const validateAndChangePage = (value) => {
		const isValid = /^[0-9]*$/.test(value);

		let newActivePage = isValid ? +value : MINIMAL_PAGE;

		if (newActivePage < MINIMAL_PAGE) {
			setValueAndChangePage(MINIMAL_PAGE);
		} else if (newActivePage > totalPages) {
			setValueAndChangePage(totalPages);
		} else {
			setValueAndChangePage(newActivePage);
		}
	};

	const debouncedValidateAndSetPage = debounce(validateAndChangePage, DEBOUNCE_TIME);

	const handleChange = ({ target: { value } }) => {
		setValue(value);

		if (value !== '') {
			debouncedValidateAndSetPage(value);
		}
	};

	const handleBlur = ({ target: { value } }) => {
		const newActivePage = +value;

		validateAndChangePage(newActivePage);
	};

	const handleControlsClick = (shiftFactor) => {
		const newActivePage = activePage + shiftFactor;

		validateAndChangePage(newActivePage);
	};

	return (
		<Container className={loading && 'loading'}>
			<PaginationText>{i18n.__('Strona', 'reports')}</PaginationText>
			<PaginationInput
				disable={loading}
				value={value}
				onChange={handleChange}
				onBlur={handleBlur}
			/>
			<PaginationText>{i18n.__('z', 'reports') + ' ' + totalPages}</PaginationText>
			<PaginationControls
				onDecrease={() => handleControlsClick(DECREASE_FACTOR)}
				onIncrease={() => handleControlsClick(INCREASE_FACTOR)}
			/>
		</Container>
	);
};

Pagination.propTypes = {
	activePage: PropTypes.number,
	limit: PropTypes.number,
	total: PropTypes.number,
	loading: PropTypes.bool,
	onPageChange: PropTypes.func,
};

export default Pagination;
