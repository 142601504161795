import tpl from './chart-property-switcher.html';
import { SwitcherController } from '../../controllers/switcher.controller';

require('./chart-property-switcher.scss');

export const ChartPropertySwitcherComponent = {
	template: tpl,
	controller: SwitcherController,
	bindings: {
		items: '<',
		model: '=',
		onSelect: '&',
	},
};
