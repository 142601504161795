import angular from 'angular';

import { TagsWrapper } from './TagsWrapper/TagsWrapper';
import { TagsList } from './TagsList/TagsList';
import { TagItem } from './TagItem/TagItem';
import { TagCreator } from './TagManagement/TagCreator/TagCreator';
import { TagEditor } from './TagManagement/TagEditor/TagEditor';

angular
	.module('app.tags', [])
	.component('tagsWrapper', TagsWrapper)
	.component('tagsList', TagsList)
	.component('tagItem', TagItem)
	.component('tagCreator', TagCreator)
	.component('tagEditor', TagEditor);
