import { quickFiltersConfig } from './QuickFiltersConfig';
const Entities = require('html-entities').AllHtmlEntities;

class QuickFilterGenerator {
	constructor(filtersService, $rootScope, i18n, filtersHelper, modalService) {
		'ngInject';

		this.filtersService = filtersService;
		this.filtersHelper = filtersHelper;
		this.modalService = modalService;
		this.$rootScope = $rootScope;
		this.i18n = i18n;

		this.npsGroups = quickFiltersConfig.npsGroups;
	}

	handleQuickFilter(data, filterId = null) {
		if (filterId) {
			this.handleExistingFilter(
				filterId,
				data.questionData,
				data.answerTitle,
				data.answers,
				data.internalAnswers
			);
		} else {
			this.generateNewFilter(
				data.questionData,
				data.answerTitle,
				data.answers,
				data.internalAnswers
			);
		}
	}

	handleExistingFilter(filterId, questionData, answerTitle, answer, internal = null) {
		this.filtersService.fetchFilter(filterId).then((filterData) => {
			if (!filterData.isQuick) {
				this.showEditingNormalFilterInfo(filterData.title, answerTitle).then(
					() => {
						this.updateFilter(filterData, questionData, answerTitle, answer, internal);
					},
					() => {}
				);
			} else {
				this.updateFilter(filterData, questionData, answerTitle, answer, internal);
			}
		});
	}

	updateFilter(filterData, questionData, answerTitle, answer, internal = null) {
		answerTitle = answerTitle.replace(
			new RegExp(quickFiltersConfig.title.separator, 'g'),
			quickFiltersConfig.title.separatorReplacement
		);

		filterData.title = `${filterData.title}${quickFiltersConfig.title.separator} ${answerTitle}`;
		filterData.questionRules.push(this.prepareQuestionRule(questionData, answer, internal));

		this.filtersService.quickFilterPatch(filterData).then((updatedFilterData) => {
			if (!updatedFilterData) {
				return;
			}

			this.emitHandleQuickFilter(updatedFilterData, false, true);
		});
	}

	generateNewFilter(questionData, answerTitle, answer, internal = null) {
		answerTitle = Entities.decode(answerTitle);
		answerTitle = answerTitle.replace(
			new RegExp(quickFiltersConfig.title.separator, 'g'),
			quickFiltersConfig.title.separatorReplacement
		);

		const filterStructure = this.prepareFilter(
			answerTitle,
			this.prepareQuestionRule(questionData, answer, internal)
		);

		this.filtersService.quickFilterPost(filterStructure).then((data) => {
			this.emitHandleQuickFilter(data);
		});
	}

	revertFilter(filter) {
		this.filtersService.removeLastQuestionRule(filter.id).then(
			(data) => {
				if (data.removed) {
					this.emitHandleQuickFilter(filter, true, true);
				} else {
					filter.title = data.filterInfo.title;
					this.emitHandleQuickFilter(filter, false, true);
				}
			},
			() => {
				this.modalService.show({
					size: '400',
					title: this.i18n.__('Nie można usunąć filtra', 'reports'),
					text: this.i18n.__('Filtr jest używany w alercie lub raporcie', 'reports'),
					icon: {
						font: 'fa-exclamation-triangle',
						color: 'gray',
					},
					button: {
						text: this.i18n.__('Zamknij', 'reports'),
						type: 'button--big button--gray',
						closeOnClick: true,
					},
				});
			}
		);
	}

	prepareQuestionRule(questionData, answer, internal = null) {
		const questionRule = this.filtersService.getEmptyQuestionRule();

		questionRule.question = questionData.id;
		questionRule.condition = this.filtersHelper.getConditionTypeForQuickFilter();
		questionRule.type = questionData.type;

		if (Array.isArray(answer)) {
			questionRule.answers = answer;
		} else {
			questionRule.answers.push(answer);
		}

		if (internal) {
			questionRule.internalAnswers = [internal];
		}

		return questionRule;
	}

	prepareFilter(filterTitle, questionRule) {
		const filterStructure = this.filtersService.getEmptyFilterData();

		filterStructure.title = filterTitle;
		filterStructure.isQuick = 1;
		filterStructure.questionRules.push(questionRule);

		return filterStructure;
	}

	emitHandleQuickFilter(filterData, removed = false, edited = false) {
		this.$rootScope.$broadcast('HandleQuickFilter', {
			filter: filterData,
			edited,
			removed,
		});

		this.$rootScope.$broadcast('CloseCompletionsModal');
	}

	prepareTwoPartAnswerTitle(firstPart, secondPart) {
		return `${firstPart} - ${secondPart}`;
	}

	prepareTitleForNPS(answers) {
		switch (answers.type) {
			case this.npsGroups.detractors.type:
				return this.i18n.__('Krytycy', 'reports');
			case this.npsGroups.neutrals.type:
				return this.i18n.__('Neutralni', 'reports');
			case this.npsGroups.promoters.type:
				return this.i18n.__('Promotorzy', 'reports');
			default:
				return;
		}
	}

	showEditingNormalFilterInfo(filterTitle, answerTitle) {
		const config = {
			text: this.i18n._s(
				'Uwaga! Jeżeli wykorzystujesz filtr %s w alertach lub raportach cyklicznych, zmienisz sposób działania alertu lub wyniki, które będą produkowane do raportu cyklicznego. %sCzy na pewno chcesz zmienić istniejący filtr %s?',
				`<strong>${filterTitle}</strong>`,
				'<br><br>',
				`<br><strong>${filterTitle}</strong>`,
				'reports'
			),
			title: this.i18n.__('Zmieniasz istniejący filtr!', 'reports'),
			confirmText: this.i18n.__('Tak', 'reports'),
			cancelText: this.i18n.__('Anuluj', 'reports'),
		};

		return new Promise((resolve, reject) => {
			this.modalService.showConfirm(config).then(resolve, reject);
		});
	}
}

export default QuickFilterGenerator;
