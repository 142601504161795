import tpl from './VerbatimParametersElement.html';
import { VerbatimParametersEvents } from './events.verbatimParameters';
const Entities = require('html-entities').AllHtmlEntities;

class VerbatimParametersElement {
	constructor($scope) {
		'ngInject';

		this.$scope = $scope;
		this.entities = new Entities();

		this.parameter = null;
		this.indexOfList = null;
	}

	// in UI
	parameterClick($event) {
		$event.stopPropagation();
		this.parameter.active = !this.parameter.active;

		this.$scope.$emit(VerbatimParametersEvents.parameterClick, {
			parameter: this.parameter,
			indexOfList: this.indexOfList,
		});
	}
}

export const verbatimParametersElement = {
	template: tpl,
	controller: VerbatimParametersElement,
	bindings: {
		parameter: '<',
		indexOfList: '<',
	},
};
