import tpl from '../TagCreator.html';

import { TagCreator } from '../TagCreator/TagCreator';
import { ManagementMode } from '../ManagementMode';

class TagEditorController extends TagCreator.controller {
	constructor(i18n, verbatimTagsService, alertsService) {
		super(i18n, verbatimTagsService, alertsService);

		this.mode = ManagementMode.editing;
	}

	save() {
		this.verbatimTagsService.updateTagPromise(this.resolve.tag.id, this.tagTitle).then(
			(tag) => {
				this.close({ $value: tag });
				this.alertsService.success(this.i18n.__('Tag został zapisany.', 'tags'));
			},
			(status) => {
				if (status === 400) {
					this.alertsService.error(this.i18n.__('Ta nazwa taga została już użyta.', 'tags'));
				} else {
					this.alertsService.error(this.i18n.__('Wystąpił błąd podczas tworzenia taga.', 'tags'));
				}
			}
		);
	}

	isValid() {
		return (
			this.form.tagTitle.$valid &&
			this.form.tagTitle.$dirty &&
			this.tagTitle !== this.resolve.tag.title
		);
	}

	$onInit() {
		this.tagTitle = this.resolve.tag.title;
	}
}

export const TagEditor = {
	template: tpl,
	controller: TagEditorController,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&',
	},
};
