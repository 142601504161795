import tpl from './cross-tables-wrapper.html';

class CrossTablesWrapperController {
	constructor(
		$scope,
		$rootScope,
		i18n,
		surveyProviderService,
		modalService,
		alertsService,
		crossTablesService,
		reportsService,
		userService,
		crossTablesApiService,
		aclManager,
		segmentService
	) {
		'ngInject';

		this.crossTablesApiService = crossTablesApiService;
		this.surveyProviderService = surveyProviderService;
		this.crossTablesService = crossTablesService;
		this.reportsService = reportsService;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.userService = userService;
		this.$rootScope = $rootScope;
		this.aclManager = aclManager;
		this.segmentService = segmentService;
		this.$scope = $scope;
		this.i18n = i18n;

		this.isLoading = true;

		this.selectedFilter = null;

		this.crossTablesList = null;
		this.selectedCrossTable = null;
		this.crossTableModalShown = false;
		this.isExportHidden = false;

		this.crossTableResult = null;

		this.exportPrefix = '/user/export/';

		this.dataExportOptions = [
			{
				id: 'cross-table_xlsx',
				title: this.i18n.__('XLSX', 'reports'),
				url: 'table-xls-file',
				icon: 'fa fa-file-excel-o',
				csrf: true,
			},
			{
				id: 'cross-table_csv',
				title: this.i18n.__('CSV', 'reports'),
				url: 'table-csv-file',
				icon: 'fa fa-file-excel-o',
				csrf: true,
			},
		];

		this.LOCAL_STORAGE_KEY = 'crossTable';
		this.COOKIE_KEY = 'infoCrossTables';

		this.ELEMENT_FOR_EMPTY = {
			id: null,
			title: this.i18n.__('Brak tabel krzyżowych', 'reports'),
			disabled: true,
		};

		this.crossTablesLoaded = false;
		this.noCrossTables = true;
		this.areCrossTablesEnabled = false;

		this.deprecatedFunctionText = this.i18n.__(
			'Dodawanie nowych analiz w wygaszanych funkcjach (Filtry, Kreator raportów, Tabele krzyżowe, Rankingi, Trendy) nie jest już możliwe. Zapraszamy do nowego modułu Wyników.',
			'user'
		);
	}

	async $onInit() {
		this.bindFiltersActions();
		this.bindSurveyChange();
		this.bindWatchers();
		await this.setCrossTablesCreationAvailability();
	}

	bindSurveyChange() {
		this.$scope.$on('SurveyChanged', () => {
			this.isLoading = true;

			this.reportsService.clearCache('addToReport');
			this.selectedFilter = null;
			this.crossTablesList = [];
			this.selectedCrossTable = null;
			this.crossTableResult = null;

			this.removeCrossTableFromLocalStorage();
			this.setCrossTablesCreationAvailability();
		});
	}

	bindWatchers() {
		this.$scope.$watch(
			() => this.crossTablesList,
			() => {
				if (!this.crossTablesList) {
					this.noCrossTables = true;
				} else {
					this.noCrossTables = Boolean(
						this.crossTablesList[0] && this.crossTablesList[0].id === null
					);
				}
			},
			true
		);

		this.$scope.$watch(
			() => this.crossTablesLoaded,
			() => {
				if (this.crossTablesLoaded) {
					this.sendAnalyticsEvent();
				}
			}
		);
	}

	sendAnalyticsEvent() {
		const getModuleState = () => {
			const isFeaturePurchased = this.aclManager.isFeatureEnabled(
				this.$rootScope.features.moduleCrossTables
			);

			if (!isFeaturePurchased) {
				return 'unavailable';
			} else if (isFeaturePurchased && !this.areCrossTablesEnabled) {
				return 'disabled';
			} else if (isFeaturePurchased && this.noCrossTables) {
				return 'enabled';
			} else {
				return 'activated';
			}
		};

		const moduleState = getModuleState();

		this.segmentService.track('Tabele Krzyżowe - wejście', {
			state: moduleState,
		});
	}

	// Filters

	bindFiltersActions() {
		this.$scope.$on('filtersFetched', (event, data) => {
			this.$rootScope.$broadcast('loadingOn');

			this.fetchCrossTables().then(() => {
				this.selectedFilter = data.filterId;
				this.checkLocalStorage();
				this.isLoading = false;
				this.$scope.$apply();
				this.$rootScope.$broadcast('loadingOff');
			});
		});

		this.$scope.$on('filterChanged', (event, data) => {
			this.selectedFilter = data.filterId;
			this.crossTablesSelectChange(this.selectedCrossTable);
		});
	}

	// Cross Tables

	fetchCrossTables() {
		return new Promise((resolve) => {
			this.crossTablesApiService.getList().then((crossTablesList) => {
				if (crossTablesList.length > 0) {
					this.crossTablesList = crossTablesList;
				} else {
					this.crossTablesList = [this.ELEMENT_FOR_EMPTY];
				}

				this.crossTablesLoaded = true;

				resolve();
			});
		});
	}

	newCrossTableClick() {
		if (!this.crossTableModalShown) {
			this.crossTableModalShown = true;

			this.crossTablesService.openCreator({
				onCreateCallback: (createdCrossTable) => {
					if (!this.crossTablesList[0].id) {
						this.crossTablesList.splice(0, 1);
					}
					this.crossTablesList.unshift({
						id: createdCrossTable.id,
						title: createdCrossTable.title,
					});

					this.selectedCrossTable = this.crossTablesList[0];
					this.crossTablesSelectChange(this.selectedCrossTable);

					this.crossTablesService.closeCreator();
					this.alertsService.success(
						this.i18n.__('Pomyślnie utworzono tabelę krzyżową', 'reports')
					);
				},
				onCloseCallback: () => {
					this.crossTableModalShown = false;
				},
			});
		}
	}

	crossTablesSelectChange($item) {
		this.$rootScope.$broadcast('loadingOn');

		if ($item) {
			this.crossTablesApiService
				.getResult($item.id, this.selectedFilter)
				.then((crossTableResult) => {
					this.crossTableResult = this.crossTablesService.handleTranslation(crossTableResult);
					this.saveCrossTableInLocalStorage($item.id);
					this.blockExportIfSegmentsInside(this.crossTableResult);

					this.$scope.$apply();
					this.$rootScope.$broadcast('loadingOff');
				});
		} else {
			this.crossTableResult = null;
			this.removeCrossTableFromLocalStorage();
			this.$rootScope.$broadcast('loadingOff');
		}
	}

	blockExportIfSegmentsInside(crossTableResult) {
		this.isExportHidden = Boolean(crossTableResult.sets.find((set) => set.questionType === null));
	}

	emptyScreenOnButtonClick = () => {
		this.newCrossTableClick();
	};

	// Edit

	editCrossTable(event, index, crossTable) {
		event.stopPropagation();

		if (!this.crossTableModalShown) {
			this.crossTableModalShown = true;

			this.crossTablesService.openCreator({
				onCreateCallback: (editedCrossTable) => {
					let listItem = {
						id: editedCrossTable.id,
						title: editedCrossTable.title,
					};

					this.crossTablesList.splice(index, 1, listItem);

					if (
						this.selectedCrossTable &&
						Number(this.selectedCrossTable.id) === Number(listItem.id)
					) {
						this.selectedCrossTable = listItem;
						this.crossTablesSelectChange(listItem);
					}

					this.crossTablesService.closeCreator();
					this.alertsService.success(
						this.i18n.__('Pomyślnie zedytowano tabelę krzyżową', 'reports')
					);
				},
				onCloseCallback: () => {
					this.crossTableModalShown = false;
				},
				crossTableId: crossTable.id,
			});
		}
	}

	// Delete

	removeCrossTable(event, index, crossTable) {
		event.stopPropagation();

		if (crossTable.isReportConnected) {
			this.showDisabledModal();
			return;
		}

		this.confirmRemovingPromise().then(() => {
			this.crossTablesApiService.remove(crossTable.id).then(
				() => {
					this.crossTablesList.splice(index, 1);

					if (
						this.selectedCrossTable &&
						Number(this.selectedCrossTable.id) === Number(crossTable.id)
					) {
						this.selectedCrossTable = null;
						this.crossTableResult = null;
					}
					if (this.crossTablesList.length === 0) {
						this.crossTablesList = [this.ELEMENT_FOR_EMPTY];
					}

					this.modalService.close();
					this.alertsService.success(this.i18n.__('Pomyślnie usunięto tabelę krzyżową', 'reports'));
				},
				() => {
					this.modalService.close();
					this.showDisabledModal();
				}
			);
		});
	}

	confirmRemovingPromise() {
		let ctrl = this;

		return new Promise((resolve, reject) => {
			let config = {
				size: '400',
				title: ctrl.i18n.__('Usuwanie tabeli krzyżowej', 'reports'),
				text: ctrl.i18n.__('Czy na pewno chcesz usunąć?', 'reports'),
				icon: {
					font: 'fa-trash',
					color: 'gray',
				},
				button: {
					text: ctrl.i18n.__('Anuluj', 'reports'),
					type: 'button--big button--gray',
				},
				button2: {
					text: ctrl.i18n.__('Tak, usuń', 'reports'),
					type: 'button--big',
				},
			};

			this.modalService.show(config).then((reason) => {
				if (reason === 'button1') {
					ctrl.modalService.close();
				}

				if (reason === 'button2') {
					ctrl.modalService.loading(this.i18n.__('Usuwanie tabeli krzyżowej...', 'reports'));
					resolve();
				}
			});
		});
	}

	showDisabledModal() {
		this.modalService.show({
			size: '400',
			title: this.i18n.__('Nie można usunąć tabeli krzyżowej', 'reports'),
			text: this.i18n.__('Tabela krzyżowa jest użyta w raporcie', 'reports'),
			icon: {
				font: 'fa-exclamation-triangle',
				color: 'gray',
			},
			button: {
				text: this.i18n.__('Zamknij', 'reports'),
				type: 'button--big button--gray',
				closeOnClick: true,
			},
		});
	}

	// Methods for localStorage

	checkLocalStorage() {
		const idFromLocalStorage = this.getCrossTableFromLocalStorage();

		if (idFromLocalStorage) {
			this.selectedCrossTable = this.crossTablesList.find(
				(crossTable) => Number(crossTable.id) === Number(idFromLocalStorage)
			);
			this.crossTablesSelectChange(this.selectedCrossTable);
		} else if (!this.crossTablesList[0].disabled) {
			this.selectedCrossTable = this.crossTablesList[0];
			this.crossTablesSelectChange(this.selectedCrossTable);
		}
	}

	saveCrossTableInLocalStorage(crossTableId) {
		window.localStorage.setItem(this.LOCAL_STORAGE_KEY, crossTableId);
	}

	removeCrossTableFromLocalStorage() {
		window.localStorage.removeItem(this.LOCAL_STORAGE_KEY);
	}

	getCrossTableFromLocalStorage() {
		return window.localStorage.getItem(this.LOCAL_STORAGE_KEY);
	}

	exportClick(exportOption) {
		if (this.selectedCrossTable) {
			this.$rootScope.$broadcast('faceboxLoadingOn');

			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.surveyProviderService
					.dataExportGetFileCrossTablePromise(
						exportOption.title,
						surveyId,
						this.selectedCrossTable.id,
						this.selectedFilter
					)
					.then(
						() => {
							this.$rootScope.$broadcast('faceboxLoadingOff');
						},
						() => {
							this.$rootScope.$broadcast('faceboxLoadingOff');

							this.alertsService.error(this.i18n.__('Błąd pobierania pliku.', 'reports'));
						}
					);
			});
		}
	}

	addToReport() {
		let ctrl = this;

		this.$scope.$broadcast('addToReport', {
			filterId: this.selectedFilter,
			crossTableData: this.selectedCrossTable,
			successCallback: () => {
				ctrl.selectedCrossTable.isReportConnected = true;
			},
		});
	}

	async setCrossTablesCreationAvailability() {
		const questions = await this.crossTablesApiService.getQuestions();
		this.areCrossTablesEnabled = !!questions.length;
	}

	canCreateCrossTable() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}

	canCreateReport() {
		return this.canCreateCrossTable();
	}
}

export const CrossTablesWrapperComponent = {
	template: tpl,
	controller: CrossTablesWrapperController,
};
