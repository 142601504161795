import angular from 'angular';
import $ from 'jquery';
import { AclFeature } from '../components/acl/acl.config';

class ProjectMembersService {
	constructor($rootScope, i18n, alertsService, aclManager) {
		'ngInject';

		this.$rootScope = $rootScope;
		this.i18n = i18n;
		this.alertsService = alertsService;
		this.aclManager = aclManager;

		this.getMembersAccessTypeForProject = () => {
			var projectId = this.$rootScope.currentSurveyData.id;

			return new Promise(function (resolve, reject) {
				$.ajax({
					url: '/user/projects/' + projectId + '/members',
					success: function (response) {
						resolve(response.data);
					},
					error: function (response) {
						reject(JSON.parse(response.responseText));
					},
				});
			});
		};

		this.grantAccess = (payload) => {
			const _i18n = this.i18n;
			const _alertsService = this.alertsService;
			return new Promise(function (resolve, reject) {
				$.ajax({
					url: '/user/projects/' + payload.projectId + '/members',
					type: 'PUT',
					contentType: 'application/json',
					dataType: 'json',
					data: JSON.stringify(payload),
					success: function (response) {
						resolve(response.data);
					},
					error: function (response) {
						let text = _i18n.__('Wystąpił błąd');
						if (response.status === 403) {
							text = _i18n.__(
								'Nie masz uprawnień do zapraszania osób. Jeśli chcesz dodać nową osobę, skontaktuj się z managerem konta.',
								'user'
							);
						}
						if (response.status === 409) {
							text = _i18n.__(
								'Na ten e-mail jest już założone konto w naszej bazie. Podaj inny e-mail lub skontaktuj się z osobą, którą chcesz zaprosić.',
								'user'
							);
						}
						_alertsService.error(text);

						reject(JSON.parse(response.responseText));
					},
				});
			});
		};

		this.getMembersSlots = () => {
			return new Promise(function (resolve, reject) {
				$.ajax({
					url: '/user/members/slots',
					success: function (response) {
						resolve(response.data);
					},
					error: function (response) {
						reject(JSON.parse(response.responseText));
					},
				});
			});
		};

		this.shareModuleAvailable = () => {
			return this.aclManager.isFeatureEnabled(AclFeature.moduleSurveySharing);
		};
	}
}

export default ProjectMembersService;
