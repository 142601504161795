const getNumeralFilterWords = (lang) => {
	if (lang === 'pl') {
		return {
			odpowiedź: {
				1: 'odpowiedź',
				default: 'odpowiedzi',
			},
			wynik: {
				1: 'wynik',
				_range: [
					{
						decimal: true,
						min: 0,
						max: 1,
						text: 'wyników',
					},
					{
						min: 12,
						max: 14,
						text: 'wyników',
					},
					{
						decimal: true,
						min: 2,
						max: 4,
						text: 'wyniki',
					},
					{
						decimal: true,
						min: 5,
						max: 9,
						text: 'wyników',
					},
				],
			},
		};
	} else if (lang === 'en') {
		return {
			answer: {
				1: 'answer',
				default: 'answers',
			},
			result: {
				1: 'result',
				default: 'result',
			},
		};
	} else if (lang === 'cs') {
		return {
			odpověď: {
				1: 'odpověď',
				_range: [
					{
						min: 2,
						max: 4,
						text: 'odpovědi',
					},
				],
				default: 'odpovědí',
			},
			výsledek: {
				1: 'výsledek',
				_range: [
					{
						min: 2,
						max: 4,
						text: 'výsledky',
					},
				],
				default: 'výsledků',
			},
		};
	} else if (lang === 'sk') {
		return {
			odpoveď: {
				1: 'odpoveď',
				default: 'odpovede',
			},
			výsledok: {
				1: 'výsledok',
				default: 'výsledkov',
			},
		};
	}
};

function numeralFilter(langService) {
	'ngInject';

	return (item, word, wordOnly) => {
		let lWord = word.toLowerCase();
		let isLower = lWord === word;

		const numeralFilterWords = getNumeralFilterWords(langService.getLang());

		if (Object.prototype.hasOwnProperty.call(numeralFilterWords, lWord)) {
			let dict = numeralFilterWords[lWord];
			let decimal = String(item).substr(-1);

			for (let index in dict) {
				if (Object.prototype.hasOwnProperty.call(dict, index)) {
					if (Number(item) === Number(index)) {
						if (!isLower) {
							let text = dict[index].substr(0, 1).toUpperCase() + dict[index].substr(1);
							return wordOnly ? text : item + ' ' + text;
						}
						return wordOnly ? dict[index] : item + ' ' + dict[index];
					}
					if (index === '_range') {
						for (var i = 0; i < dict[index].length; i++) {
							let range = dict[index][i];

							if (!range.decimal && item >= range.min && item <= range.max) {
								if (!isLower) {
									let text = range.text.substr(0, 1).toUpperCase() + range.text.substr(1);
									return wordOnly ? text : item + ' ' + text;
								}
								return wordOnly ? range.text : item + ' ' + range.text;
							}
							if (range.decimal && decimal >= range.min && decimal <= range.max) {
								if (!isLower) {
									let text = range.text.substr(0, 1).toUpperCase() + range.text.substr(1);
									return wordOnly ? text : item + ' ' + text;
								}
								return wordOnly ? range.text : item + ' ' + range.text;
							}
						}
					}
				}
			}
			if (!isLower) {
				let def = dict.default.substr(0, 1).toUpperCase() + dict.default.substr(1);
				return wordOnly ? def : item + ' ' + def;
			}
			return wordOnly ? dict.default : item + ' ' + dict.default;
		}
		return wordOnly ? word : item + ' ' + word;
	};
}

export { numeralFilter };
