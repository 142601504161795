import angular from 'angular';

import { AnalyzeSetStorage } from './Service/AnalyzeSetStorage';
import { QuestionChooser } from './ChooserType/QuestionChooser';
import { SegmentChooser } from './ChooserType/SegmentChooser';
import { TagChooser } from './ChooserType/TagChooser';
import { MetricChooser } from './ChooserType/MetricChooser';
import { QuestionRankingChooser } from './ChooserType/QuestionRankingChooser';
import { QuestionTrendChooser } from './ChooserType/QuestionTrendChooser';
import { QuestionRowsChooser } from './ChooserType/QuestionRowsChooser';
import { PeriodChooser } from './ChooserType/PeriodChooser';
import { TrendRangeChooser } from './ChooserType/TrendRangeChooser';

angular
	.module('app.analyze-set', [])
	.service('analyzeSetStorage', AnalyzeSetStorage)
	.component('questionChooser', QuestionChooser)
	.component('segmentChooser', SegmentChooser)
	.component('tagChooser', TagChooser)
	.component('metricChooser', MetricChooser)
	.component('questionRankingChooser', QuestionRankingChooser)
	.component('questionTrendChooser', QuestionTrendChooser)
	.component('questionRowsChooser', QuestionRowsChooser)
	.component('periodChooser', PeriodChooser)
	.component('trendRangeChooser', TrendRangeChooser);
