import tpl from './single-dropdown.html';
import { SwitcherController } from '../../controllers/switcher.controller';

export const SingleDropdownComponent = {
	template: tpl,
	controller: SwitcherController,
	bindings: {
		items: '<',
		model: '<',
		disabled: '<',
		onSelect: '&',
		title: '@',
	},
};
