import tpl from './SentimentRankingChart.html';
import highcharts from 'highcharts';

class SentimentRankingChartController {
	constructor(i18n, $element, CHART_COLORS) {
		'ngInject';

		this.i18n = i18n;
		this.$element = $element;
		this.CHART_COLORS = CHART_COLORS;

		this.chartContainer = null;
		this.chart = null;

		this.chartConfig = {
			chart: {
				alignThresholds: true,
				style: {
					fontFamily: '"Poppins", sans-serif',
					fontSize: '12px',
				},
				type: 'column',
				events: {
					redraw: this.sortGroups,
					load: this.sortGroups,
				},
			},
			credits: {
				enabled: false,
			},
			title: {
				text: '',
			},
			xAxis: [
				{
					title: '',
					categories: [
						i18n.__('Pozytywne', 'reports'),
						i18n.__('Neutralne', 'reports'),
						i18n.__('Negatywne', 'reports'),
					],
					crosshair: true,
					lineWidth: 2,
					lineColor: '#777',
					labels: {
						style: {
							color: '#032c3e',
							fontSize: '12px',
						},
						autoRotationLimit: 130,
					},
					tickWidth: 0,
					plotBands: [
						{
							color: 'rgba(188, 231, 142, 0.2)',
							from: -0.5,
							to: 0.5,
						},
						{
							color: 'rgba(253, 199, 122, 0.1)',
							from: 0.5,
							to: 1.5,
						},
						{
							color: 'rgba(249, 104, 107, 0.1)',
							from: 1.5,
							to: 2.5,
						},
					],
				},
			],
			yAxis: [
				{
					title: '',
					min: 0,
					labels: {
						style: {
							color: '#032c3e',
							fontSize: '12px',
						},
					},
					lineWidth: 2,
					lineColor: '#777',
				},
			],
			tooltip: {
				shared: true,
			},
			legend: {
				enabled: true,
				margin: 20,
				itemStyle: {
					width: '120px',
				},
			},
			series: [],
			plotOptions: {
				column: {
					borderWidth: 0,
					pointPadding: 0,
				},
			},
		};
	}

	drawChart() {
		this.chart = highcharts.chart(this.chartContainer, this.chartConfig);
	}

	$onInit() {
		this.prepareElements();
	}

	$onChanges() {
		if (this.sentimentRankingData && this.sentimentRankingData.length) {
			this.prepareElements();
			this.prepareSeries();
			this.drawChart();
		}
	}

	prepareSeries() {
		this.chartConfig.series = [];

		this.sentimentRankingData.forEach((tag, index) => {
			this.chartConfig.series.push({
				name: tag.title,
				data: [tag.positive, tag.neutral, tag.negative],
				color: this.CHART_COLORS[index % this.CHART_COLORS.length],
			});
		});
	}

	prepareElements() {
		this.chartContainer = this.$element.find('.chart__main')[0];
	}

	sortGroups() {
		var series = this.series;

		for (let i = 0; i < this.series[0].points.length; i++) {
			let pointsPos = [];
			let pointsGroup = [];

			series.forEach((series, j) => {
				let point = series.points[i];

				if (series.visible) {
					let args = point.shapeArgs;
					pointsGroup.push(series.points[i]);
					pointsPos.push({
						transX: args.x,
					});
				}
			});

			pointsGroup
				.sort((a, b) => {
					return b.y - a.y;
				})
				.forEach((point, i) => {
					point.graphic.attr({
						x: pointsPos[i].transX,
					});
				});
		}
	}
}

export const SentimentRankingChart = {
	template: tpl,
	controller: SentimentRankingChartController,
	bindings: {
		sentimentRankingData: '<',
	},
};
