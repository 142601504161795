import tpl from './Alert.html';

class AlertController {
	constructor(alertsService, $rootScope, $timeout) {
		'ngInject';

		this.alertsService = alertsService;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;

		this.visible = false;
		this.timeout = null;

		this.animationTime = 500;
	}

	$onInit() {
		this.$timeout(() => {
			this.visible = true;
		}, 50);

		if (this.alert.type === 'success') {
			this.timeout = this.$timeout(() => {
				this.remove(this.index);
			}, this.alert.time);
		}
	}

	$onDestroy() {
		clearTimeout(this.timeout);
	}

	remove(index) {
		this.visible = false;

		this.$timeout(() => {
			this.alertsService.removeAlert(index);
		}, this.animationTime);
	}
}

export const Alert = {
	template: tpl,
	controller: AlertController,
	bindings: {
		alert: '<',
		index: '<',
	},
};
