const tpl = require('./QuestionRanking.html');

class QuestionRankingController {
	constructor(modalService, i18n) {
		'ngInject';

		this.modalService = modalService;
		this.i18n = i18n;

		this.top = [];
		this.answers = [];
		this.topPositions = 3;

		this.displayLimit = 4;
		this.ready = false;
	}

	$onInit() {
		this.answers = this.questionData.answers;
		this.top = this.questionData.answers.slice(0, this.topPositions);

		this.ready = true;
		this.questionCtrl.setChild(this);
	}
	// $postLink() {
	// }
	isReady() {
		return this.ready;
	}

	showDistribution(index, isTopAnswer) {
		let answer = this.answers[index];

		this.modalService.openDistributionChartModal(
			this.questionCtrl.questionIndex,
			index,
			this.questionData,
			answer.others
		);
	}
}

export const questionRanking = {
	template: tpl,
	controller: QuestionRankingController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
