import Template from './VerbatimEmptyScreen.html';

class VarbatimEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Oznacz wypowiedzi respondentów tematami, których dotyczą np. obsługa klienta czy proces dostawy.',
					'user'
				),
				this.i18n.__(
					'Ustal sentyment - czy respondent wypowiada się o danym aspekcie pozytywnie, neutralnie czy negatywnie.',
					'user'
				),
			],
			2: [
				this.i18n.__(
					'Analizuj najczęściej wspominane tematy i monitoruj jak zadowolenie klientów zmienia się w czasie.',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}

	getTooltipText() {
		return this.tooltipText;
	}

	getDescriptionText() {
		const description = this.i18n.__('Nie masz jeszcze otagowanych odpowiedzi.', 'user');

		const enabledPropertyExists = typeof this.enabled !== 'undefined';

		if (!enabledPropertyExists) {
			return description;
		}

		return this.enabled ? description : this.disabledDescription;
	}
}
export const VerbatimEmptyScreenComponent = {
	template: Template,
	controller: ['i18n', VarbatimEmptyScreenController],
	bindings: {
		onButtonClick: '<',
		enabled: '<',
		disabledDescription: '@?',
		tooltipText: '@',
	},
};
