import tpl from './QuickFilterComponent.html';

class QuickFilterComponentController {
	constructor(i18n, quickFilterGenerator, $scope, questionHelper, aggregatedModeService) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.questionHelper = questionHelper;
		this.quickFilterGenerator = quickFilterGenerator;
		this.aggregatedModeService = aggregatedModeService;
	}

	onQuickFilterClick() {
		const data = this.$scope.$parent.$ctrl.getQuickFilterData(this.answers);
		this.quickFilterGenerator.handleQuickFilter(data, this.filterId);
	}

	isAllAggregatedMode() {
		return this.aggregatedModeService.isAllAggregatedMode();
	}
}

export const quickFilterComponent = {
	template: tpl,
	controller: QuickFilterComponentController,
	bindings: {
		answers: '<',
		questionData: '<',
		filterId: '<',
	},
};
