const ngClickOutsideDirective = ($document) => {
	return {
		restrict: 'A',
		link: (scope, element, attr) => {
			$document.bind('click', (event) => {
				const isClickedElement = element.find(event.target).length > 0;

				if (isClickedElement) {
					return;
				}

				scope.$apply(() => {
					scope.$eval(attr.ngClickOutside);
				});
			});
		},
	};
};

ngClickOutsideDirective.$inject = ['$document'];

export { ngClickOutsideDirective };
