import tpl from './SubMenu.html';
import '../../scss/subMenu.scss';

class SubMenuController {
	constructor() {
		'ngInject';
	}
}

export const subMenu = {
	template: tpl,
	controller: SubMenuController,
	transclude: true,
};
