const tpl = require('./CompletionsWithQuestionModal.html');
const Entities = require('html-entities').AllHtmlEntities;

const { AbstractPaginationManager } = require('./AbstractPaginationManager.js');

class CompletionsWithQuestionModalController extends AbstractPaginationManager {
	constructor(surveyProviderService, modalService, i18n, $scope, questionHelper, $rootScope) {
		'ngInject';

		super(surveyProviderService, modalService, i18n, questionHelper);

		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.entities = new Entities();
	}

	$onInit() {
		this.questionIndex = this.resolve.questionIndex;
		this.questionData = this.resolve.questionData;

		this.$scope.showPagination = !this.noPagination;

		if (this.attachment) {
			this.attachment.addChild(this);
		}

		this.$rootScope.$on('CloseCompletionsModal', () => {
			this.close();
		});
	}

	handleDownloadedAnswers(data, pageNumber) {
		super.handleDownloadedAnswers(data, pageNumber);

		this.questionData.answers = this.answers;

		this.updatePaginationView();
	}

	htmlEntityDecode(text) {
		return this.entities.decode(text);
	}
}

export const completionsWithQuestionModal = {
	template: tpl,
	controller: CompletionsWithQuestionModalController,
	require: {
		attachment: '?^reportAttachment',
	},
	bindings: {
		resolve: '<',
		close: '&',
		noPagination: '<',
	},
};
