import tpl from './trend-result.html';
import angular from 'angular';

class TrendResultController {
	constructor(i18n, $scope, trendsService, $timeout, reportHelper, $rootScope, questionHelper) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.trendsService = trendsService;
		this.reportHelper = reportHelper;
		this.questionHelper = questionHelper;
		this.$rootScope = $rootScope;

		this.typesForSorting = reportHelper.getTypesForTrendSorting();

		this.SETUP_TYPES = {
			INACTIVE: 0,
			ASC: 1,
			DESC: 2,
		};

		this.SERIES_TYPE_SETUP = {
			area: 0,
			column: 1,
		};

		this.FORMAT_TYPE_SETUP = {
			percent: 0,
			count: 1,
		};

		this.SERIES_MODE = [
			{
				title: `<i class="fa fa-area-chart" aria-hidden="true"></i>`,
				type: this.SERIES_TYPE_SETUP.area,
				chart: 'area',
			},
			{
				title: `<i class="fa fa-bar-chart" aria-hidden="true"></i>`,
				type: this.SERIES_TYPE_SETUP.column,
				chart: 'column',
			},
		];

		this.FORMAT_MODE = [
			{
				title: `<i class="fa fa-percent" aria-hidden="true"></i>`,
				type: this.FORMAT_TYPE_SETUP.percent,
				stacking: 'percent',
			},
			{
				title: `123`,
				type: this.FORMAT_TYPE_SETUP.count,
				stacking: 'normal',
			},
		];

		this.selectedSeriesMode = this.SERIES_MODE[0];
		this.selectedFormatMode = this.FORMAT_MODE[0];

		this.additionalSettings = [];

		this.sortedTrendResult = null;

		this.isLoading = true;
		this.columnIndex = null;
	}

	$onInit() {
		this.bindUIActions();
	}

	bindUIActions() {
		let ctrl = this;

		this.$scope.$on('addToReport', (event, data) => {
			ctrl.addToReport(data.trendData, data.filterId, data.successCallback);
		});

		this.$scope.$watch(
			() => this.trendResult,
			(newValue, oldValue) => {
				ctrl.trendResultWatcher(newValue, oldValue);
			}
		);

		this.$scope.$watch(
			() => this.sortedTrendResult,
			(newValue, oldValue) => {
				ctrl.sortedTrendResultWatcher(newValue, oldValue);
			}
		);
	}

	trendResultWatcher(newValue) {
		if (newValue) {
			this.translateTrendData();
			this.sortedTrendResult = angular.copy(this.trendResult);
			this.isLoading = false;
			this.activeSortingColumn = this.reportHelper.additionalSettingsTypes.trendDate;
			this.activeSetup = this.SETUP_TYPES.ASC;

			if (this.partCtrl) {
				this.chartCtrl.updateThemeColor(this.reportBuilderCtrl.getColors());
			}

			this.chartCtrl.drawChart(this.trendResult);
		}
	}

	sortedTrendResultWatcher(newValue, oldValue) {
		if (this.partCtrl && newValue && !oldValue) {
			this.initForPart();

			if (this.additionalSettings.length > 0) {
				const columnSetting = this.additionalSettings.find((setting) => {
					return setting.type === this.reportHelper.additionalSettingsTypes.trendSortedColumn;
				});

				if (columnSetting) {
					const typeSetting = this.additionalSettings.find((setting) => {
						return setting.type === this.reportHelper.additionalSettingsTypes.trendSortedType;
					});

					this.columnId = columnSetting.setup;
					this.activeSetup = typeSetting.setup;

					this.setActiveSorting(typeSetting.type);
					this.sortMatrixTypeColumnBy(this.activeSetup, this.columnId);
				} else {
					const otherSortedSetting = this.additionalSettings.find((setting) => {
						return this.typesForSorting.indexOf(setting.type) !== -1;
					});

					if (otherSortedSetting) {
						this.activeSetup = otherSortedSetting.setup;
						this.setActiveSorting(otherSortedSetting.type);

						if (
							this.activeSetup &&
							[
								this.reportHelper.additionalSettingsTypes.trendCritics,
								this.reportHelper.additionalSettingsTypes.trendNeutrals,
								this.reportHelper.additionalSettingsTypes.trendPromotors,
							].indexOf(otherSortedSetting.type) !== -1
						) {
							this.sortNpsTypeColumnBy(otherSortedSetting.type, this.activeSetup);
						} else {
							this.sortBy(otherSortedSetting.type, this.activeSetup);
						}
					}
				}

				const formatSetting = this.additionalSettings.find((setting) => {
					return setting.type === this.reportHelper.additionalSettingsTypes.trendChartFormat;
				});

				if (formatSetting) {
					this.selectedFormatMode = this.FORMAT_MODE.find((mode) => {
						return mode.type === formatSetting.setup;
					});
				}

				const seriesSetting = this.additionalSettings.find((setting) => {
					return setting.type === this.reportHelper.additionalSettingsTypes.trendChartSeries;
				});

				if (seriesSetting) {
					this.selectedSeriesMode = this.SERIES_MODE.find((mode) => {
						return mode.type === seriesSetting.setup;
					});
				}
			}

			console.log(this.additionalSettings);

			this.reportBuilderCtrl.reportFullyLoaded();
		}
	}

	translateTrendData() {
		for (let i = 0; i < this.trendResult.sets.length; i++) {
			if (this.trendResult.sets[i].shouldBeTranslated) {
				this.trendResult.sets[i].title = this.i18n.__(this.trendResult.sets[i].title, 'reports');
			}
		}
	}

	// SORTING

	sortDate(type) {
		if (this.isTags()) {
			return;
		}

		this.checkIfAnotherSortingApplied(type);
		this.setupDateAdditionalSettings(type);
		this.setActiveSorting(type);

		this.sortBy(type, this.activeSetup);
	}

	sortMatrix(type, columnIndex) {
		if (this.isTags()) {
			return;
		}

		this.checkIfAnotherSortingApplied(type, columnIndex);
		this.setupMatrixAdditionalSettings(type, columnIndex);
		this.setActiveSorting(type);

		if (this.activeSetup !== this.SETUP_TYPES.INACTIVE) {
			this.sortMatrixTypeColumnBy(this.activeSetup, columnIndex);
		} else {
			this.sortedTrendResult = angular.copy(this.trendResult);
		}
	}

	sortNpsType(type) {
		this.checkIfAnotherSortingApplied(type);
		this.setupAdditionalSettings(type);
		this.setActiveSorting(type);

		if (this.activeSetup !== this.SETUP_TYPES.INACTIVE) {
			this.sortNpsTypeColumnBy(type, this.activeSetup);
		} else {
			this.sortedTrendResult = angular.copy(this.trendResult);
		}
	}

	sortOtherResults(type) {
		this.checkIfAnotherSortingApplied(type);
		this.setupAdditionalSettings(type);
		this.setActiveSorting(type);

		if (this.activeSetup !== this.SETUP_TYPES.INACTIVE) {
			this.sortBy(type, this.activeSetup);
		} else {
			this.sortedTrendResult = angular.copy(this.trendResult);
		}
	}

	sortBy(type, setup) {
		let temporarySortedTrend = angular.copy(this.trendResult);
		let sortedColumn = [];
		const score = 'score';

		type = this.trendsService.mapTypeToColumn(type);

		if (type === 'ltr') {
			sortedColumn = angular.copy(
				this.trendResult[type].map((item, itemIndex) => {
					return { score: item.score, type: item.type, index: itemIndex };
				})
			);

			sortedColumn.sort(this.comparingFunction(setup));
		} else if (type === 'rows') {
			sortedColumn = angular.copy(
				this.trendResult[type].map((item, itemIndex) => {
					return { score: item.index, index: itemIndex };
				})
			);

			sortedColumn.sort(this.comparingFunction(setup));
		} else if (
			type === 'topBox' ||
			type === 'topTwoBoxes' ||
			type === 'bottomBox' ||
			type === 'bottomTwoBoxes'
		) {
			sortedColumn = angular.copy(
				this.trendResult[type].map((item, itemIndex) => {
					return { score: item.percent, count: item.count, index: itemIndex };
				})
			);

			sortedColumn.sort(this.comparingFunction(setup));
		} else {
			sortedColumn = angular.copy(
				this.trendResult[type].map((item, itemIndex) => {
					return { score: item, index: itemIndex };
				})
			);

			sortedColumn.sort(this.comparingFunction(setup));
		}

		temporarySortedTrend[type].forEach((item, index) => {
			if (type === 'ltr') {
				item.score = sortedColumn[index].score;
				item.type = sortedColumn[index].type;
			} else if (type === 'rows') {
				temporarySortedTrend[type][index] = angular.copy(
					this.trendResult[type][sortedColumn[index].index]
				);
			} else if (
				type === 'topBox' ||
				type === 'topTwoBoxes' ||
				type === 'bottomBox' ||
				type === 'bottomTwoBoxes'
			) {
				item.count = sortedColumn[index].count;
				item.percent = sortedColumn[index].score;
			} else {
				temporarySortedTrend[type][index] = sortedColumn[index].score;
			}
		});

		for (let column in this.sortedTrendResult) {
			if (
				column !== type &&
				column !== 'sets' &&
				column !== 'columns' &&
				this.trendResult[column] !== null
			) {
				for (let i = 0; i < sortedColumn.length; i++) {
					temporarySortedTrend[column][i] = angular.copy(
						this.trendResult[column][sortedColumn[i].index]
					);
				}
			}
		}

		this.$timeout(() => {
			this.sortedTrendResult = temporarySortedTrend;
		});
	}

	sortMatrixTypeColumnBy(setup, columnIndex) {
		this.columnIndex = columnIndex;
		let temporarySortedTrend = angular.copy(this.trendResult);
		let sortedColumn = angular.copy(
			this.trendResult.answers.map((item, itemIndex) => {
				return { score: item[this.columnIndex], index: itemIndex };
			})
		);

		sortedColumn.sort(this.comparingFunction(setup));

		temporarySortedTrend.answers = temporarySortedTrend.answers.map((item, index) => {
			return angular.copy(this.trendResult.answers[sortedColumn[index].index]);
		});

		for (let column in this.sortedTrendResult) {
			if (
				column !== 'answers' &&
				column !== 'sets' &&
				column !== 'columns' &&
				this.trendResult[column] !== null
			) {
				for (let i = 0; i < sortedColumn.length; i++) {
					temporarySortedTrend[column][i] = angular.copy(
						this.trendResult[column][sortedColumn[i].index]
					);
				}
			}
		}

		this.$timeout(() => {
			this.sortedTrendResult = temporarySortedTrend;
		});
	}

	sortNpsTypeColumnBy(type, setup) {
		let temporarySortedTrend = angular.copy(this.trendResult);
		const convertedType = this.trendsService.convertNpsTypeToIndex(type);
		let sortedColumn = angular.copy(
			this.trendResult.answers.map((item, itemIndex) => {
				return {
					score: item[convertedType].percent,
					count: item[convertedType].count,
					index: itemIndex,
				};
			})
		);

		sortedColumn.sort(this.comparingFunction(setup));

		temporarySortedTrend.answers = temporarySortedTrend.answers.map((item, index) => {
			return angular.copy(this.trendResult.answers[sortedColumn[index].index]);
		});

		for (let column in this.sortedTrendResult) {
			if (
				column !== 'answers' &&
				column !== 'sets' &&
				column !== 'columns' &&
				this.trendResult[column] !== null
			) {
				for (let i = 0; i < sortedColumn.length; i++) {
					temporarySortedTrend[column][i] = angular.copy(
						this.trendResult[column][sortedColumn[i].index]
					);
				}
			}
		}

		this.$timeout(() => {
			this.sortedTrendResult = temporarySortedTrend;
		});
	}

	checkIfAnotherSortingApplied(type, columnIndex = null) {
		if (
			this.activeSortingColumn !== type &&
			this.activeSortingColumn !== null &&
			type === this.reportHelper.additionalSettingsTypes.trendDate
		) {
			this.activeSetup = this.SETUP_TYPES.ASC;
		} else if (
			this.activeSortingColumn !== type &&
			this.activeSortingColumn !== null &&
			type !== this.reportHelper.additionalSettingsTypes.trendDate
		) {
			this.activeSetup = this.SETUP_TYPES.INACTIVE;
		} else if (
			type === this.reportHelper.additionalSettingsTypes.trendSortedType &&
			this.activeSortingColumn === type &&
			this.columnIndex !== columnIndex
		) {
			this.activeSetup = this.SETUP_TYPES.INACTIVE;
		}
	}

	// HANDLING ADDITIONAL SETTINGS

	setupAdditionalSettings(settingType) {
		if (this.activeSetup === null || this.activeSetup === this.SETUP_TYPES.INACTIVE) {
			this.activeSetup = this.SETUP_TYPES.DESC;
		} else if (this.activeSetup === this.SETUP_TYPES.DESC) {
			this.activeSetup = this.SETUP_TYPES.ASC;
		} else {
			this.activeSetup = this.SETUP_TYPES.INACTIVE;
		}

		const index = this.additionalSettings.findIndex((setting) => {
			return this.typesForSorting.indexOf(setting.type) !== -1;
		});

		if (index !== -1) {
			this.additionalSettings[index].type = settingType;
			this.additionalSettings[index].setup = this.activeSetup;
		} else {
			this.additionalSettings.push({ type: settingType, setup: this.activeSetup });
		}

		if (this.partCtrl) {
			this.updateAdditionalSetting(settingType, this.activeSetup);
		}
	}

	setupMatrixAdditionalSettings(columnType, index) {
		if (this.activeSetup === null || this.activeSetup === this.SETUP_TYPES.INACTIVE) {
			this.activeSetup = this.SETUP_TYPES.DESC;
		} else if (this.activeSetup === this.SETUP_TYPES.DESC) {
			this.activeSetup = this.SETUP_TYPES.ASC;
		} else {
			this.activeSetup = this.SETUP_TYPES.INACTIVE;
		}

		const columnIndex = this.additionalSettings.findIndex((setting) => {
			return this.typesForSorting.indexOf(setting.type) !== -1;
		});

		if (columnIndex !== -1) {
			this.additionalSettings[columnIndex].type =
				this.reportHelper.additionalSettingsTypes.trendSortedColumn;
			this.additionalSettings[columnIndex].setup = index;
		} else {
			this.additionalSettings.push({
				type: this.reportHelper.additionalSettingsTypes.trendSortedColumn,
				setup: index,
			});
		}

		const sortedIndex = this.additionalSettings.findIndex((setting) => {
			return setting.type === this.reportHelper.additionalSettingsTypes.trendSortedType;
		});

		if (sortedIndex !== -1) {
			this.additionalSettings[sortedIndex].type = columnType;
			this.additionalSettings[sortedIndex].setup = this.activeSetup;
		} else {
			this.additionalSettings.push({ type: columnType, setup: this.activeSetup });
		}

		if (this.partCtrl) {
			this.updateMatrixAdditionalSetting(columnType, this.activeSetup, index);
		}
	}

	setupDateAdditionalSettings(columnType) {
		this.activeSetup =
			this.activeSetup === this.SETUP_TYPES.DESC ? this.SETUP_TYPES.ASC : this.SETUP_TYPES.DESC;

		const index = this.additionalSettings.findIndex((setting) => {
			return this.typesForSorting.indexOf(setting.type) !== -1;
		});

		if (index !== -1) {
			this.additionalSettings[index].type = columnType;
			this.additionalSettings[index].setup = this.activeSetup;
		} else {
			this.additionalSettings.push({ type: columnType, setup: this.activeSetup });
		}

		if (this.partCtrl) {
			this.updateAdditionalSetting(columnType, this.activeSetup);
		}
	}

	setActiveSorting(type) {
		this.activeSortingColumn = type;
	}

	comparingFunction(setup) {
		if (setup === this.SETUP_TYPES.DESC) {
			return (a, b) => {
				return b.score - a.score;
			};
		} else if (setup === this.SETUP_TYPES.ASC) {
			return (a, b) => {
				return a.score - b.score;
			};
		}
	}

	getPercentSpan(value) {
		return value + ' %';
	}

	onSeriesModeChange($event) {
		this.handleChartPropertyChange(
			this.reportHelper.additionalSettingsTypes.trendChartSeries,
			$event.item.type
		);
	}

	onFormatModeChange($event) {
		this.handleChartPropertyChange(
			this.reportHelper.additionalSettingsTypes.trendChartFormat,
			$event.item.type
		);
	}

	handleChartPropertyChange(type, setup) {
		const index = this.additionalSettings.findIndex((setting) => {
			return setting.type === type;
		});

		if (index !== -1) {
			this.additionalSettings[index].type = type;
			this.additionalSettings[index].setup = setup;
		} else {
			this.additionalSettings.push({ type, setup });
		}

		if (this.partCtrl) {
			this.updateAdditionalSetting(type, setup);
		}
	}

	// report methods

	addToReport(trendData, filterId, successCallback) {
		this.trendsService.openAddTrendToReportModal({
			trendData,
			additionalSettings: this.additionalSettings,
			filterId,
			successCallback,
		});
	}

	initForPart() {
		this.loading = true;
		this.additionalSettings = this.partCtrl.part.additionalSettings;
		this.reportBuilderCtrl.setTrendReference(this);
	}

	updateAdditionalSetting(type, value) {
		let chapterId = this.partCtrl.chapterCtrl.chapter.id;
		let partId = this.partCtrl.id;

		let callback;

		if (this.typesForSorting.indexOf(type) !== -1) {
			callback = (setting) => this.typesForSorting.indexOf(setting.type) !== -1;
		} else {
			callback = (setting) => setting.type === type;
		}

		const setting = this.additionalSettings.find(callback);

		const data = {
			type,
			setup: value,
		};

		if (!setting.id) {
			this.reportBuilderCtrl.reportsService
				.addAdditionalSettingsPromise(null, chapterId, partId, data)
				.then((data) => {
					setting.id = data.id;
				});
		} else {
			this.reportBuilderCtrl.reportsService.updateAdditionalSettingsPromise(
				null,
				chapterId,
				partId,
				setting.id,
				data
			);
		}
	}

	updateMatrixAdditionalSetting(type, activeSetup, index) {
		let chapterId = this.partCtrl.chapterCtrl.chapter.id;
		let partId = this.partCtrl.id;
		let settings = this.partCtrl.part.additionalSettings;

		if (settings.length) {
			settings.forEach((setting) => {
				if (setting.type === this.reportHelper.additionalSettingsTypes.trendSortedColumn) {
					this.indexId = setting.id;
				} else {
					this.columnId = setting.id;
				}
			});
		}

		let columnData = {
			type,
			setup: activeSetup,
		};

		let indexData = {
			type: this.reportHelper.additionalSettingsTypes.trendSortedColumn,
			setup: index,
		};

		if (!this.indexId) {
			this.reportBuilderCtrl.reportsService
				.addAdditionalSettingsPromise(null, chapterId, partId, indexData)
				.then((response) => {
					this.indexId = response.id;
				});
		} else {
			this.reportBuilderCtrl.reportsService.updateAdditionalSettingsPromise(
				null,
				chapterId,
				partId,
				this.indexId,
				indexData
			);
		}

		if (!this.columnId) {
			this.reportBuilderCtrl.reportsService
				.addAdditionalSettingsPromise(null, chapterId, partId, columnData)
				.then((response) => {
					this.columnId = response.id;
				});
		} else {
			this.reportBuilderCtrl.reportsService.updateAdditionalSettingsPromise(
				null,
				chapterId,
				partId,
				this.columnId,
				columnData
			);
		}
	}

	isTableWithChart() {
		return (
			!this.partCtrl ||
			(this.partCtrl &&
				this.partCtrl.part.type === this.reportHelper.reportPartTypes.TREND_TABLE_CHART)
		);
	}

	isOnlyTable() {
		return (
			this.partCtrl && this.partCtrl.part.type === this.reportHelper.reportPartTypes.TREND_TABLE
		);
	}

	isOnlyChart() {
		return (
			(this.partCtrl &&
				this.partCtrl.part.type === this.reportHelper.reportPartTypes.TREND_CHART) ||
			(this.trendResult &&
				this.trendResult.answers &&
				this.trendResult.answers.every((row) => row.length === 0))
		);
	}

	isReady() {
		return !!this.sortedTrendResult;
	}

	isNPS() {
		if (this.trendResult) {
			return this.trendResult.sets[1].questionType === this.questionHelper.getTypes().nps;
		}
	}

	isRating() {
		if (this.trendResult) {
			return this.trendResult.sets[1].questionType === this.questionHelper.getTypes().rating;
		}
	}

	isSingleMatrix() {
		if (this.trendResult) {
			return this.trendResult.sets[1].questionType === this.questionHelper.getTypes().matrixSingle;
		}
	}

	isSingleChoice() {
		if (this.trendResult) {
			return this.trendResult.sets[1].questionType === this.questionHelper.getTypes().single;
		}
	}

	isTags() {
		if (this.trendResult) {
			return this.trendResult.sets[1].type === 3;
		}
	}

	hasManyRows() {
		if (this.trendResult) {
			return !this.isNPS() && !this.isSingleChoice() && this.trendResult.answers !== null;
		}
	}

	themeUpdate(newTheme) {
		this.chartCtrl.updateThemeColor(newTheme);
		this.chartCtrl.drawChart(this.trendResult);
	}
}

export const TrendResultComponent = {
	template: tpl,
	controller: TrendResultController,
	require: {
		partCtrl: '?^reportPart',
		reportBuilderCtrl: '?^reportBuilder',
	},
	bindings: {
		trendResult: '<',
	},
};
