function colorFilter() {
	return function (item) {
		let result = item;

		if (item && item.length && item[0]) {
			let rgbRegex = /^rgb(a)?\(/i;

			if (item[0] !== '#' && !rgbRegex.test(item)) {
				result = '#' + item;
			}
		}

		return result;
	};
}

export { colorFilter };
