export const AclFeature = {
	surveyTest: 'SurveyTestFeature',
	surveyWidget: 'SurveyWidgetFeature',
	surveyPageLogicByQuestions: 'SurveyPageDisplayRulesFeature',
	surveyQuotas: 'SurveyQuotasFeature',
	surveyEmailAfterCompletion: 'SurveyEmailAfterCompletionFeature',
	accountSmtp: 'AccountSmtpFeature',
	accountExternalDomain: 'AccountExternalDomainFeature',
	accountApiAccess: 'ApiFeature',
	accountWebhooksAccess: 'AccountWebhooksAccessFeature',
	accountWhitelabel: 'AccountWhitelabelFeature',
	accountExternalScripts: 'AccountNoExternalScriptsFeature',
	integrationSalesManago: 'SalesManagoIntegrationFeature',
	integrationFocusTelecom: 'FocusTelecomIntegrationFeature',
	integrationFreshmail: 'FreshMailIntegrationFeature',
	integrationGA: 'GoogleAnalyticsIntegrationFeature',
	moduleAlerts: 'ModuleAlertsFeature',
	moduleAlertsWebhooks: 'ModuleAlertsWebhooksFeature',
	moduleAlertsFilter: 'ModuleAlertsFilterFeature',
	moduleCyclicReports: 'ModuleCyclicReportsFeature',
	moduleReportsCreator: 'ReportCreatorFeature',
	moduleCrossTables: 'ModuleCrossTablesFeature',
	moduleRankings: 'ModuleRankingFeature',
	moduleTrends: 'ModuleTrendsFeature',
	moduleVerbatims: 'ModuleVerbatimsFeature',
	moduleContacts: 'AccountSupportPhoneContactFeature',
	moduleCloseTheLoop: 'AccountSupportChatContactFeature',
	moduleMailings: 'ModuleMailingsFeature',
	reportPPTX: 'ReportPptxFeature',
	reportDOCX: 'ReportDocxFeature',
	reportPDF: 'ReportPdfFeature',
	reportHTML: 'ReportHtmlFeature',
	additionalDomains: 'AccountExternalDomainFeature',
	reportLIVE: 'ReportLiveFeature',
	moduleSurveySharing: 'ModuleSurveySharingFeature',
	moduleAlertsSlack: 'ModuleAlertsSlackFeature',
	moduleDistributionOffline: 'ModuleDistributionOfflineFeature',
	accountCustomCss: 'AccountCustomCssFeature',
	modulePartialShipping: 'ModulePartialShippingFeature',
	surveyAnswersCompletionsLimits: 'SurveyAnswersCompletionsLimitsFeature',
	moduleAlertsReportsNoTemplate: 'ModuleAlertsReportsNoTemplateFeature',
	accountSupportPhoneContact: 'AccountSupportPhoneContactFeature',
	accountZapierAccess: 'AccountZapierAccessFeature',
	reportCSV: 'ReportCsvFeature',
	reportXLSX: 'ReportXlsxFeature',
	reportSPSS: 'ReportSpssFeature',
	reportRawCSV: 'ReportRawCsvFeature',
	reportRawXLSX: 'ReportRawXlsxFeature',
	accountNoAds: 'AccountNoAdsFeature',
	accountSupportChatContact: 'AccountSupportChatContactFeature',
	accountSupportEmailContact: 'AccountSupportMailContactFeature',
	moduleCyclicDistribution: 'ModuleCyclicDistributionFeature',
	moduleFiltering: 'ModuleFilteringFeature',
	surveyAdvancedQuestions: 'SurveyAdvancedQuestionsFeature',
	surveyTheme: 'SurveyThemeFeature',
};
