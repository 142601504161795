import angular from 'angular';

const trustAsHtml = ($sce, $rootScope, config) => {
	'ngInject';

	const purgeSrc = (html, tag) => {
		const dom = angular.element(html);
		const elements = dom.find(tag);

		elements.each((index, element) => {
			element = angular.element(element);

			const src = element.attr('src');

			if (!isSecureDomain(src)) {
				element.attr('data-url', src);
				element.attr('src', '#');
			}
		});

		const initialValue = '';

		const reducer = (accumulator, item) => {
			if (item.nodeName === '#text') {
				accumulator += item.textContent;
			} else {
				accumulator += item.outerHTML;
			}

			return accumulator;
		};

		return dom.toArray().reduce(reducer, initialValue);
	};

	const isSecureDomain = (src) => {
		const secureDomains = config.SECURE_DOMAINS || [];
		const host = new URL(src).hostname;

		return secureDomains.split(',').findIndex((domain) => domain === host) !== -1;
	};

	return (value) => {
		const external = $rootScope.userInfo.external;

		if (!value) {
			return value;
		}

		if (external && value.indexOf('iframe') !== -1) {
			value = purgeSrc(value, 'iframe');
		}

		if (external && value.indexOf('img') !== -1) {
			value = purgeSrc(value, 'img');
		}

		return $sce.trustAsHtml(value);
	};
};

export { trustAsHtml };
