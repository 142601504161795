import angular from 'angular';

export class SwitcherController {
	constructor(EventEmitter) {
		'ngInject';

		this.EventEmitter = EventEmitter;
	}

	onClick(item) {
		this.model = item;

		this.onSelect(
			this.EventEmitter({
				item,
			})
		);
	}

	isSelected(item) {
		return angular.equals(this.model, item);
	}
}
