import angular from 'angular';

import template from './VerbatimElementQuestions.html';
import { VerbatimParametersEvents } from '../verbatimParameters/events.verbatimParameters.js';
import { VerbatimParametersType } from '../../services/verbatims/type.verbatimParameters';

const Entities = require('html-entities').AllHtmlEntities;

class VerbatimElementQuestionsController {
	constructor($scope, $rootScope, $timeout, $interpolate, questionHelper) {
		'ngInject';

		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$interpolate = $interpolate;
		this.entities = new Entities();

		this.typesOfQuestions = questionHelper.getTypes();
		this.sheetQuestions = null; //bindings - object

		this.questions = [];
		this.chosenQuestions = [];
		this.mergedQuestions = [];

		this.loadQuestions();
	}

	loadQuestions() {
		let observer1 = this.$rootScope.$on(VerbatimParametersEvents.loaded, (event, data) => {
			this.questions = data.parameters[VerbatimParametersType.questionsList];
			this.chosenQuestions = data.chosenParameters[VerbatimParametersType.questionsList];
			this.prepareMergedQuestion();
		});

		let observer2 = this.$rootScope.$on(VerbatimParametersEvents.changed, (event, data) => {
			this.chosenQuestions = data.chosenParameters[VerbatimParametersType.questionsList];
		});

		this.$scope.$on('$destroy', observer1);
		this.$scope.$on('$destroy', observer2);
	}

	prepareMergedQuestion() {
		//if you are reading this, God should bless you
		let questionsData = this.questions;
		let sheetAnswers = this.sheetQuestions;
		let chosenQuestions = this.chosenQuestions;
		let mergedQuestions = [];

		sheetAnswers.forEach((questionData) => {
			let elements = questionsData.filter(
				(question) => Number(question.id) === Number(questionData.id)
			); //we find here elements (question or questions rows)

			if (elements.length === 1) {
				//normal question (not popierdolone like matrix)
				let element = angular.copy(elements[0]);
				element.sheetAnswers = questionData.answers;
				element.sheetOthers = questionData.others;
				mergedQuestions.push(element);
			} else if (elements.length > 1) {
				//is matrix or UFO
				elements.forEach((question) => {
					let element = angular.copy(question);
					let type = Number(element.type);

					//we have to check that we choose this matrix to show
					if (
						chosenQuestions.indexOf(this.getKeyWithRowIndex(question.id, question.rowIndex)) !== -1
					) {
						let others = questionData.others;

						if (type === this.typesOfQuestions.form) {
							if (others && others[question.rowIndex - 1]) {
								element.sheetAnswers = [others[question.rowIndex - 1]];
							}
						} else {
							element.sheetAnswers = questionData.answers[question.rowIndex];

							if (others) {
								element.sheetOthers = others;
							}
						}

						mergedQuestions.push(element);
					}
				});
			} else {
				// :dance2: :party:
			}
		});

		mergedQuestions.forEach((question) => {
			if (angular.isArray(question.sheetAnswers)) {
				question.sheetAnswers = question.sheetAnswers.map((key, i) =>
					this.getTextOfAnswer(question, i)
				);
			} else if (angular.isObject(question.sheetAnswers)) {
				let sheetAnswers = [];
				let answer = '';
				Object.keys(question.sheetAnswers).forEach((key) => {
					answer = this.getTextOfAnswer(question, Number(key));

					if (answer !== '') {
						sheetAnswers.push(answer);
					}
				});
				question.sheetAnswers = sheetAnswers;
			} else if (angular.isString(question.sheetAnswers)) {
				question.sheetAnswers = [this.getTextOfAnswer(question, Number(question.sheetAnswers))];
			}
		});

		this.mergedQuestions = mergedQuestions;
	}

	getKeyWithRowIndex(questionId, rowIndex = false) {
		if (rowIndex) {
			return `${questionId}_${rowIndex}`;
		}

		return questionId;
	}

	sprintfAnswerWithOther(answer, other) {
		let text = answer;
		if (other) {
			text += ' (' + other + ')';
		}
		return text;
	}

	getClassicQuestionAnswer(question, key) {
		//{"id":"4952687","title":"Jednokrotnego z pogłębienieniem","number":"13","type":"0","answers":[{"rowIndex":1,"title":"Odpowiedź 1"},{"rowIndex":2,"title":"Odpowiedź 2"}],"active":1,"sheetAnswers":{"2":"1"},"sheetOthers":[{"row":"2","col":null,"answer":"Pobłębienie jednokrotnego"}]}
		//{"id":"4952689","title":"Wielokrotnego z pogłębieniem","number":"14","type":"6","answers":[{"rowIndex":1,"title":"Odp1"},{"rowIndex":2,"title":"Odp2"}],"active":true,"sheetAnswers":{"1":"1","2":"1"},"sheetOthers":[{"row":"2","col":null,"answer":"Pobłębienie wielokrotnego"}]}
		let answer = question.answers.find((answer) => Number(answer.rowIndex) === key);

		let other = null;
		if (question.sheetOthers) {
			other = question.sheetOthers.find((answer) => Number(answer.row) === key);
		}

		answer = answer ? answer.title : '';
		other = other ? other.answer : '';

		return this.sprintfAnswerWithOther(answer, other);
	}

	getNpsQuestionAnswer(question, key) {
		//{"id":4952693,"title":"Jak bardzo prawdopodobne jest, że polecisz nas znajomemu? + pobłębienie","number":16,"rowIndex":null,"totalRows":null,"type":16,"active":true,"sheetAnswers":{"3":"1"},"sheetOthers":[{"row":"3","col":null,"answer":"Uzasadnij NPSa :)"}]}
		let answer = key;

		if (isNaN(key) || key === 'isNpsWith0') {
			return '';
		}

		let other = null;
		if (question.sheetOthers) {
			other = question.sheetOthers.find((answer) => Number(answer.row) === key);
		}

		other = other ? other.answer : '';

		return this.sprintfAnswerWithOther(answer, other);
	}

	getOpenQuestionAnswer(question, key) {
		//{"id":4952239,"title":"Pytanie otwarte","number":3,"rowIndex":null,"totalRows":null,"type":2,"active":1,"sheetAnswers":[{"title":"Otwarte pole 2","count":"1"},{"title":"Otwarte pole 1","count":"1"}]}

		let answer = question.sheetAnswers[key];
		answer = answer ? answer.title : '';

		return answer;
	}

	getDateQuestionAnswer(question, key) {
		let answer = this.getOpenQuestionAnswer(question, key);

		if (!isNaN(answer)) {
			let exp = this.$interpolate("{{ dateParameter | date: 'dd/MM/yyyy'}}");
			return exp({ dateParameter: answer * 1000 });
		}

		return answer;
	}

	getFormQuestionAnswer(question, key) {
		//{"id":"4952243","title":"Formularz - 1","number":"5","rowIndex":1,"totalRows":"3","columns":[],"type":"4","active":1,"sheetAnswers":[{"row":"1","col":null,"answer":"Odpowiedź form 1"}]}

		let answer = question.sheetAnswers[key];
		return answer ? answer.answer : '';
	}

	getRankingQuestionAnswer(question, key) {
		//{"id":"4952701","title":"Ranking","number":"20","type":"5","answers":[{"rowIndex":1,"title":"Odp 1"},{"rowIndex":2,"title":"Odp 2"},{"rowIndex":3,"title":"Odp 3"},{"rowIndex":4,"title":"Odp 4"}],"active":1,"sheetAnswers":{"1":"2.00","2":"1.00","3":"3.00","4":"4.00"}}

		let answer = '';
		if (question.sheetAnswers) {
			let position = parseInt(question.sheetAnswers[key], 10);
			answer = question.answers[position - 1];
		}

		return answer ? answer.title : '';
	}

	getPointsQuestionAnswer(question, key) {
		//{"id":"4952703","title":"Przydzielanie punktów","number":"21","type":"3","answers":[{"rowIndex":1,"title":"Waga 1"},{"rowIndex":2,"title":"Waga 2"},{"rowIndex":3,"title":"Waga 3"}],"active":true,"sheetAnswers":{"1":{"sum":"10","avg":"10.00"},"2":{"sum":"80","avg":"80.00"},"3":{"sum":"10","avg":"10.00"}}}

		let answer = '';
		let title = question.answers.find((answer) => Number(answer.rowIndex) === key);

		if (question.sheetAnswers[key]) {
			answer = this.sprintfAnswerWithOther(title.title, question.sheetAnswers[key].sum);
		}

		return answer ? answer : '';
	}

	getSliderQuestionAnswer(question, key) {
		//[{"id":"4952695","title":"Suwak ze skalami - 1","number":"17","rowIndex":1,"totalRows":"1","answers":["lewa","prawa"],"type":"8","active":true,"sheetAnswers":2.00}]
		let answer = '';
		let position = '';

		if (Number(question.rowIndex) === key) {
			answer = question.answers[0] + ' - ' + question.answers[1];

			if (typeof question.sheetAnswers === 'object') {
				//only one row
				position = parseInt(question.sheetAnswers[key], 10);
			} else {
				position = parseInt(question.sheetAnswers, 10);
			}

			answer = this.sprintfAnswerWithOther(answer, position);
		}

		return answer ? answer : '';
	}

	getRatingQuestionAnswer(question, key) {
		//[{"id":"4952249","title":"Ocena zwykła - Ocena zwykła","number":"8","rowIndex":1,"totalRows":"1","columns":["1","2","3","4","5"],"type":"20","active":true,"sheetAnswers":{"1":[{"col":"3","count":"1"}]}}]
		//[{"id":"4952251","title":"Ocena macierzowa - 1","number":"9","rowIndex":1,"totalRows":"2","columns":["1","2","3","4","5"],"type":"20","active":true,"sheetAnswers":[{"col":"3","count":"1"}]}]

		let answer = '';
		let totalRows = Number(question.totalRows);

		if (totalRows === 1) {
			//single rating
			if (question.sheetAnswers[key]) {
				answer = question.sheetAnswers[key][0];
			}
		} else if (question.sheetAnswers[key]) {
			answer = question.sheetAnswers[key];
		}

		// add text from nested answer if answer exists
		if (answer && question.sheetOthers) {
			const filteredNestedAnswer = question.sheetOthers.find(
				(oth) => oth.row === String(question.rowIndex)
			);

			if (!filteredNestedAnswer) {
				return answer.col;
			}

			return this.sprintfAnswerWithOther(answer.col, filteredNestedAnswer.answer);
		}

		return answer ? answer.col : '';
	}

	getMatrixQuestionAnswer(question, key) {
		//{"id":"4952709","title":"Macierz z pogłębieniem - Wiersz 1","number":"24","rowIndex":1,"totalRows":"2","columns":["Kolumna 1","Kolumna 2"],"type":"1","active":true,"sheetAnswers":[{"row":"1","col":"1","answer":"Pobłębienie macierzy 1"}],"sheetOthers":[{"row":"1","col":"1","answer":"Pobłębienie macierzy 1"},{"row":"1","col":"1","answer":"Inne - wiersz 1"},{"row":"2","col":"2","answer":"Pobłębienie macierzy 2"}]}
		//{"id":"4952253","title":"Macierz zwykła - <p>Wiersz 1 😃</p>","number":"10","rowIndex":1,"totalRows":"2","columns":["<p>Kolumna 1 😃</p>","<p>Kolumna 2 😃</p>"],"type":"1","active":1,"sheetAnswers":[{"col":"2","count":"1"}]}
		//{"id":"4952255","title":"Macierz wielokrotna - Wiersz 1","number":"11","rowIndex":1,"totalRows":"3","columns":["Kolumna 1","Kolumna 2"],"type":"7","active":true,"sheetAnswers":[{"col":"1","count":"1"},{"col":"2","count":"1"}]}

		let answer = '';
		let other = '';

		if (question.sheetAnswers[key].col) {
			answer = question.sheetAnswers[key].col;

			if (question.sheetOthers) {
				let others = question.sheetOthers.filter(
					(otherAns) =>
						Number(otherAns.row) === Number(question.rowIndex) &&
						Number(otherAns.col) === Number(answer)
				);

				if (others.length) {
					other = others.map((answer, i, arr) => (i === 1 ? ' ' : '') + answer.answer); //additional space if other and explanation
				}
			}

			answer = question.columns[answer - 1];
			answer = this.sprintfAnswerWithOther(answer, other);
		}

		return answer ? answer : '';
	}

	getMatrixOpenQuestionAnswer(question, key) {
		//{"id":"4952691","title":"Macierz otwarta - Wiersz 1","number":"15","rowIndex":1,"totalRows":"2","columns":["Kolumna 1","Kolumna 2"],"type":"17","active":true,"sheetAnswers":[{"row":"1","col":"1","answer":"1-1"}]}

		let answer = '';
		if (question.sheetOthers) {
			answer = question.sheetOthers.find(
				(answer) =>
					Number(answer.row) === Number(question.rowIndex) && Number(answer.col) === key + 1
			);

			if (answer) {
				answer = question.columns[Number(answer.col) - 1] + ': ' + answer.answer;
			}
		}

		return answer ? answer : '';
	}

	getTextOfAnswer(question, key) {
		let answer = null;
		let type = Number(question.type);

		key = Number(key);

		switch (type) {
			case this.typesOfQuestions.single:
			case this.typesOfQuestions.multiple:
			case this.typesOfQuestions.dropdown:
				return this.getClassicQuestionAnswer(question, key);
			case this.typesOfQuestions.nps:
				return this.getNpsQuestionAnswer(question, key);
			case this.typesOfQuestions.text:
			case this.typesOfQuestions.email:
			case this.typesOfQuestions.number:
			case this.typesOfQuestions.attachment:
				return this.getOpenQuestionAnswer(question, key);
			case this.typesOfQuestions.date:
				return this.getDateQuestionAnswer(question, key);
			case this.typesOfQuestions.form:
				return this.getFormQuestionAnswer(question, key);
			case this.typesOfQuestions.ranking:
				return this.getRankingQuestionAnswer(question, key);
			case this.typesOfQuestions.points:
				return this.getPointsQuestionAnswer(question, key);
			case this.typesOfQuestions.slider:
				return this.getSliderQuestionAnswer(question, key);
			case this.typesOfQuestions.rating:
				return this.getRatingQuestionAnswer(question, key);
			case this.typesOfQuestions.matrixSingle:
			case this.typesOfQuestions.matrixMulti:
			case this.typesOfQuestions.dragAndDrop:
				return this.getMatrixQuestionAnswer(question, key);
			case this.typesOfQuestions.matrixOpen:
				return this.getMatrixOpenQuestionAnswer(question, key);

			default:
				return key;
		}

		// if (question.answers) {
		//     answer = question.answers.find((answer) => Number(answer.rowIndex) === key);
		//     return answer ? answer.title : '';
		// }
		// if (question.columns && question.sheetAnswers[key] && question.sheetAnswers[key].col) {
		//     answer = question.columns[question.sheetAnswers[key].col - 1];
		//     if (answer) {
		//         return answer;
		//     }
		// }
		//
		// if (question.sheetAnswers && question.sheetAnswers[key]) {
		//     let answer = question.sheetAnswers[key];
		//
		//     if (answer.title) {
		//         return answer.title; //open text questions - like description, email
		//     }
		//     if (answer && answer[0] && answer[0].col) {
		//         return answer[0].col; //single matrix
		//     }
		// }
	}
}

export const verbatimElementQuestions = {
	template,
	controller: VerbatimElementQuestionsController,
	bindings: {
		sheetQuestions: '<',
	},
};
