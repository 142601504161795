//https://stackoverflow.com/a/48549837/10576200

function limitString($filter) {
	'ngInject';

	return function (input, limit, begin, dots) {
		if (!input || input.length <= limit || !isNaN(input)) {
			return input;
		}

		begin = begin || 0;
		dots = dots || '...';

		return $filter('limitTo')(input, limit, begin) + dots;
	};
}

export { limitString };
