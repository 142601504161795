class AuthService {
	constructor(TokenRestangular, $q, $window) {
		'ngInject';

		this.tokenRestangular = TokenRestangular;
		this.$q = $q;
		this.$window = $window;

		this.tokenLifeTime = 360000;
	}

	getToken() {
		return this.$window.localStorage.getItem('token');
	}

	setToken(token, expirationDate) {
		this.$window.localStorage.setItem('token', token);
		this.$window.localStorage.setItem('token-expiration-date', expirationDate);
	}

	isAuthenticated() {
		const token = this.getToken();
		const tokenExpirationDate = this.$window.localStorage.getItem('token-expiration-date');

		return token !== null && tokenExpirationDate > new Date().getTime();
	}

	signIn() {
		const deferred = this.$q.defer();

		if (this.isAuthenticated()) {
			deferred.resolve();

			return deferred.promise;
		}

		this.tokenRestangular
			.one('generate')
			.get()
			.then((data) => {
				if (data.data) {
					this.setToken(data.data, new Date().getTime() + this.tokenLifeTime);
					deferred.resolve();
				}

				deferred.reject();
			}, deferred.reject);

		return deferred.promise;
	}

	signOut() {
		this.$window.localStorage.removeItem('token');
		this.$window.localStorage.removeItem('token-expiration-date');
	}
}

export { AuthService };
