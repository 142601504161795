class PostMessageService {
	constructor(config) {
		'ngInject';

		this.config = config;
		this.parentWindow = window.parent;
	}

	on(eventType, callback) {
		window.addEventListener('message', (event) => {
			if (!this.isSecureDomain(event.origin)) {
				console.warn('Domain not allowed: ', event.origin);
				return;
			}

			if (event.data.type === eventType) {
				callback(event.data);
			}
		});
	}

	emit(eventType, payload) {
		this.parentWindow.postMessage(
			{
				type: eventType,
				data: payload,
			},
			'*'
		);
	}

	isSecureDomain(src) {
		const secureDomains = this.config.SECURE_DOMAINS || [];
		const host = new URL(src).hostname;

		return secureDomains.split(',').findIndex((domain) => domain === host) !== -1;
	}
}

export { PostMessageService };
