const angular = require('angular');

import { FiltersListEmptyScreenComponent } from './app/analyze/FiltersListEmptyScreen';
let { filtersList } = require('./app/analyze/FiltersList');
let { filterCreator } = require('./app/analyze/FilterCreator');
let { filterCreatorAbstractQuestion } = require('./app/analyze/FilterCreatorAbstractQuestion');
let { filterCreatorSimpleQuestion } = require('./app/analyze/FilterCreatorSimpleQuestion');
let { filterCreatorNPSQuestion } = require('./app/analyze/FilterCreatorNPSQuestion');
let { filterCreatorDNDQuestion } = require('./app/analyze/FilterCreatorDNDQuestion');
let { filterCreatorRangeQuestion } = require('./app/analyze/FilterCreatorRangeQuestion');
let { filterCreatorSliderQuestion } = require('./app/analyze/FilterCreatorSliderQuestion');
let { filterCreatorTextQuestion } = require('./app/analyze/FilterCreatorTextQuestion');
let { filterCreatorNumberQuestion } = require('./app/analyze/FilterCreatorNumberQuestion');
let { filterCreatorFormQuestion } = require('./app/analyze/FilterCreatorFormQuestion');
let { filterCreatorPointsQuestion } = require('./app/analyze/FilterCreatorPointsQuestion');
let { filterCreatorDisabledRule } = require('./app/analyze/FilterCreatorDisabledRule');
let { filterCreatorTimeRule } = require('./app/analyze/FilterCreatorTimeRule');
let { filterCreatorRespondentRule } = require('./app/analyze/FilterCreatorRespondentRule');
let { filterCreatorTagRule } = require('./app/analyze/FilterCreatorTagRule');

let { abstractAddToReportModal } = require('./app/analyze/AbstractAddToReportModal');
let { addToReportModal } = require('./app/analyze/AddToReportModal');
let { addCrossTableToReportModal } = require('./app/analyze/AddCrossTableToReportModal');
let { addRankingToReportModal } = require('./app/analyze/AddRankingToReportModal');
let { addTrendToReportModal } = require('./app/analyze/AddTrendToReportModal');
let { showOtherAnswersModal } = require('./app/analyze/ShowOtherAnswersModal');
let { completionsWithQuestionModal } = require('./app/analyze/CompletionsWithQuestionModal');
let { extendedAnswersModal } = require('./app/analyze/ExtendedAnswersModal');
let { exportDataModal } = require('./app/analyze/ExportDataModal');
let { distributionChartModal } = require('./app/charts/DistributionChartModal');

const { intersectionsWrapper } = require('./app/analyze/IntersectionsWrapper');
const { analyzeWrapper } = require('./app/analyze/AnalyzeWrapper');
const { subMenuAnalyze } = require('./app/analyze/SubMenuAnalyze');

const FiltersService = require('./app/services/FiltersService').default;
const ModalService = require('./app/services/ModalService').default;
const RespondentService = require('./app/services/RespondentService').default;
const RankingsService = require('./app/services/RankingsService').default;
const TrendsService = require('./app/services/TrendsService').default;
const AggregatedModeService = require('./app/services/AggregatedModeService').default;
const VerbatimService = require('./app/services/verbatims/VerbatimService').default;
const VerbatimTagsService = require('./app/services/verbatims/VerbatimTagsService').default;
const VerbatimParametersService =
	require('./app/services/verbatims/VerbatimParametersService').default;
const VerbatimLogTagsService = require('./app/services/verbatims/VerbatimLogTagsService').default;

import { quickFilterComponent } from './app/quickFilter/quickFilterComponent/QuickFilterComponent';
import { quickFilterRevertComponent } from './app/quickFilter/quickFilterRevertComponent/QuickFilterRevertComponent';
import { chooseFilterComponent } from './app/analyzeFilters/chooseFilterComponent/ChooseFilterComponent';
import { pagerComponent } from './app/common/pagerComponent/PagerComponent';
import { filterComponent } from './app/analyzeFilters/filterComponent/FilterComponent';
import { FiltersLabelComponent } from './app/analyzeFilters/filtersLabel/FiltersLabel';
import { deleteCompletionsModal } from './app/aggregatedResults/DeleteCompletionsModal/DeleteCompletionsModal';

import { verbatimWrapper } from './app/verbatim/VerbatimWrapper';

import { dynamicHoverDirective } from './app/analyzeFilters/DynamicHoverDirective';
import { SentimentsModule } from './app/Sentiments/sentiments.module';
import { AclFeature } from './app/components/acl/acl.config';

require('ui-select');

angular
	.module('app.analyze', [
		'ngSanitize',
		'ui.select',
		'ui.bootstrap',
		'app.verbatims',
		'app.analyze-set',
		SentimentsModule,
	])
	.service('filtersService', FiltersService)
	.service('modalService', ModalService)
	.service('respondentService', RespondentService)
	.service('rankingsService', RankingsService)
	.service('trendsService', TrendsService)
	.service('aggregatedModeService', AggregatedModeService)
	.service('verbatimService', VerbatimService)
	.service('verbatimTagsService', VerbatimTagsService)
	.service('verbatimParametersService', VerbatimParametersService)
	.service('verbatimLogTagsService', VerbatimLogTagsService)
	.component('analyzeWrapper', analyzeWrapper)
	.component('subMenuAnalyze', subMenuAnalyze)
	.component('intersectionsWrapper', intersectionsWrapper)
	.component('filtersList', filtersList)
	.component('filtersListEmptyScreen', FiltersListEmptyScreenComponent)
	.component('filterCreator', filterCreator)
	.controller('filterCreatorAbstractQuestion', filterCreatorAbstractQuestion)
	.component('filterCreatorSimpleQuestion', filterCreatorSimpleQuestion)
	.component('filterCreatorNPSQuestion', filterCreatorNPSQuestion)
	.component('filterCreatorDNDQuestion', filterCreatorDNDQuestion)
	.component('filterCreatorRangeQuestion', filterCreatorRangeQuestion)
	.component('filterCreatorSliderQuestion', filterCreatorSliderQuestion)
	.component('filterCreatorTextQuestion', filterCreatorTextQuestion)
	.component('filterCreatorNumberQuestion', filterCreatorNumberQuestion)
	.component('filterCreatorFormQuestion', filterCreatorFormQuestion)
	.component('filterCreatorPointsQuestion', filterCreatorPointsQuestion)
	.component('filterCreatorDisabledRule', filterCreatorDisabledRule)
	.component('filterCreatorTimeRule', filterCreatorTimeRule)
	.component('filterCreatorRespondentRule', filterCreatorRespondentRule)
	.component('filterCreatorTagRule', filterCreatorTagRule)
	.controller('abstractAddToReportModal', abstractAddToReportModal)
	.component('addToReportModal', addToReportModal)
	.component('addCrossTableToReportModal', addCrossTableToReportModal)
	.component('addRankingToReportModal', addRankingToReportModal)
	.component('addTrendToReportModal', addTrendToReportModal)
	.component('showOtherAnswersModal', showOtherAnswersModal)
	.component('completionsWithQuestionModal', completionsWithQuestionModal)
	.component('distributionChartModal', distributionChartModal)
	.component('extendedAnswersModal', extendedAnswersModal)
	.component('exportDataModal', exportDataModal)
	.component('quickFilterComponent', quickFilterComponent)
	.component('quickFilterRevertComponent', quickFilterRevertComponent)
	.component('chooseFilterComponent', chooseFilterComponent)
	.component('deleteCompletionsModal', deleteCompletionsModal)
	.component('pagerComponent', pagerComponent)
	.component('filterComponent', filterComponent)
	.component('filtersLabel', FiltersLabelComponent)
	.directive('dynamicHover', dynamicHoverDirective)
	.config(function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
		'ngInject';
		// $locationProvider.html5Mode(false).hashPrefix('');
		$locationProvider.html5Mode(false);
		// $locationProvider.html5Mode(true);
		$urlRouterProvider.otherwise('/');

		$stateProvider
			.state('analyze', {
				url: '/analyze',
				component: 'analyzeWrapper',
				redirectTo: 'analyze.aggregatedResults',
			})
			.state('analyze.intersections', {
				url: '/intersections',
				component: 'intersectionsWrapper',
				data: {
					title: 'Filtry',
				},
			})
			.state('analyze.aggregatedResults', {
				url: '/aggregatedResults',
				component: 'aggregatedResultsWrapper',
				data: {
					title: 'Wyniki zbiorcze',
				},
				onExit(filtersService) {
					filtersService.interrupt();
				},
			})
			.state('analyze.crossTables', {
				url: '/crossTables',
				component: 'crossTablesWrapper',
				data: {
					title: 'Tabele Krzyżowe',
				},
			})
			.state('analyze.rankings', {
				url: '/rankings',
				component: 'rankingsWrapper',
				data: {
					title: 'Rankingi',
				},
			})
			.state('analyze.verbatim', {
				url: '/verbatim',
				component: 'verbatimWrapper',
				data: {
					title: 'Tagowanie odpowiedzi',
				},
				resolve: {
					sorting: (sortingManager) => {
						return sortingManager.getState();
					},
				},
			})
			.state('analyze.trends', {
				url: '/trends',
				component: 'trendsWrapper',
				data: {
					title: 'Trendy',
				},
			})
			.state('analyze.sentiments', {
				url: '/sentiments',
				component: 'sentimentsWrapper',
				data: {
					title: 'Analiza sentymentu',
				},
			});
	});
