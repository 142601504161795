const tpl = require('./SubMenuReports.html');
const jquery = require('jquery');

class SubMenuReportsController {
	constructor($log, $element, i18n, $scope, surveyProviderService, $state) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.surveyProviderService = surveyProviderService;
		this.readOnly = false;
		this.$state = $state;

		let subLevelClassNameRegex = /subMenu--level-[0-9]+/g;
		let subLevelElement;

		// let themeEditor = null;

		this.$postLink = function postLink() {
			subLevelElement = jquery($element).find('.subMenu');
		};

		this.activateLevel = function activateLevel(subLevel) {
			if (subLevel === 1) {
				subLevelElement.addClass('subMenu--level-1');
				// if (themeEditor) {
				// themeEditor.init();
				// }
			} else if (subLevel === 0) {
				subLevelElement.removeClass(function functionName(i, className) {
					let matchArray = className.match(subLevelClassNameRegex);

					if (matchArray && matchArray.length) {
						return matchArray.join(' ');
					}

					return undefined;
				});
			}
		};

		// this.addThemeEditorReference = function addThemeEditorReference(editor) {
		// themeEditor = editor;
		// };
	}

	$onInit() {
		this.reportsWrapper.addSubMenuReference(this);

		this.$scope.$watch(
			() => this.$state.$current.name,
			() => {
				const menuSubLevel = this.$state.$current.data.subLevel;
				this.activateLevel(menuSubLevel);
			}
		);
	}
}

export const subMenuReports = {
	template: tpl,
	controller: SubMenuReportsController,
	require: {
		reportsWrapper: '^reportsWrapper',
	},
};
