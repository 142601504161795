export const ChartColors = [
	'#BBE889',
	'#7ED6FE',
	'#C286FD',
	'#8779FF',
	'#FEC873',
	'#99DFB4',
	'#77A1FF',
	'#FF6CAB',
	'#C4C8A5',
	'#7FD88D',
	'#FFB343',
	'#FF7F7F',
	'#FFB4F1',
	'#76D2C9',
	'#FAE249',
	'#DEB794',
	'#99E285',
	'#FFA9A9',
	'#9B7A7A',
	'#FFD951',
	'#518EFF',
	'#B466BD',
	'#C23055',
	'#22AE88',
	'#8EACFF',
	'#FFD628',
	'#C860FF',
	'#B7E2DF',
	'#859DD1',
	'#FFD4A2',
];
