const tpl = require('./AddToReportModal.html');

let { AbstractAddToReportModal } = require('./AbstractAddToReportModal');

class AddToReportModalController extends AbstractAddToReportModal {
	constructor(
		reportsService,
		reportHelper,
		$scope,
		surveyProviderService,
		modalService,
		i18n,
		supportCRMService,
		$timeout,
		questionHelper
	) {
		'ngInject';

		super(
			reportsService,
			reportHelper,
			$scope,
			surveyProviderService,
			i18n,
			$timeout,
			modalService
		);

		let ctrl = this;

		this.questionHelper = questionHelper;
		this.supportCRMService = supportCRMService;

		this.questionControllers = [];
		this.onlyNewMode = false;
		this.showVisualisation = false;
		this.showTrend = false;

		this.partType = null;

		this.TYPES = null;
	}

	$onInit() {
		super.$onInit();

		this.getResolveData();
		this.customizeModal();
	}

	getResolveData() {
		this.questionCtrl = this.resolve.questionCtrl;
		this.questionControllers = this.resolve.questionControllers;
		this.addWholeSurvey = this.resolve.addWholeSurvey;
		this.filterId = this.resolve.filterId;
		this.onlyNewMode = this.resolve.onlyNew;
	}

	customizeModal() {
		if (this.questionCtrl && this.questionCtrl.questionData) {
			if (this.questionHelper.isNPS(this.questionCtrl.questionData)) {
				this.showTrend = true;
				this.TYPES = this.getTypes();
				this.showVisualisation = true;
			} else if (
				this.questionHelper.isMatrixSingle(this.questionCtrl.questionData) ||
				this.questionHelper.isMatrixMultiple(this.questionCtrl.questionData)
			) {
				this.TYPES = this.getTypes();
				this.showVisualisation = true;
			}
		}

		if (this.showTrend) {
			this.partType = String(this.types.TABLE_TREND);
		} else {
			this.partType = String(this.types.TABLE_CHART);
		}
	}

	add() {
		if (this.locked || this.saved) {
			return;
		}
		this.locked = true;

		let reportId;
		// let reportId = this.$scope.reportsSelect;
		let report = this.$scope.reportsSelect;
		reportId = report ? report.id : undefined;
		// let chapterIndex = this.$scope.chapterSelect;
		let chapterIndex = this.$scope.chapterSelect ? this.$scope.chapterSelect.position : undefined;
		let position = this.$scope.positionSelect;
		let newReportName = this.reportChooser === 'new' ? this.$scope.newReportName : '';
		let reportName = newReportName.length ? newReportName : report.title;
		let partType = this.partType;

		let self = this;
		if (this.addWholeSurvey) {
			let config = {
				newReportName,
				filterId: this.filterId,
				partType,
				reportId,
				newChapterName: this.newChapterName,
				chapterIndex,
				questionControllers: this.questionControllers,
			};

			this.reportsService.addWholeSurveyToReportPromise(config).then(
				(data) => {
					self.addSuccessCallback(data, config, false);
				},
				(errData) => {
					self.addErrorCallback();
				}
			);
		} else {
			let config = {
				reportId,
				chapterIndex,
				position,
				newReportName,
				partType,
				questionId: this.questionCtrl.getId(),
				filterId: this.filterId,
				newChapterName: this.newChapterName,
				additionalSettings: this.questionCtrl.additionalSettings,
			};

			this.reportsService.addToReportPromise(config).then(
				(data) => {
					self.addSuccessCallback(data, config);
					this.supportCRMService.emitReportPartAdded();
				},
				(errData) => {
					self.addErrorCallback();
				}
			);
		}
	}

	getTypes() {
		let ctrl = this;
		return [
			{
				partType: ctrl.showTrend ? String(ctrl.types.TABLE_TREND) : String(ctrl.types.TABLE_CHART),
				icon: ctrl.showTrend
					? '/images/reports/report-tabletrend-icon.svg'
					: '/images/reports/report-tablechart-icon.png',
				title: ctrl.showTrend
					? ctrl.i18n.__('Tabela i trend', 'reports')
					: ctrl.i18n.__('Tabela i wykres', 'reports'),
				id: 'typeTableChart',
			},
			{
				partType: ctrl.showTrend ? String(ctrl.types.TREND) : String(ctrl.types.CHART),
				icon: ctrl.showTrend
					? '/images/reports/report-trend-icon.svg'
					: '/images/reports/report-chart-icon.png',
				title: ctrl.showTrend
					? ctrl.i18n.__('Trend', 'reports')
					: ctrl.i18n.__('Wykres', 'reports'),
				id: 'typeChart',
			},
			{
				partType: String(ctrl.types.TABLE),
				icon: ctrl.showTrend
					? '/images/reports/report-table-notrend-icon.svg'
					: '/images/reports/report-table-icon.png',
				title: ctrl.i18n.__('Tabela', 'reports'),
				id: 'typeTable',
			},
		];
	}

	isButtonDisabled() {
		if (this.reportChooser === 'existing' && this.addWholeSurvey) {
			if (this.addNewChapter) {
				let chapterTitle = this.newChapterName.trim();

				return !(chapterTitle.length > 0);
			}

			return this.partsLocked;
		}

		return super.isButtonDisabled();
	}
}

export const addToReportModal = {
	template: tpl,
	controller: AddToReportModalController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
