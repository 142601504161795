const restangularApiConfig = (Restangular, langService) => {
	'ngInject';

	let apiUrl;
	const apiConfigurationKey = 'API_BASE_URL_' + langService.getLang().toUpperCase();

	if (process.env[apiConfigurationKey]) {
		apiUrl = process.env[apiConfigurationKey];
	}

	window.DATA_INFO = { api: apiUrl };
	Restangular.setBaseUrl(apiUrl);
};

export { restangularApiConfig };
