const tpl = require('./QuestionText.html');
const Entities = require('html-entities').AllHtmlEntities;
const $ = require('jquery');

class QuestionTextController {
	constructor(modalService, i18n, $scope, questionHelper, surveyProviderService) {
		'ngInject';

		this.questionHelper = questionHelper;
		this.surveyProviderService = surveyProviderService;
		this.modalService = modalService;
		this.i18n = i18n;
		this.$scope = $scope;

		this.mostPopular = {};
		this.ready = false;
		this.inReport = false;
		this.entities = new Entities();
	}

	getPopular() {
		this.mostPopular = this.questionHelper.getMostPopularAnswer(this.questionData);
	}

	$onInit() {
		this.inReport = !!this.reportPart;
		this.getPopular();
		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	isReady() {
		return this.ready;
	}

	htmlEntityDecode(text) {
		return this.entities.decode(text);
	}

	getQuickFilterData(answer) {
		return {
			questionData: this.questionData,
			answerTitle: answer.title,
			answers: this.htmlEntityDecode(answer.title),
		};
	}
}

export const questionText = {
	template: tpl,
	controller: QuestionTextController,
	require: {
		questionCtrl: '^aRQuestion',
		reportPart: '?^reportPart',
	},
	bindings: {
		questionData: '<',
	},
};
