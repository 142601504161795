const angular = require('angular');

import { ReportsListEmptyScreenComponent } from './app/reports/ReportsListEmptyScreen';
let { reportsWrapper } = require('./app/reports/ReportsWrapper');
let { reportsBuilderWrapper } = require('./app/reports/ReportsBuilderWrapper');
let { reportBuilder } = require('./app/reports/ReportBuilder');
let { reportThemeEditor } = require('./app/reports/ReportThemeEditor');
let { reportChapter } = require('./app/reports/ReportChapter');
let { reportPart } = require('./app/reports/ReportPart');
let { reportExport } = require('./app/reports/ReportExport');
let { reportsList } = require('./app/reports/ReportsList');
let { reportAttachment } = require('./app/reports/ReportAttachment');
let { newReportModal } = require('./app/reports/NewReportModal');
let { editReportNameModal } = require('./app/reports/EditReportNameModal');
let { fakeFileInput } = require('./app/utils/FakeFileInput');

let ReportsService = require('./app/services/ReportsService').default;
let ReportHelper = require('./app/services/ReportHelper').default;
let ReportBuilderHelper = require('./app/services/ReportBuilderHelper').default;
let ReportPartEditorService = require('./app/services/ReportPartEditorService').default;

import { ReportsLabelComponent } from './app/reports/ReportsLabel';

angular
	.module('app.reports', ['dndLists', 'ngSanitize'])
	.service('reportsService', ReportsService)
	.service('reportHelper', ReportHelper)
	.service('reportBuilderHelper', ReportBuilderHelper)
	.service('reportPartEditorService', ReportPartEditorService)
	.component('reportsWrapper', reportsWrapper)
	.component('reportsList', reportsList)
	.component('reportsListEmptyScreen', ReportsListEmptyScreenComponent)
	.component('reportsLabel', ReportsLabelComponent)
	.component('reportsBuilderWrapper', reportsBuilderWrapper)
	.component('reportBuilder', reportBuilder)
	.component('reportThemeEditor', reportThemeEditor)
	.component('reportChapter', reportChapter)
	.component('reportPart', reportPart)
	.component('reportExport', reportExport)
	.component('newReportModal', newReportModal)
	.component('editReportNameModal', editReportNameModal)
	.component('reportAttachment', reportAttachment)
	.component('fakeFileInput', fakeFileInput)
	.config(function routesConfig($stateProvider, $urlRouterProvider, $locationProvider) {
		'ngInject';
		// $locationProvider.html5Mode(false).hashPrefix('');
		$locationProvider.html5Mode(false);
		// $locationProvider.html5Mode(true);
		$urlRouterProvider.otherwise('/');

		$stateProvider
			.state('reports', {
				url: '/reports',
				component: 'reportsWrapper',
				redirectTo: 'reports.list',
				data: {
					title: 'Lista raportów',
					subLevel: 1,
				},
			})
			.state('reports.list', {
				url: '',
				component: 'reportsList',
				data: {
					title: 'Lista raportów',
					subLevel: 0,
				},
				onExit(reportsService) {
					reportsService.interrupt();
					return true;
				},
			})
			.state('reports.generate', {
				url: '/generate',
				views: {
					'': {
						component: 'reportsBuilderWrapper',
					},
					themeEditor: {
						component: 'reportThemeEditor',
					},
				},
				data: {
					title: 'Tworzenie raportu',
					subLevel: 1,
				},
				onExit($transition$, reportsService, modalService, reportBuilderHelper, i18n) {
					let res = true;
					let cond1 =
						reportsService.isReportDownloaded() &&
						!reportsService.isDataSynchronized() &&
						reportBuilderHelper.getBuilder() &&
						!reportBuilderHelper.getBuilder().isLoading &&
						!reportBuilderHelper.getThemeEditor().isLoading();
					let cond2 =
						!!reportBuilderHelper.getBuilder() &&
						!reportBuilderHelper.getBuilder().isLoading &&
						reportBuilderHelper.getBuilder().editionInProgress;

					if (cond1 || cond2) {
						// modal zwraca promise
						res = modalService.showConfirm({
							title: i18n.__('Jesteś pewien?', 'reports'),
							text: i18n.__(
								'Raport nie został zapisany. Czy na pewno chcesz przerwać edycję?',
								'reports'
							),
							confirmText: i18n.__('Wyjdź', 'reports'),
							cancelText: i18n.__('Kontynuuj edycję', 'reports'),
						});
					} else {
						reportsService.interrupt();
					}

					return res;
				},
			})
			.state('reports.generate.edit', {
				url: '/:id',
				component: 'reportsBuilderWrapper',
			})
			.state('reports.download', {
				url: '/download',
				redirectTo: 'download-center',
			});
	});
