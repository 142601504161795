import { AclFeature } from '../../components/acl/acl.config';

const featureGuard = ($transitions, aclManager, $state, $rootScope) => {
	'ngInject';

	$rootScope.features = AclFeature;
	$rootScope.isFeatureEnabled = (feature) => aclManager.isFeatureEnabled(feature);

	$transitions.onStart({}, (transition) => {
		const destinationRoute = transition.to();

		if (
			destinationRoute.requiredFeature &&
			!aclManager.isFeatureEnabled(destinationRoute.requiredFeature)
		) {
			parent.window.location.href = '/user';
		}

		return true;
	});
};

export { featureGuard };
