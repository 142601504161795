const restangularCounterInit = (Restangular, $rootScope) => {
	'ngInject';

	$rootScope.xhr = 0;

	Restangular.addRequestInterceptor((element) => {
		$rootScope.xhr++;

		return element;
	});

	Restangular.setErrorInterceptor(() => {
		$rootScope.xhr--;

		return true;
	});

	Restangular.addResponseInterceptor((data) => {
		$rootScope.xhr--;

		return data;
	});
};

export { restangularCounterInit };
