import angular from 'angular';

import { AlertsLayer } from './AlertsLayer/AlertsLayer';
import { Alert } from './Alert/Alert';

import AlertsService from './AlertsService';

angular
	.module('app.alerts', [])
	.component('alertsLayer', AlertsLayer)
	.component('alert', Alert)
	.service('alertsService', AlertsService);
