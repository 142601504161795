const tpl = require('./FilterCreatorDisabledRule.html');

let { FilterCreatorAbstractQuestionController } = require('./FilterCreatorAbstractQuestion');

class FilterCreatorDisabledRuleController extends FilterCreatorAbstractQuestionController {
	constructor($scope, surveyProviderService, reportHelper, i18n) {
		'ngInject';
		super($scope, surveyProviderService, reportHelper, i18n);
	}

	$onChanges(changes) {
		if (changes.question) {
			if (!changes.question.isFirstChange()) {
				this.onQuestionChange();
			}
		}
	}
}

export const filterCreatorDisabledRule = {
	template: tpl,
	controller: FilterCreatorDisabledRuleController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
