import angular from 'angular';

class SentimentService {
	constructor(
		surveyProviderService,
		sentimentDistributionStorage,
		sentimentTrendStorage,
		Observer
	) {
		'ngInject';

		this.localStorageKey = 'sentiment-model';

		this.surveyProviderService = surveyProviderService;
		this.sentimentDistributionStorage = sentimentDistributionStorage;
		this.sentimentTrendStorage = sentimentTrendStorage;
		this.observer = new Observer();

		this.model = null;
	}

	async getModel(surveyId) {
		if (this.model !== null) {
			this.observer.notify(this.isValidModel());
			return angular.copy(this.model);
		}

		const surveyData = await this.surveyProviderService.getSurveyPromise(surveyId);

		this.model = this.getModelFromLocalStorage(surveyId);

		if (this.model !== null) {
			this.observer.notify(this.isValidModel());
			return angular.copy(this.model);
		}

		const currentTimestamp = new Date().getTime();

		this.saveModel(
			{
				from: surveyData.firstLogTimestamp ? surveyData.firstLogTimestamp * 1000 : currentTimestamp,
				to: currentTimestamp,
				tags: [],
			},
			surveyId
		);

		this.observer.notify(this.isValidModel());
		return angular.copy(this.model);
	}

	getModelFromLocalStorage(surveyId) {
		return angular.fromJson(window.localStorage.getItem(`${this.localStorageKey}-${surveyId}`));
	}

	calculate(model, surveyId) {
		if (!angular.equals(this.model, model)) {
			this.clearModel();
			this.saveModel(angular.copy(model), surveyId);
		}

		this.sentimentDistributionStorage.fetch(model.from, model.to, model.tags);
		this.sentimentTrendStorage.fetch(model.from, model.to, model.tags);
	}

	saveModel(model, surveyId) {
		this.model = model;

		window.localStorage.setItem(`${this.localStorageKey}-${surveyId}`, angular.toJson(model));
	}

	clearModel(notify = false, localStorage = false) {
		this.sentimentDistributionStorage.clear(notify);
		this.sentimentTrendStorage.clear(notify);

		this.model = null;

		if (localStorage) {
			window.localStorage.removeItem(this.localStorageKey);
		}
	}

	isValidModel() {
		return this.model.from && this.model.to && this.model.tags.length;
	}

	addObserver(observer) {
		this.observer.addObserver(observer);
	}

	removeObserver(observer) {
		this.observer.removeObserver(observer);
	}
}

SentimentService.$inject = [
	'surveyProviderService',
	'sentimentDistributionStorage',
	'sentimentTrendStorage',
	'Observer',
];

export { SentimentService };
