import tpl from './MainWrapper.html';

class MainWrapperController {
	constructor(modalService, $scope, $rootScope) {
		'ngInject';

		this.modalService = modalService;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
	}

	$onInit() {
		let testIe1 = /internet explorer/i;
		let testIe2 = /trident/i;

		if (testIe1.test(navigator.userAgent) || testIe2.test(navigator.userAgent)) {
			document.body.className += ' ie';
		}
	}

	close(index) {
		this.modalService.closeAlert(index);
	}
}

export const mainWrapper = {
	template: tpl,
	controller: MainWrapperController,
	transclude: true,
	bindings: {
		userToken: '<',
	},
};
