import { AbstractStorage } from './AbstractStorage';

class SentimentDistributionStorage extends AbstractStorage {
	constructor(sentimentAPIService, Observer) {
		'ngInject';

		super(sentimentAPIService, Observer);

		// this.localStorageKey = 'sentiment-distribution';
	}

	async fetchFromApi(from, to, tags) {
		return await this.sentimentAPIService.getDistribution(from, to, tags);
	}
}

SentimentDistributionStorage.$inject = ['sentimentAPIService', 'Observer'];

export { SentimentDistributionStorage };
