const tpl = require('./AddToReportModal.html');
let { AbstractAddToReportModal } = require('./AbstractAddToReportModal');

class AddTrendToReportModal extends AbstractAddToReportModal {
	constructor(
		reportsService,
		reportHelper,
		$scope,
		surveyProviderService,
		modalService,
		i18n,
		$timeout
	) {
		'ngInject';
		super(
			reportsService,
			reportHelper,
			$scope,
			surveyProviderService,
			i18n,
			$timeout,
			modalService
		);

		let ctrl = this;

		this.partType = String(this.types.TREND_TABLE_CHART);
		this.showVisualisation = true;

		this.TYPES = [
			{
				partType: String(ctrl.types.TREND_TABLE_CHART),
				icon: '/images/reports/report-tablechart-icon.png',
				title: ctrl.i18n.__('Tabela i wykres', 'reports'),
				id: 'typeTableChart',
			},
			{
				partType: String(ctrl.types.TREND_CHART),
				icon: '/images/reports/report-chart-icon.png',
				title: ctrl.i18n.__('Wykres', 'reports'),
				id: 'typeChart',
			},
			{
				partType: String(ctrl.types.TREND_TABLE),
				icon: '/images/reports/report-table-icon.png',
				title: ctrl.i18n.__('Tabela', 'reports'),
				id: 'typeTable',
			},
		];
	}

	$onInit() {
		super.$onInit();
		this.getResolveData();
	}

	getResolveData() {
		this.trendData = this.resolve.trendData;
		this.additionalSettings = this.resolve.additionalSettings;
		this.filterId = this.resolve.filterId;
		this.successCallback = this.resolve.successCallback;
	}

	add() {
		if (this.locked || this.saved) {
			return;
		}
		this.locked = true;

		let self = this;
		let config = this.getBaseConfig();

		this.reportsService
			.addPartDataToReportPromise(config, (newPart) => {
				newPart.title = self.trendData.title;
				newPart.type = self.partType;
				newPart.text = '';
				newPart.filter = self.filterId;
				newPart.trend = self.trendData.id;
				newPart.additionalSettings = self.additionalSettings;

				return newPart;
			})
			.then(
				(reportData) => {
					self.addSuccessCallback(reportData, config);
					this.successCallback();
				},
				(errorData) => {
					self.addErrorCallback(errorData);
				}
			);
	}
}

export const addTrendToReportModal = {
	template: tpl,
	controller: AddTrendToReportModal,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
