const ngEscapeDirective = () => {
	return {
		restrict: 'A',
		link: (scope, element, attr) => {
			element.attr('tabindex', -1);
			element.on('keydown keypress keyup', (event) => {
				if (event.which === 27) {
					scope.$apply(() => {
						scope.$eval(attr.ngEscape);
					});

					event.preventDefault();
				}
			});
		},
	};
};

export { ngEscapeDirective };
