let tpl = require('./ReportsBuilderWrapper.html');

class ReportsBuilderWrapperController {
	constructor($scope, $stateParams, $state, reportsService, i18n) {
		'ngInject';

		this.i18n = i18n;
		this.editId = $stateParams.id === undefined ? null : $stateParams.id;
		this.reportsService = reportsService;

		this.currentReport = null;
		this.builder = null;

		$scope.$on('SurveyChanged', function onSurveyChanged() {
			parent.location.href = `/user#angular?r=/reports/list`;
		});
	}

	$onInit() {
		window.addEventListener('beforeunload', this.onBeforeUnload());
	}

	$onDestroy() {
		window.removeEventListener('beforeunload', this.onBeforeUnload());
	}

	getBuilder() {
		return this.builder;
	}

	setBuilder(report) {
		this.builder = report;
	}

	getCurrentReport() {
		return this.currentReport;
	}

	setCurrentReport(report) {
		this.currentReport = report;
	}

	onBeforeUnload() {
		const ctrl = this;

		return function (e) {
			if (
				(!ctrl.reportsService.isDataSynchronized() && ctrl.builder && !ctrl.builder.isLoading) ||
				(ctrl.builder && !ctrl.builder.isLoading && ctrl.builder.editionInProgress)
			) {
				e.returnValue = ctrl.i18n.__(
					'Raport nie został zapisany. Czy na pewno chcesz przerwać edycję?',
					'reports'
				);
				e.preventDefault();

				return e.returnValue;
			}
		};
	}
}

export const reportsBuilderWrapper = {
	template: tpl,
	controller: ReportsBuilderWrapperController,
	require: {
		reportsWrapper: '^reportsWrapper',
	},
};
