import tpl from './SurveyWrapper.html';
import '../../scss/survey.scss';

class SurveyWrapperController {
	constructor(
		surveyProviderService,
		$scope,
		$rootScope,
		i18n,
		questionHelper,
		alertsService,
		$timeout
	) {
		'ngInject';

		this.surveyProviderService = surveyProviderService;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.i18n = i18n;
		this.questionHelper = questionHelper;
		this.alertsService = alertsService;
		this.$timeout = $timeout;

		this.loading = true;
		this.empty = false;
		this.filterRefreshInProgress = false;
		this.surveyRefreshInProgress = false;

		this.pages = {};
		this.errorMessage = null;
		this.questions = [];
		this.questionCount = 0;
		this.controllers = [];
	}

	$onInit() {
		this.bindEvents();
		this.aggregatedResults.setSurveyWrapper(this);
	}

	$onDestroy() {
		this.controllers = [];
	}

	bindEvents() {
		this.$scope.$on('filterChanged', (event, data) => {
			if (data.refresh) {
				this.filterRefreshInProgress = true;
				this.refresh(false, true);
			}
		});

		this.$scope.$on('SurveyReady', () => {
			if (!this.filterRefreshInProgress) {
				this.surveyRefreshInProgress = true;
				this.refresh(true, false);
			} else {
				this.$scope.$watch(
					() => this.filterRefreshInProgress,
					(newVal, oldVal) => {
						if (oldVal && !newVal) {
							this.surveyRefreshInProgress = true;
							this.refresh(true, false);
						}
					}
				);
			}
		});
	}

	refresh(fromSurvey, fromFilter) {
		if (this.loading) {
			this.surveyProviderService.interruptQuestions();
		}

		this.pages = null;
		this.loading = true;

		this.questions = [];
		this.controllers = [];
		this.questionCount = 0;

		this.aggregatedModeChangePossibility = true;

		this.surveyProviderService.getCurrentSurveyPromise().then(
			(survey) => {
				// console.log('survey', survey);
				if (survey) {
					// refresh przy pierwszym otworzeniu ankiety lub po zmianie aktywnej ankiety

					if (survey.fillCount) {
						this.loadPagesAndResults(fromSurvey, fromFilter);
					} else {
						this.surveyProviderService.checkUnfinishedPromise().then((data) => {
							if (parseInt(data.count, 10)) {
								this.aggregatedModeChangePossibility = false;
								this.surveyProviderService.aggregatedModeService.changeMode(
									this.surveyProviderService.aggregatedModeService.aggregatedModes.all.mode
								);

								this.loadPagesAndResults(fromSurvey, fromFilter);
							} else {
								this.setEmptyResults(fromSurvey, fromFilter);
							}
						});
					}
				} else {
					this.setEmptyResults(fromSurvey, fromFilter);
				}
			},
			() => {
				// błąd

				if (fromFilter) {
					this.filerRefreshInProgress = false;
				}
				if (fromSurvey) {
					this.surveyRefreshInProgress = false;
				}
				this.empty = true;
				this.loading = false;
			}
		);
	}

	setEmptyResults(fromSurvey, fromFilter) {
		this.$timeout(() => {
			if (fromFilter) {
				this.filerRefreshInProgress = false;
			}
			if (fromSurvey) {
				this.surveyRefreshInProgress = false;
			}
			this.empty = true;
			this.loading = false;
		});
	}

	loadPagesAndResults(fromSurvey, fromFilter) {
		this.surveyProviderService.getPagesPromise(true).then(
			(pagesData) => {
				if (fromFilter) {
					this.filterRefreshInProgress = false;
					if (this.surveyRefreshInProgress) {
						return;
					}
				}

				if (fromSurvey) {
					this.surveyRefreshInProgress = false;
				}

				this.pages = pagesData;
				this.questionCount = 0;
				for (let i = 0; i < pagesData.length; i++) {
					if (pagesData[i].questionIds) {
						this.questionCount += pagesData[i].questionIds.length;
					}
				}

				this.loading = false;

				this.empty = this.questionCount === 0;
			},
			(errData) => {
				if (fromFilter) {
					this.filerRefreshInProgress = false;
				}
				if (fromSurvey) {
					this.surveyRefreshInProgress = false;
				}
				this.loading = false;
				if (parseInt(errData.status, 10) !== -1) {
					this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem', 'reports'));
				}
			}
		);
	}

	addQuestion(data) {
		this.questions.push(data);
	}

	addQuestionController(ctrl) {
		this.controllers.push(ctrl);
		if (this.controllers.length === this.questionCount) {
			for (var i = 0; i < this.controllers.length; i++) {
				this.controllers[i].update();
			}

			this.$timeout(() => {
				this.$scope.$apply();
			});
		}
	}

	isEmpty() {
		return this.empty;
	}

	isAggregatedModeChangePossibility() {
		return this.aggregatedModeChangePossibility;
	}

	isLoading() {
		return this.loading;
	}

	getQuestionIndex(question) {
		return this.questionHelper.getQuestionIndex(question, this.questions);
	}

	getQuestionControllers() {
		return this.controllers;
	}
}

export const surveyWrapper = {
	template: tpl,
	controller: SurveyWrapperController,
	transclude: true,
	require: {
		aggregatedResults: '^aggregatedResultsWrapper',
	},
	bindings: {
		viewType: '@',
		filterId: '<',
	},
};
