const tpl = require('./FilterCreatorTextQuestion.html');

let { FilterCreatorAbstractQuestionController } = require('./FilterCreatorAbstractQuestion');

class FilterCreatorTextQuestionController extends FilterCreatorAbstractQuestionController {
	constructor($scope, surveyProviderService, reportHelper, i18n, $element, filtersHelper) {
		'ngInject';

		super($scope, surveyProviderService, reportHelper, i18n);

		this.$element = $element;

		this.valueChangeTimeout = null;
		this.valueDisabled = false;

		this.selectedAnswers = [];
		this.answers = [];
		this.conditions = filtersHelper.getConditionsForOpen();

		this.taggingLabel = i18n.__('(nowa fraza)', 'reports');
	}

	$onInit() {
		super.$onInit();

		if (this.ruleData.condition === 4 || this.ruleData.condition === 5) {
			this.valueDisabled = true;
		}
	}

	$onDestroy() {
		super.$onDestroy();
		// musi być bo bez onDestroy przy niszczeniu komponentu wywoluje się onChanges
	}

	init() {
		super.init();
		this.initQuestions();
	}

	initQuestions() {
		if (this.ruleData.answers && this.ruleData.answers.length) {
			this.selectedAnswers = [];
			this.ruleData.answers.forEach((item) => {
				this.selectedAnswers.push(item);
				if (this.answers.indexOf(item) === -1) {
					this.answers.unshift(item);
				}
			});
		}
	}

	$onChanges(changes) {
		if (changes.ruleData && changes.ruleData.isFirstChange()) {
			this.init();

			this.validate();
		}

		if (changes.question) {
			if (!changes.question.isFirstChange()) {
				this.onQuestionChange();
				this.selectedAnswers = [];
			}

			if (this.question.answers && this.question.answers.length) {
				this.updateAnswers();
				this.initQuestions();
			}

			this.surveyProviderService.continouslyLoadAnswers(
				this.question,
				(data) => {
					this.updateAnswers();
				},
				null,
				null,
				true
			);

			this.$scope.condition = this.ruleData.condition;
		}
	}

	updateAnswers() {
		this.answers = this.question.answers.map((item) => item.title);
	}

	conditionSelectChange(item, model) {
		this.ruleData.condition = model;
		this.creator.setEdited(true);

		if (this.ruleData.condition === 4 || this.ruleData.condition === 5) {
			this.valueDisabled = true;
			this.ruleData.answers = [];
			this.selectedAnswers = [];
		} else {
			this.valueDisabled = false;
		}

		this.validate();
		this.creator.validate();
	}

	optionClick(item, multiSelectController, ev, isLast) {
		this.creator.setEdited(true);

		if (this.selectedAnswers.length && this.selectedAnswers.indexOf(item) !== -1) {
			this.selectedAnswers.splice(this.selectedAnswers.indexOf(item), 1);
			this.ruleData.answers = this.selectedAnswers.slice(0);

			ev.stopPropagation();
		}
		//
		if (!this.selectedAnswers.length || this.ruleData.condition === null) {
			this.valid = false;
			this.creator.validate();
		}
	}

	answerSelectChange(item) {
		this.creator.setEdited(true);

		let newItem = item.replace(' ' + this.taggingLabel, '');
		if (this.answers.indexOf(newItem) === -1) {
			this.answers.unshift(newItem);
		}

		this.selectedAnswers = this.selectedAnswers.map((label) =>
			label.replace(' ' + this.taggingLabel, '')
		);
		if (item !== undefined) {
			this.ruleData.answers = this.selectedAnswers.slice(0);

			this.validate();
			this.creator.validate();
		}
	}
	answerSelectRemove(item) {
		this.ruleData.answers = this.selectedAnswers.slice(0);
		this.creator.setEdited(true);
		this.validate();
		this.creator.validate();
	}

	validate() {
		if (
			(this.ruleData.condition !== undefined &&
				this.ruleData.condition !== null &&
				this.selectedAnswers &&
				this.selectedAnswers.length) ||
			this.ruleData.condition === 4 ||
			this.ruleData.condition === 5
		) {
			this.valid = true;
		} else {
			this.valid = false;
		}
	}
}

export const filterCreatorTextQuestion = {
	template: tpl,
	controller: FilterCreatorTextQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
