import tpl from './ranking-result.html';
import angular from 'angular';

class RankingResultController {
	constructor(i18n, $scope, rankingsService, $timeout, reportHelper, $rootScope) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.$timeout = $timeout;
		this.rankingsService = rankingsService;
		this.reportHelper = reportHelper;
		this.$rootScope = $rootScope;

		this.SETUP_TYPES = {
			INACTIVE: 0,
			ASC: 1,
			DESC: 2,
		};

		this.DISPLAY_MODE_SETUP = {
			nonEmpty: 0,
			all: 1,
		};

		this.DISPLAY_MODE = [
			{
				type: this.DISPLAY_MODE_SETUP.nonEmpty,
				title: i18n.__('tylko niezerowe', 'reports'),
			},
			{
				type: this.DISPLAY_MODE_SETUP.all,
				title: i18n.__('wszystkie', 'reports'),
			},
		];

		this.SORTING_TYPES = [
			reportHelper.additionalSettingsTypes.npsLtr,
			reportHelper.additionalSettingsTypes.npsScore,
			reportHelper.additionalSettingsTypes.ratingTopBox,
			reportHelper.additionalSettingsTypes.ratingTopTwoBoxes,
			reportHelper.additionalSettingsTypes.ratingAvg,
			reportHelper.additionalSettingsTypes.total,
		];

		this.activeSortingColumn = null;
		this.sortedRankingResult = null;
		this.activeSetup = 0;
		this.activeDisplayMode = this.DISPLAY_MODE_SETUP.nonEmpty;

		this.additionalSettings = [];

		this.isLoading = true;
	}

	$onInit() {
		this.bindUIActions();
	}

	bindUIActions() {
		let ctrl = this;

		this.$scope.$on('addToReport', (event, data) => {
			ctrl.addToReport(data.rankingData, data.filterId, data.successCallback);
		});

		this.$scope.$watch(
			() => this.rankingResult,
			(newValue, oldValue) => {
				ctrl.rankingResultWatcher(newValue, oldValue);
			}
		);

		this.$scope.$watch(
			() => this.sortedRankingResult,
			(newValue, oldValue) => {
				ctrl.sortedRankingResultWatcher(newValue, oldValue);
			}
		);
	}

	rankingResultWatcher(newValue) {
		if (newValue) {
			this.sortedRankingResult = angular.copy(this.rankingResult);
			this.isLoading = false;
			this.activeSetup = 0;
		}

		if (this.rankingResult && this.rankingResult.average !== null && !this.partCtrl) {
			this.sortResults(this.reportHelper.additionalSettingsTypes.ratingAvg);
		}

		if (this.rankingResult && this.areAllEmptyRows() && !this.partCtrl) {
			this.activeDisplayMode = this.DISPLAY_MODE_SETUP.all;
			this.onDisplayModeChange();
		}
	}

	sortedRankingResultWatcher(newValue, oldValue) {
		if (this.partCtrl && newValue && !oldValue) {
			this.initForPart();
			this.initAdditionalSettings();
			this.reportBuilderCtrl.reportFullyLoaded();
		}
	}

	getPercentSpan(value) {
		return value + ' %';
	}

	sortResults(type) {
		this.checkIfAnotherSortingApplied(type);
		this.setupAdditionalSettings(type);
		this.setActiveSorting(type);

		if (this.activeSetup !== 0) {
			this.sortBy(type, this.activeSetup);
		} else {
			this.sortedRankingResult = angular.copy(this.rankingResult);
		}
	}

	sortBy(type, setup) {
		let temporarySortedRanking = angular.copy(this.rankingResult);
		let sortedColumn = [];
		const score = 'score';

		type = this.mapTypeToColumn(type);

		if (type === 'ltr') {
			sortedColumn = angular.copy(
				this.rankingResult[type].map((item, itemIndex) => {
					return { score: item.score, type: item.type, index: itemIndex };
				})
			);

			sortedColumn.sort(this.comparingFunction(setup));
		} else if (type === 'topBox' || type === 'topTwoBoxes') {
			sortedColumn = angular.copy(
				this.rankingResult[type].map((item, itemIndex) => {
					return { score: item.percent, count: item.count, index: itemIndex };
				})
			);

			sortedColumn.sort(this.comparingFunction(setup));
		} else {
			sortedColumn = angular.copy(
				this.rankingResult[type].map((item, itemIndex) => {
					return { score: item, index: itemIndex };
				})
			);

			sortedColumn.sort(this.comparingFunction(setup));
		}

		temporarySortedRanking[type].forEach((item, index) => {
			if (type === 'ltr') {
				item.score = sortedColumn[index].score;
				item.type = sortedColumn[index].type;
			} else if (type === 'topBox' || type === 'topTwoBoxes') {
				item.count = sortedColumn[index].count;
				item.percent = sortedColumn[index].score;
			} else {
				temporarySortedRanking[type][index] = sortedColumn[index].score;
			}
		});

		for (let column in this.sortedRankingResult) {
			if (
				column !== type &&
				column !== 'sets' &&
				column !== 'columns' &&
				this.rankingResult[column] !== null
			) {
				for (let i = 0; i < sortedColumn.length; i++) {
					temporarySortedRanking[column][i] = angular.copy(
						this.rankingResult[column][sortedColumn[i].index]
					);
				}
			}
		}

		this.$timeout(() => {
			this.sortedRankingResult = temporarySortedRanking;
		});
	}

	mapTypeToColumn(type) {
		switch (type) {
			case this.reportHelper.additionalSettingsTypes.npsLtr:
				return 'ltr';
			case this.reportHelper.additionalSettingsTypes.npsScore:
				return 'nps';
			case this.reportHelper.additionalSettingsTypes.ratingTopBox:
				return 'topBox';
			case this.reportHelper.additionalSettingsTypes.ratingTopTwoBoxes:
				return 'topTwoBoxes';
			case this.reportHelper.additionalSettingsTypes.total:
				return 'totalRows';
			case this.reportHelper.additionalSettingsTypes.ratingAvg:
				return 'average';
			default:
				return 'ltr';
		}
	}

	checkIfAnotherSortingApplied(type) {
		if (this.activeSortingColumn !== type && this.activeSortingColumn !== null) {
			this.activeSetup = 0;
		}
	}

	setupAdditionalSettings(type) {
		if (this.activeSetup === null || this.activeSetup === 0) {
			this.activeSetup = this.SETUP_TYPES.DESC;
		} else if (this.activeSetup === this.SETUP_TYPES.DESC) {
			this.activeSetup = this.SETUP_TYPES.ASC;
		} else {
			this.activeSetup = this.SETUP_TYPES.INACTIVE;
		}

		let settingIndex = this.additionalSettings.findIndex((setting) => {
			return this.SORTING_TYPES.indexOf(setting.type) !== -1;
		});

		if (settingIndex !== -1) {
			this.additionalSettings[settingIndex].type = type;
			this.additionalSettings[settingIndex].setup = this.activeSetup;
		} else {
			this.additionalSettings.push({
				type,
				setup: this.activeSetup,
			});
		}

		if (this.partCtrl) {
			this.updateAdditionalSetting(type, this.activeSetup);
		}
	}

	setActiveSorting(type) {
		this.activeSortingColumn = type;
	}

	comparingFunction(setup) {
		if (setup === 2) {
			return (a, b) => {
				return b.score - a.score;
			};
		} else if (setup === 1) {
			return (a, b) => {
				return a.score - b.score;
			};
		}
	}

	onDisplayModeChange() {
		const settingIndex = this.additionalSettings.findIndex((setting) => {
			return setting.type === this.reportHelper.additionalSettingsTypes.rankingDisplayMode;
		});

		if (settingIndex !== -1) {
			this.additionalSettings[settingIndex].setup = this.activeDisplayMode;
		} else {
			this.additionalSettings.push({
				type: this.reportHelper.additionalSettingsTypes.rankingDisplayMode,
				setup: this.activeDisplayMode,
			});
		}

		if (this.partCtrl) {
			this.updateAdditionalSetting(
				this.reportHelper.additionalSettingsTypes.rankingDisplayMode,
				this.activeDisplayMode
			);
		}
	}

	isAnyEmptyRow() {
		if (
			!this.rankingResult ||
			!this.rankingResult.totalRows ||
			this.rankingResult.totalRows.length === 0
		) {
			return false;
		}

		const emptyRowIndex = this.rankingResult.totalRows.findIndex((rowTotal) => {
			return rowTotal === 0;
		});

		return emptyRowIndex !== -1;
	}

	areAllEmptyRows() {
		if (
			!this.rankingResult ||
			!this.rankingResult.totalRows ||
			this.rankingResult.totalRows.length === 0
		) {
			return false;
		}

		return this.rankingResult.totalRows.every((value) => value === 0);
	}

	// report methods

	addToReport(rankingData, filterId, successCallback) {
		this.rankingsService.openAddRankingToReportModal({
			rankingData,
			additionalSettings: this.additionalSettings,
			filterId,
			successCallback,
		});
	}

	initForPart() {
		this.loading = true;
		this.additionalSettings = this.partCtrl.part.additionalSettings;
		this.reportBuilderCtrl.setRankingReference(this);
	}

	updateAdditionalSetting(type, value) {
		let chapterId = this.partCtrl.chapterCtrl.chapter.id;
		let partId = this.partCtrl.id;

		let callback;

		if (this.SORTING_TYPES.indexOf(type) !== -1) {
			callback = (setting) => this.SORTING_TYPES.indexOf(setting.type) !== -1;
		} else {
			callback = (setting) => setting.type === type;
		}

		let setting = this.partCtrl.part.additionalSettings.find(callback);

		let data = {
			type,
			setup: value,
		};

		if (!setting.id) {
			this.reportBuilderCtrl.reportsService
				.addAdditionalSettingsPromise(null, chapterId, partId, data)
				.then((data) => {
					setting.id = data.id;
				});
		} else {
			this.reportBuilderCtrl.reportsService.updateAdditionalSettingsPromise(
				null,
				chapterId,
				partId,
				setting.id,
				data
			);
		}
	}

	isReady() {
		return !!this.sortedRankingResult;
	}

	initAdditionalSettings() {
		if (this.additionalSettings.length === 0) {
			return;
		}

		this.additionalSettings.forEach((setting) => {
			if (setting.type === this.reportHelper.additionalSettingsTypes.rankingDisplayMode) {
				this.activeDisplayMode = setting.setup;
			} else if (this.SORTING_TYPES.indexOf(setting.type) !== -1) {
				this.activeSetup = setting.setup;
				this.setActiveSorting(setting.type);

				if (this.activeSetup) {
					this.sortBy(setting.type, this.activeSetup);
				}
			}
		});
	}
}

export const RankingResultComponent = {
	template: tpl,
	controller: RankingResultController,
	require: {
		partCtrl: '?^reportPart',
		reportBuilderCtrl: '?^reportBuilder',
	},
	bindings: {
		rankingResult: '<',
	},
};
