const tpl = require('./FilterCreatorTagRule.html');

class FilterCreatorTagRuleController {
	constructor($scope, filtersService, i18n, verbatimTagsService, $element) {
		'ngInject';
		this.$scope = $scope;
		this.filtersService = filtersService;
		this.i18n = i18n;
		this.verbatimTagsService = verbatimTagsService;
		this.$element = $element;

		this.dummyOptions = [this.i18n.__('wypełnienie', 'reports')];
		this.dummySelect = this.dummyOptions[0];

		this.anyTagOption = {
			title: i18n._s('%sDowolny%s', '<span style="font-weight: 700;">', '</span>', 'reports'),
			id: 0,
		};

		this.tags = null;
		this.selectedTags = [];
		this.selectedCondition = null;

		this.valid = false;
	}

	$onInit() {
		this.fetchTags();
	}

	$onDestroy() {
		if (!this.isEmpty) {
			this.creator.removeTagRuleController(this);
		}
	}

	fetchTags() {
		this.verbatimTagsService.getTagsListPromise().then((tags) => {
			this.tags = this.prepareTags(tags);

			if (this.ruleData) {
				this.mapInputData();
			} else {
				this.ruleData = this.filtersService.getEmptyTagRule();
			}

			if (!this.isEmpty) {
				this.creator.addTagRuleController(this);
			}

			if (this.creator.data.tagRules.length === 1 && !this.creator.data.id) {
				setTimeout(() => {
					this.$element.find('.multiSelect input').trigger('click');
				});
			}
		});
	}

	prepareTags(tags) {
		if (tags.length === 0) {
			return null;
		}

		tags.unshift(this.anyTagOption);

		return tags;
	}

	mapInputData() {
		if (Number.isInteger(this.ruleData.condition)) {
			this.selectedCondition = this.filtersService.tagConditionOptions.find((option) => {
				return Number(option.id) === Number(this.ruleData.condition);
			});
		}

		if (this.ruleData.tagIds) {
			if (this.ruleData.tagIds.length === 0) {
				this.selectedTags = [this.tags[0]];
			} else {
				this.selectedTags = this.tags.filter((tag) => {
					return this.ruleData.tagIds.indexOf(tag.id) !== -1;
				});
			}
		}

		if (Number.isInteger(this.ruleData.condition) && this.ruleData.tagIds) {
			this.valid = true;
		}
	}

	onTagsSelect(event) {
		if (event.items.length === 0) {
			this.selectedTags = [];
			this.selectedCondition = null;
			this.ruleData.tagIds = null;
			this.ruleData.condition = null;
		} else {
			const anyItemOptionIndex = event.items.findIndex((item) => {
				return item.id === this.anyTagOption.id;
			});

			if (anyItemOptionIndex !== -1) {
				if (anyItemOptionIndex === event.items.length - 1) {
					this.selectedTags = [this.anyTagOption];
				} else {
					this.selectedTags = event.items;
					this.selectedTags.splice(anyItemOptionIndex, 1);
				}
			} else {
				this.selectedTags = event.items;
			}

			this.ruleData.tagIds = this.selectedTags
				.map((tag) => {
					return tag.id;
				})
				.filter((id) => {
					return id !== this.anyTagOption.id;
				});
		}

		this.creator.setEdited(true);

		if (this.isEmpty) {
			this.creator.addTagRule(this.ruleData);
		} else if (this.ruleData.condition !== null) {
			this.creator.validate();
		}
	}

	onConditionSelect(event) {
		this.selectedCondition = event.selected;
		this.ruleData.condition = this.selectedCondition.id;
		this.creator.setEdited(true);
		this.valid = true;
		this.creator.validate();
	}

	isValid() {
		return this.valid;
	}

	setValid(val) {
		this.valid = val;
	}

	getData() {
		return this.ruleData;
	}
}

export const filterCreatorTagRule = {
	template: tpl,
	controller: FilterCreatorTagRuleController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		isEmpty: '<',
	},
};
