import Template from './trend-empty-screen.html';

class TrendEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Trendy pozwalają na obserwowanie zmian wskaźnika w czasie, np. spadającego wyniku NPS lub średniej oceny satysfakcji.',
					'user'
				),
				this.i18n.__(
					'Ustaw różne trendy na bazie segmentów odpowiedzi i dodaj je do zbiorczych dashboardów.',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}
}
export const TrendEmptyScreenComponent = {
	template: Template,
	controller: ['i18n', TrendEmptyScreenController],
	bindings: {
		onButtonClick: '<',
		enabled: '<',
	},
};
