import angular from 'angular';

function scrollBottomDirective($document, $rootScope, $timeout) {
	'ngInject';

	return {
		restrict: 'A',
		link: (scope, element, attrs) => {
			let html = angular.element($document)[0].querySelector('html');
			let body = angular.element($document)[0].body;
			let resizeTimeout;
			let notBottom = true;

			let scroll = () => {
				// If the end of the page
				if (
					notBottom &&
					(body.scrollTop + window.innerHeight + 10 >= body.scrollHeight ||
						html.scrollTop + window.innerHeight + 10 >= html.scrollHeight)
				) {
					notBottom = false;
					// Check loading
					if (element[0].className.indexOf('fetching') === -1) {
						$timeout(() => {
							scope.$apply(attrs.scrollBottom);
						});
					}
					// If scrollTop == 0 , it's necessary cause the page can have bigger height and then
					// it should load more data
					if (body.scrollTop === 0) {
						// After loading, we execute the function again
						// because there can be more space to load next part of data,
						// but we need to check also, if there are still some data to load and avoid looping
						if (element[0].className.indexOf('nothingToLoad') === -1) {
							setTimeout(() => {
								$document.scroll();
							}, 1000);
						}
					}

					setTimeout(() => {
						notBottom = true;
					}, 500);
				}
			};

			let resize = () => {
				clearTimeout(resizeTimeout);
				resizeTimeout = setTimeout(() => {
					scroll();
				}, 100);
			};

			$document.bind('scroll', scroll).scroll();

			$(window).bind('resize', resize);

			scope.$watch(
				() => {
					return body.scrollHeight;
				},
				(newValue, oldValue) => {
					if (newValue < oldValue) {
						scroll();
					}
				}
			);

			scope.$on('$destroy', () => {
				$document.unbind('scroll', scroll);
				$(window).unbind('resize', resize);
			});
		},
	};
}

export default scrollBottomDirective;
