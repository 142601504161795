const tpl = require('./ReportsList.html');

import { AclFeature } from '../components/acl/acl.config';
import '../../scss/reportList.scss';
import dayjs from 'dayjs';

require('../../scss/reports.scss');

class ReportsListController {
	constructor(
		reportsService,
		modalService,
		alertsService,
		$state,
		$scope,
		i18n,
		reportHelper,
		userService,
		aclManager,
		segmentService
	) {
		'ngInject';
		let ctrl = this;

		this.reportsService = reportsService;
		this.$state = $state;
		this.$scope = $scope;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.reportHelper = reportHelper;
		this.i18n = i18n;
		this.userService = userService;
		this.aclManager = aclManager;
		this.segmentService = segmentService;

		this.reportsList = [];
		this.dataRefreshTimeoutId = null;
		this.deletedReports = 0;
		this.pageLoaded = 0;
		this.nothingToLoad = false;

		this.COOKIE_KEY = 'infoReportsCreator';

		this.noReports = true;

		this.fetchData = (reportsCount = 0) => {
			this.fetching = true;
			this.pageLoaded += 1;

			reportsService.getReportsListPromise(true, this.pageLoaded).then(
				(data) => {
					if (reportsCount !== 0 && reportsCount < data.length) {
						data = data.slice(-reportsCount);
					}

					this.reportsList = this.reportsList.concat(data);
					this.nothingToLoad = this.reportsService.getTotalCount() <= this.reportsList.length;
					this.fetching = false;

					$scope.loaded = 1;
					$scope.$applyAsync();
				},
				() => {
					this.fetching = false;
				}
			);
		};

		this.fetchData();

		$scope.$on('SurveyChanged', function onSurveyChanged() {
			ctrl.reportsList = [];
			ctrl.pageLoaded = 0;
			$scope.loaded = 0;

			ctrl.fetchData();
		});

		this.deleteReport = (report, event) => {
			event.stopPropagation();

			if (report.isBlockedByCyclicReport) {
				this.openBlockedByCyclicReportModal(report);
				return;
			}

			if (report.isBlockedByNewReport) {
				this.openBlockedByNewReportModal();
				return;
			}

			let ctrl = this;
			//  it's because ng-click on parent tr element

			let config = {
				size: '400',
				title: this.i18n.__('Usuwanie raportu', 'reports'),
				text: this.i18n.__('Czy na pewno chcesz usunąć?', 'reports'),
				icon: {
					font: 'fa-trash',
					color: 'gray',
				},
				button: {
					text: this.i18n.__('Anuluj', 'reports'),
					type: 'button--big button--gray',
				},
				button2: {
					text: this.i18n.__('Tak, usuń', 'reports'),
					type: 'button--big',
				},
			};
			modalService.show(config).then((data) => {
				if (data === 'button1') {
					modalService.close();
				}

				if (data === 'button2') {
					modalService.loading(this.i18n.__('Usuwanie raportu...', 'reports'));

					reportsService.deleteReport(report.id).then(
						(data) => {
							let index = ctrl.reportsList.findIndex((report) => report.id === data.id);
							ctrl.reportsList.splice(index, 1);
							ctrl.deletedReports += 1;
							modalService.close();
							alertsService.success(this.i18n.__('Raport usunięty', 'reports'));
						},
						(errData) => {
							if (errData.status === 409) {
								modalService.close();
								alertsService.warning(
									this.i18n.__('Raport nie został usunięty. Trwa generowanie.', 'reports')
								);
							}
						}
					);
				}
			});
		};

		this.exportReport = (report, event) => {
			event.stopPropagation();
			//  it's because ng-click on parent tr element

			if (
				(report.partsWithQuestion + report.partsWithCrossTable === 0 + report.partsWithRankins) ===
				0
			) {
				modalService.show({
					size: '400',
					title: this.i18n.__('Raport jest pusty', 'reports'),
					text: this.i18n.__('Eksport będzie możliwy po dodaniu treści do raportu.', 'reports'),
					icon: {
						font: 'fa-exclamation-triangle',
						color: 'gray',
					},
					button: {
						text: this.i18n.__('Zamknij', 'reports'),
						type: 'button--big button--gray',
						closeOnClick: true,
					},
				});
				return;
			}

			this.modalService.openExportReportModal(report);
		};

		this.deprecatedFunctionText = this.i18n.__(
			'Dodawanie nowych analiz w wygaszanych funkcjach (Filtry, Kreator raportów, Tabele krzyżowe, Rankingi, Trendy) nie jest już możliwe. Zapraszamy do nowego modułu Wyników.',
			'user'
		);
	}

	$onInit() {
		this.$scope.loaded = 0;

		this.bindWatchers();
	}

	bindWatchers() {
		this.$scope.$watch(
			() => this.reportsList,
			() => {
				this.noReports = this.reportsList.length <= 0;
			},
			true
		);

		this.$scope.$watch('loaded', () => {
			if (this.$scope.loaded) {
				this.sendAnalyticsEvent();
			}
		});
	}

	sendAnalyticsEvent() {
		const getModuleState = () => {
			const isFeaturePurchased = this.aclManager.isFeatureEnabled(AclFeature.moduleReportsCreator);

			if (!isFeaturePurchased) {
				return 'unavailable';
			} else if (isFeaturePurchased && this.noReports) {
				return 'enabled';
			} else {
				return 'activated';
			}
		};

		const moduleState = getModuleState();

		this.segmentService.track('Lista raportów - wejście', {
			state: moduleState,
		});
	}

	newReportClick() {
		this.reportHelper.openNewReportModal({
			successCallback: () => {},
		});
	}

	emptyScreenOnButtonClick = () => {
		this.newReportClick();
	};

	openReport($reportID) {
		parent.location.href = `/user#angular?r=/reports/generate/${$reportID}`;
	}

	getFormattedDate(timestamp) {
		const miliseconds = timestamp * 1000;

		return dayjs(miliseconds).format('DD/MM/YYYY');
	}

	updateReports() {
		// Liczę na bieżąco ile wszystkich bo TotalCount przeliczane jest po fetchu
		let totalCount = this.reportsService.getTotalCount() - this.deletedReports;

		if (totalCount > this.reportsList.length && this.pageLoaded >= 1) {
			// Sprawdzam czy są jakieś usunięte po wcześniejszym doładowaniu - jeśli tak, trzeba je doładować z poprzedniej/poprzednich stron bo tam wskoczyły
			if (this.deletedReports) {
				// Ile potrzebuje doładować do pełnej dziesiątki
				let reportsToLoad = this.deletedReports % 10;

				// Sprawdzam czy w ogóle tyle jest do doładowania, jeśli nie - doładowuję tyle ile zostało
				if (reportsToLoad + this.reportsList.length > totalCount) {
					reportsToLoad = totalCount - this.reportsList.length;
				}

				// Ustawiam stronę z której należy doładować filtry
				this.pageLoaded -= Math.ceil(this.deletedReports / 10);

				// Pobieram filtry które przeskoczyły między stronami - doładowuję do pełnej 10
				this.fetchData(reportsToLoad);

				this.deletedReports = 0;
			} else {
				// w przypadku gdy zaskoczą dwa doładowania jednocześnie
				let wait = () => {
					if (!this.fetching) {
						this.fetchData();
					} else {
						setTimeout(() => {
							wait();
						}, 500);
					}
				};

				wait();
			}
		}
	}

	openBlockedByCyclicReportModal(report) {
		let reportsTitles =
			'<ul>' +
			report.blockingCyclicReports
				.map((reportName) => {
					return '<li>' + reportName + '</li>';
				})
				.join('');

		let text;

		if (report.blockingCyclicReports.length > 1) {
			text = this.i18n._s(
				'Nie możesz usunąć tego raportu ponieważ jest wykorzystywany w raportach cyklicznych o nazwach: %s',
				reportsTitles,
				'reports'
			);
		} else {
			text = this.i18n._s(
				'Nie możesz usunąć tego raport ponieważ jest wykorzystywany w raporcie cyklicznym o nazwie %s',
				reportsTitles,
				'reports'
			);
		}

		this.openBlockedReportModal(text);
	}

	openBlockedByNewReportModal() {
		this.openBlockedReportModal(
			this.i18n.__('Nie możesz usunąć tego raportu ponieważ jest w trakcie generowania.', 'reports')
		);
	}

	openBlockedReportModal(text) {
		this.modalService.show({
			size: '400',
			title: this.i18n.__('Nie można usunąć raportu...', 'reports'),
			text,
			icon: {
				font: 'fa-exclamation-triangle',
				color: 'gray',
			},
			button: {
				text: this.i18n.__('Zamknij', 'reports'),
				type: 'button--big button--gray',
				closeOnClick: true,
			},
		});
	}

	canCreateReport() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}
}

export const reportsList = {
	template: tpl,
	controller: ReportsListController,
	require: {
		reportsWrapper: '^reportsWrapper',
	},
};
