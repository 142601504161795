let lang = null;

const getLang = () => {
	if (!lang) {
		lang = parent.window.countryCode || 'pl';
	}

	return lang;
};

export { getLang };
