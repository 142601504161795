import { AbstractResource, AbstractResourceConfig } from './AbstractResource';

import tpl from '../ChooserTemplate/SingleChooser.html';

class PeriodChooserController extends AbstractResource {
	constructor(i18n, analyzeSetStorage, EventEmitter) {
		'ngInject';

		super(i18n, analyzeSetStorage);

		this.EventEmitter = EventEmitter;

		this.placeholder = i18n.__('Wymiar trendu', 'reports');
	}

	$onDestroy() {
		delete this.set.period;
	}

	fetchResource() {
		this.resourceList = $$await(this.analyzeSetStorage.fetchPeriods());

		if (this.set.period) {
			this.selectedResource = this.set.period;
		}
	}

	onSelectResource() {
		this.set.period = this.selectedResource;

		if (this.onChange) {
			this.onChange(
				this.EventEmitter({
					item: this.selectedResource,
				})
			);
		}
	}
}

PeriodChooserController.$inject = ['i18n', 'analyzeSetStorage', 'EventEmitter'];

export const PeriodChooser = Object.assign(
	{
		template: tpl,
		controller: PeriodChooserController,
	},
	AbstractResourceConfig
);
