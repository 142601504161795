const tpl = require('./AddToReportModal.html');
let { AbstractAddToReportModal } = require('./AbstractAddToReportModal');

class AddCrossTableToReportModal extends AbstractAddToReportModal {
	constructor(
		reportsService,
		reportHelper,
		$scope,
		surveyProviderService,
		modalService,
		i18n,
		$timeout
	) {
		'ngInject';
		super(
			reportsService,
			reportHelper,
			$scope,
			surveyProviderService,
			i18n,
			$timeout,
			modalService
		);

		let ctrl = this;

		this.partType = String(this.types.CROSS_TABLES_TABLE_CHART);
		this.showVisualisation = true;

		this.TYPES = [
			{
				partType: String(ctrl.types.CROSS_TABLES_TABLE_CHART),
				icon: '/images/reports/report-tablechart-icon.png',
				title: ctrl.i18n.__('Tabela i wykres', 'reports'),
				id: 'typeTableChart',
			},
			{
				partType: String(ctrl.types.CROSS_TABLES_CHART),
				icon: '/images/reports/report-chart-icon.png',
				title: ctrl.i18n.__('Wykres', 'reports'),
				id: 'typeChart',
			},
			{
				partType: String(ctrl.types.CROSS_TABLES_TABLE),
				icon: '/images/reports/report-table-icon.png',
				title: ctrl.i18n.__('Tabela', 'reports'),
				id: 'typeTable',
			},
		];
	}

	$onInit() {
		super.$onInit();
		this.getResolveData();
	}

	getResolveData() {
		this.crossTableData = this.resolve.crossTableData;
		this.additionalSettings = this.resolve.additionalSettings;
		this.filterId = this.resolve.filterId;
		this.successCallback = this.resolve.successCallback;
	}

	add() {
		if (this.locked || this.saved) {
			return;
		}
		this.locked = true;

		let self = this;
		let config = this.getBaseConfig();

		this.reportsService
			.addPartDataToReportPromise(config, (newPart) => {
				newPart.title = self.crossTableData.title;
				newPart.type = self.partType;
				newPart.text = '';
				newPart.filter = self.filterId;
				newPart.crossTable = self.crossTableData.id;
				newPart.additionalSettings = self.reportsService.getAdditionalSettingsParsed(
					self.additionalSettings
				);

				return newPart;
			})
			.then(
				(reportData) => {
					self.addSuccessCallback(reportData, config);
					this.successCallback();
				},
				(errorData) => {
					self.addErrorCallback(errorData);
				}
			);
	}
}

export const addCrossTableToReportModal = {
	template: tpl,
	controller: AddCrossTableToReportModal,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
