const SegmentService = function () {
	'ngInject';

	this.track = (event, properties) => {
		if (parent.window.Analytics === undefined) {
			return;
		}

		parent.window.Analytics.segment.track(event, properties);
	};
};

export { SegmentService };
