const tpl = require('./FilterCreatorSimpleQuestion.html');
const angular = require('angular');

let { FilterCreatorAbstractQuestionController } = require('./FilterCreatorAbstractQuestion');

class FilterCreatorSimpleQuestionController extends FilterCreatorAbstractQuestionController {
	constructor($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper) {
		'ngInject';

		super($scope, surveyProviderService, reportHelper, i18n);

		this.selectedAnswers = [];
		this.conditions = filtersHelper.getStandardConditions();

		this.stopPropagation = false;

		this.questionHelper = questionHelper;
		this.isNps = false;
		this.i18n = i18n;

		this.data = {
			answerData: null,
		};
	}

	$onDestroy() {
		super.$onDestroy();
		// musi być bo bez onDestroy przy niszczeniu komponentu wywoluje się onChanges
	}

	$onChanges(changes) {
		if (changes.ruleData && changes.ruleData.isFirstChange()) {
			this.init();
			this.validate();
		}

		const isQuestionLoaded = changes.question && angular.equals(changes.question.previousValue, {});

		if (isQuestionLoaded) {
			if (!changes.question.isFirstChange()) {
				this.onQuestionChange();
			}

			this.data.answerData = this.question.answers;
			if (
				(this.data.answerData[0] && !this.data.answerData[0].none) ||
				!this.data.answerData.length
			) {
				this.data.answerData.unshift(this.noneAnswer);
			}

			this.selectedCondition = this.ruleData.condition;

			// this.selectedAnswers = this.ruleData.answers.length ? this.ruleData.answers.slice(0) : [];
			if (this.ruleData.answers.length) {
				this.selectedAnswers = this.ruleData.answers.map((val) => Number(val));
			} else {
				this.selectedAnswers = [];
			}

			if (this.isNoneSelected()) {
				// console.log('none', this.isNoneSelected(), this.ruleData);
				this.selectedAnswers = [this.noneAnswer];
				this.selectedCondition = null;
				this.noneSelected();
			}
		} else if (changes.question) {
			this.onQuestionChange();
		}
	}

	onQuestionChange() {
		super.onQuestionChange();

		this.ruleData.condition = null;
		this.ruleData.answers.length = 0;

		this.creator.setEdited(true);
		this.valid = false;
		this.creator.validate();
		if (!this.selectedAnswers.length || this.ruleData.condition === null) {
			this.valid = false;
		}
	}

	answerSelectChange(item, selected) {
		this.creator.setEdited(true);
		if (item.none) {
			this.noneSelected();
		} else if (item.index !== undefined) {
			this.removeNone();

			this.ruleData.answers = this.selectedAnswers.slice(0);

			this.validate();
			this.creator.validate();
		}
	}
	answerSelectRemove(item, selected) {
		this.ruleData.answers = this.selectedAnswers.slice(0);
		this.creator.setEdited(true);
		this.validate();
		this.creator.validate();
	}

	optionClick(index, item, multiSelectController, ev, isLast) {
		this.creator.setEdited(true);

		if (this.selectedAnswers.length && this.selectedAnswers.indexOf(item.index) !== -1) {
			this.selectedAnswers.splice(this.selectedAnswers.indexOf(item.index), 1);
			this.ruleData.answers = this.selectedAnswers.slice(0);

			ev.stopPropagation();
		}

		if (!this.selectedAnswers.length || this.ruleData.condition === null) {
			this.valid = false;
			this.creator.validate();
		}
	}

	conditionSelectChange(item, selected) {
		// console.log('conditionSelectChange', this.selectedCondition, item, selected);
		this.ruleData.condition = selected;
		this.validate();
		this.creator.setEdited(true);
		this.creator.validate();
	}

	validate() {
		// console.log('przed validate', this.questionId, this.ruleData.answers, this.ruleData.condition);
		if (
			(this.questionId || this.questionId === 0) &&
			this.ruleData.answers &&
			this.ruleData.answers.length &&
			(this.ruleData.condition || this.ruleData.condition === 0)
		) {
			this.valid = true;
		} else if ((this.questionId || this.questionId === 0) && this.isNoneSelected()) {
			this.valid = true;
		} else {
			this.valid = false;
		}
		// console.log('validate', this.valid);
	}
}

export const filterCreatorSimpleQuestion = {
	template: tpl,
	controller: FilterCreatorSimpleQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
