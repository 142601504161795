import template from './VerbatimElementTags.html';

import { TagEvents } from '../../Settings/Tags/events.tags';
import { KeyCodes } from '../../utils/KeyCodes';

class VerbatimElementTagsController {
	constructor(
		i18n,
		$timeout,
		$rootScope,
		$scope,
		$document,
		$element,
		verbatimLogTagsService,
		verbatimTagsService,
		alertsService
	) {
		'ngInject';

		this.i18n = i18n;
		this.$timeout = $timeout;
		this.$rootScope = $rootScope;
		this.$scope = $scope;
		this.$document = $document;
		this.$element = $element;
		this.verbatimLogTagsService = verbatimLogTagsService;
		this.verbatimTagsService = verbatimTagsService;
		this.alertsService = alertsService;

		this.logId = null; //bindings
		this.order = null; //bindings
		this.keyEvent = null;
		this.lastAddedTag = null;
		this.keyEventHooked = false;

		this.tagsList = [];
		this.logTagsList = [];
		this.colorsList = [];
	}

	$onInit() {
		this.currentLogObserver();
		this.loadTags();
		this.loadColors();
		this.loadTagsForLog();
	}

	$onDestroy() {
		this.removeKeyEvent();
	}

	getElementInput() {
		return this.$element.find('tags-input .input');
	}

	currentLogObserver() {
		let observer = this.$rootScope.$on(TagEvents.activeSheetChanged, (event, data) => {
			if (this.order === data.currentLog) {
				this.focusTagsInput();
				this.addKeyEvent();
			} else {
				this.removeKeyEvent();
			}
		});
		this.$scope.$on('$destroy', observer);
	}

	focusTagsInput() {
		let headerHeight = 70; //could be calculated using .height() (in future)

		let scrollToBegin = () =>
			this.$timeout(() => {
				let parent = this.$element.closest('.verbatim__element');
				let offset = parseInt(parent.offset().top, 10);
				offset -= headerHeight;

				window.scrollTo(0, offset);
			}, 50); //timeouted, because clicking in input cause scroll

		this.$timeout(() => {
			this.getElementInput().click();
			scrollToBegin();
		});
	}

	loadTagsForLog() {
		if (!this.isTaggingPossible()) {
			return false;
		}

		this.$timeout(() => {
			this.verbatimLogTagsService.getTagsForLog(this.logId).then((data) => {
				this.logTagsList = data;
			});
		});
	}

	loadTags() {
		let observer = this.$rootScope.$on(TagEvents.loaded, (event, data) => {
			this.$timeout(() => {
				this.tagsList = data.tags;
			});
		});

		this.$scope.$on('$destroy', observer);
	}

	loadColors() {
		let observer = this.$rootScope.$on(TagEvents.colorsLoaded, (event, data) => {
			this.$timeout(() => {
				this.colorsList = data.colors;
			});
		});

		this.$scope.$on('$destroy', observer);
	}

	filterTags($query) {
		const clearedQuery = $query.trim();

		const tagsList = this.tagsList.filter((tag) => this.textContains(clearedQuery, tag.title));

		this.decideCreateNewTagButton(clearedQuery, tagsList);

		return tagsList;
	}

	textContains(query, text) {
		return text.toLowerCase().indexOf(query.toLowerCase()) !== -1;
	}

	decideCreateNewTagButton($query, tagsList) {
		const noTags = $query && tagsList.length === 0;
		const tagNotExists =
			$query &&
			tagsList.filter((tag) => tag.title.toLowerCase() === $query.toLowerCase()).length === 0;

		if (noTags || tagNotExists) {
			tagsList.push({
				title: `${this.i18n.__('+ Stwórz nowy', 'tags')}: ${$query}`,
				tagId: 0,
				custom: true,
			});
		}
	}

	addTag(tag) {
		if (!tag.tagId) {
			return this.addNewTag(tag);
		}

		this.verbatimLogTagsService.addTagForLog(this.logId, tag.tagId).then((data) => {
			this.lastAddedTag = tag.tagId;
			tag.id = data.relationId;

			this.$scope.$apply();
		});

		return true;
	}

	addNewTag(tag) {
		tag.title = tag.title.split(':')[1].trim();

		if (!tag.title) {
			return false;
		}

		this.verbatimTagsService.createTagPromise(tag.title).then((newTag) => {
			this.verbatimLogTagsService.addTagForLog(this.logId, newTag.id).then((data) => {
				this.lastAddedTag = newTag.id;
				tag.tagId = newTag.id;
				tag.id = data.relationId;
				tag.custom = false;
				this.tagsList.push(tag);
				this.$scope.$apply();
			});
		});

		return true;
	}

	removeTag(tag) {
		this.verbatimLogTagsService.removeTagForLog(tag.id).then(() => {
			//dont worry, be happy
		});
	}

	setColor(tagId, colorId, $event) {
		$event.stopPropagation();

		let actualTag = this.logTagsList.find((elem) => Number(elem.tagId) === Number(tagId));
		let actualColorId = actualTag.colorId;
		let addedColor = this.colorsList.find((elem) => elem.id === colorId);

		let requestCallback = (data) => {
			actualTag.colorId = addedColor.id;
			actualTag.color = addedColor.name;

			this.lastAddedTag = null;

			this.$scope.$apply();
		};

		if (actualColorId) {
			this.verbatimLogTagsService.updateColor(actualTag.id, colorId).then(requestCallback);
		} else {
			this.verbatimLogTagsService.setColor(tagId, this.logId, colorId).then(requestCallback);
		}
	}

	removeColor(tag, $event) {
		$event.stopPropagation();

		this.verbatimLogTagsService.removeColor(tag.id).then((data) => {
			tag.colorId = null;
			tag.color = null;

			this.$scope.$apply();
		});
	}

	addKeyEvent() {
		this.keyEvent = (e) => {
			const keyCode = parseInt(e.keyCode, 10);
			const colorKeys = [KeyCodes.number1, KeyCodes.number2, KeyCodes.number3];
			const arrowDownKey = KeyCodes.arrowDown;
			const colorKeyIndex = colorKeys.indexOf(keyCode);

			let tagId = 0;
			if (this.lastAddedTag) {
				tagId = this.lastAddedTag;
			} else {
				tagId = parseInt(this.$element.find('.tag-item.selected .tag-element').data('tagId'), 10);
			}

			if (tagId && e.shiftKey && colorKeyIndex !== -1) {
				e.preventDefault();

				const colorId = this.colorsList[colorKeyIndex].id;
				this.setColor(tagId, colorId, e);
				return false;
			} else if (arrowDownKey === keyCode && !e.shiftKey) {
				e.preventDefault();
				const input = this.getElementInput();
				input.click();
			}
		};

		if (!this.keyEventHooked) {
			this.$document.keydown(this.keyEvent);
			this.keyEventHooked = true;
		}
	}

	removeKeyEvent() {
		if (this.keyEventHooked) {
			this.$document.off('keydown', this.keyEvent);
			this.keyEvent = null;
			this.keyEventHooked = false;
		}
	}

	isTaggingPossible() {
		return this.$rootScope.surveyAccess.full || this.$rootScope.surveyAccess.editFilteredResults;
	}
}

export const verbatimElementTags = {
	template,
	controller: VerbatimElementTagsController,
	bindings: {
		logId: '<',
		order: '<',
	},
};
