import { AclFeature } from '../components/acl/acl.config';

const tpl = require('./FilterCreator.html');
const $ = require('jquery');
const angular = require('angular');
const Entities = require('html-entities').AllHtmlEntities;

// zmienne deklarowane w tymn miejscu są współdzielone przez wszystkie instancje
let _protected = {};

class FilterCreatorController {
	constructor(
		$scope,
		surveyProviderService,
		filtersService,
		$element,
		modalService,
		alertsService,
		questionHelper,
		i18n,
		$state,
		$timeout,
		verbatimTagsService,
		aclManager,
		segmentService
	) {
		'ngInject';
		let ctrl = this;

		this.surveyProviderService = surveyProviderService;
		this.filtersService = filtersService;
		this.$scope = $scope;
		this.$rootScope = $scope.$root;
		this.$element = $element;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.questionHelper = questionHelper;
		this.verbatimTagsService = verbatimTagsService;
		this.i18n = i18n;
		this.$state = $state;
		this.aclManager = aclManager;
		this.segmentService = segmentService;
		this.entities = new Entities();

		_protected.$timeout = $timeout;

		this.edited = false;
		this.saved = false;

		this.questions = null;
		this.questionsById = null;
		this.isTagsLoaded = false;

		this.data = null;
		this.filterId = null;

		this.valid = true;
		this.showEmptySelect = false;

		this.digestOnValidateTimeout = null;

		// zmienne pomocne przy wyswietlaniu reguł
		this.ui = {
			questionRules: false,
			timeRules: false,
			respondentRules: false,
			tagRules: false,
			timeOptions: false,
		};

		this.dom = {};

		this.isNew = false;
		this.isCopy = false;
		this.inResults = false;
		this.isEditable = false;

		this.ruleControllers = {
			questions: [],
			time: null,
			respondent: [],
			tag: [],
		};

		this.locked = false;
		this.saved = false;

		this.headerText = this.i18n.__('Dodaj nowy filtr', 'reports');
		this.tooltipText = this.i18n.__(
			'Filtry to podstawowe narzędzie do analizy ankiety - pozwolą Ci w łatwy i szybki sposób dokonać podziału wyników badania. Możesz wyselekcjonować wypowiedzi konkretnej grupy respondentów (na podstawie danych), wyróżnić odpowiedzi na podstawie czasu lub tego, jaką odpowiedź respondent wybrał w konkretnym pytaniu. Wyniki otrzymane na podstawie filtrów możesz umieścić w osobnych raportach lub dodać do raportu zbiorczego.',
			'reports'
		);
	}

	$onInit() {
		let ctrl = this;
		this.dom.main = $(this.$element);
		this.filterId = this.resolve.id;
		this.inResults = this.resolve.inResults;
		this.isCopy = this.resolve.isCopy;
		this.isEditable = this.resolve.isEditable;

		if (this.resolve.filterData) {
			if (this.isCopy) {
				this.data = Object.assign({}, this.resolve.filterData);
			} else {
				this.data = this.resolve.filterData;
				this.headerText = this.i18n.__('Edycja filtra', 'reports');
			}

			this.checkData();
		} else if (this.filterId) {
			this.headerText = this.i18n.__('Edycja filtra', 'reports');
			this.filtersService.getFiltersPromise(this.filterId).then(
				(data) => {
					ctrl.data = data;
					ctrl.$scope.data = data;
					ctrl.checkData();
				},
				(error) => {
					console.error('Init filter creator:', error);
				}
			);
		} else {
			this.isNew = true;
			this.data = this.filtersService.getEmptyFilterData();

			this.$scope.data = this.data;
			this.checkData();
		}

		if (!this.isEditable) {
			this.headerText = this.i18n.__('Parametry zastosowanego filtra', 'reports');
			this.tooltipText = this.i18n.__(
				'Twórca badania udzielił Ci dostępu do przefiltrowanych wyników. Oznacza to, że nie widzisz wszystkich odpowiedzi udzielonych w badaniu. W tym miejscu poznasz parametry nałożonego filtra, czyli dowiesz się na podstawie jakich odpowiedzi lub cech respondenta wyselekcjonowane zostały wyniki, które widzisz.',
				'reports'
			);
		}

		// pobiera wszystkie pytania i dopiero jak pobierze to można coś robic
		// warunek jest w HTMLu
		this.surveyProviderService.loadAllQuestions(
			this,
			(data) => {
				this.parseLoadedData(data);
				this.validate();
			},
			false
		);

		this.verbatimTagsService.getTagsListPromise().then((tags) => (this.isTagsLoaded = true));

		// pokazuje modal jak uzytkownik cos zmieni w filtrze ale nie zapisze
		this.$scope.$on('modal.closing', function functionName(ev) {
			if (!ctrl.canCreateFilter() || !ctrl.isEditable) {
				ctrl.edited = false;
				ctrl.modalService.close();
				ctrl.close();
				ctrl.surveyProviderService.interrupt();
				return;
			}

			if (ctrl.edited && !ctrl.saved) {
				ev.preventDefault();
				let config = {
					size: '500',
					title: ctrl.i18n.__('Filtr nie został zapisany', 'reports'),
					text: ctrl.i18n.__('Czy na pewno chcesz przerwać edycję?', 'reports'),
					icon: {
						font: 'fa-question',
						color: 'gray',
					},
					button: {
						text: ctrl.i18n.__('Zamknij bez zapisywania', 'reports'),
						type: 'button--big button--gray',
					},
					button2: {
						text: ctrl.i18n.__('Kontynuuj edycję', 'reports'),
						type: 'button--big',
					},
				};
				ctrl.modalService.show(config).then((data) => {
					if (data === 'button2') {
						// edit
						ctrl.modalService.close();
						ev.preventDefault();
						// console.log('edit');
					}

					if (data === 'button1') {
						// close
						ctrl.edited = false;
						ctrl.modalService.close();
						ctrl.close();
						ctrl.surveyProviderService.interrupt();
					}
				});
			} else if (ctrl.edited && ctrl.saved) {
				// wyedytowane i zapisane - zaktualizuj liste filtrów
				ctrl.filtersService.invalidate({
					onlyBasic: true,
				});
			}
			setTimeout(() => {
				let select = document.querySelectorAll('.ui-select-container.timeRuleSelect');
				try {
					angular.element(select).scope().$select.open = false;
				} catch (error) {
					console.error(error);
				}
			}, 1);

			if (ctrl.resolve.onCloseCallback && typeof ctrl.resolve.onCloseCallback === 'function') {
				ctrl.resolve.onCloseCallback(!ctrl.saved, ctrl.data);
			}
		});
	}

	$onDestroy() {
		clearTimeout(this.digestOnValidateTimeout);
		this.dom.main.off('click');
	}

	checkData() {
		this.hasQuestionRules = Boolean(
			this.data.questionRules && this.data.questionRules.length && this.data.questionRules[0]
		);
		this.ui.questionRules = this.hasQuestionRules;

		this.ui.timeRules = Boolean(Number(this.data.period));

		this.hasRespondentRules =
			Boolean(this.data.respondentRules) && this.data.respondentRules.length !== 0;
		this.ui.respondentRules = this.hasRespondentRules;

		this.hasTagRules = this.data.tagRules && this.data.tagRules.length !== 0;
		this.ui.tagRules = this.hasTagRules;

		// if (!this.hasQuestionRules && !this.ui.timeRules && !this.hasRespondentRules) {
		if (this.isNew) {
			this.ui.questionRules = true;
		} else if (!this.hasQuestionRules && !this.hasRespondentRules && !this.hasTagRules) {
			this.ui.timeRules = true;
		}
		// }

		if (this.isCopy) {
			this.data.title += this.i18n._s(' %skopia%s', '(', ')', 'reports');
		}

		this.$scope.title = this.entities.decode(this.data.title);

		_protected.$timeout(() => {
			this.$scope.$apply();
		});

		this.validate();
	}

	parseLoadedData(data) {
		this.questions = [];
		this.questionsById = {};

		for (let i = 0; i < data.length; i++) {
			if (data[i].type !== this.questionHelper.getTypes().staticText) {
				data[i].title = this.prepareString(data[i].title);

				if (data[i].rows) {
					data[i].rows.forEach((row) => {
						row.title = this.prepareString(row.title);
					});
				}

				if (data[i].answers) {
					data[i].answers.forEach((answer) => {
						answer.title = answer.title ? this.prepareString(answer.title) : null;
					});
				}

				if (data[i].columns) {
					data[i].columns.forEach((column) => {
						column.title = this.prepareString(column.title);
					});
				}

				if (data[i].title && data[i].title.length > 155) {
					data[i].title = data[i].title.substr(0, 155) + '...';
				}

				this.questions.push(data[i]);
				this.questionsById[data[i].id] = data[i];
			}

			if (data[i].type === this.questionHelper.getTypes().form) {
				data[i].answers.forEach((answer) => {
					for (let prop in answer) {
						if (prop !== 'title') {
							answer[prop] = this.prepareString(answer[prop]);
						}
					}
				});
			}
		}
		return data;
	}

	prepareString(string) {
		return this.entities.decode(string).replace(/<(?:.|\n)*?>/gm, '');
	}

	addQuestionRule(showEmpty = false, index = -1) {
		let ctrl = this;

		this.showEmptySelect = showEmpty;

		if (this.emptyQuestionRuleSelected) {
			let newRule = this.filtersService.getEmptyQuestionRule();

			newRule.question = this.emptyQuestionRuleSelected.id;
			newRule.type = this.emptyQuestionRuleSelected.type;

			if (!this.data.questionRules) {
				this.data.questionRules = [];
			}

			if (index === -1) {
				this.data.questionRules.push(newRule);
			} else {
				this.data.questionRules.splice(index, 0, newRule);
			}

			this.edited = true;
			this.emptyQuestionRuleSelected = null;
		}
	}

	removeQuestionRule(index, ruleData) {
		if (index !== undefined && index !== null) {
			this.data.questionRules.splice(index, 1);
			this.edited = true;
		} else if (ruleData) {
			let index = this.data.questionRules.indexOf(ruleData);
			if (index !== -1) {
				this.data.questionRules.splice(index, 1);
				this.edited = true;

				return index;
			}
		}
	}

	addRespondentRule(ruleData) {
		this.edited = true;
		if (ruleData) {
			this.data.respondentRules.push(ruleData);
		} else {
			this.data.respondentRules.push(this.filtersService.getEmptyRespondentRule());
		}
	}

	removeRespondentRule(index) {
		// console.log('removeRespondentRule', index, this.data.respondentRules, this.data.respondentRules[index], tmp, tmp[index], tmp.splice(index, 1)[0]);
		if (index !== undefined) {
			this.data.respondentRules.splice(index, 1);
			this.edited = true;
		}
	}

	addTagRule(ruleData) {
		this.edited = true;

		if (ruleData) {
			this.data.tagRules.push(ruleData);
		} else {
			this.data.tagRules.push(this.filtersService.getEmptyTagRule());
		}
	}

	removeTagRule(index) {
		if (index !== undefined) {
			this.data.tagRules.splice(index, 1);
			this.edited = true;
		}
	}

	questionSelectChange(item, selected, isNew = false, rule = null) {
		this.valid = false;
		this.edited = true;
		this.surveyProviderService.interrupt();
		if (isNew) {
			this.addQuestionRule();
		} else {
			rule.answers = [];

			this.$scope.$digest();
		}
	}

	nameEditorOnChange() {
		if (this.$scope.title && this.$scope.title !== this.data.title) {
			// poprawny tytuł
			this.data.title = this.$scope.title;
			this.edited = true;
			if (!this.valid) {
				this.validate();
			}
		} else {
			this.valid = false;
		}
	}

	onChangeTimeRule() {
		this.edited = true;
		this.validate();
	}

	save(activeAndGo) {
		if (this.locked || this.saved) {
			return;
		}

		this.locked = true;

		const handleError = (errorData) => {
			this.locked = false;
			this.alertsService.error(this.i18n.__('Wystąpił błąd podczas tworzenia filtra!', 'reports'));
			console.error(errorData);
		};

		if (this.filterId && !this.isCopy) {
			this.filtersService.patchFilterPromise(this.filterId, this.data).then(
				() => {
					this.saved = true;
					this.close();
					this.locked = false;

					this.segmentService.track('Dodano nowy filtr');

					if (activeAndGo) {
						this.filtersService.quickActivateFilter(this.filterId);
						parent.location.href = `/user#angular?r=/analyze/aggregatedResults`;
					}
				},
				(data) => handleError(data)
			);
		} else {
			this.filtersService.postFilterPromise(this.data).then(
				(data) => {
					this.saved = true;
					this.close();
					this.locked = false;

					this.segmentService.track('Dodano nowy filtr');

					if (
						this.resolve.onCreateCallback &&
						typeof this.resolve.onCreateCallback === 'function'
					) {
						this.resolve.onCreateCallback(data);
					}

					if (activeAndGo) {
						this.filtersService.quickActivateFilter(data.id);
						parent.location.href = `/user#angular?r=/analyze/aggregatedResults`;
					}
				},
				(data) => handleError(data)
			);
		}
	}

	setEdited(val) {
		this.edited = val;
	}

	isValid() {
		return this.valid;
	}

	/**
	 * validate - iteruje po regulach i sprawdza czy sie waliduja
	 *
	 * @return {type}  description
	 */
	validate() {
		let ctrl = this;
		let rulesValid = true;

		clearTimeout(this.digestOnValidateTimeout);

		if (
			(this.ruleControllers.questions && this.ruleControllers.questions.length) ||
			(this.ruleControllers.respondent && this.ruleControllers.respondent.length) ||
			(this.ruleControllers.tag && this.ruleControllers.tag.length) ||
			this.ruleControllers.time
		) {
			this.ruleControllers.questions.forEach(function functionName(item, i) {
				if (!item.isValid()) {
					rulesValid = false;
				}
			});

			this.ruleControllers.respondent.forEach(function functionName(item, i) {
				if (!item.isValid()) {
					rulesValid = false;
				}
			});

			this.ruleControllers.tag.forEach((item) => {
				if (!item.isValid()) {
					rulesValid = false;
				}
			});

			if (this.ruleControllers.time && !this.ruleControllers.time.isValid()) {
				rulesValid = false;
			}
		} else {
			rulesValid = false;
		}

		this.valid = rulesValid;

		this.digestOnValidateTimeout = setTimeout(function () {
			ctrl.$scope.$apply();
		}, 1);
	}

	addQuestion(data) {
		// NOOP - używane przez SurveyProviderService podczas ładowania pytan
	}

	addQuestionError(data) {
		this.alertsService.error(this.i18n.__('Błąd połączenia z serwerem ', 'reports'));
	}

	addQuestionRuleController(cont) {
		this.ruleControllers.questions.push(cont);
		this.validate();
	}

	addTimeRuleController(cont) {
		this.ruleControllers.time = cont;
		this.validate();
	}

	addRespondentRuleController(cont) {
		this.ruleControllers.respondent.push(cont);
		this.validate();
	}

	addTagRuleController(controller) {
		this.ruleControllers.tag.push(controller);
		this.validate();
	}

	removeQuestionRuleController(cont) {
		this.ruleControllers.questions.splice(this.ruleControllers.questions.indexOf(cont), 1);
		this.validate();
	}

	removeTimeRuleController(cont) {
		this.ruleControllers.time = null;
		this.validate();
	}

	removeRespondentRuleController(cont) {
		this.ruleControllers.respondent.splice(this.ruleControllers.respondent.indexOf(cont), 1);
		this.validate();
	}

	removeTagRuleController(controller) {
		this.ruleControllers.tag.splice(this.ruleControllers.tag.indexOf(controller), 1);
		this.validate();
	}

	getQuestionIndex(question) {
		return this.questionHelper.getQuestionIndex(question, this.questions);
	}

	isVerbatimsEnabled() {
		return this.aclManager.isFeatureEnabled(AclFeature.moduleVerbatims);
	}

	canCreateFilter() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}
}

export const filterCreator = {
	template: tpl,
	controller: FilterCreatorController,
	bindings: {
		resolve: '<',
		close: '&',
		onCreateCallback: '<',
		onCloseCallback: '<',
	},
};
