function textOverflowDirective($timeout, i18n) {
	'ngInject';

	// let maxHeight = 350;
	let maxHeight = 150;
	let expandText = i18n.__('rozwiń', 'reports');
	let contractText = i18n.__('zwiń', 'reports');

	return {
		restrict: 'AE',
		scope: {},
		link: (scope, element, attrs) => {
			scope.expandText = expandText;

			scope.onClick = (e) => {
				if (scope.initOverflow) {
					element.toggleClass('showAll');
					scope.expandText = scope.expandText === expandText ? contractText : expandText;
				}
			};

			$timeout(() => {
				let height = element[0].offsetHeight;

				if (height > maxHeight) {
					scope.initOverflow = true;
				}
			});
		},
		transclude: true,
		template:
			'<div ng-class="{\'htmlContent__initOverflow\': initOverflow}"><ng-transclude></ng-transclude> <span ng-click="onClick($event)" class="htmlContent__expand">{{expandText}}<i></i></span></div>',
	};
}

export default textOverflowDirective;
