const tpl = require('./QuestionNumber.html');

class QuestionNumberController {
	constructor($scope, $element, i18n, questionHelper) {
		'ngInject';

		this.$scope = $scope;
		this.$element = $element;
		this.i18n = i18n;
		this.questionHelper = questionHelper;

		this.chartBarLimit = 10;
		// this.chartSolidColor = '#98b1bd';
		this.chartSolidColor = '';

		this.chart = null;
		this.mainWrapper = null;
		this.chartContainer = null;

		this.inited = false;
		this.ready = false;
	}

	$onInit() {
		this.questionData = this.questionHelper.filterDotForNumeric(this.questionData);
		this.chart = this.questionCtrl.getChart();
		this.mainWrapper = this.$element[0].children[0];
		this.ready = true;
		this.questionCtrl.setChild(this);
	}

	$onChanges(changes) {
		if (changes && changes.reportData && changes.reportData.currentValue) {
			this.chartSolidColor = this.reportData.theme.chartColor || '#98b1bd';
		} else if (this.questionCtrl.pageWrapper) {
			this.chartSolidColor = '#98b1bd';
		}
	}

	isReady() {
		return this.ready && this.inited;
	}

	update(newTheme) {
		if (this.inited && newTheme.chartColor && this.chartSolidColor !== newTheme.chartColor) {
			this.chartSolidColor = newTheme.chartColor;
		}
	}

	chartInitialized() {
		let categories = [];
		let i;
		let max =
			this.questionData.answers.length < this.chartBarLimit
				? this.questionData.answers.length
				: this.chartBarLimit;

		this.chart = this.questionCtrl.getChartController().getChart();

		if (
			this.chart.renderTo &&
			this.chart.renderTo.children &&
			this.chart.renderTo.children.length
		) {
			this.chartContainer = this.chart.renderTo.children[0];
		} else {
			this.chartContainer = null;
		}

		for (i = 0; i < max; i++) {
			categories[i] = this.questionData.answers[i].title;
		}

		this.chart.update(
			{
				plotOptions: {
					column: {
						dataLabels: {
							enabled: true,
							style: {
								color: '#032c3e',
								fontSize: '12px',
								fontWeight: 'normal',
								textOutline: '2px 2px contrast',
							},
							useHTML: false,
							format: '{point.percent}%<br><em>({y})</em>',
						},
					},
				},
				chart: {
					spacingLeft: 22,
					spacingRight: 22,
				},
				xAxis: {
					labels: {
						enabled: true,
					},
					categories,
				},
			},
			true
		);

		if (this.chartContainer) {
			this.$scope.$watch(
				() => this.chart.renderTo.clientWidth,
				() => {
					try {
						if (
							this.chartContainer.clientWidth &&
							this.mainWrapper.clientWidth &&
							this.chartContainer.clientWidth > this.mainWrapper.clientWidth
						) {
							this.chart.reflow();
						}
					} catch (e) {
						console.error(e);
					}
				}
			);
		}

		this.inited = true;
	}
}

export const questionNumber = {
	template: tpl,
	controller: QuestionNumberController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		reportData: '<',
	},
};
