import tpl from './ChartSingleType.html';
import jquery from 'jquery';
import angular from 'angular';
import highcharts from 'highcharts';
let { AbstractChartController } = require('./AbstractChartController');

class ChartSingleTypeController extends AbstractChartController {
	constructor($element, chartColorHelper) {
		'ngInject';

		super(chartColorHelper);

		this.$element = $element;
		this.chartColorHelper = chartColorHelper;

		this.chartConfig = {
			chart: {
				type: 'pie',
				boderWidth: 0,
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: false,
					},
				},
			},
			title: {
				text: undefined,
			},
			colors: this.chartColorHelper.getColorsTableForChart(),
		};
	}

	$onInit() {
		let ctrl = this;
		let dataSeries = this.createSeries(this.questionTitle, this.answers);
		let config = this.createConfig(dataSeries);

		this.chart = highcharts.chart(jquery(this.$element).find('.chart')[0], config);

		if (this.questionCtrl) {
			this.questionCtrl.setChartReference(this);
		}
	}
}

export const chartSingleType = {
	template: tpl,
	controller: ChartSingleTypeController,
	require: {
		questionCtrl: '?^aRQuestion',
	},
	bindings: {
		answers: '<',
		questionTitle: '<',
	},
};
