import angular from 'angular';
import { react2angular } from 'react2angular';

// Global components
import Pagination from './components/table/pagination/Pagination';

export const ReactModule = angular
	.module('reactComponents', [])
	.component(
		'pagination',
		react2angular(Pagination, ['activePage', 'limit', 'total', 'loading', 'onPageChange'])
	).name;
