import angular from 'angular';

import { SettingsWrapper } from './SettingsWrapper/SettingsWrapper';
import { SubMenuSettings } from './SubMenuSettings/SubMenuSettings';
import { AclFeature } from '../components/acl/acl.config';

angular
	.module('app.settings', ['app.tags'])
	.component('settingsWrapper', SettingsWrapper)
	.component('subMenuSettings', SubMenuSettings)
	.config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
		'ngInject';

		$locationProvider.html5Mode(false);
		$urlRouterProvider.otherwise('/');

		$stateProvider
			.state('settings', {
				url: '/settings',
				component: 'settingsWrapper',
				redirectTo: 'settings.tags',
				data: {
					title: 'Ustawienia konta',
				},
			})
			.state('settings.tags', {
				url: '/tags',
				component: 'tagsWrapper',
				data: {
					title: 'Tagi',
				},
				requiredFeature: AclFeature.moduleVerbatims,
			});
	});
