import tpl from './TagsList.html';

class TagsListController {}

export const TagsList = {
	template: tpl,
	controller: TagsListController,
	bindings: {
		tags: '<',
	},
};
