class AclService {
	constructor(aclApiService, $q) {
		'ngInject';

		this.aclApiService = aclApiService;
		this.$q = $q;
		this.agreementDetails = null;
	}

	initOffer() {
		var deferred = this.$q.defer();

		this.aclApiService.fetchOfferDetails().then((agreementDetails) => {
			this.agreementDetails = agreementDetails;
			deferred.resolve();
		});

		return deferred.promise;
	}

	isFeatureEnabled(name) {
		return this.agreementDetails.features.find((feature) => feature.name === name).available;
	}

	getPlanName() {
		return this.agreementDetails.planName;
	}

	hasDeprecatedResultsAccess() {
		const allowedPlans = ['Team', 'Enterprise', 'Custom'];

		return allowedPlans.includes(this.getPlanName());
	}
}

export { AclService };
