import { AbstractResource, AbstractResourceConfig } from './AbstractResource';

import tpl from '../ChooserTemplate/MultipleChooser.html';

class TagChooserController extends AbstractResource {
	constructor(i18n, analyzeSetStorage) {
		'ngInject';

		super(i18n, analyzeSetStorage);

		this.placeholder = i18n.__('Wybierz tagi', 'reports');
		this.multiSelectLabel = i18n.__('Tagi', 'reports');
		this.additionalInfo = i18n._s(
			'%sInformacja:%s%sMożna wybrać maksymalnie 30 tagów.',
			'<strong>',
			'</strong>',
			'<br/>',
			'reports'
		);

		this.selectedResource = [];
		this.selectedIndexes = [];

		this.maxItems = 30;
	}

	$onDestroy() {
		delete this.set.tags;
	}

	async fetchResource() {
		this.resourceList = $$await(await this.analyzeSetStorage.fetchTags());

		if (this.set.tags) {
			this.selectedResource = this.set.tags;

			this.selectedResource.forEach((selectedTag) => {
				const i = this.resourceList.findIndex((tag) => {
					return Number(tag.id) === Number(selectedTag.id);
				});

				if (i !== -1) {
					this.selectedIndexes.push(i);
				}
			});
		}

		this.set.tags = this.selectedResource;
	}

	onItemSelect(selectedItem, index, event) {
		event.stopPropagation();

		const indexInSelected = this.selectedIndexes.indexOf(index);

		if (indexInSelected === -1) {
			if (this.selectedResource.length === this.maxItems) {
				return;
			}

			this.selectedIndexes.push(index);
			this.selectedResource.push(selectedItem);
		} else {
			this.selectedIndexes.splice(indexInSelected, 1);
			this.selectedResource.splice(indexInSelected, 1);
		}
	}
}

TagChooserController.$inject = ['i18n', 'analyzeSetStorage'];

export const TagChooser = Object.assign(
	{
		template: tpl,
		controller: TagChooserController,
	},
	AbstractResourceConfig
);
