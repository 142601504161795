import tpl from './ReportsWrapper.html';

class ReportsWrapperController {
	constructor() {
		'ngInject';
	}
}

export const reportsWrapper = {
	template: tpl,
	controller: ReportsWrapperController,
};
