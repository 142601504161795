export const TrendsConfig = {
	setType: {
		question: 1,
		period: 2,
		tag: 3,
	},
	periodType: {
		weekly: 2,
		monthly: 3,
		quarterly: 4,
	},
	trendRange: {
		all: 1,
		specified: 2,
	},
};
