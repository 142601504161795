import tpl from '../TagCreator.html';

import { ManagementMode } from '../ManagementMode';

class TagCreatorController {
	constructor(i18n, verbatimTagsService, alertsService) {
		'ngInject';

		this.i18n = i18n;
		this.verbatimTagsService = verbatimTagsService;
		this.alertsService = alertsService;

		this.mode = ManagementMode.creating;

		this.loading = false;
		this.tagTitle = '';
	}

	save() {
		this.verbatimTagsService.createTagPromise(this.tagTitle).then(
			(tag) => {
				this.close({ $value: tag });
				this.alertsService.success(this.i18n.__('Tag został utworzony.', 'tags'));
			},
			(status) => {
				if (status === 400) {
					this.alertsService.error(this.i18n.__('Ta nazwa taga została już użyta.', 'tags'));
				} else {
					this.alertsService.error(this.i18n.__('Wystąpił błąd podczas tworzenia taga.', 'tags'));
				}
			}
		);
	}

	isValid() {
		return this.form.tagTitle.$valid;
	}

	isCreatingMode(mode) {
		return mode === ManagementMode.creating;
	}

	isEditingMode(mode) {
		return mode === ManagementMode.editing;
	}
}

export const TagCreator = {
	template: tpl,
	controller: TagCreatorController,
	bindings: {
		close: '&',
		dismiss: '&',
	},
};
