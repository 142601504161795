class VerbatimLogTagsService {
	constructor(surveyProviderService, Restangular, apiHelper) {
		'ngInject';

		this.surveyProviderService = surveyProviderService;
		this.restangular = Restangular;
		this.apiHelper = apiHelper;
	}

	getTagsForLog(logId) {
		// /api/{survey-id}/verbatim/tag/log/{log-id}
		// body:  {items:[{id (id relacji), tag_id, title, logId, color_id, color}]}

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('tag')
					.one('log', logId)
					.get()
					.then((data) => {
						data = data && data.plain ? data.plain() : data;
						resolve(data.items);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	addTagForLog(logId, tagId) {
		//POST  /api/{survey-id}/verbatim/tag/log

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('tag')
					.all('log')
					.post({ tag: tagId, log: logId })
					.then((data) => {
						resolve(data);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	removeTagForLog(relationId) {
		// DELETE /api/{survey-id}/verbatim/tag/log/relation/{relation_id}

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('tag')
					.one('log')
					.one('relation', relationId)
					.remove()
					.then((data) => {
						resolve();
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	//***colors

	getColors() {
		// /api/verbatim/tag/color
		// body:  {items: [{id, name}]}

		return new Promise((resolve, reject) => {
			this.restangular
				.one('verbatim')
				.one('tag')
				.one('color')
				.get()
				.then((data) => {
					data = data && data.plain ? data.plain() : data;
					resolve(data.items);
				}, this.apiHelper.handleDataError);
		});
	}

	setColor(tag, log, color) {
		// /api/{survey-id}/verbatim/tag/log/color
		// POST body:  {tag: id taga, log: id loga, color: id koloru}

		tag = parseInt(tag, 10);
		log = parseInt(log, 10);
		color = parseInt(color, 10);

		let data = { tag, log, color };

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('tag')
					.one('log')
					.post('color', data)
					.then((data) => {
						data = data && data.plain ? data.plain() : data;
						resolve(data);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	updateColor(relationId, colorId) {
		// PATCH /api/{survey_id}/verbatim/tag/log/relation/{relation_id}/color

		relationId = parseInt(relationId, 10);
		colorId = parseInt(colorId, 10);

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('tag')
					.one('log')
					.one('relation', relationId)
					.one('color')
					.patch({ color: colorId })
					.then((data) => {
						resolve();
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}

	removeColor(relationId) {
		// DELETE /api/{survey-id}/verbatim/tag/log/relation/{relation_id]/color

		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
				this.restangular
					.one(String(surveyId))
					.one('verbatim')
					.one('tag')
					.one('log')
					.one('relation', relationId)
					.one('color')
					.remove()
					.then((data) => {
						resolve(data);
					}, this.apiHelper.handleDataError);
			}, this.apiHelper.handleDataError);
		});
	}
}

export default VerbatimLogTagsService;
