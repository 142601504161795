import { AbstractResource, AbstractResourceConfig } from './AbstractResource';

import tpl from '../ChooserTemplate/SingleChooser.html';

class SegmentChooserController extends AbstractResource {
	constructor(i18n, analyzeSetStorage) {
		'ngInject';

		super(i18n, analyzeSetStorage);

		this.placeholder = i18n.__('Segment', 'reports');
		this.additionalInfo = i18n._s(
			'%sInformacja:%s%sDostępne są tylko te segmenty danych, które mają nie więcej niż 500 unikalnych wartości.',
			'<strong>',
			'</strong>',
			'<br/>',
			'reports'
		);
	}

	$onDestroy() {
		delete this.set.segment;
	}

	async fetchResource() {
		this.resourceList = $$await(await this.analyzeSetStorage.fetchSegments());

		if (this.set.segment) {
			this.selectedResource = this.set.segment;
		}
	}

	onSelectResource() {
		this.set.segment = this.selectedResource;
	}
}

SegmentChooserController.$inject = ['i18n', 'analyzeSetStorage'];

export const SegmentChooser = Object.assign(
	{
		template: tpl,
		controller: SegmentChooserController,
	},
	AbstractResourceConfig
);
