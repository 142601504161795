import tpl from './ranking-creator.html';
import angular from 'angular';
import { AbstractCreator } from '../../creator/abstract-creator';

class RankingCreatorController extends AbstractCreator {
	constructor(
		RANKINGS_CONFIG,
		$scope,
		i18n,
		rankingsService,
		modalService,
		analyzeSetStorage,
		segmentService
	) {
		'ngInject';

		super($scope, i18n, modalService, analyzeSetStorage);

		this.RANKINGS_CONFIG = RANKINGS_CONFIG;
		this.rankingsService = rankingsService;
		this.i18n = i18n;
		this.segmentService = segmentService;

		this.TYPES_OF_DATA_HELPER = [
			{
				type: RANKINGS_CONFIG.setType.question,
				text: i18n.__('Pytanie', 'reports'),
				icon: 'analyze-set-question',
				disabled: false,
				disabledTooltip: '',
			},
			{
				type: RANKINGS_CONFIG.setType.segment,
				text: i18n.__('Segment danych', 'reports'),
				icon: 'analyze-set-segment',
				disabled: false,
				disabledTooltip: i18n.__('Dostępne wkrótce'),
			},
			{
				type: RANKINGS_CONFIG.setType.tag,
				text: i18n.__('Tag', 'reports'),
				icon: 'analyze-set-tag',
				disabled: false,
			},
		];

		this.rankingData = {
			sets: [
				{
					type: RANKINGS_CONFIG.setType.question,
					details: true,
				},
				{
					type: RANKINGS_CONFIG.setType.npsMetric,
				},
			],
		};

		this.rankingBase = null;
	}

	async $onInit() {
		super.$onInit();

		if (this.resolve.rankingId) {
			this.getRankingData();
		} else {
			$$await(
				await Promise.all([
					this.analyzeSetStorage.fetchQuestionsForRanking(),
					this.analyzeSetStorage.fetchMetrics(),
				])
			);
			this.isPrefetchingCompleted = true;
		}
	}

	validate() {
		let firstValidationStatement;
		let secondValidationStatement = this.rankingData.sets[1].metric;

		if (this.rankingData.sets[0].type === this.RANKINGS_CONFIG.setType.question) {
			firstValidationStatement = this.rankingData.sets[0].question;
		} else if (this.rankingData.sets[0].type === this.RANKINGS_CONFIG.setType.segment) {
			firstValidationStatement = this.rankingData.sets[0].segment;
		} else if (this.rankingData.sets[0].type === this.RANKINGS_CONFIG.setType.tag) {
			firstValidationStatement = this.rankingData.sets[0].tags;
		}

		if (firstValidationStatement && secondValidationStatement) {
			return true;
		}
	}

	async getRankingData() {
		const rankingData = await this.rankingsService.getRankingDataPromise(this.resolve.rankingId);
		this.parseFetchedData(rankingData);
	}

	async save() {
		this.generating = true;

		const data = this.rankingsService.parsePreparedData(this.rankingData);

		let rankingResult;

		if (this.resolve.rankingId) {
			rankingResult = await this.rankingsService.updateRankingDataPromise(
				this.resolve.rankingId,
				data
			);
		} else {
			rankingResult = await this.rankingsService.createRankingPromise(data);
			this.segmentService.track('Dodano nowy ranking');
		}

		this.resolve.onCreateCallback(rankingResult);
	}

	async parseFetchedData(rankingData) {
		this.rankingData.title = rankingData.title;

		const [questions, segments, tags, metrics] = $$await(
			await Promise.all([
				this.analyzeSetStorage.fetchQuestionsForRanking(),
				this.analyzeSetStorage.fetchSegments(),
				this.analyzeSetStorage.fetchTags(),
				this.analyzeSetStorage.fetchMetrics(),
			])
		);

		rankingData.sets.forEach((set, index) => {
			this.rankingData.sets[index].type = set.type;

			if (set.type === this.RANKINGS_CONFIG.setType.question) {
				this.rankingData.sets[index].question = questions.find((question) => {
					const sameQuestions = Number(question.id) === Number(set.question);

					if (question.rowIndex) {
						return sameQuestions && Number(question.rowIndex) === Number(set.rowIndexes[0]);
					}

					return sameQuestions;
				});
			} else if (set.type === this.RANKINGS_CONFIG.setType.segment) {
				this.rankingData.sets[index].segment = segments.find(
					(segment) => Number(segment.rowIndex) === Number(set.rowIndexes[0])
				);
			} else if (set.type === this.RANKINGS_CONFIG.setType.npsMetric) {
				this.rankingData.sets[index].metric = metrics.find(
					(metric) => Number(metric.id) === Number(set.question)
				);
			} else if (set.type === this.RANKINGS_CONFIG.setType.ratingMetric) {
				this.rankingData.sets[index].metric = metrics.find((metric) => {
					const sameQuestions = Number(metric.id) === Number(set.question);

					if (metric.rowIndex) {
						return sameQuestions && Number(metric.rowIndex) === Number(set.rowIndexes[0]);
					}

					return sameQuestions;
				});
			} else if (set.type === this.RANKINGS_CONFIG.setType.tag) {
				this.rankingData.sets[index].tags = tags.filter((tag) => {
					return set.rowIndexes.indexOf(Number(tag.id)) !== -1;
				});
			}
		});

		this.rankingBase = angular.copy(this.rankingData);

		this.isPrefetchingCompleted = true;
	}

	isAnyChanges() {
		if (this.rankingBase && angular.toJson(this.rankingData) !== angular.toJson(this.rankingBase)) {
			return true;
		} else if (!this.rankingBase && (this.rankingData.sets[0].question || this.rankingData.title)) {
			return true;
		}

		return false;
	}

	closeWithConfirmation() {
		if (!this.isAnyChanges()) {
			this.close();
		} else {
			this.confirmClosing(this.i18n.__('Ranking nie został zapisany', 'reports'));
		}
	}
}

RankingCreatorController.$inject = [
	'RANKINGS_CONFIG',
	'$scope',
	'i18n',
	'rankingsService',
	'modalService',
	'analyzeSetStorage',
	'segmentService',
];

export const RankingCreatorComponent = {
	template: tpl,
	controller: RankingCreatorController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
