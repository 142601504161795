import Template from './image-section.html';
require('./image-section.scss');

class ImageSectionController {
	constructor(langService) {
		this.langService = langService;
	}

	getClass() {
		let cssClass = `image-section--${this.imagePosition}`;

		if (this.extraClass) {
			cssClass += ` ${this.extraClass}`;
		}

		return cssClass;
	}

	getImagePath() {
		const lang = this.langService.getLang() === 'pl' ? 'pl' : 'en';
		return `/images/user/empty-screens/${this.imageName}.${lang}.png 2x`;
	}
}

export const ImageSectionComponent = {
	template: Template,
	controller: ['langService', ImageSectionController],
	bindings: {
		imageName: '@',
		imagePosition: '@',
		extraClass: '@?',
	},
	transclude: {
		innerWrapper: '?innerWrapper',
	},
};
