import tpl from './multiple-select.html';
import angular from 'angular';

require('./multiple-select.scss');

class MultipleSelectController {
	constructor(EventEmitter, $scope, $compile, $transclude, $element) {
		'ngInject';

		this.EventEmitter = EventEmitter;

		this.$scope = $scope;
		this.$compile = $compile;
		this.transclude = $transclude;
		this.$element = $element;

		this.selected = [];
	}

	$onInit() {
		var templateEl = angular.element(tpl);

		this.transclude(this.$scope, (clonedContent) => {
			const tags = ['SELECT-MATCH', 'SELECT-ITEM'];

			Object.values(clonedContent).forEach((element) => {
				if (element.tagName && tags.indexOf(element.tagName) !== -1) {
					templateEl.find(element.tagName).replaceWith(element);
				}
			});

			this.$compile(templateEl)(this.$scope, (clonedTemplate) => {
				this.$element.append(clonedTemplate);
			});
		});
	}

	onItemSelect(selectedItem, event) {
		event.stopPropagation();

		const indexInSelected = this.findIndexInSelected(selectedItem);

		if (indexInSelected === -1) {
			if (this.selected.length === this.maxItems) {
				return;
			}

			this.selected.push(selectedItem);
		} else {
			this.selected.splice(indexInSelected, 1);
		}

		this.onSelect(
			this.EventEmitter({
				items: this.selected,
			})
		);
	}

	findIndexInSelected(item) {
		return this.selected.findIndex((element) => {
			return angular.equals(element, item);
		});
	}
}

export const MultipleSelectComponent = {
	controller: MultipleSelectController,
	bindings: {
		items: '<',
		selected: '<',
		maxItems: '<',
		onSelect: '&',
		label: '@',
		placeholder: '@',
		noItemsPlaceholder: '@',
		separator: '@',
	},
	transclude: true,
};
