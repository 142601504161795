import tpl from './trends-wrapper.html';

class TrendsWrapperController {
	constructor(
		$scope,
		$rootScope,
		i18n,
		modalService,
		alertsService,
		trendsService,
		userService,
		aclManager,
		segmentService
	) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.trendsService = trendsService;
		this.userService = userService;
		this.aclManager = aclManager;
		this.segmentService = segmentService;

		this.isLoading = true;

		this.trendsList = null;
		this.selectedFilter = null;
		this.selectedTrend = null;
		this.trendResult = null;
		this.trendModalShown = false;

		this.LOCAL_STORAGE_KEY = 'trend';
		this.COOKIE_KEY = 'infoTrends';

		this.ELEMENT_FOR_EMPTY = {
			id: null,
			title: this.i18n.__('Brak trendów', 'reports'),
			disabled: true,
		};

		this.trendsLoaded = false;
		this.noTrends = true;
		this.areTrendsEnabled = false;

		this.deprecatedFunctionText = this.i18n.__(
			'Dodawanie nowych analiz w wygaszanych funkcjach (Filtry, Kreator raportów, Tabele krzyżowe, Rankingi, Trendy) nie jest już możliwe. Zapraszamy do nowego modułu Wyników.',
			'user'
		);
	}

	$onInit() {
		this.bindFiltersActions();
		this.bindSurveyChange();
		this.bindWatchers();
		this.setTrendsCreationAvailability();
	}

	bindSurveyChange() {
		this.$scope.$on('SurveyChanged', () => {
			this.isLoading = true;

			this.selectedFilter = null;
			this.trendsList = [];
			this.selectedTrend = null;
			this.trendResult = null;

			this.removeTrendFromLocalStorage();
			this.setTrendsCreationAvailability();
		});
	}

	bindFiltersActions() {
		this.$scope.$on('filtersFetched', (event, data) => {
			this.$rootScope.$broadcast('loadingOn');

			this.fetchTrends().then(() => {
				this.selectedFilter = data.filterId;
				this.checkLocalStorage();
				this.isLoading = false;
				this.$scope.$apply();
				this.$rootScope.$broadcast('loadingOff');
			});
		});

		this.$scope.$on('filterChanged', (event, data) => {
			this.selectedFilter = data.filterId;
			this.trendsSelectChange(this.selectedTrend);
		});
	}

	bindWatchers() {
		this.$scope.$watch(
			() => this.trendsList,
			() => {
				if (!this.trendsList) {
					this.noTrends = true;
				} else {
					this.noTrends = Boolean(this.trendsList[0] && this.trendsList[0].id === null);
				}
			},
			true
		);

		this.$scope.$watch(
			() => this.trendsLoaded,
			() => {
				if (this.trendsLoaded) {
					this.sendAnalyticsEvent();
				}
			}
		);
	}

	sendAnalyticsEvent() {
		const getModuleState = () => {
			const isFeaturePurchased = this.aclManager.isFeatureEnabled(
				this.$rootScope.features.moduleTrends
			);

			if (!isFeaturePurchased) {
				return 'unavailable';
			} else if (isFeaturePurchased && !this.areTrendsEnabled) {
				return 'disabled';
			} else if (isFeaturePurchased && this.noTrends) {
				return 'enabled';
			} else {
				return 'activated';
			}
		};

		const moduleState = getModuleState();

		this.segmentService.track('Trendy - wejście', {
			state: moduleState,
		});
	}

	//Trend creation, editing and removing actions

	createNewTrend() {
		if (!this.trendModalShown) {
			this.trendModalShown = true;

			this.trendsService.openCreator({
				onCreateCallback: (createdTrend) => {
					if (!this.trendsList[0].id) {
						this.trendsList.splice(0, 1);
					}
					this.trendsList.unshift({
						id: createdTrend.id,
						title: createdTrend.title,
					});
					this.selectedTrend = this.trendsList[0];
					this.trendsSelectChange(this.selectedTrend);

					this.trendsService.closeCreator();
					this.alertsService.success(this.i18n.__('Pomyślnie utworzono trend', 'reports'));
				},
				onCloseCallback: () => {
					this.trendModalShown = false;
				},
			});
		}
	}

	emptyScreenOnButtonClick = () => {
		this.createNewTrend();
	};

	editTrend(event, index, trend) {
		event.stopPropagation();
		if (!this.trendModalShown) {
			this.trendModalShown = true;

			this.trendsService.openCreator({
				onCreateCallback: (editedTrend) => {
					let listItem = {
						id: editedTrend.id,
						title: editedTrend.title,
					};

					this.trendsList.splice(index, 1, listItem);

					if (this.selectedTrend && Number(this.selectedTrend.id) === Number(listItem.id)) {
						this.selectedTrend = listItem;
						this.trendsSelectChange(listItem);
					}

					this.trendsService.closeCreator();
					this.alertsService.success(this.i18n.__('Pomyślnie zedytowano trend', 'reports'));
				},
				onCloseCallback: () => {
					this.trendModalShown = false;
				},
				trendId: trend.id,
			});
		}
	}

	removeTrend(event, index, trend) {
		event.stopPropagation();

		if (trend.isReportConnected) {
			this.showDisabledModal();
			return;
		}

		this.confirmRemovingPromise().then(() => {
			this.trendsService.removeTrendPromise(trend.id).then(
				() => {
					this.trendsList.splice(index, 1);

					if (this.selectedTrend && Number(this.selectedTrend.id) === Number(trend.id)) {
						this.selectedTrend = null;
						this.trendResult = null;
					}
					if (this.trendsList.length === 0) {
						this.trendsList = [this.ELEMENT_FOR_EMPTY];
					}

					this.modalService.close();
					this.alertsService.success(this.i18n.__('Pomyślnie usunięto trend', 'reports'));
				},
				() => {
					this.modalService.close();
					this.showDisabledModal();
				}
			);
		});
	}

	confirmRemovingPromise() {
		let ctrl = this;

		return new Promise((resolve, reject) => {
			let config = {
				size: '400',
				title: ctrl.i18n.__('Usuwanie trendu', 'reports'),
				text: ctrl.i18n.__('Czy na pewno chcesz usunąć?', 'reports'),
				icon: {
					font: 'fa-trash',
					color: 'gray',
				},
				button: {
					text: ctrl.i18n.__('Anuluj', 'reports'),
					type: 'button--big button--gray',
				},
				button2: {
					text: ctrl.i18n.__('Tak, usuń', 'reports'),
					type: 'button--big',
				},
			};

			this.modalService.show(config).then((reason) => {
				if (reason === 'button1') {
					ctrl.modalService.close();
				}

				if (reason === 'button2') {
					ctrl.modalService.loading(this.i18n.__('Usuwanie trendu...', 'reports'));
					resolve();
				}
			});
		});
	}

	//Selects actions

	trendsSelectChange($item) {
		this.$rootScope.$broadcast('loadingOn');

		if ($item) {
			this.trendsService
				.getTrendResultPromise($item.id, this.selectedFilter)
				.then((trendResult) => {
					this.trendResult = trendResult;
					this.saveTrendInLocalStorage($item.id);

					this.$scope.$apply();
					this.$rootScope.$broadcast('loadingOff');
				});
		} else {
			this.trendResult = null;
			this.removeTrendFromLocalStorage();
			this.$rootScope.$broadcast('loadingOff');
		}
	}

	//Fetching data

	fetchTrends() {
		return new Promise((resolve) => {
			this.trendsService.getTrendsListPromise().then((trendsList) => {
				if (trendsList.length > 0) {
					this.trendsList = trendsList;
				} else {
					this.trendsList = [this.ELEMENT_FOR_EMPTY];
				}

				this.trendsLoaded = true;

				resolve();
			});
		});
	}

	//Local storage methods

	checkLocalStorage() {
		const idFromLocalStorage = this.getTrendFromLocalStorage();

		if (idFromLocalStorage) {
			this.selectedTrend = this.trendsList.find(
				(trend) => Number(trend.id) === Number(idFromLocalStorage)
			);
			this.trendsSelectChange(this.selectedTrend);
		} else if (!this.trendsList[0].disabled) {
			this.selectedTrend = this.trendsList[0];
			this.trendsSelectChange(this.selectedTrend);
		}
	}

	saveTrendInLocalStorage(trendId) {
		window.localStorage.setItem(this.LOCAL_STORAGE_KEY, trendId);
	}

	removeTrendFromLocalStorage() {
		window.localStorage.removeItem(this.LOCAL_STORAGE_KEY);
	}

	getTrendFromLocalStorage() {
		return window.localStorage.getItem(this.LOCAL_STORAGE_KEY);
	}

	//Reports methods

	addToReport() {
		let ctrl = this;

		this.$scope.$broadcast('addToReport', {
			filterId: this.selectedFilter,
			trendData: this.selectedTrend,
			successCallback: () => {
				ctrl.selectedTrend.isReportConnected = true;
			},
		});
	}

	showDisabledModal() {
		this.modalService.show({
			size: '400',
			title: this.i18n.__('Nie można usunąć trendu', 'reports'),
			text: this.i18n.__('Trend jest użyty w raporcie', 'reports'),
			icon: {
				font: 'fa-exclamation-triangle',
				color: 'gray',
			},
			button: {
				text: this.i18n.__('Zamknij', 'reports'),
				type: 'button--big button--gray',
				closeOnClick: true,
			},
		});
	}

	setTrendsCreationAvailability() {
		this.trendsService.getQuestionsForTrendPromise().then((data) => {
			this.areTrendsEnabled = !!data.length;
		});
	}

	canCreateTrend() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}

	canCreateReport() {
		return this.canCreateTrend();
	}
}

export const TrendsWrapperComponent = {
	template: tpl,
	controller: TrendsWrapperController,
};
