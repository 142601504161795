import tpl from './SentimentsWrapper.html';

class SentimentsWrapperController {
	constructor(
		$rootScope,
		aclManager,
		segmentService,
		i18n,
		sentimentDistributionStorage,
		sentimentTrendStorage,
		tagsStorage,
		sentimentService
	) {
		'ngInject';

		this.$rootScope = $rootScope;
		this.aclManager = aclManager;
		this.segmentService = segmentService;
		this.i18n = i18n;

		this.isSendEventDisabled = false;

		this.sentimentDistributionStorage = sentimentDistributionStorage;
		this.sentimentTrendStorage = sentimentTrendStorage;
		this.tagsStorage = tagsStorage;
		this.sentimentService = sentimentService;

		this.sentimentDistributionData = null;
		this.sentimentTrendData = null;
		this.tags = null;

		this.areSentimentsEnabled = false;

		this.emptyScreenStorageKeyName = 'sentiment_empty_screen_visited';
	}

	$onInit() {
		this.initObservers();

		this.$rootScope.$on('SurveyChanged', () => {
			this.tagsStorage.removeObserver(this.tagsObserver);
			this.isSendEventDisabled = true;
		});
	}

	$onDestroy() {
		this.removeObservers();
	}

	initObservers() {
		this.distributionObserver = this.sentimentDistributionStorage.addObserver((data) => {
			this.sentimentDistributionData = data;
		});

		this.trendObserver = this.sentimentTrendStorage.addObserver((data) => {
			this.sentimentTrendData = data;
		});

		this.validModelObserver = this.sentimentService.addObserver((data) => {
			const isValidModel = data;

			if (this.isSendEventDisabled) {
				return;
			}

			this.tagsObserver = this.tagsStorage.addObserver((data) => {
				this.tags = data;
				this.sendAnalyticsEvent(isValidModel);
			});
		});
	}

	removeObservers() {
		this.sentimentDistributionStorage.removeObserver(this.distributionObserver);
		this.sentimentTrendStorage.removeObserver(this.trendObserver);
		this.tagsStorage.removeObserver(this.tagsObserver);
		this.sentimentService.removeObserver(this.validModelObserver);
	}

	sendAnalyticsEvent(isValidModel) {
		if (this.isSendEventDisabled) {
			return;
		}

		const getModuleState = () => {
			const isFeaturePurchased = this.aclManager.isFeatureEnabled(
				this.$rootScope.features.moduleVerbatims
			);

			const noTags = this.tags && this.tags.length === 0;

			if (!isFeaturePurchased) {
				return 'unavailable';
			} else if (isFeaturePurchased && noTags) {
				return 'disabled';
			} else if (isFeaturePurchased && !isValidModel) {
				return 'enabled';
			} else {
				return 'activated';
			}
		};

		const moduleState = getModuleState();

		this.segmentService.track('Analiza sentymentu - wejście', {
			state: moduleState,
		});

		this.isSendEventDisabled = true;
	}

	shouldDisplayEmptyScreen() {
		const noTags = this.tags && this.tags.length === 0;

		const isFeaturePurchased = this.aclManager.isFeatureEnabled(
			this.$rootScope.features.moduleVerbatims
		);

		this.areSentimentsEnabled = !noTags;

		if (noTags) {
			return true;
		}

		const surveyId = this.$rootScope.currentSurveyData && this.$rootScope.currentSurveyData.id;
		const keyNameWithSurveyId = `${this.emptyScreenStorageKeyName}_${surveyId}`;

		const wasButtonClicked = Boolean(Number(localStorage.getItem(keyNameWithSurveyId)));

		const isReadOnly = this.$rootScope.surveyAccess && this.$rootScope.surveyAccess.readOnly;
		const hasFilter = this.$rootScope.surveyAccess && this.$rootScope.surveyAccess.filter;

		const noSelectedTags = !this.sentimentTrendData || !this.sentimentDistributionData;

		return (
			!isFeaturePurchased || (!wasButtonClicked && noSelectedTags && !isReadOnly && !hasFilter)
		);
	}

	emptyScreenOnButtonClick = () => {
		const keyNameWithSurveyId = `${this.emptyScreenStorageKeyName}_${this.$rootScope.currentSurveyData.id}`;

		localStorage.setItem(keyNameWithSurveyId, 1);
	};
}

export const SentimentsWrapper = {
	template: tpl,
	controller: SentimentsWrapperController,
};
