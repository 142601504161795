const tpl = require('./FilterCreatorFormQuestion.html');

let { FilterCreatorAbstractQuestionController } = require('./FilterCreatorAbstractQuestion');

let priv = {};

class FilterCreatorFormQuestionController extends FilterCreatorAbstractQuestionController {
	constructor(
		$scope,
		surveyProviderService,
		reportHelper,
		i18n,
		$element,
		$timeout,
		filtersHelper
	) {
		'ngInject';

		super($scope, surveyProviderService, reportHelper, i18n, $element);

		priv.$timeout = $timeout;

		this.selectedCondition = null;
		this.selectedAnswer = null;
		this.selectedInternalAnswers = [];
		this.answers = [];

		this.data = {
			answerLabel: this.i18n.__('pole', 'reports'),
			answerData: null,
			conditionData: filtersHelper.getConditionsForForm(),
			conditionLabel: this.i18n.__('jest', 'reports'),
		};

		this.valueChangeTimeout = null;
	}

	$onInit() {
		super.$onInit();
	}

	$onChanges(changes) {
		if (changes.ruleData && changes.ruleData.isFirstChange()) {
			this.init();

			if (!this.ruleData.internalAnswers || !this.ruleData.internalAnswers.length) {
				this.ruleData.internalAnswers = [];
			}

			this.validate();
		}

		if (changes.question) {
			if (!changes.question.isFirstChange()) {
				this.onQuestionChange();
			}

			if (!this.reportHelper.getQuestionHelper().isForm(this.question)) {
				return;
			}

			this.$scope.condition = this.ruleData.condition;

			if (this.ruleData.condition !== null) {
				this.selectedCondition = Number(this.ruleData.condition);

				if (this.selectedAnswer && this.question.fields[this.selectedAnswer - 1] !== undefined) {
					this.data.conditionLabel = this.question.fields[this.selectedAnswer - 1].title;
				}
			}

			this.updateAnswers();
			this.answersLoading = true;
			this.surveyProviderService.continouslyLoadAnswers(
				this.question,
				(data) => {},
				() => {
					this.updateAnswers();
					this.answersLoading = false;
				},
				null,
				true
			);

			if (this.ruleData.internalAnswers && this.ruleData.internalAnswers.length) {
				this.selectedInternalAnswers = this.ruleData.internalAnswers.slice(0);
			}

			if (this.ruleData.answers && this.ruleData.answers.length) {
				this.selectedAnswer = Number(this.ruleData.answers[0]);

				let selected = Number(this.ruleData.answers[0]);
				this.column = [];
				this.internalAnswers.forEach((answer) => {
					if (this.column.indexOf(answer[selected]) === -1 && String(answer[selected]).length) {
						this.column.push(answer[selected]);
					}
				});
			} else {
				this.selectedAnswer = null;
			}

			this.data.answerData = this.question.fields.slice(0);
			this.data.answerData = this.data.answerData.map((item) => {
				item.index = Number(item.index);
				return item;
			});
		}
	}

	answerSelectChange(item, selected) {
		this.creator.setEdited(true);
		this.column = [];

		if (item) {
			this.ruleData.answers = [Number(this.selectedAnswer)];
			this.selectedInternalAnswers = [];
			this.ruleData.internalAnswers = [];

			this.selectedCondition = null;

			this.internalAnswers.forEach((answer) => {
				if (this.column.indexOf(answer[selected]) === -1 && String(answer[selected]).length) {
					this.column.push(answer[selected]);
				}
			});

			this.validate();
			this.creator.validate();
		}
	}

	internalAnswerSelectChange(item) {
		this.creator.setEdited(true);

		if (item !== undefined) {
			this.ruleData.internalAnswers = this.selectedInternalAnswers.slice(0);

			this.validate();
			this.creator.validate();
		}
	}

	internalAnswerSelectRemove(item) {
		this.ruleData.internalAnswers = this.selectedInternalAnswers.slice(0);
		this.creator.setEdited(true);
		this.validate();
		this.creator.validate();
	}

	optionClick(item, multiSelectController, ev, isLast) {
		this.creator.setEdited(true);

		if (this.selectedInternalAnswers.length && this.selectedInternalAnswers.indexOf(item) !== -1) {
			this.selectedInternalAnswers.splice(this.selectedInternalAnswers.indexOf(item), 1);
			this.ruleData.internalAnswers = this.selectedInternalAnswers.slice(0);

			ev.stopPropagation();
		}

		if (!this.selectedInternalAnswers.length || this.ruleData.condition === null) {
			this.valid = false;
			this.creator.validate();
		}
	}

	conditionSelectChange(item, selected) {
		this.ruleData.condition = selected;
		this.validate();
		this.creator.setEdited(true);
		this.creator.validate();
	}

	updateAnswers(onFinish) {
		this.internalAnswers = this.question.answers.slice(0);
		this.updateColumnData();
		priv.$timeout(() => {
			this.$scope.$apply();
		});
	}

	updateColumnData() {
		if (this.internalAnswers && this.internalAnswers.length && this.selectedAnswer) {
			let selected = this.selectedAnswer;
			this.internalAnswers.forEach((answer) => {
				if (this.column.indexOf(answer[selected]) === -1 && String(answer[selected]).length) {
					this.column.push(answer[selected]);
				}
			});
		}
	}

	validate() {
		if (
			this.ruleData.answers &&
			this.ruleData.answers.length &&
			this.ruleData.condition &&
			((this.ruleData.internalAnswers && this.ruleData.internalAnswers.length) ||
				this.ruleData.condition === 4 ||
				this.ruleData.condition === 5)
		) {
			this.valid = true;
		} else {
			this.valid = false;
		}
	}
}

export const filterCreatorFormQuestion = {
	template: tpl,
	controller: FilterCreatorFormQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
