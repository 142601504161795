const tpl = require('./FilterCreatorPointsQuestion.html');

let { FilterCreatorAbstractQuestionController } = require('./FilterCreatorAbstractQuestion');

class FilterCreatorPointsQuestionController extends FilterCreatorAbstractQuestionController {
	constructor($scope, surveyProviderService, reportHelper, i18n, filtersHelper) {
		'ngInject';

		super($scope, surveyProviderService, reportHelper, i18n);

		this.selectedAnswers = null;
		this.selectedCondition = null;

		this.data = {
			answerLabel: this.i18n.__('odpowiedź', 'reports'),
			answerData: null,
			conditionData: filtersHelper.getConditionsForPoints(),
			conditionLabel: this.i18n.__('jest', 'reports'),
		};
	}

	$onInit() {
		super.$onInit();
	}

	$onChanges(changes) {
		// console.log('onChanges', changes, (changes.question ? changes.question.isFirstChange() : false), (changes.ruleData ? changes.ruleData.isFirstChange() : false));
		if (changes.ruleData && changes.ruleData.isFirstChange()) {
			this.init();

			if (!this.ruleData.internalAnswers || !this.ruleData.internalAnswers.length) {
				this.ruleData.internalAnswers = [''];
			}

			this.validate();
		}

		if (changes.question) {
			if (!changes.question.isFirstChange()) {
				this.onQuestionChange();
			}

			this.selectedCondition = this.ruleData.condition;

			if (this.ruleData.answers && this.ruleData.answers.length) {
				this.selectedAnswers = Number(this.ruleData.answers[0]);
			} else {
				this.selectedAnswers = null;
			}

			this.data.answerData = this.question.answers.slice(0);
		}
	}

	answerSelectChange(item, selected) {
		// console.log('answerSelectChange', this.selectedAnswers, item, selected);
		this.creator.setEdited(true);
		if (item) {
			this.ruleData.answers = [Number(this.selectedAnswers)];

			this.validate();
			this.creator.validate();
		}
	}

	conditionSelectChange(item, selected) {
		this.ruleData.condition = selected;
		this.validate();
		this.creator.setEdited(true);
		this.creator.validate();
	}

	valueOnChange() {
		clearTimeout(this.valueChangeTimeout);
		this.creator.setEdited(true);
		this.valueChangeTimeout = setTimeout(() => {
			this.validate();
			this.creator.validate();
		}, 100);
	}

	validate() {
		let numberValidation = /^\d+$/;
		let isValid =
			this.ruleData.answers &&
			this.ruleData.answers.length &&
			(this.ruleData.condition || this.ruleData.condition === 0) &&
			this.ruleData.internalAnswers[0] !== '' &&
			numberValidation.test(this.ruleData.internalAnswers[0]);

		this.valid = isValid;
	}
}

export const filterCreatorPointsQuestion = {
	template: tpl,
	controller: FilterCreatorPointsQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
