import angular from 'angular';

import { EmptyScreenComponent } from './empty-screen/empty-screen.component';
import { ImageSectionComponent } from './image-section/image-section.component';
import { EmptyScreenListComponent } from './list/list.component';
import { CallToActionComponent } from './call-to-action-box/call-to-action-box.component';

export const EmptyScreenModule = angular
	.module('emptyScreen', [])
	.component('emptyScreen', EmptyScreenComponent)
	.component('imageSection', ImageSectionComponent)
	.component('emptyScreenList', EmptyScreenListComponent)
	.component('emptyScreenCta', CallToActionComponent).name;
