const tokenInterceptor = (Restangular, authService) => {
	'ngInject';

	Restangular.addFullRequestInterceptor(
		(element, operation, path, url, headers, params, httpConfig) => {
			const request = {
				element: element,
				headers: headers,
				params: params,
				httpConfig: httpConfig,
			};

			const token = authService.getToken();

			if (null === token) {
				return request;
			}

			request.headers['Authorization'] = `Bearer ${token}`;

			return request;
		}
	);
};

export { tokenInterceptor };
