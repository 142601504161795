import Template from './ranking-empty-screen.html';

class RankingEmptyScreenController {
	constructor(i18n) {
		this.i18n = i18n;
	}

	$onInit() {
		this.prepareLists();
	}

	prepareLists() {
		this.lists = {
			1: [
				this.i18n.__(
					'Twórz zestawienia najlepiej i najgorzej ocenianych elementów (np. działów czy regionów) tak by wiedzieć które działania promować i gdzie skierować swoje wysiłki naprawcze.',
					'user'
				),
			],
			2: [
				this.i18n.__(
					'Dodawaj rankingi do dashboardów i eksportuj jednym kliknięciem do PDF lub Excela',
					'user'
				),
			],
		};
	}

	getList(number) {
		return this.lists[number];
	}
}
export const RankingEmptyScreenComponent = {
	template: Template,
	controller: ['i18n', RankingEmptyScreenController],
	bindings: {
		onButtonClick: '<',
		enabled: '<',
	},
};
