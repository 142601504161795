import tpl from './SurveyPageWrapper.html';
// import '../../scss/surveyPage.scss';

const highcharts = require('highcharts');

class SurveyPageWrapperController {
	constructor(surveyProviderService, $scope, i18n, $timeout) {
		'ngInject';

		// this.questions = {};
		this.questions = [];
		this.surveyProviderService = surveyProviderService;
		this.$scope = $scope;
		this.i18n = i18n;
		this.$timeout = $timeout;

		this.isEmpty = false;
	}

	$onInit() {
		this.surveyProviderService.getQuestionsByPage(this.pageId, this, false, this.filterId).then(
			(data) => {
				// console.log('getQuestionsByPage done', this.questions, highcharts);
				// ctrl.questions = data;
				this.isEmpty = false;

				this.$timeout(() => {
					if (highcharts.charts.length) {
						highcharts.charts.forEach((chart, i) => {
							// console.log(chart.userOptions.chart.type);
							if (chart && chart.userOptions.chart.type === 'column') {
								chart.reflow();
							}
						});
					}
				});
			},
			(data) => {
				if (!this.questions.length) {
					this.isEmpty = true;
				}
			}
		);
	}

	addQuestion(data) {
		this.questions.push(data);
		this.surveyWrapper.addQuestion(data);
	}

	addQuestionError(data) {
		this.questions.push(data);
	}
}

export const surveyPageWrapper = {
	template: tpl,
	controller: SurveyPageWrapperController,
	require: {
		surveyWrapper: '^surveyWrapper',
	},
	bindings: {
		pageIndex: '@',
		pageId: '<',
		viewType: '@',
		filterId: '<',
	},
	transclude: true,
};
