import Template from './FiltersLabel.html';

require('./filtersLabel.scss');

class FiltersLabelController {
	constructor(i18n) {
		'ngInject';

		this.i18n = i18n;
		this.sort = {
			alpha: 0,
		};
	}

	changeOrder(e) {
		e.preventDefault();

		if (this.sort.alpha === 0) {
			this.sort.alpha = 1;
			return;
		}

		this.sort.alpha *= -1;
	}
}

export const FiltersLabelComponent = {
	template: Template,
	controller: FiltersLabelController,
};
