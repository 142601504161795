import angular from 'angular';
import { TrendsConfig } from './trends.config';
import { TrendsWrapperComponent } from './trends-wrapper/trends-wrapper.component';
import { TrendCreatorComponent } from './trend-creator/trend-creator.component';
import { TrendResultComponent } from './trend-result/trend-result.component';
import { TrendChartComponent } from './trend-chart/trend-chart.component';
import { TrendEmptyScreenComponent } from './trend-empty-screeen/trend-empty-screen.component';

require('./trends.scss');

export const TrendsModule = angular
	.module('trends', [])
	.constant('TRENDS_CONFIG', TrendsConfig)
	.component('trendsWrapper', TrendsWrapperComponent)
	.component('trendCreator', TrendCreatorComponent)
	.component('trendResult', TrendResultComponent)
	.component('trendChart', TrendChartComponent)
	.component('trendsEmptyScreen', TrendEmptyScreenComponent).name;
