import template from './VerbatimElement.html';

import { AppUrls } from '../../utils/AppUrls';
import { TagEvents } from '../../Settings/Tags/events.tags';

class VerbatimElementController {
	constructor(i18n, $scope, $rootScope, verbatimService, surveyProviderService, verbatimKeyboard) {
		'ngInject';

		this.i18n = i18n;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.verbatimService = verbatimService;
		this.surveyProviderService = surveyProviderService;
		this.verbatimKeyboard = verbatimKeyboard;

		this.currentLog = null;
	}

	$onInit() {
		this.currentLogObserver();
	}

	currentLogObserver() {
		let observer = this.$rootScope.$on(TagEvents.activeSheetChanged, ($event, data) => {
			if (!this.isEditingSheetPossible()) {
				return false;
			}
			this.currentLog = data.currentLog;
		});

		this.$scope.$on('$destroy', observer); //https://toddmotto.com/all-about-angulars-emit-broadcast-on-publish-subscribing/#rootscope-destroy
	}

	tagSheet($event, order) {
		if (!this.isEditingSheetPossible()) {
			return false;
		}

		if (this.currentLog !== order) {
			this.currentLog = order;

			this.verbatimKeyboard.updateCurrentLog(this.currentLog);
		}

		$event.stopPropagation();
	}

	editLog($event, id) {
		$event.stopPropagation();

		parent.window.location.href = `${AppUrls.singleSheet}?id=${id}`;
	}

	deleteLog($event, logId, orderId) {
		// $ctrl.sheet.meta.id, $ctrl.sheet.order
		$event.stopPropagation();

		this.verbatimService.deleteLog(logId, orderId).then(() => {
			parent.window.location.reload();
		});
	}

	isEditingSheetPossible() {
		return this.$rootScope.surveyAccess.full || this.$rootScope.surveyAccess.editFilteredResults;
	}

	isDeletingSheetPossible() {
		return this.$rootScope.surveyAccess.full;
	}
}

export const verbatimElement = {
	template,
	controller: VerbatimElementController,
	bindings: {
		sheet: '<',
	},
};
