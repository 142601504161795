import { QuestionChooser } from './QuestionChooser';

class QuestionRankingChooserController extends QuestionChooser.controller {
	constructor(i18n, analyzeSetStorage) {
		'ngInject';
		super(i18n, analyzeSetStorage);
	}

	async fetchResource() {
		this.resourceList = $$await(await this.analyzeSetStorage.fetchQuestionsForRanking());

		if (this.set.question) {
			this.selectedResource = this.set.question;
		}
	}
}

QuestionRankingChooserController.$inject = ['i18n', 'analyzeSetStorage'];

export const QuestionRankingChooser = Object.assign({}, QuestionChooser, {
	controller: QuestionRankingChooserController,
});
