import angular from 'angular';
import { RankingsConfig } from './rankings.config';
import { RankingsWrapperComponent } from './rankings-wrapper/rankings-wrapper.component';
import { RankingResultComponent } from './ranking-result/ranking-result.component';
import { RankingCreatorComponent } from './ranking-creator/ranking-creator.component';
import { RankingEmptyScreenComponent } from './ranking-empty-screen/ranking-empty-screen.component';

require('./rankings.scss');

export const RankingsModule = angular
	.module('rankings', [])
	.constant('RANKINGS_CONFIG', RankingsConfig)
	.component('rankingsWrapper', RankingsWrapperComponent)
	.component('rankingResult', RankingResultComponent)
	.component('rankingCreator', RankingCreatorComponent)
	.component('rankingsEmptyScreen', RankingEmptyScreenComponent).name;
