const tpl = require('./ExportDataModal.html');

class ExportDataModalController {
	constructor(i18n, $rootScope, $scope) {
		'ngInject';

		this.i18n = i18n;
		this.$rootScope = $rootScope;
		this.$scope = $scope;

		this.filterName = $rootScope.activeFilterName;

		this.description = this.i18n.__('Które wypełnienia chcesz wyeksportować?', 'reports');
	}

	$onInit() {
		this.optionData = this.resolve.optionData;
		this.onCancel = this.resolve.onCancel;
		this.fullCallback = this.resolve.fullCallback;
		this.completedOnlyCallkback = this.resolve.completedOnlyCallkback;

		this.checkDescription();
	}

	checkDescription() {
		if (this.$rootScope.surveyHasTimePeriodFilter) {
			this.description = this.i18n.__(
				'Masz zastosowany filtr, który zawiera przedział czasu dla zakończonych ankiet. Z tego powodu plik z wyeksportowanymi danymi, będzie zawierał tylko zakończone wypełnienia.',
				'reports'
			);
		} else {
			this.description = this.i18n.__('Które wypełnienia chcesz wyeksportować?', 'reports');
		}
	}

	fullClick() {
		this.fullCallback();
		this.close();
	}

	completedOnlyClick() {
		this.completedOnlyCallkback();
		this.close();
	}

	cancelClick() {
		if (this.onCancel) {
			this.onCancel();
		}
		this.$rootScope.xhr--;
		this.close();
	}

	exitClick() {
		this.$rootScope.xhr--;
		this.close();
	}
}

export const exportDataModal = {
	template: tpl,
	controller: ExportDataModalController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
