import tpl from './AggregatedResultsWrapper.html';
import { AclFeature } from '../components/acl/acl.config';
import { getCookie } from '../utils/Cookies';

class AggregatedResultsWrapperController {
	constructor(
		surveyProviderService,
		$scope,
		$rootScope,
		reportsService,
		modalService,
		alertsService,
		reportHelper,
		i18n,
		userService,
		$timeout,
		aggregatedModeService,
		exportService,
		aclManager,
		segmentService
	) {
		'ngInject';
		let ctrl = this;

		this.surveyProviderService = surveyProviderService;
		this.questionHelper = this.surveyProviderService.questionHelper;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.reportsService = reportsService;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.reportHelper = reportHelper;
		this.i18n = i18n;
		this.userService = userService;
		this.$timeout = $timeout;
		this.aggregatedModeService = aggregatedModeService;
		this.aclManager = aclManager;

		this.selectedFilterId = null;
		this.initLoading = true;

		this.unfinishedCount = 0;

		this.questionTypes = this.questionHelper.getTypes();
		this.surveyWrapper = null;

		this.fullAccess = false;

		this.DATA_INFO = window.DATA_INFO;

		this.aggregatedModes = this.aggregatedModeService.getModes();
		this.aggregatedModeVisibility = false;

		this.dataExportOptions = exportService.exportOptions();
		this.reportOptions = exportService.reportOptions();
		this.dataExportClick = exportService.dataExportClick();

		this.segmentService = segmentService;

		$scope.$on('SurveyChanged', () => {
			ctrl.selectedFilterId = null;
			ctrl.reportsService.clearCache('addToReport');
		});

		this.surveyFillCount = null;
	}

	$onInit() {
		this.bindFiltersActions();
		this.sendTrackingEvent();
	}

	bindFiltersActions() {
		this.$scope.$on('filtersFetched', (event, data) => {
			this.selectedFilterId = data.filterId;
			this.fullAccess = data.fullAccess;

			this.updateUnfinishedCount(data.filterId).then(
				() => {
					this.aggregatedModeService.getFromLocalStorage();

					if (this.selectedFilterId) {
						this.aggregatedModeService.changeMode(this.aggregatedModes.finished.mode);
					}

					this.$scope.$broadcast('SurveyReady');
				},
				() => {}
			);
		});

		const updateUnfinishedCountAndSetFilter = (event, data) => {
			this.updateUnfinishedCount(data.filterId);
			this.selectedFilterId = data.filterId;
		};

		this.$scope.$on('filterChanged', updateUnfinishedCountAndSetFilter);
		this.$scope.$on('filtersFetched', updateUnfinishedCountAndSetFilter);
	}

	sendTrackingEvent() {
		const userId = this.userService.getId();
		let eventLock = false;

		this.$scope.$on('SurveyReady', () => {
			eventLock = false;

			this.surveyFillCount = Number(this.$rootScope.currentSurveyData.fillCount);

			if (this.surveyFillCount >= 5) {
				this.segmentService.track('Wyniki - 5 odpowiedzi');
			}

			setTimeout(() => {
				this.segmentService.track('Wyniki zbiorcze 10 s');
			}, 10000);

			setTimeout(() => {
				if (eventLock) {
					return;
				}

				// empty screen context
				this.segmentService.track('Wyniki zbiorcze - wejście', {
					'Liczba wyników': this.surveyFillCount,
					projekt_beta_dostep: 0,
				});
			}, 3000);
		});

		// Results View context -> page-info request sent
		this.$scope.$on('LoadedPageInfo', (event, pageInfo) => {
			eventLock = true;

			this.segmentService.track('Wyniki zbiorcze - wejście', {
				'Liczba wyników': this.surveyFillCount,
				typy_pytania: pageInfo.questionTypes,
			});
		});
	}

	$onDestroy() {
		this.surveyProviderService.interruptQuestions();
		this.reportsService.clearCache();
	}

	updateUnfinishedCount(filterId) {
		return new Promise((resolve) => {
			this.surveyProviderService.checkUnfinishedPromise(filterId).then((data) => {
				this.unfinishedCount = data.count;

				resolve();
			});
		});
	}

	deleteCompletions() {
		this.segmentService.track('Usuń wszystkie wypełnienia click');
		this.showSingleResultsRemoveModal();
	}

	addEverythingToReport(opts) {
		opts = opts || {};

		if (!(opts && opts.onlyNew)) {
			opts.onlyNew = false;
		}

		this.reportHelper.openAddToReportModal({
			questionCtrl: null,
			questionControllers: this.surveyWrapper.getQuestionControllers(),
			addWholeSurvey: true,
			filterId: this.selectedFilterId,
			onlyNew: opts.onlyNew,
		});
	}

	setSurveyWrapper(ctrl) {
		this.surveyWrapper = ctrl;
	}

	isEmpty() {
		return this.surveyWrapper.isEmpty();
	}

	isAggregatedModeChangePossibility() {
		return this.surveyWrapper.isAggregatedModeChangePossibility();
	}

	isLoading() {
		const isLoading = this.surveyWrapper.isLoading();

		if (this.initLoading && !isLoading) {
			this.$timeout((this.initLoading = false));
		}

		return isLoading;
	}

	sendChangeEvent() {
		const label = this.aggregatedModeService.getLabel();

		this.segmentService.track('Zmiana widoku wypełnień', {
			'zmieniono na': label,
		});
	}

	sendMouseEnterEvent() {
		if (this.isAllAggregatedMode()) {
			this.segmentService.track('Eksport niedostępny hover');
		}
	}

	onAggregatedModeChange(aggregatedMode) {
		if (aggregatedMode === this.aggregatedModeService.getMode()) {
			return;
		}

		this.aggregatedModeVisibility = false;

		const self = this;
		const isFiltered = !!this.selectedFilterId;

		if (aggregatedMode === this.aggregatedModes.all.mode) {
			self.aggregatedModeService.changeMode(aggregatedMode);

			this.$scope.$broadcast('DisableFiltering', {
				callback: () => {
					self.$scope.$broadcast('SurveyReady');

					if (isFiltered) {
						this.alertsService.success(this.i18n.__('Filtr został wyłączony', true));
					}
				},
			});
		} else {
			self.aggregatedModeService.changeMode(aggregatedMode);

			this.$scope.$broadcast('EnableFiltering', {
				callback: () => {
					self.$scope.$broadcast('SurveyReady');
				},
			});
		}

		this.sendChangeEvent(aggregatedMode);
	}

	isAllAggregatedMode() {
		return this.aggregatedModeService.isAllAggregatedMode();
	}

	getAggregatedMode() {
		return this.aggregatedModeService.getMode();
	}

	getAggregatedLabel() {
		return this.aggregatedModeService.getLabel();
	}

	isReportsCreatorEnabled() {
		return this.aclManager.isFeatureEnabled(AclFeature.moduleReportsCreator);
	}

	closeNewRawExportModal() {
		const modal = document.querySelector('[data-target="single-result-modal"]');
		modal.classList.add('hidden');
	}

	showRawExportInstructionModal() {
		const modal = document.querySelector('[data-target="single-result-modal"]');

		modal.classList.remove('hidden');
	}

	closeSingleResultsRemoveModal() {
		const modal = document.querySelector('[data-target="single-results-remove-modal"]');
		modal.classList.add('hidden');
	}

	showSingleResultsRemoveModal() {
		const modal = document.querySelector('[data-target="single-results-remove-modal"]');

		modal.classList.remove('hidden');
	}

	getLegacyBannerText() {
		return this.i18n.__('Korzystasz z nieaktualnej wersji tej funkcji', 'user');
	}

	getLegacyBannerLinkText() {
		return this.i18n.__('Sprawdź ulepszone Wyniki 2.0', 'user');
	}

	canGenerateReport() {
		return this.aclManager.hasDeprecatedResultsAccess();
	}
}

export const aggregatedResultsWrapper = {
	template: tpl,
	controller: AggregatedResultsWrapperController,
};
