import angular from 'angular';

class SurveyProviderService {
	constructor(
		Restangular,
		UserRestangular,
		apiHelper,
		userService,
		questionHelper,
		$rootScope,
		aggregatedModeService,
		$q
	) {
		'ngInject';
		let self = this;

		this.userService = userService;
		this.apiHelper = apiHelper;
		this.questionHelper = questionHelper;
		this.$rootScope = $rootScope;
		this.restangular = Restangular;
		this.aggregatedModeService = aggregatedModeService;
		this.$q = $q;

		this.questions = [];
		this.restangularObjects = {
			questions: {},
		};

		this.accessLevels = {
			READ_ONLY: 1,
			ALL_INCLUSIVE: 2,
			FILTR: 3,
			EDIT_FILTR: 4,
		};

		let currentSurveyQuestions = null;
		let exportRestangular = UserRestangular.one('export');

		this.currentSurveyId = null;
		this.currentSurveyData = null;
		this.currentPagesInfo = null;
		this.surveysList = null;
		this.filterId = null;

		this.requestsLimit = 10;
		this.surveysListRestPromise = null;
		this.loadedQuestions = 0;
		this.questionsCount = 0;

		this.dataExportTypes = [
			'pdf',
			'pptx',
			'pdf_arkusz',
			'docx',
			'docx_arkusz',
			'arkusze',
			'spss',
			'xlsx_zbiorcze',
			'xlsx_surowe',
			'xlsx_arkusz',
			'csv_zbiorcze',
			'csv_surowe',
			'csv_arkusz',
			'respondenci_xlsx',
			'respondenci_csv',
			'kwestionariusz_wypelniony',
			'kwestionariusz_pusty',
		];

		this.state = {};
		this.state.loadingProcesses = {
			answers: false,
			others: false,
			matrix: false,
		};
		this.interruptQueries = false;
		this.questionsToLoad = {
			pages: {
				count: 0,
			},
			all: {
				items: [],
				count: 0,
			},
		};

		let getQuestionQueryString = (
			filterId,
			paginationPageNumber,
			globalFilter = true,
			limitPage = null
		) => {
			let query = {};
			let hasResult = false;

			if (filterId) {
				query.filter = filterId;
				hasResult = true;
			} else if (globalFilter && this.filterId) {
				query.filter = this.filterId;
				hasResult = true;
			}

			if (paginationPageNumber) {
				query.page = paginationPageNumber;
				hasResult = true;
			}

			if ($rootScope.userInfo.external) {
				hasResult = true;
				query.limit = 'report';
			}

			if (limitPage) {
				hasResult = true;
				query['custom-pagination-count'] = limitPage;
			}

			if (this.aggregatedModeService.isAllAggregatedMode()) {
				hasResult = true;
				query.unfinished = 1;
			}

			if (hasResult) {
				return query;
			}

			return null;
		};

		this.getCurrentSurveyId = () => this.currentSurveyId;
		this.currentSurveyIdPromise = null;
		this.getCurrentSurveyIdPromise = () =>
			new this.$q((resolve, reject) => {
				let savedId = this.userService.getUserSurveyInfo().surveyId;
				let found = false;

				if (!this.currentSurveyId) {
					this.init().then(() => {
						// console.log('savedId', savedId);
						if (savedId) {
							this.currentSurveyId = savedId;
							for (let i = 0; i < this.surveysList.length; i++) {
								if (Number(this.surveysList[i].id) === Number(this.currentSurveyId)) {
									found = true;
									break;
								}
							}

							if (!found) {
								this.currentSurveyId = this.surveysList[0].id;
							}

							this.currentSurveyData = null;
							this.setupCurrentSurveyData(this.currentSurveyId);

							resolve(this.currentSurveyId);
						} else {
							// console.log('else', this.surveysList);
							this.currentSurveyId = this.surveysList[0].id;
							// this.currentSurveyData = this.setCurrentSurveyId(this.currentSurveyId);
							this.currentSurveyData = this.setupCurrentSurveyData(this.currentSurveyId);
							// console.log('leci resolve', this.currentSurveyId, this.currentSurveyData);
							resolve(this.currentSurveyId);
						}
					});
				} else {
					if (!this.currentSurveyData || this.currentSurveyId !== this.currentSurveyData.id) {
						this.setupCurrentSurveyData(this.currentSurveyId);
					}
					resolve(this.currentSurveyId);
				}
			});
		this.getCurrentSurveyPromise = () =>
			new this.$q((resolve, reject) => {
				this.getCurrentSurveyIdPromise().then((id) => {
					resolve(this.currentSurveyData);
					return this.currentSurveyData;
				});
			});

		this.setupCurrentSurveyData = (id) => {
			// console.log('setupCurrentSurveyData', id, this.surveysList && this.surveysList.length);
			if (this.surveysList && this.surveysList.length) {
				for (let i = 0; i < this.surveysList.length; i++) {
					if (Number(this.surveysList[i].id) === Number(this.currentSurveyId)) {
						this.currentSurveyData = this.surveysList[i];

						break;
					}
				}
				if (!this.currentSurveyData) {
					this.currentSurveyData = this.surveysList[0];
				}
				this.$rootScope.surveyAccess = {};
				this.$rootScope.surveyAccess.readOnly =
					this.currentSurveyData.accessLevel === this.accessLevels.READ_ONLY;
				this.$rootScope.surveyAccess.full =
					this.currentSurveyData.accessLevel === this.accessLevels.ALL_INCLUSIVE;
				this.$rootScope.surveyAccess.filter =
					this.currentSurveyData.accessLevel === this.accessLevels.FILTR;
				this.$rootScope.surveyAccess.editFilteredResults =
					this.currentSurveyData.accessLevel === this.accessLevels.EDIT_FILTR;

				this.userService.setUserSurveyInfo(id);
			}

			return this.currentSurveyData;
		};

		this.getCurrentSurvey = () => this.currentSurveyData;

		this.invalidate = () => {
			this.interrupt();
			this.interruptQuestions();

			this.currentPagesInfo = null;
			this.restangularObjects = {
				questions: {},
			};
			this.questions = [];
			currentSurveyQuestions = null;
			this.currentSurveyData = null;
			this.surveysList = null;

			this.pagesInfoPromise = null;
			this.surveysListRestPromise = null;
			this.currentSurveyId = null;
			this.currentPagesInfo = null;
			this.filterId = null;

			this.userService.removeFilter();
			return this.getAllSurveysPromise(true);
		};

		this.interrupt = () => {
			// console.log('interrupt', this.state.loadingProcesses.answers, this.state.loadingProcesses.others, this.state.loadingProcesses.matrix);
			if (
				this.state.loadingProcesses.answers ||
				this.state.loadingProcesses.others ||
				this.state.loadingProcesses.matrix
			) {
				this.interruptQueries = true;
			} else {
				this.interruptQueries = false;
			}
		};
		this.finishInterrupt = () => {
			// console.log('finishInterrupt', this.state.loadingProcesses.answers, this.state.loadingProcesses.others, this.state.loadingProcesses.matrix);
			if (
				!this.state.loadingProcesses.answers &&
				!this.state.loadingProcesses.others &&
				!this.state.loadingProcesses.matrix
			) {
				this.interruptQueries = false;
			}
		};
		this.interruptQuestions = () => {
			// console.log('interruptQuestions');
			this.questionsToLoad = {
				pages: {},
				all: {
					items: [],
					count: 0,
				},
			};
		};

		this.init = () =>
			new this.$q(function (resolve, reject) {
				apiHelper.init().then(
					function functionName(data) {
						if (self.currentSurveyId) {
							resolve(true);
						} else {
							return self.getAllSurveysPromise().then(
								function done() {
									resolve(true);
									return true;
								},
								function error() {
									reject(false);
									return false;
								}
							);
						}
					},
					function error(data) {
						console.error(data);
						self.apiHelper.handleDataError(data);
						reject(false);
						return false;
					}
				);
			});

		let getAllSurveysPromiseCallback = (resolve) =>
			function done(data) {
				self.surveysList = data.surveys;

				resolve(self.surveysList);
				return self.surveysList;
			};
		this.getAllSurveysPromise = (forceRefresh) =>
			new this.$q((resolve, reject) => {
				// console.log('getAllSurveysPromise', self.surveysList);

				if (self.surveysList !== null && !forceRefresh) {
					resolve(self.surveysList);
					return self.surveysList;
				} else if (self.surveysListRestPromise) {
					return self.surveysListRestPromise.then(
						getAllSurveysPromiseCallback(resolve),
						self.apiHelper.handleDataError
					);
				}

				let listOpts = this.userService.getUserSurveyListInfo();
				if (listOpts) {
					// console.log('listOpts', listOpts);
					if (!listOpts.column) {
						delete listOpts.column;
					}
					if (!listOpts.order) {
						delete listOpts.order;
					}
				}
				self.surveysListRestPromise = null;
				return apiHelper.init().then(function functionName() {
					self.surveysListRestPromise = Restangular.one('survey').get(listOpts);
					return self.surveysListRestPromise.then(getAllSurveysPromiseCallback(resolve));
				});
				// }
			});

		this.getSurveyPromise = (surveyId) =>
			new this.$q((resolve, reject) => {
				// console.log('getSurveyPromise', surveyId);
				// `/api/survey/{survey_id}`

				surveyId = surveyId ? surveyId : this.currentSurveyId;
				// surveyId
				let getData = (surveyId, resolve, reject) => {
					if (!surveyId) {
						reject();
						return;
					}
					Restangular.one('survey', surveyId)
						.get()
						.then((data) => {
							resolve(data.plain());
							return data;
						}, reject);
				};

				if (!surveyId && !this.currentSurveyId) {
					this.getCurrentSurveyIdPromise().then((id) => getData(id, resolve, reject));
				} else if (self.surveysList) {
					getData(surveyId, resolve, reject);
				} else {
					self.init().then(() => getData(surveyId, resolve, reject), reject);
				}
			});

		this.loadAllQuestions = (questionsContainer, finishCallback, globalFilter = true) => {
			// console.log('loadAllQuestions', questionsContainer);
			return this.getQuestionsByPage(
				null,
				questionsContainer,
				false,
				null,
				finishCallback,
				globalFilter
			);
		};

		this.continouslyLoadAnswers = (
			questionData,
			successCallback,
			finishCallback,
			paginationPageNumber,
			isFilterCreator = false
		) => {
			// console.log('continouslyLoadAnswers', this.interruptQueries, this.state, this.filterId);
			let total = questionData.paginationTotalCount;
			let count = questionData.answers.length;
			let perPage = questionData.paginationPageCount;
			let pageNumber = paginationPageNumber ? paginationPageNumber : 1;
			let nextPageNumber = pageNumber + 1;

			const filterId = isFilterCreator ? null : this.filterId;

			if (this.interruptQueries) {
				this.state.loadingProcesses.answers = false;
				this.finishInterrupt();
				return;
			}

			if (count < total) {
				this.state.loadingProcesses.answers = true;
				this.getQuestionPromiseById(questionData.id, filterId, nextPageNumber).then(
					(data) => {
						questionData.answers = questionData.answers.concat(data.answers);
						if (successCallback) {
							successCallback(data, nextPageNumber);
						}
						this.continouslyLoadAnswers(
							questionData,
							successCallback,
							finishCallback,
							nextPageNumber
						);
					},
					(errData) => {
						this.state.loadingProcesses.answers = false;
						console.error(errData);
					}
				);
			} else {
				this.state.loadingProcesses.answers = false;
				if (finishCallback) {
					finishCallback(count);
				}
			}
		};
		this.getOthersForQuestionPromise = (
			questionId,
			answerIndex,
			page,
			filterId,
			useGlobalFilter,
			pageLimit = null
		) =>
			new this.$q((resolve, reject) => {
				// console.log('getOthersForQuestionPromise', questionId, answerIndex, page);
				page = page || 1;
				this.init().then(
					() => {
						let query = { index: answerIndex, page };
						if (filterId) {
							query.filter = filterId;
						}
						if (useGlobalFilter) {
							query.filter = this.filterId;
						}
						if ($rootScope.userInfo.external) {
							query.limit = 'report';
						}

						if (pageLimit) {
							query['custom-pagination-count'] = pageLimit;
						}

						if (this.aggregatedModeService.isAllAggregatedMode()) {
							query.unfinished = 1;
						}

						Restangular.one('results', questionId)
							.one('others')
							.get(query)
							.then((data) => {
								data = data.plain ? data.plain() : data;
								resolve(data);
							}, reject);
					},
					() => {}
				);
			});
		this.continouslyLoadOthers = (
			questionData,
			paginationData,
			answerIndex,
			items,
			successCallback,
			finishCallback,
			paginationPageNumber,
			filterId,
			useGlobalFilter
		) => {
			let total = paginationData ? paginationData.total : null;
			let count = items ? items.length : null;
			let pageNumber = paginationPageNumber ? paginationPageNumber : 1;
			let nextPageNumber = pageNumber + 1;

			// console.log('continouslyLoadOthers', items, count, total);
			if (this.interruptQueries) {
				this.state.loadingProcesses.others = false;
				this.finishInterrupt();
				return;
			}

			if (count < total) {
				this.state.loadingProcesses.others = true;
				this.getOthersForQuestionPromise(
					questionData.id,
					answerIndex,
					nextPageNumber,
					filterId,
					useGlobalFilter
				).then(
					(data) => {
						items = items.concat(data.items);
						if (successCallback) {
							successCallback(data.items, nextPageNumber, items);
						}
						this.continouslyLoadOthers(
							questionData,
							paginationData,
							answerIndex,
							items,
							successCallback,
							finishCallback,
							nextPageNumber,
							filterId,
							useGlobalFilter
						);
					},
					(errData) => {
						this.state.loadingProcesses.others = false;
						console.error(errData);
					}
				);
			} else if (finishCallback) {
				this.state.loadingProcesses.others = false;
				finishCallback(count);
			}
		};
		this.getDistributionForQuestionPromise = (questionId, answerIndex) =>
			new this.$q((resolve, reject) => {
				this.init().then(() => {
					let query = { index: answerIndex, filter: this.filterId };

					if ($rootScope.userInfo.external) {
						query.limit = 'report';
					}

					if (this.aggregatedModeService.isAllAggregatedMode()) {
						query.unfinished = 1;
					}

					Restangular.one('results', questionId)
						.one('distribution')
						.get(query)
						.then((data) => {
							data = data.plain ? data.plain() : data;
							resolve(data);
						}, reject);
				}, reject);
			});
		this.getOthersForMatrixQuestionPromise = (
			questionId,
			row,
			column,
			hasOthers,
			page,
			filterId,
			useGlobalFilter,
			pageLimit = null
		) =>
			new this.$q((resolve, reject) => {
				let type = hasOthers ? 'other' : 'reason';
				// console.log('getOthersForMatrixQuestionPromise', questionId, row, column, page);
				page = page || 1;
				let requestData = {
					page,
					row: row.index,
					col: column.index,
					type,
				};
				if (filterId) {
					requestData.filter = filterId;
				}
				if (useGlobalFilter) {
					requestData.filter = this.filterId;
				}
				if ($rootScope.userInfo.external) {
					requestData.limit = 'report';
				}
				if (pageLimit) {
					requestData['custom-pagination-count'] = pageLimit;
				}
				if (this.aggregatedModeService.isAllAggregatedMode()) {
					requestData.unfinished = 1;
				}

				// /api/results/{question-id}/matrix?row=index&column=index&type={other/reason}
				this.init().then(
					() => {
						Restangular.one('results', questionId)
							.one('matrix')
							.get(requestData)
							.then((data) => {
								data = data.plain ? data.plain() : data;
								resolve(data);
							}, reject);
						// Restangular.one('results', questionId).one('others').get({index: answerIndex}).then(resolve, reject);
					},
					() => {}
				);
			});
		this.continouslyLoadOthersForMatrix = (
			questionData,
			paginationData,
			row,
			column,
			hasOthers,
			items,
			successCallback,
			finishCallback,
			paginationPageNumber
		) => {
			let total = paginationData ? paginationData.total : null;
			let count = items ? items.length : null;
			let pageNumber = paginationPageNumber ? paginationPageNumber : 1;
			let nextPageNumber = pageNumber + 1;

			// console.log('continouslyLoadOthersForMatrix', questionData, paginationData, row, column, hasOthers, items, !!successCallback, !!finishCallback, paginationPageNumber);
			if (this.interruptQueries) {
				this.state.loadingProcesses.matrix = false;
				this.finishInterrupt();
				return;
			}

			if (count < total) {
				this.state.loadingProcesses.matrix = true;
				this.getOthersForMatrixQuestionPromise(
					questionData.id,
					row,
					column,
					hasOthers,
					nextPageNumber
				).then(
					(data) => {
						items = items.concat(data.items);
						if (successCallback) {
							successCallback(data.items, nextPageNumber, items);
						}
						this.continouslyLoadOthersForMatrix(
							questionData,
							paginationData,
							row,
							column,
							hasOthers,
							items,
							successCallback,
							finishCallback,
							nextPageNumber
						);
					},
					(errData) => {
						this.state.loadingProcesses.matrix = false;
						console.error(errData);
					}
				);
			} else if (finishCallback) {
				this.state.loadingProcesses.matrix = false;
				finishCallback(count);
			}
		};

		this.getQuestionPromiseById = (
			id,
			filterId,
			paginationPageNumber,
			useGlobalFilter = false,
			limitPage = null
		) =>
			new this.$q((resolve, reject) => {
				// filterId = filterId || this.filterId;
				filterId = filterId || null;
				if (useGlobalFilter) {
					filterId = this.filterId;
				}
				// console.log('getQuestionPromiseById', id, filterId, paginationPageNumber, this.filterId);
				this.init().then(function functionName() {
					if (!self.restangularObjects.questions || !self.restangularObjects.questions[id]) {
						self.restangularObjects.questions[id] = Restangular.one('results', id).one(
							'aggregated'
						);
					}
					self.restangularObjects.questions[id]
						.get(getQuestionQueryString(filterId, paginationPageNumber, false, limitPage))
						.then(function done(data) {
							resolve(data.plain());
						}, reject);
				}, apiHelper.handleNoToken);
			});

		let loadSingleQuestion = (
			filterId,
			pageId,
			questionsContainer,
			finishCallback,
			globalFilter = true
		) => {
			if (
				(this.questionsToLoad.pages.count !== undefined && this.questionsToLoad.pages.count < 0) ||
				(this.questionsToLoad.all.count !== undefined && this.questionsToLoad.all.count < 0)
			) {
				return;
			}

			let item = null;
			if (
				pageId &&
				this.questionsToLoad.pages[pageId] &&
				this.questionsToLoad.pages[pageId].items &&
				this.questionsToLoad.pages[pageId].items.length
			) {
				item = this.questionsToLoad.pages[pageId].items.shift();
			} else if (this.questionsToLoad.all.items && this.questionsToLoad.all.items.length) {
				item = this.questionsToLoad.all.items.shift();
			} else {
				return;
			}

			let errorObject = {
				error: true,
				id: item,
				rejectData: null,
			};

			let check = (_filterId, _pageId, _questionsContainer, _finishCallback) => {
				// console.log('check', this.questionsToLoad);
				if (_pageId && this.questionsToLoad.pages[_pageId]) {
					this.questionsToLoad.pages[_pageId].count--;
				} else {
					this.questionsToLoad.all.count--;
				}
				if (
					(_pageId &&
						this.questionsToLoad.pages[_pageId] &&
						this.questionsToLoad.pages[_pageId].count === 0) ||
					(!_pageId && this.questionsToLoad.all.count === 0)
				) {
					if (_pageId) {
						// this.questionsToLoad.pages[_pageId] = undefined;
						delete this.questionsToLoad.pages[_pageId];
					}
					if (_finishCallback) {
						_finishCallback(this.questions);
					}
				} else {
					loadSingleQuestion(
						_filterId,
						_pageId,
						_questionsContainer,
						_finishCallback,
						globalFilter
					);
				}
			};

			let qPromise = Restangular.one('results', item)
				.one('aggregated')
				.get(getQuestionQueryString(filterId, null, globalFilter));

			qPromise.then(
				(qData) => {
					let tmp = qData.plain();
					questionsContainer.addQuestion(tmp);
					this.questions.push(tmp);
					check(filterId, pageId, questionsContainer, finishCallback);
				},
				(qData) => {
					errorObject.rejectData = qData.plain ? qData.plain() : qData;
					errorObject.oldData =
						currentSurveyQuestions && currentSurveyQuestions.length && currentSurveyQuestions[item]
							? angular.copy(currentSurveyQuestions[item].plain())
							: null;
					errorObject.order = errorObject.oldData ? errorObject.oldData.order : 0;
					questionsContainer.addQuestionError(errorObject);
					check(filterId, pageId, questionsContainer, finishCallback);
				}
			);
		};

		this.getQuestionsByPage = function getQuestionsByPage(
			pageId,
			questionsContainer,
			useMultiResult,
			filterId,
			finishCallback,
			globalFilter = true
		) {
			// console.log('getQuestionsByPage', !!questionsContainer, pageId);
			let questionsByPage = [];
			let promise = new this.$q((resolve, reject) => {
				let initThrottle = false;
				this.questions = [];

				this.getPagesPromise().then((pagesData) => {
					let ids = [];
					finishCallback = finishCallback || resolve;

					// wyciąga id pytań
					for (let i = 0; i < pagesData.length; i++) {
						if (pagesData[i].id === pageId) {
							ids = pagesData[i].questionIds;
							break;
						} else if (
							!pageId &&
							pageId !== 0 &&
							pagesData[i].questionIds &&
							pagesData[i].questionIds.length
						) {
							if (ids.length) {
								ids = ids.concat(pagesData[i].questionIds);
							} else {
								ids = pagesData[i].questionIds.slice(0);
							}
						}
					}

					if (!ids || !ids.length) {
						reject(null);
						return null;
					}
					if (pageId) {
						this.questionsToLoad.pages[pageId] = {
							items: ids.slice(0),
							count: ids.length,
						};
					} else {
						this.questionsToLoad.all.items = ids.slice(0);
						this.questionsToLoad.all.count = ids.length;
					}

					let questionsLimit = Math.min(ids.length, this.requestsLimit);

					for (let i = 0; i < questionsLimit; i++) {
						loadSingleQuestion(filterId, pageId, questionsContainer, finishCallback, globalFilter);
					}
				}, reject);
			});

			return promise;
		};

		let getPagesPromiseCallback = (resolve) =>
			function done(data) {
				data = data && data.plain ? data.plain() : data;

				self.$rootScope.$broadcast('LoadedPageInfo', data);

				self.currentPagesInfo = data;

				resolve(data.pages);
				return data.pages;
			};
		this.getPagesPromise = (refresh) =>
			new this.$q(function (resolve, reject) {
				if (
					!refresh &&
					self.currentPagesInfo &&
					self.currentPagesInfo.id !== undefined &&
					self.currentPagesInfo.id === self.currentSurveyId
				) {
					// informacje o stronach były juz pobrane
					resolve(self.currentPagesInfo.pages);
				} else if (!refresh && self.pagesInfoPromise) {
					self.pagesInfoPromise.then(getPagesPromiseCallback(resolve), function error(data) {});
				} else {
					self.pagesInfoPromise = null;
					self.getCurrentSurveyIdPromise().then(
						function done(sid) {
							self.pagesInfoPromise = Restangular.one('survey', sid).one('pages-info').get();

							self.pagesInfoPromise.then(getPagesPromiseCallback(resolve), function error(data) {
								reject(data);
								self.apiHelper.handleDataError(data);
								return data;
							});
						},
						function error(data) {
							reject(data);
							self.apiHelper.handleDataError(data);
							return data;
						}
					);
				}
			});

		this.getResultsTimelinePromise = (id, period, filterId) =>
			new this.$q((resolve, reject) => {
				let postData = {};
				// console.log('getResultsTimelinePromise', id, period, filterId);

				postData.period = period || 2;
				if (filterId) {
					postData.filter = filterId;
				}
				if (this.aggregatedModeService.isAllAggregatedMode()) {
					postData.unfinished = 1;
				}

				Restangular.one('results', id)
					.one('time-line')
					.get(postData)
					.then((data) => {
						let res = data.plain ? data.plain() : data;
						resolve(res);
					}, reject);
			});

		this.dataExportGetFileCrossTablePromise = (exportType, surveyId, crossTable, filterId) =>
			new this.$q(function (resolve, reject) {
				const queryData = filterId ? { filter: filterId, type: exportType } : { type: exportType };

				apiHelper.getCSRF().then((csrf) => {
					UserRestangular.one('export')
						.one('cross-tables')
						.one('survey', surveyId)
						.one('calculate', crossTable)
						.one('csrf', csrf)
						.customGET('', queryData, {
							'X-Requested-With': 'XMLHttpRequest',
						})
						.then(
							(response) => {
								location.replace(response.data.url);
								resolve();
							},
							(errData) => {
								reject(errData);
							}
						);
				});
			});

		this.checkUnfinishedPromise = (filterId) =>
			new this.$q((resolve, reject) => {
				// /api/{survey_id}/results/unfinished
				this.getCurrentSurveyIdPromise().then((surveyId) => {
					let data = {};
					if (filterId) {
						data.filter = filterId;
					}

					Restangular.one(String(surveyId))
						.one('results')
						.one('unfinished')
						.get(data)
						.then(resolve, reject);
				});
			});

		this.isSurvey = (id) => {
			let data = this.getCurrentSurvey();
			if (data) {
				return data.type === 0;
			}
			return false;
		};
		this.isTest = (id) => {
			let data = this.getCurrentSurvey();
			if (data) {
				return data.type === 1;
			}
			return false;
		};
		this.isWidget = (id) => {
			let data = this.getCurrentSurvey();
			if (data) {
				return data.type === 2;
			}
			return false;
		};

		this.getSurveyStylePromise = (id) =>
			new this.$q(function (resolve, reject) {
				// Style:
				// fontColor => textColor
				// questionHint => descriptionColor
				// questionColor => headlineColor

				let result = {
					theme: {},
					logo: null,
				};
				let go = (surveyId) => {
					if (surveyId) {
						Restangular.one(String(surveyId || self.currentSurveyId))
							.one('survey-style')
							.get()
							.then((data) => {
								data = data && data.plain ? data.plain() : data;
								const whiteColors = ['FFF', 'FFFFFF'];
								const isWhite = (color) =>
									whiteColors.some((white) => white === color.toUpperCase());

								const fontColor = isWhite(data.fontColor) ? '000000' : data.fontColor;

								result.theme.headlineColor = `#${fontColor}`;
								result.theme.textColor = `#${fontColor}`;
								result.theme.descriptionColor = `#${fontColor}`;

								if (data.logo) {
									result.logo = data.logo;
								}

								resolve(result);
								return result;
							}, reject);
					}
				};

				if (id) {
					go(id);
				} else {
					self.getCurrentSurveyIdPromise().then(go, reject);
				}
			});
		this.getWidgetSkinPromise = (id) =>
			new this.$q(function (resolve, reject) {
				// Skiny:
				// questionColor=>headlineColor
				// colorWidget=>textColor
				// colorWidget=>descriptionColor

				let result = {
					theme: {},
					logo: null,
				};
				let go = (surveyId) => {
					if (surveyId) {
						Restangular.one(String(surveyId || self.currentSurveyId))
							.one('widget-skin')
							.get()
							.then((data) => {
								data = data && data.plain ? data.plain() : data;
								result.theme.headlineColor = '#' + data.questionColor;
								result.theme.textColor = '#' + data.colorWidget;
								result.theme.descriptionColor = '#' + data.colorWidget;
								resolve(result);
								return result;
							}, reject);
					}
				};

				if (id) {
					go(id);
				} else {
					self.getCurrentSurveyIdPromise().then(go, reject);
				}
			});

		this.hasQuestionWithType = (type) => {
			// console.log('hasQuestionWithType', Number(new Date()), this.questions);
			for (let i = 0, max = this.questions.length; i < max; i++) {
				if (type === this.questions[i].type) {
					return true;
				}
			}
			return false;
		};

		this.checkSurveyData = (survey, key, value, resolve, reject) => {
			let result = false;
			if (survey && survey[key]) {
				result = survey[key] === value;
			}

			resolve(result);
			return result;
		};

		this.getAccessLevel = (id) =>
			new this.$q((resolve, reject) => {
				this.getSurveyPromise(id).then((survey) => {
					resolve(survey.accessLevel);
				});
			});

		this.hasQuestionsPromise = () =>
			new this.$q((resolve, reject) => {
				this.getPagesPromise().then((data) => {
					if (data && data.length) {
						for (let i = 0; i < data.length; i++) {
							if (data[i].questionIds && data[i].questionIds.length) {
								resolve(true);
								return true;
							}
						}
					}
					resolve(false);
				}, reject);
			});

		this.getQuestionIndex = (question) => {
			return this.questionHelper.getQuestionIndex(question, this.questions);
		};

		this.activateFilter = (id) => {
			// console.log('spp activateFilter', id);
			this.filterId = id;
		};
		this.deactivateFilter = () => {
			this.filterId = null;
		};

		apiHelper.setDataService('survey', this);
		apiHelper.init();
	}

	getQuestion(questionId, limit, page) {
		const query = {
			page,
			'custom-pagination-count': limit,
		};

		if (this.filterId) {
			query.filter = this.filterId;
		}

		if (this.aggregatedModeService.isAllAggregatedMode()) {
			query.unfinished = 1;
		}

		return new this.$q((resolve, reject) => {
			this.restangular
				.one('results', questionId)
				.one('aggregated')
				.get(query)
				.then((data) => {
					resolve(data.plain());
				});
		});
	}
}

export default SurveyProviderService;
