import { AlertType } from './type.alerts';
import { AlertImage } from './image.alerts';
import { AlertConfig } from './conf.alerts';
import { AlertEvent } from './events.alerts';

class AlertsService {
	constructor($rootScope) {
		'ngInject';

		this.$rootScope = $rootScope;

		this.alerts = [];
	}

	addAlert(message, type, image, time = AlertConfig.defaultLifeTime) {
		if (this.alerts.length === AlertConfig.maxVisibleAlerts) {
			this.removeAlert(0);
		}

		this.alerts.push({ message, type, image, time });
		this.updateState();
	}

	updateState() {
		this.$rootScope.$emit(AlertEvent.updated, {
			alerts: this.alerts.slice(),
		});
	}

	removeAlert(index) {
		this.alerts.splice(index, 1);
		this.updateState();
	}

	success(message) {
		this.addAlert(message, AlertType.success, AlertImage.success);
	}

	warning(message) {
		this.addAlert(message, AlertType.warning, AlertImage.warning);
	}

	error(message) {
		this.addAlert(message, AlertType.error, AlertImage.error);
	}
}

export default AlertsService;
