class FilterCreatorAbstractQuestionController {
	constructor($scope, surveyProviderService, reportHelper, i18n) {
		// "ngInject";
		this.$scope = $scope;
		this.surveyProviderService = surveyProviderService;
		this.reportHelper = reportHelper;
		this.i18n = i18n;

		this.type = 0;
		this.questionId = null;
		this.selectedCondition = null;

		this.answersLoading = false;

		this.noneAnswer = {
			title: this.i18n.__('Żadna nie jest zaznaczona', 'reports'),
			index: 0,
			none: true,
		};

		this.valid = false;
	}

	$onInit() {
		if (this.creator) {
			this.creator.addQuestionRuleController(this);
		}
	}
	$onDestroy() {
		if (this.creator) {
			this.creator.removeQuestionRuleController(this);
		}
	}

	init() {
		if (this.ruleData && this.question) {
			this.questionId = this.ruleData.question;
			this.type = this.question.type;
			this.valid = true;
		}
	}

	isValid() {
		return this.valid;
	}
	setValid(val) {
		this.valid = val;
	}

	/**
	 * onQuestionChange - gdy po lewej zostanie wybrane inne pytanie trzeba usunac stara i dodac nowa regule.
	 *
	 * @return {type}  description
	 */
	onQuestionChange() {
		const removedIndex = this.creator.removeQuestionRule(null, this.ruleData);

		this.creator.emptyQuestionRuleSelected = this.question;
		this.creator.addQuestionRule(false, removedIndex);
		this.type = this.question.type;
	}

	getData() {
		return this.ruleData;
	}

	/**
	 * noneSelected - uniwersalna metoda do wyboru "żadna nie jest zaznaczona". Może byc nadpuisana w dzieciach
	 * this.ruleData.internalAnswers; this.selectedCondition; this.$scope.condition sa uzyte w roznych regulach (nie wszystkich)
	 *
	 * @return {type}  description
	 */
	noneSelected() {
		this.ruleData.answers = [];
		if (this.ruleData.internalAnswers) {
			this.ruleData.internalAnswers = [];
		}
		if (this.selectedCondition !== undefined) {
			this.selectedCondition = null;
		}
		if (this.$scope.condition !== undefined) {
			this.$scope.condition = null;
		}
		this.selectedAnswers = [0]; // gdy jest możliwosc wyboru "zadna" to jest to zawsze pierwsza odpowiedź
		this.ruleData.condition = 4; // 4 - żadna odpowiedź
		this.validate();
		this.creator.validate();
	}

	/**
	 * removeNone - czyści wybór 'żadnej' odpowiedz
	 *
	 * @return {type}  description
	 */
	removeNone() {
		if (this.isNoneSelected() && this.selectedAnswers.length) {
			for (let i = 0; i < this.selectedAnswers.length; i++) {
				if (!this.selectedAnswers[i].index) {
					// index NONE
					this.selectedAnswers.splice(i, 1);
					break;
				}
			}
			this.ruleData.condition = null;
		}
	}
	isNoneSelected() {
		return this.ruleData.condition === 4;
	}
}

export { FilterCreatorAbstractQuestionController };
