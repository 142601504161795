const tpl = require('./ARQuestionMultiple.html');
// const tpl = require('./ARQuestionSingle.html');

const ARQuestionSingleController = require('./ARQuestionSingle.js').aRQuestionSingle.controller;

class ARQuestionMultipleController extends ARQuestionSingleController {
	constructor($element, $scope, $filter, filtersHelper, quickFilterGenerator) {
		'ngInject';

		super($element, $scope, $filter, filtersHelper, quickFilterGenerator);
	}
}

export const aRQuestionMultiple = {
	template: tpl,
	controller: ARQuestionMultipleController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		chartColor: '<',
		questionData: '<',
	},
};
