const angular = require('angular');

class FiltersService {
	constructor(
		surveyProviderService,
		Restangular,
		apiHelper,
		UserRestangular,
		i18n,
		userService,
		modalService,
		alertsService,
		$timeout,
		$rootScope
	) {
		'ngInject';
		let self = this;

		this.surveyProviderService = surveyProviderService;
		this.modalService = modalService;
		this.alertsService = alertsService;
		this.$uibModal = modalService.getUibModal();
		this.Restangular = Restangular;
		this.apiHelper = apiHelper;
		this.UserRestangular = UserRestangular;
		this.i18n = i18n;
		this.userService = userService;
		this.$timeout = $timeout;
		this.$rootScope = $rootScope;

		this.totalCount = 0;

		this.timePeriodValues = {
			all: 0,
			fragment: 1,
			relative: 2,
			sheet: 3,
		};

		this.timeRelativeValues = {
			day: 0,
			week: 1,
			month: 2,
		};

		this.timeRelativeOptions = [
			{
				id: 0,
				text: i18n.__('Wczoraj', 'reports'),
			},
			{
				id: 1,
				text: i18n.__('Poprzedni tydzień', 'reports'),
			},
			{
				id: 2,
				text: i18n.__('Poprzedni miesiąc', 'reports'),
			},
			{
				id: 10,
				text: i18n.__('Poprzedni kwartał', 'reports'),
			},
			{
				id: 3,
				text: i18n.__('Ostatnie 7 dni', 'reports'),
			},
			{
				id: 4,
				text: i18n.__('Ostatnie 14 dni', 'reports'),
			},
			{
				id: 5,
				text: i18n.__('Ostatnie 30 dni', 'reports'),
			},
			{
				id: 11,
				text: i18n.__('Ostatnie 90 dni', 'reports'),
			},
			{
				id: 6,
				text: i18n.__('Przed 2 dniami', 'reports'),
			},
			{
				id: 7,
				text: i18n.__('Przed 7 dniami', 'reports'),
			},
			{
				id: 8,
				text: i18n.__('Przed 14 dniami', 'reports'),
			},
			{
				id: 9,
				text: i18n.__('Przed 30 dniami', 'reports'),
			},
		];

		this.respondentConditionValues = {
			notEqual: 0,
			equal: 1,
			notContain: 2,
			contain: 3,
		};

		this.respondentConditionOptions = [
			{
				id: 0,
				text: i18n.__('różne', 'reports'),
			},
			{
				id: 1,
				text: i18n.__('równe', 'reports'),
			},
			{
				id: 2,
				text: i18n.__('nie zawiera', 'reports'),
			},
			{
				id: 3,
				text: i18n.__('zawiera', 'reports'),
			},
		];

		this.tagConditionOptions = [
			{
				id: 0,
				text: i18n.__('nieprzypisany', 'reports'),
			},
			{
				id: 1,
				text: i18n.__('bez emocji', 'reports'),
			},
			{
				id: 2,
				text: i18n.__('pozytywny', 'reports'),
			},
			{
				id: 3,
				text: i18n.__('neutralny', 'reports'),
			},
			{
				id: 4,
				text: i18n.__('negatywny', 'reports'),
			},
		];

		this.logicStates = {
			or: 0,
			and: 1,
		};

		this.logTimeOptions = {
			endedAt: 0,
			updatedAt: 1,
		};

		this.defaultFilterName = i18n.__('Bez nazwy', 'reports');

		this.defaultFilterNameRegex = new RegExp('^' + this.defaultFilterName + '( \\(([0-9]+)\\))?$');

		this.filtersList = [];
		this.basicFiltersList = [];
		this.reportsFiltersMap = null;

		this.creatorModal = null;

		let filtersMap = null;

		let mapIds = () => {
			if (this.filtersList && this.filtersList.length) {
				let i;
				filtersMap = {};

				for (i = 0; i < this.filtersList.length; i++) {
					filtersMap[this.filtersList[i].id] = i;
				}
			} else if (this.basicFiltersList && this.basicFiltersList.length) {
				filtersMap = {};

				for (let i = 0; i < this.basicFiltersList.length; i++) {
					filtersMap[this.basicFiltersList[i].id] = i;
				}
			}
		};

		let emptyFilter = {
			title: '',
			questionRules: [],
			respondentRules: [],
			tagRules: [],
			period: 0,
			from: null,
			to: null,
			relative: null,
			questionRulesCondition: self.logicStates.and,
			respondentRulesCondition: self.logicStates.and,
			tagRulesCondition: self.logicStates.and,
			logTimeOption: self.logTimeOptions.endedAt,
		};

		let emptyQuestionRules = {
			type: 0,
			question: '',
			answers: [],
			condition: null,
		};

		let emptyRespondentRules = {
			label: '',
			condition: null,
			value: '',
		};

		const emptyTagRule = {
			tagIds: null,
			condition: null,
		};

		this.interruptQueries = false;
		this.state = {};
		this.state.loadingProcesses = {};
		this.state.loadingProcesses = {
			basicList: false,
		};

		this.invalidate = (opts) => {
			this.interrupt();
			if (opts && opts.onlyBasic) {
				this.basicFiltersList = [];

				return;
			}
			this.filtersList = [];
			this.basicFiltersList = [];

			this.deactivateFilter();
		};
		this.interrupt = () => {
			if (this.state.loadingProcesses.basicList) {
				this.interruptQueries = true;
			} else {
				this.interruptQueries = false;
			}
		};
		this.finishInterrupt = () => {
			if (!this.state.loadingProcesses.basicList) {
				this.interruptQueries = false;
			}
		};

		this.cleanUpFilterData = (filterData) => {
			if (filterData) {
				delete filterData.editable;
			}
		};

		this.fetchFilters = (id, basic = false, page = 1) =>
			new Promise(function (resolve, reject) {
				let queryData = { page };

				if (basic) {
					queryData.type = 'basic';
				}

				surveyProviderService.getCurrentSurveyIdPromise().then(
					function done(surveyId) {
						surveyId = String(surveyId);
						if (id || id === 0) {
							Restangular.one(String(surveyId))
								.one('filter', id)
								.get(queryData)
								.then(
									(data) => {
										resolve(data);
										return data;
									},
									(data) => {
										console.error(data);
										reject(data);
									}
								);
						} else {
							Restangular.one(String(surveyId))
								.one('filter')
								.get(queryData)
								.then(
									function done2(data) {
										self.totalCount = data.total;
										if (basic) {
											self.basicFiltersList = data.items;
											resolve(self.basicFiltersList);
											return self.basicFiltersList;
										}

										self.filtersList = data.items;

										mapIds();
										resolve(self.filtersList);
										return self.filtersList;
									},
									(data) => {
										console.error(data);
										self.apiHelper.handleDataError(data);
										reject(data);
									}
								);
						}
					},
					(data) => {
						console.error(data);
						self.apiHelper.handleDataError(data);
						reject(data);
					}
				);
			});

		this.continouslyLoadFilters = (
			filtersData,
			successCallback,
			finishCallback,
			paginationPageNumber
		) => {
			let total = filtersData.total;
			let count = filtersData.items.length;
			let basic = filtersData.basic;
			let pageNumber = paginationPageNumber ? paginationPageNumber : 1;
			let nextPageNumber = pageNumber + 1;

			this.state.loadingProcesses.basicList = true;
			if (this.interruptQueries) {
				this.state.loadingProcesses.basicList = false;
				this.finishInterrupt();
				return;
			}

			if (count < total) {
				this.fetchFilters(null, basic, nextPageNumber).then(
					(data) => {
						filtersData.items = filtersData.items.concat(data);
						if (successCallback) {
							successCallback(filtersData.items, nextPageNumber);
						}
						this.continouslyLoadFilters(
							filtersData,
							successCallback,
							finishCallback,
							nextPageNumber
						);
					},
					(errData) => {
						this.state.loadingProcesses.basicList = false;
						console.error(errData);
					}
				);
			} else if (finishCallback) {
				this.state.loadingProcesses.basicList = false;
				finishCallback(count);
			}
		};

		this.getFilters = function getFilters(id) {
			if (id !== undefined) {
				return this.filtersList[filtersMap[id]];
			}
			return this.filtersList;
		};

		this.getFiltersPromise = (id, basic = false, page = 1) =>
			new Promise(function (resolve, reject) {
				self.fetchFilters(id, basic, page).then(
					function done(data) {
						if (!id && id !== 0) {
							// dane były pobrane w fetchFilters
							if (basic) {
								resolve(self.basicFiltersList);
								return self.basicFiltersList;
							}
							resolve(self.filtersList);
							return self.filtersList;
						}

						// pojedynczy
						resolve(data);
						return data;
					},
					(data) => {
						console.error(data);
						self.apiHelper.handleDataError(data);
						reject(data);
					}
				);
			});

		this.patchFilterPromise = (id, filterData) =>
			new Promise(function (resolve, reject) {
				surveyProviderService.getCurrentSurveyIdPromise().then(
					function done(surveyId) {
						let tmpData = angular.copy(filterData);
						delete tmpData.id;
						delete tmpData.editable;
						Restangular.one(String(surveyId))
							.one('filter', id)
							.customPUT(tmpData)
							.then(
								function done2(data) {
									data = data.plain ? data.plain() : data;
									// self.filtersList[filtersMap[id]] = data;
									resolve(data);
									return data;
								},
								function error(data) {}
							);
						// mapIds();
					},
					function error(data) {
						console.error(data);
						self.apiHelper.handleDataError(data);
						reject(data);
					}
				);
			});

		this.removeFilterPromise = (id) =>
			new Promise((resolve, reject) => {
				surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
					Restangular.one(String(surveyId))
						.one('filter', id)
						.remove()
						.then(
							(data) => {
								let info = this.userService.getUserSurveyInfo();
								if (id && info && info.filterId && Number(id) === Number(info.filterId)) {
									this.userService.removeFilter();
									this.deactivateFilter();
								}

								resolve(data);
								return data;
							},
							(errData) => {
								console.error(errData);
								if (errData && errData.status === 409) {
									this.alertsService.error(
										this.i18n.__('Nie można usunąć tego filtra.', 'reports')
									);
								} else {
									self.apiHelper.handleDataError(errData);
								}
								reject(errData);
								return errData;
							}
						);
				});
			});

		this.postFilterPromise = (filterData) =>
			new Promise((resolve, reject) => {
				surveyProviderService.getCurrentSurveyIdPromise().then(
					(id) => {
						let filters =
							this.filtersList && this.filtersList.length
								? this.filtersList
								: this.basicFiltersList;

						if (!filterData.title) {
							let counter = 0;
							let numbers = [];
							filterData.title = this.defaultFilterName;

							if (filters && filters.length) {
								filters.forEach((filter) => {
									let check = this.defaultFilterNameRegex.exec(filter.title);
									if (check && check.length && check[2]) {
										numbers.push(Number(check[2]));
									}
								});

								if (numbers.length) {
									counter = Math.max.apply(null, numbers);
									filterData.title += ' (' + (counter + 1) + ')';
								} else {
									filterData.title += ' (1)';
								}
							} else {
								filterData.title += ' (1)';
							}
						}

						return Restangular.one(String(id))
							.post('filter', filterData)
							.then((data) => {
								data = data.plain ? data.plain() : data;

								// console.log('postFilterPromise', filterData, data);

								self.filtersList.unshift(data);
								mapIds();

								resolve(data);
								return data;
							});
					},
					function error(data) {
						console.error(data);
						self.apiHelper.handleDataError(data);
						reject(data);
					}
				);
			});

		this.getReportsFiltersPromise = (force, id) =>
			new Promise((resolve, reject) => {
				if (!force && self.reportsFiltersMap && self.reportsFiltersMap.length) {
					if (id !== undefined) {
						let res = this.getReportsFiltersData(id);
						resolve(res);
						return res;
					}

					resolve(self.reportsFiltersMap);
					return self.reportsFiltersMap;
				}
				surveyProviderService.getCurrentSurveyIdPromise().then(
					function done(surveyId) {
						// Restangular.one(String(surveyId)).one('reports').one('filters').get().then(data => {
						Restangular.one(String(surveyId))
							.one('filter-report-connections')
							.get()
							.then(
								(data) => {
									self.reportsFiltersMap = data ? data.plain().items : [];

									if (id !== undefined) {
										let res = this.getReportsFiltersData(id);
										resolve(res);
										return res;
									}

									resolve(self.reportsFiltersMap);
									return self.reportsFiltersMap;
								},
								(err) => self.apiHelper.handleDataError(err)
							);
					},
					(data) => {
						console.error(data);
						self.apiHelper.handleDataError(data);
						reject(data);
						return data;
					}
				);
			});

		this.activateFilter = (id, onlyServer) =>
			new Promise((resolve, reject) => {
				apiHelper.init().then(() => {
					UserRestangular.one('filter')
						.one('activate')
						.get({ filter: id })
						.then((data) => {
							if (!onlyServer) {
								surveyProviderService.activateFilter(id);
								this.userService.setUserSurveyInfo(undefined, id);
								this.$rootScope.surveyHasTimePeriodFilter = this.checkTimePeriodFilter(data);
							}
							resolve(data);
							return data;
						}, reject);
				});
			});

		this.quickActivateFilter = (id) => {
			this.userService.setUserSurveyInfo(undefined, id);
			this.surveyProviderService.activateFilter(id);
			this.$timeout(() => {
				this.activateFilter(id, true);
			});
		};

		this.deactivateFilter = () =>
			new Promise((resolve, reject) => {
				this.surveyProviderService.deactivateFilter();
				apiHelper.init().then(() => {
					this.userService.removeFilter();
					this.$rootScope.surveyHasTimePeriodFilter = this.checkTimePeriodFilter();
					UserRestangular.one('filter').one('deactivate').get().then(resolve, reject);
				});
			});

		this.checkTimePeriodFilter = (filter) => {
			let timePeriodFilterExists = false;

			if (this.$rootScope.surveyAccess.filter) {
				const defaultAppliedFilters = this.basicFiltersList.filter(
					(filter) => filter.isDefaultApplied
				);
				timePeriodFilterExists = !!defaultAppliedFilters.find((filter) => filter.hasTimeRules);
			}

			if (filter && !timePeriodFilterExists) {
				timePeriodFilterExists = filter.hasTimeRules;
			}

			return timePeriodFilterExists;
		};

		this.getReportsFiltersData = (id) => {
			// console.log('reportsFiltersMap', id, this.reportsFiltersMap);
			if (id !== undefined) {
				for (let i = 0, max = this.reportsFiltersMap.length; i < max; i++) {
					if (Number(this.reportsFiltersMap[i].id) === Number(id)) {
						return this.reportsFiltersMap[i];
					}
				}
			}
			return this.reportsFiltersMap;
		};

		this.getFilterResultCountByFilterId = (filterId) =>
			new Promise((resolve, reject) => {
				const query = { filters: filterId };

				surveyProviderService.getCurrentSurveyIdPromise().then((surveyId) => {
					Restangular.one(String(surveyId))
						.one('filter-completions')
						.get(query)
						.then(resolve, reject);
				});
			});

		this.getEmptyFilterData = () => angular.copy(emptyFilter);

		this.getEmptyQuestionRule = () => angular.copy(emptyQuestionRules);

		this.getEmptyRespondentRule = () => angular.copy(emptyRespondentRules);

		this.getEmptyTagRule = () => angular.copy(emptyTagRule);

		this.openCreator = (opts) => {
			let id = opts && opts.id ? opts.id : null;
			let onCreateCallback = opts && opts.onCreateCallback ? opts.onCreateCallback : null;
			let onCloseCallback = opts && opts.onCloseCallback ? opts.onCloseCallback : null;
			let inResults = opts && opts.inResults ? opts.inResults : null;
			let isCopy = opts && opts.isCopy ? opts.isCopy : null;

			this.creatorModal = this.$uibModal.open({
				component: 'filterCreator',
				size: '75per',
				resolve: {
					id: () => id,
					inResults: () => inResults,
					filterData: () => (id ? angular.copy(self.filtersList[filtersMap[id]]) : null),
					onCreateCallback: () => onCreateCallback,
					onCloseCallback: () => onCloseCallback,
					isCopy: () => isCopy,
					isEditable: () => opts.isEditable,
				},
			});
			this.creatorModal.result.then(
				() => {},
				() => {}
			);

			return this.creatorModal;
		};

		this.closeCreator = function closeCreator() {
			this.creatorModal.close();
		};

		this.getTotalCount = () => this.totalCount;

		apiHelper.setDataService('filters', this);

		// New functions

		this.fetchFiltersBasicList = () =>
			new Promise((resolve, reject) => {
				surveyProviderService
					.getCurrentSurveyIdPromise()
					.then((surveyId) => {
						return Restangular.one(String(surveyId)).one('filter').get({ type: 'basic' });
					})
					.then((data) => {
						this.basicFiltersList = data.items;
						mapIds();
						resolve(angular.copy(data.items));
					})
					.catch((error) => {
						this.apiHelper.handleDataError(error);
						reject(error);
					});
			});

		this.quickFilterPost = (filterData) =>
			new Promise((resolve, reject) => {
				surveyProviderService
					.getCurrentSurveyIdPromise()
					.then((surveyId) => {
						return Restangular.one(String(surveyId)).post('filter', filterData);
					})
					.then((data) => {
						data = data.plain ? data.plain() : data;
						this.basicFiltersList.unshift(data);
						resolve(angular.copy(data));
					})
					.catch((error) => {
						this.apiHelper.handleDataError(error);
						reject(error);
					});
			});

		this.quickFilterPatch = (filterData) =>
			new Promise((resolve, reject) => {
				surveyProviderService
					.getCurrentSurveyIdPromise()
					.then((surveyId) => {
						return Restangular.one(String(surveyId))
							.one('filter', filterData.id)
							.customPUT(filterData);
					})
					.then((data) => {
						data = data.plain ? data.plain() : data;
						this.basicFiltersList[this.getBasicFilterIndex(data.id)] = data;
						resolve(angular.copy(data));
					})
					.catch((error) => {
						this.apiHelper.handleDataError(error);
						reject(error);
					});
			});

		this.fetchFilter = (id) =>
			new Promise((resolve, reject) => {
				surveyProviderService
					.getCurrentSurveyIdPromise()
					.then((surveyId) => {
						return Restangular.one(String(surveyId)).one('filter', id).get();
					})
					.then((data) => {
						resolve(data.plain ? data.plain() : data);
					})
					.catch((error) => {
						reject(error);
					});
			});

		this.removeLastQuestionRule = (id) =>
			new Promise((resolve, reject) => {
				surveyProviderService
					.getCurrentSurveyIdPromise()
					.then((surveyId) => {
						Restangular.setFullResponse(true);
						return Restangular.one(String(surveyId)).one('filter', id).all('revert-rule').remove();
					})
					.then((response) => {
						Restangular.setFullResponse(false);
						if (response.status === 204) {
							this.basicFiltersList.splice(this.getBasicFilterIndex(id), 1);
							resolve({ removed: true });
						} else {
							resolve({ removed: false, filterInfo: response.data.plain() });
						}
					})
					.catch((error) => {
						Restangular.setFullResponse(false);
						if (error.status === 409) {
							reject();
						}
					});
			});

		this.getBasicFilterIndex = (filterId) => {
			return this.basicFiltersList.findIndex((filter) => {
				return Number(filter.id) === Number(filterId);
			});
		};

		this.replaceFilterData = (filterData) => {
			const index = this.filtersList.findIndex((filter) => {
				return Number(filter.id) === Number(filterData.id);
			});

			this.filtersList[index] = angular.copy(filterData);
		};
	}
}

export default FiltersService;
