import angular from 'angular';

class AbstractStorage {
	constructor(sentimentAPIService, Observer) {
		this.sentimentAPIService = sentimentAPIService;
		this.observer = new Observer();

		this.data = null;
	}

	addObserver(observer) {
		this.observer.addObserver(observer);
	}

	removeObserver(observer) {
		this.observer.removeObserver(observer);
	}

	async fetch(from, to, tags) {
		if (this.data) {
			this.observer.notify(angular.copy(this.data));
			return;
		}

		this.data = $$await(
			await this.fetchFromApi(
				Math.floor(from / 1000),
				Math.floor(to / 1000),
				tags.map((tag) => {
					return tag.id;
				})
			)
		);

		this.observer.notify(angular.copy(this.data));
	}

	clear(notify = false) {
		this.data = null;

		if (notify) {
			this.observer.notify(this.data);
		}
	}
}

export { AbstractStorage };
