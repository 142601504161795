const tpl = require('./ExtendedAnswersModal.html');
class ExtendedAnswersModalController {
	constructor(i18n, surveyProviderService) {
		'ngInject';

		this.i18n = i18n;
		this.surveyProviderService = surveyProviderService;

		this.hasOthers = false;
	}

	$onInit() {
		this.questionData = this.resolve.questionData;
		this.answer = this.resolve.answer;
		this.row = this.resolve.row;
		this.column = null;
		this.reasons = this.resolve.reasons;
		this.others = [];
		this.hasOthers = !!this.resolve.others;
		this.hasReasons = !!this.resolve.reasons;
		this.isNPS = this.resolve.isNPS;

		if (this.hasOthers) {
			this.others = [];
			if (this.row && this.row.otherIndexes && this.row.otherIndexes.length) {
				this.row.otherIndexes.forEach((item) => {
					let data = {};
					data.title = this.questionData.columns[item - 1].title;
					data.index = item;
					this.others.push(data);
				});
			}
		}
		if (this.hasReasons) {
			this.column = this.answer;
		}
	}
}

export const extendedAnswersModal = {
	template: tpl,
	controller: ExtendedAnswersModalController,
	bindings: {
		resolve: '<',
		close: '&',
		noPagination: '<',
	},
};
