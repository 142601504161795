const tpl = require('./IntersectionsWrapper.html');

class IntersectionsWrapperController {
	constructor(i18n, $rootScope, userService) {
		'ngInject';

		this.i18n = i18n;
		this.$rootScope = $rootScope;
		this.userService = userService;
		this.COOKIE_KEY = 'infoFilters';
	}
}

export const intersectionsWrapper = {
	template: tpl,
	controller: IntersectionsWrapperController,
};
