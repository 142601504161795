const stripHtmlTags = require('striptags');

function stripTags() {
	return function (text) {
		if (!isNaN(text)) {
			return text;
		}
		return stripHtmlTags(text);
	};
}

export { stripTags };
