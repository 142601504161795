class UserService {
	constructor(Restangular, UserRestangular, apiHelper, $cookies, $rootScope, $q, langService) {
		'ngInject';

		this.UserRestangular = UserRestangular;
		this.Restangular = Restangular;
		this.$rootScope = $rootScope;
		this.apiHelper = apiHelper;
		this.$cookies = $cookies;
		this.$q = $q;
		this.langService = langService;

		this.userInfo = null;
		this.userSurveyInfo = null;

		this.userSurveyInfoKey = 'userInfo';
	}

	fetchAccount() {
		const deferred = this.$q.defer();

		this.Restangular.one('account')
			.get()
			.then(
				(userData) => {
					userData = userData.plain ? userData.plain() : userData;

					// userData.external = true;
					// console.log('external');

					userData.days = parseInt(
						Math.ceil((userData.expirationDate * 1000 - Date.now()) / (1000 * 60 * 60 * 24)),
						10
					);

					this.userInfo = userData;
					this.$rootScope.userInfo = userData;

					deferred.resolve();
				},
				(error) => {
					deferred.reject(error);
				}
			);

		return deferred.promise;
	}

	fetchCachedSurveyInfoForUser() {
		const isReportGenerator = window.localStorage.getItem('reportGenerator') || false;
		const cookieKey = 'current_survey__' + this.langService.getLang();

		this.userSurveyInfo = JSON.parse(window.localStorage.getItem(this.userSurveyInfoKey)) || {};

		if (!isReportGenerator) {
			this.userSurveyInfo.surveyId = Number(this.$cookies.get(cookieKey));
		}
	}

	getUserSurveyInfo() {
		return this.userSurveyInfo;
	}

	getUserSurveyListInfo() {
		return {
			column: this.$cookies.get('user_surveys_list_column'),
			order: this.$cookies.get('user_surveys_list_order'),
		};
	}

	isReportGeneratorUser() {
		return (
			Object.prototype.hasOwnProperty.call(this.userInfo, 'external') && this.userInfo.external
		);
	}

	setUserSurveyInfo(surveyId, filterId) {
		let info = this.userSurveyInfo || {};

		if (surveyId) {
			info.surveyId = surveyId;

			this.apiHelper.getCSRF().then((csrf) => {
				this.UserRestangular.one('my-surveys')
					.one('choose')
					.one('id', surveyId)
					.one('csrf', csrf)
					.get();
			});
		}

		if (filterId) {
			info.filterId = filterId;
		}

		window.localStorage.setItem(this.userSurveyInfoKey, JSON.stringify(info));

		this.userSurveyInfo = info;
	}

	removeFilter() {
		if (!this.userSurveyInfo || !this.userSurveyInfo.filterId) {
			return;
		}

		this.userSurveyInfo.filterId = null;
		window.localStorage.setItem(this.userSurveyInfoKey, JSON.stringify(this.userSurveyInfo));
	}

	logoutUser() {
		this.userSurveyInfo = {};
		this.setUserSurveyInfo();
		window.localStorage.removeItem(this.userSurveyInfoKey);
	}

	isManager() {
		return this.userInfo.id === this.userInfo.managerId;
	}

	getId() {
		return this.userInfo.id;
	}
}

export default UserService;
