class DynamicLoader {
	constructor(config) {
		this.pageLoaded = 0;
		this.deletedItems = 0;
		this.nothingToLoad = false;
		this.fetching = false;
		this.itemsList = [];
		this.totalCount = 0;

		this.fetchDataFunction = config.fetchPromise;
		this.getTotalCountFunction = config.totalCountFunction;
		this.successCallbackFunction = config.successCallbackFunction;
		this.intervalTime = 500;

		this.fetch();
	}

	updateList() {
		// Liczę na bieżąco ile wszystkich bo TotalCount przeliczane jest po fetchu
		let totalCount = this.totalCount - this.deletedItems;

		if (totalCount > this.itemsList.length && this.pageLoaded >= 1 && !this.refreshing) {
			// Sprawdzam czy są jakieś usunięte po wcześniejszym doładowaniu - jeśli tak, trzeba je doładować z poprzedniej/poprzednich stron bo tam wskoczyły
			if (this.deletedItems) {
				// Ile potrzebuje doładować do pełnej dziesiątki
				let itemsToLoad = this.deletedItems % 10;

				// Sprawdzam czy w ogóle tyle jest do doładowania, jeśli nie - doładowuję tyle ile zostało
				if (itemsToLoad + this.itemsList.length > totalCount) {
					itemsToLoad = totalCount - this.itemsList.length;
				}

				// Ustawiam stronę z której należy doładować filtry
				this.pageLoaded -= Math.ceil(this.deletedItems / 10);

				// Pobieram filtry które przeskoczyły między stronami - doładowuję do pełnej 10
				this.fetch(itemsToLoad);
				this.deletedItems = 0;
			} else {
				// w przypadku gdy zaskoczą dwa doładowania jednocześnie
				let wait = () => {
					if (!this.fetching) {
						this.fetch();
					} else {
						setTimeout(() => {
							wait();
						}, 500);
					}
				};

				wait();
			}
		}
	}

	fetch(itemsToLoad = 0) {
		this.fetching = true;
		this.pageLoaded += 1;

		this.fetchDataFunction(this.pageLoaded).then((data) => {
			if (itemsToLoad !== 0 && itemsToLoad < data.length) {
				data = data.slice(-itemsToLoad);
			}

			this.itemsList = this.itemsList.concat(data);
			this.successCallbackFunction(this.itemsList);

			this.fetching = false;
			this.totalCount = this.getTotalCountFunction();
			this.nothingToLoad = this.getTotalCountFunction() <= this.itemsList.length;
		});
	}

	deleteItem(itemsList) {
		this.deletedItems += 1;
		this.itemsList = itemsList;
	}

	surveyChange() {
		this.itemsList = [];
		this.pageLoaded = 0;
		this.deletedItems = 0;
		this.nothingToLoad = false;
		this.fetching = false;
		this.totalCount = 0;

		this.fetch();
	}
}

export { DynamicLoader };
