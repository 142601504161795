const tpl = require('./GetEmailModal.html');

class GetEmailModalController {
	constructor(surveyProviderService, i18n, userService, $scope, $rootScope) {
		'ngInject';

		this.surveyProviderService = surveyProviderService;
		this.i18n = i18n;
		this.userService = userService;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.filterName = $rootScope.activeFilterName;
	}

	$onInit() {
		this.optionData = this.resolve.optionData;
		this.onSend = this.resolve.onSend;
		this.onCancel = this.resolve.onCancel;
		this.email = this.optionData.userEmail;
		this.url = null;
	}

	sendClick() {
		this.onSend(this.email);
		this.close();
	}

	cancelClick() {
		this.onCancel();
		this.close();
	}

	exitClick() {
		this.$rootScope.xhr--;
		this.close();
	}
}

export const getEmailModal = {
	template: tpl,
	controller: GetEmailModalController,
	bindings: {
		resolve: '<',
		close: '&',
	},
};
