const tpl = require('./QuestionMatrix.html');
let { AbstractQuestionMatrixController } = require('./AbstractQuestionMatrixController');

class QuestionMatrixOpenController extends AbstractQuestionMatrixController {
	constructor(
		chartColorHelper,
		modalService,
		$filter,
		questionHelper,
		i18n,
		quickFilterGenerator,
		filtersHelper
	) {
		'ngInject';
		super(
			chartColorHelper,
			modalService,
			$filter,
			questionHelper,
			i18n,
			quickFilterGenerator,
			filtersHelper
		);

		this.matrixType = this.types.matrixOpen;
	}

	$onInit() {
		super.$onInit();
	}

	showOthersClick(row, column) {
		this.modalService.openShowOtherAnswersModal(
			this.questionCtrl.getQuestionIndex(),
			null,
			this.questionData,
			null,
			{
				row,
				column,
			}
		);
	}
}

export const questionMatrixOpen = {
	template: tpl,
	controller: QuestionMatrixOpenController,
	require: {
		questionCtrl: '^aRQuestion',
	},
	bindings: {
		questionData: '<',
		chartColor: '<',
	},
};
