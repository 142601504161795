import { QuestionChooser } from './QuestionChooser';

class QuestionTrendChooserController extends QuestionChooser.controller {
	constructor(i18n, analyzeSetStorage, $rootScope) {
		'ngInject';

		super(i18n, analyzeSetStorage);

		this.$rootScope = $rootScope;
	}

	async fetchResource() {
		this.resourceList = $$await(await this.analyzeSetStorage.fetchQuestionsForTrend());

		if (this.set.question) {
			this.selectedResource = this.set.question;
		}
	}

	onSelectResource() {
		this.set.question = this.selectedResource;

		if (this.selectedResource.totalRows > 1) {
			this.$rootScope.$broadcast('CreatorQuestionChange');
		}
	}
}

QuestionTrendChooserController.$inject = ['i18n', 'analyzeSetStorage', '$rootScope'];

export const QuestionTrendChooser = Object.assign({}, QuestionChooser, {
	controller: QuestionTrendChooserController,
});
