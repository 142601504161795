const { AbstractPaginationManager } = require('../analyze/AbstractPaginationManager.js');

class PaginationManagerController extends AbstractPaginationManager {
	constructor(surveyProviderService, modalService, i18n, paginationItems, questionHelper) {
		super(surveyProviderService, modalService, i18n, questionHelper);

		this.answers = paginationItems;
	}

	getPaginationObject() {
		return this.pagination;
	}
	setScope(scope) {
		this.$scope = scope;
	}
}

export { PaginationManagerController };
