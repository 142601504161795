const tpl = require('./ReportChapter.html');
require('../../scss/reportChapter.scss');

class ReportChapterController {
	constructor(
		$element,
		reportsService,
		reportHelper,
		reportPartEditorService,
		$rootScope,
		modalService,
		i18n,
		$timeout,
		$scope
	) {
		'ngInject';

		this.$element = $element;
		this.reportsService = reportsService;
		this.reportHelper = reportHelper;
		this.modalService = modalService;
		this.reportPartEditorService = reportPartEditorService;
		this.$rootScope = $rootScope;
		this.i18n = i18n;
		this.$timeout = $timeout;
		this.$scope = $scope;

		this.titleInput = null;
		this.chartElement = null;
		this.partComponents = [];
	}

	$onInit() {
		let ctrl = this;
		this.first = Boolean(this.first);

		// console.log('chapter.onInit', this.chapterIndex, this.first, this.chapterData);

		if (this.first) {
			// Pierwszy specjalny rozdział
			this.chapter = this.chapterData;
		} else {
			// "normalny" rozdział
			this.chapter = this.builder.currentReport.chapters[this.chapterIndex];
		}

		if (!this.chapter) {
			return;
		}

		// tytuł rozdziału
		this.titleInput = this.$element[0].querySelector('.reportChapter__titleInput');
		this.titleInput.setAttribute('size', this.chapter.title.length);
		if (this.builder.disabled) {
			this.titleInput.addEventListener('click', function titleInputClick(e) {
				this.blur();
				e.preventDefault();
				e.stopPropagation();
				ctrl.builder.showDisabledModal();
				return false;
			});
		}
		this.titleInput.addEventListener('focus', function functionName(e) {
			ctrl.builder.editionInProgress = true;
		});
		this.titleInput.addEventListener('keyup', function functionName(e) {
			this.setAttribute('size', this.value.length);
		});
		this.titleInput.addEventListener('blur', function functionName(e) {
			ctrl.builder.editionInProgress = false;
			let val = this.value.trim();
			let that = this;
			this.value = val;
			this.setAttribute('size', this.value.length);
			if (ctrl.chapter.title !== val) {
				ctrl.reportsService
					.updateChapterPromise(ctrl.builder.currentReport.id, ctrl.chapter.id, {
						title: val,
					})
					.then(
						function done(data) {
							that.value = data.title;
						},
						function error(data) {}
					);
			}
		});
	}

	$onChanges(diff) {
		if (diff && diff.chapterData && this.first) {
			this.chartElement = null;
			this.partComponents = [];
			this.chapter = this.chapterData;
		}
	}

	addCommentPart(part, top) {
		let scrollPosition;

		if (part) {
			scrollPosition = part.$element.offset().top;
		} else {
			scrollPosition = null;
		}

		let index = part ? part.partIndex : this.chapter.parts.length - 1;

		if (top) {
			// gdy part.position === -1 to dodaje na początek
			index = -1;
		}

		this.builder.addCommentPart(this.chapterIndex, index, scrollPosition);

		this.$timeout(() => this.$scope.$apply());
	}

	dragStart(startIndex, chapterId, part) {
		this.builder.drag.parts = {};
		this.builder.drag.parts[part.id] = {
			source: chapterId,
			startIndex,
		};
	}

	dragDrop(destIndex, part, chapterId) {
		this.builder.drag.parts[part.id].target = chapterId;
		this.builder.drag.parts[part.id].index = destIndex;

		if (part.additionalSettings.length > 0) {
			part.additionalSettings.forEach((settings) => {
				if (settings.id) {
					delete settings.id;
				}
			});
		}

		let startIndex = this.builder.drag.parts[part.id].startIndex;

		this.chapter.parts.splice(destIndex, 0, part);

		if (destIndex < startIndex) {
			this.chapter.parts.splice(startIndex + 1, 1);
		} else {
			this.chapter.parts.splice(startIndex, 1);
		}

		let ids = [];
		this.chapter.parts.forEach((elem) => {
			ids.push(elem.id);
		});

		this.builder.onPartMoved(this.chapter.id, ids, part);
	}

	deletePart(index, force, isPlaceholder, partData) {
		// console.log('chapter.deletePart', this.builder.isDisabled(), this.builder.locked, index, force, isPlaceholder, partData);
		if (this.builder.isDisabled()) {
			this.builder.showDisabledModal();
			return;
		}
		this.reportPartEditorService.hideEditor();

		if (!force) {
			this.modalService
				.showConfirm({
					text: this.i18n.__('Czy na pewno usunąć tę sekcję?', 'reports'),
					title: this.i18n.__('Potwierdź usuwanie', 'reports'),
					confirmText: this.i18n.__('Usuń', 'reports'),
					cancelText: this.i18n.__('Anuluj', 'reports'),
				})
				.then(
					() => {
						// console.log('OK');
						this.builder.removePart(this.chapterIndex, index, false, partData);
					},
					() => {
						// console.log('Anuluj');
					}
				);
		} else {
			this.builder.removePart(this.chapterIndex, index, isPlaceholder, partData);
		}
		// this.builder.removePart(this.chapterIndex, index);
	}

	getBuilder() {
		return this.builder;
	}

	getId() {
		return this.chapter.id;
	}
}

export const reportChapter = {
	template: tpl,
	controller: ReportChapterController,
	require: {
		builder: '^reportBuilder',
	},
	bindings: {
		chapterIndex: '<',
		first: '<',
		chapterData: '<',
	},
};
