import angular from 'angular';
import { CrossTablesApiService } from './services/cross-tables-api.service';
import { CrossTablesService } from './services/cross-tables.service';
import { CrossTablesWrapperComponent } from './cross-tables-wrapper/cross-tables-wrapper.component';
import { CrossTableCreatorComponent } from './cross-table-creator/cross-table-creator.component';
import { CrossTableResultComponent } from './cross-table-result/cross-table-result.component';
import { CrossTableChartComponent } from './cross-table-chart/cross-table-chart.component';
import { CrossTableEmptyScreenComponent } from './cross-table-empty-screen/cross-table-empty-screen.component';
import { CrossTablesConfig } from './cross-tables.config';

require('./cross-tables.scss');

export const CrossTablesModule = angular
	.module('cross-tables', [])
	.constant('CROSS_TABLES_CONFIG', CrossTablesConfig)
	.service('crossTablesApiService', CrossTablesApiService)
	.service('crossTablesService', CrossTablesService)
	.component('crossTablesWrapper', CrossTablesWrapperComponent)
	.component('crossTableCreator', CrossTableCreatorComponent)
	.component('crossTableResult', CrossTableResultComponent)
	.component('crossTableChart', CrossTableChartComponent)
	.component('crossTablesEmptyScreen', CrossTableEmptyScreenComponent).name;
