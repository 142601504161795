const tpl = require('./ReportAttachment.html');

class ReportAttachmentController {
	constructor(questionHelper, $rootScope, $scope, $timeout) {
		'ngInject';

		this.questionHelper = questionHelper;
		this.$rootScope = $rootScope;
		this.$scope = $scope;
		this.$timeout = $timeout;

		this.questionCtrl = null;
		this.questionData = null;
		this.children = [];
		this.childrenReadyCount = 0;

		this.reasons = [];
		this.others = [];

		this.isNPS = false;
		this.isSimple = false;
		this.isMatrix = false;
		this.isMatrixOpen = false;
		this.isForm = false;
		this.isRating = false;
		this.loaded = false;

		this.canLoad = true;

		this.dis = false;

		this.isLimitExceeded = false;
		this.attachmentAnswersLimit = 2000;
	}

	$onInit() {
		if (!this.$rootScope.userInfo.external) {
			this.dis = true;
			return;
		}

		let clearWatch = this.$scope.$watch(
			() => this.$rootScope.xhr,
			(newVal, oldVal) => {
				if (this.canLoad && newVal <= 10) {
					this.loadTimeoutId = setTimeout(() => {
						if (this.canLoad && this.$rootScope.xhr <= 10) {
							// console.log('ładuj załącznik!');
							this.canLoad = false;
							clearWatch();

							let questions = this.attachmentData.partCtrl.builder.questionControllers;
							let types = this.questionHelper.getTypes();
							questions.forEach((question, i) => {
								// Załącznik ściśle powiązany z partem ->
								// Nie wystarczy sprawdzić ID pytania bo mogą się pojawić dwa takie same pytania w raporcie tylko z różnymi filtrami
								// Wtedy załącznik może dostać zły controller według tej logiki
								if (
									this.attachmentData.questionId === question.questionData.id &&
									this.attachmentData.partCtrl.attachmentIndex ===
										question.reportPart.attachmentIndex
								) {
									this.questionCtrl = question;
									this.questionData = this.questionCtrl.questionData;
								}
							});

							if (this.attachmentData.isCompletionsAttachment) {
								this.isForm = this.questionHelper.isForm(this.questionCtrl.questionData);
							}

							if (
								this.questionHelper.isMatrix(this.questionData) ||
								this.questionHelper.isRating(this.questionData)
							) {
								this.isMatrix = true;

								if (this.questionHelper.isMatrixOpen(this.questionData)) {
									this.isMatrixOpen = true;

									for (let i = 0; i < this.questionData.answers.length; i++) {
										let row = this.questionData.answers[i];
										for (let j = 0; j < row.length; j++) {
											if (row[j].count) {
												this.others.push({
													questionData: this.questionData,
													matrixData: {
														row: this.questionData.rows[i],
														column: this.questionData.columns[j],
													},
												});
											}
										}
									}
								} else {
									this.questionCtrl.questionData.rows.forEach((answer, i) => {
										let others = this.questionCtrl.childQuestionCtrl.showOthersForRowClick(i, true);
										if (others) {
											others.questionData = this.questionData;
											this.others.push(others);
										}
									});
									this.questionCtrl.questionData.columns.forEach((column, i) => {
										let reasons = this.questionCtrl.showReasons(column, true);
										if (reasons) {
											reasons.questionData = this.questionData;
											this.reasons.push(reasons);
										}
									});
								}

								// this.attachmentFullyLoaded();
							} else if (this.questionCtrl.isNPS) {
								this.isNPS = true;
								let reasons = this.questionCtrl.showReasons(undefined, true);
								let resolve = reasons ? {} : null;
								if (reasons) {
									resolve.questionData = this.questionData;
									resolve.answer = reasons.answer;
									resolve.row = null;
									resolve.reasons = reasons.reasons;
									resolve.others = null;
									resolve.isNPS = true;

									this.reasons.push(resolve);
								}
								// this.attachmentFullyLoaded();
							} else if (
								this.questionCtrl.questionData.type === types.single ||
								this.questionCtrl.questionData.type === types.multiple
							) {
								this.isSimple = true;
								this.questionCtrl.questionData.answers.forEach((answer, i) => {
									let resolve = {};
									if (answer.hasOthers) {
										resolve.questionIndex = 0;
										resolve.answerIndex = i;
										resolve.questionData = this.questionCtrl.questionData;
										this.others.push(resolve);
									}
								});
							}

							this.$timeout(() => this.$scope.$apply());

							if (this.reportBuilder) {
								this.reportBuilder.addAnswersAttachmentController(this);
								// this.attachmentFullyLoaded();
							}
						}
					}, 1);
				}
			}
		);
	}

	$onDestroy() {
		if (this.reportBuilder) {
			this.reportBuilder.removeAnswersAttachmentController(this);
		}
	}

	addChild(modalView) {
		if (this.children.indexOf(modalView) === -1) {
			this.children.push(modalView);
		}
	}

	attachmentFullyLoaded() {
		if (this.canLoad) {
			this.loaded = false;
		}

		this.isLimitExceeded = this.questionData.answers.length > this.attachmentAnswersLimit;

		if (
			this.attachmentData &&
			this.attachmentData.partCtrl &&
			this.attachmentData.partCtrl.initialized &&
			this.attachmentData.partCtrl.indexInReport
		) {
			if (this.children.length) {
				// console.log('this.children', this.children);
				this.childrenReadyCount++;
				if (this.childrenReadyCount === this.children.length) {
					this.loaded = true;
					// console.log('ATTACHMENT LOADED');
					this.reportBuilder.reportFullyLoaded();
				}
			} else {
				this.loaded = true;
				this.reportBuilder.reportFullyLoaded();
			}
		}
	}

	isReady() {
		return this.loaded;
	}
}

export const reportAttachment = {
	template: tpl,
	controller: ReportAttachmentController,
	require: {
		reportBuilder: '?^reportBuilder',
	},
	bindings: {
		attachmentData: '<',
	},
};
