const tpl = require('./QuestionText.html');
const Entities = require('html-entities').AllHtmlEntities;

class QuestionAttachmentController {
	constructor(i18n) {
		'ngInject';

		this.i18n = i18n;
		this.entities = new Entities();
		this.ready = false;

		this.questionAttachment = true;
	}

	$onInit() {
		this.questionCtrl.setChild(this);
	}

	$postLink() {
		this.ready = true;
		if (this.reportBuilder) {
			this.reportBuilder.reportFullyLoaded();
		}
	}

	isReady() {
		return this.ready;
	}

	htmlEntityDecode(text) {
		return this.entities.decode(text);
	}
}

export const questionAttachment = {
	template: tpl,
	controller: QuestionAttachmentController,
	require: {
		questionCtrl: '^aRQuestion',
		reportBuilder: '?^reportBuilder',
	},
	bindings: {
		questionData: '<',
	},
};
