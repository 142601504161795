const tpl = require('./FilterCreatorTextQuestion.html');

let FilterCreatorTextQuestionController = require('./FilterCreatorTextQuestion')
	.filterCreatorTextQuestion.controller;

class FilterCreatorNumberQuestionController extends FilterCreatorTextQuestionController {
	constructor($scope, surveyProviderService, reportHelper, i18n, $element, filtersHelper) {
		'ngInject';

		super($scope, surveyProviderService, reportHelper, i18n, $element, filtersHelper);

		this.conditions = filtersHelper.getConditionsForNumber();
	}

	/**
	 * answerSelectChange - Dodaje walidację wartości przed dodaniem do modelu (w rodzicu). Sprawdza czy wartośc jest liczbą
	 *
	 * @param  {type} item   wybrana wartosc
	 * @param  {type} e      event
	 * @param  {type} select obiekt uiSelect
	 */
	answerSelectChange(item, e, select) {
		let newItem = item.replace(' ' + this.taggingLabel, '');
		let num = Number(newItem);
		if (Number.isFinite(num) && !Number.isNaN(num)) {
			super.answerSelectChange(item, e);
		} else {
			this.selectedAnswers.splice(this.selectedAnswers.indexOf(item), 1);
			super.answerSelectRemove(item);
			select.open = false;
			select.search = '';
		}
	}
}

export const filterCreatorNumberQuestion = {
	template: tpl,
	controller: FilterCreatorNumberQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
