const tpl = require('./FilterCreatorRespondentRule.html');

class FilterCreatorRespondentRuleController {
	constructor($scope, respondentService, filtersService, i18n) {
		'ngInject';
		this.$scope = $scope;
		this.respondentService = respondentService;
		this.filtersService = filtersService;
		this.i18n = i18n;
		// this.text = 'My brand new component!';

		this.dummyOptions = [this.i18n.__('wypełnienie', 'reports')];
		this.dummySelect = this.dummyOptions[0];

		// this.ruleData = null;
		this.labels = null;
		this.valueTmp = null;

		this.valid = false;

		this.validatorTimeout = null;
	}

	$onInit() {
		let ctrl = this;
		this.respondentService.getLabelsPromise().then(
			function done(data) {
				ctrl.labels = data;

				ctrl.$scope.$digest();
			},
			function error(data) {
				console.error(data);
			}
		);

		if (this.ruleData) {
			this.valueTmp = this.ruleData.value;

			if (this.ruleData.label && this.ruleData.condition !== null && this.ruleData.value !== '') {
				this.valid = true;
			}
		} else {
			this.ruleData = this.filtersService.getEmptyRespondentRule();
		}

		if (!this.isEmpty) {
			this.creator.addRespondentRuleController(this);
		}
	}

	$onDestroy() {
		if (!this.isEmpty) {
			this.creator.removeRespondentRuleController(this);
		}
	}

	onLabelSelectChange() {
		this.creator.setEdited(true);
		if (this.isEmpty) {
			// this.activate();
			this.creator.addRespondentRule(this.ruleData);
		} else if (this.ruleData.condition !== null && this.ruleData.value) {
			this.creator.validate();
		}
	}
	onConditionSelectChange() {
		this.creator.setEdited(true);
		if (this.ruleData.condition !== null && this.ruleData.label && this.ruleData.value.length) {
			this.valid = true;
		}

		this.creator.validate();
	}

	onValueChange() {
		let ctrl = this;
		clearTimeout(this.validatorTimeout);
		this.creator.setEdited(true);

		if (this.valueTmp && this.valueTmp !== this.ruleData.value) {
			this.valid = true;
		} else {
			this.valid = false;
		}
		this.ruleData.value = this.valueTmp;

		this.validatorTimeout = setTimeout(function () {
			ctrl.creator.validate();
		}, 500);
	}

	// activate() {
	//     this.isEmpty = false;
	//     this.creator.addRespondentRuleController(this);
	// }

	isValid() {
		return this.valid;
	}
	setValid(val) {
		this.valid = val;
	}

	getData() {
		return this.ruleData;
	}
}

export const filterCreatorRespondentRule = {
	template: tpl,
	controller: FilterCreatorRespondentRuleController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		// ruleData: '=',
		ruleData: '<',
		isEmpty: '<',
	},
};
