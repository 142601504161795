const tpl = require('./FilterCreatorNPSQuestion.html');

let FilterCreatorSimpleQuestionController = require('./FilterCreatorSimpleQuestion')
	.filterCreatorSimpleQuestion.controller;

class FilterCreatorNPSQuestionController extends FilterCreatorSimpleQuestionController {
	constructor($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper) {
		'ngInject';
		super($scope, surveyProviderService, reportHelper, questionHelper, i18n, filtersHelper);

		let noneAnswer = this.noneAnswer;
		noneAnswer.answers = [0];
		this.npsData = [
			noneAnswer,
			{
				index: 1,
				title: this.i18n.__('Krytycy', 'reports'),
				main: true,
				indexDiff: 1,
				answers: [1, 2, 3, 4, 5, 6, 7],
			},
			{
				index: 2,
				title: 0,
				answers: [1],
			},
			{
				index: 3,
				title: 1,
				answers: [2],
			},
			{
				index: 4,
				title: 2,
				answers: [3],
			},
			{
				index: 5,
				title: 3,
				answers: [4],
			},
			{
				index: 6,
				title: 4,
				answers: [5],
			},
			{
				index: 7,
				title: 5,
				answers: [6],
			},
			{
				index: 8,
				title: 6,
				answers: [7],
			},
			{
				index: 9,
				title: this.i18n.__('Neutralni', 'reports'),
				main: true,
				indexDiff: 2,
				answers: [8, 9],
			},
			{
				index: 10,
				title: 7,
				answers: [8],
			},
			{
				index: 11,
				title: 8,
				answers: [9],
			},
			{
				index: 12,
				title: this.i18n.__('Promotorzy', 'reports'),
				main: true,
				indexDiff: 3,
				answers: [10, 11],
			},
			{
				index: 13,
				title: 9,
				answers: [10],
			},
			{
				index: 14,
				title: 10,
				answers: [11],
			},
		];

		this.isNps = true;
	}

	$onChanges(changes) {
		if (changes.ruleData && changes.ruleData.isFirstChange()) {
			this.init();

			this.validate();
		}
		if (changes.question) {
			if (!changes.question.isFirstChange()) {
				this.onQuestionChange();
			}

			this.data.answerData = this.npsData;

			this.selectedCondition = this.ruleData.condition;

			if (this.ruleData.answers.length) {
				this.ruleData.answers = this.ruleData.answers.map((a) => Number(a));

				let toAdd = [];
				let tmpAnswers = this.ruleData.answers.slice(0);

				this.npsData.forEach((npsItem) => {
					if (npsItem.main) {
						if (npsItem.answers.length > tmpAnswers.length) {
							return;
						}
						for (let i = 0; i < npsItem.answers.length; i++) {
							if (tmpAnswers.indexOf(npsItem.answers[i]) === -1) {
								return;
							}
						}
						toAdd.push(npsItem);
						tmpAnswers = tmpAnswers.filter((ans, i) => {
							return npsItem.answers.indexOf(tmpAnswers[i]) === -1;
						});
					} else if (
						npsItem.answers &&
						tmpAnswers.indexOf(npsItem.answers[0]) !== -1 &&
						toAdd.indexOf(npsItem) === -1
					) {
						toAdd.push(npsItem);
					}
				});

				this.selectedAnswers = [];
				toAdd.forEach((item, i) => {
					this.selectedAnswers.push(item.index);
				});
			} else {
				this.selectedAnswers = [];
			}

			if (this.isNoneSelected()) {
				this.selectedAnswers = [this.noneAnswer];
				this.noneSelected();
				this.selectedCondition = null;
			}
		}
	}

	answerSelectChange(item, selected) {
		this.creator.setEdited(true);
		if (item.none) {
			this.noneSelected();
		} else if (item.index !== undefined) {
			this.removeNone();

			let toRemove = [];
			let removed = 0;

			this.selectedAnswers = this.selectedAnswers.map((a) => Number(a));
			this.selectedAnswers.sort((a, b) => a - b);

			this.ruleData.answers = [];
			this.selectedAnswers.forEach((item, i) => {
				let dataItem = this.npsData[item];

				if (
					dataItem &&
					!dataItem.main &&
					dataItem.answers &&
					dataItem.answers.length &&
					this.ruleData.answers.indexOf(dataItem.answers[0]) !== -1
				) {
					toRemove.push(i);
				} else if (dataItem && dataItem.answers) {
					this.ruleData.answers = this.ruleData.answers.concat(dataItem.answers);
				}
			});

			for (let i = toRemove.length - 1; i > -1; i--) {
				this.selectedAnswers.splice(toRemove[i], 1);
			}

			this.validate();
			this.creator.validate();
		}
	}

	removeNone() {
		if (this.isNoneSelected()) {
			this.ruleData.condition = null;
			// this.$scope.condition = null;
			this.selectedCondition = null;
			this.selectedAnswers.splice(this.selectedAnswers.indexOf(this.noneAnswer.index), 1);
		}
	}

	answerSelectRemove(item, selected) {
		this.creator.setEdited(true);
		this.validate();
		this.creator.validate();
	}

	/**
	 * optionClick - kliknięcie na pozycje w selekcie z wyborem odpowiedzi.
	 * Dla NPS są specjalne haki przez ten podzial odpowiedzi na grupy (Krytycy, neutralni, promotorzy)
	 *
	 * @param  {type} index                 description
	 * @param  {type} item                  description
	 * @param  {type} multiSelectController description
	 * @param  {type} ev                    description
	 * @param  {type} isLast                description
	 * @return {type}                       description
	 */
	optionClick(index, item, multiSelectController, ev, isLast) {
		this.creator.setEdited(true);

		this.selectedAnswers = this.selectedAnswers.map((a) => Number(a));
		this.selectedAnswers.sort((a, b) => a - b);

		if (this.selectedAnswers.length) {
			if (this.selectedAnswers.indexOf(item.index) !== -1) {
				if (item.none) {
					this.removeNone();
				} else {
					this.selectedAnswers.splice(this.selectedAnswers.indexOf(item.index), 1);
					this.ruleData.answers = [];
					this.selectedAnswers.forEach((elem, i) => {
						this.ruleData.answers = this.ruleData.answers.concat(this.npsData[elem].answers);
					});
				}

				ev.stopPropagation();
			} else {
				let doChange = false;
				this.selectedAnswers.slice(0).forEach((elem, i) => {
					let dataObj = this.npsData[elem];
					if (
						item &&
						item.answers &&
						dataObj &&
						dataObj.main &&
						!item.main &&
						dataObj.answers &&
						dataObj.answers.length &&
						dataObj.answers.indexOf(item.answers[0]) !== -1
					) {
						this.selectedAnswers.splice(i, 1);
						for (var j = 0; j < dataObj.answers.length; j++) {
							if (dataObj.answers[j] !== item.answers[0]) {
								doChange = true;
								this.selectedAnswers.push(dataObj.answers[j] + dataObj.indexDiff);
							}
						}
					}
				});

				if (doChange) {
					this.ruleData.answers = [];
					this.selectedAnswers.forEach((elem, i) => {
						this.ruleData.answers = this.ruleData.answers.concat(this.npsData[elem].answers);
					});
					ev.stopPropagation();
				}
			}
		}

		if (!this.selectedAnswers.length || this.ruleData.condition === null) {
			this.valid = false;
			this.creator.validate();
		}
	}

	/**
	 * isOptionChecked - do wyswitlania checkboxów na liście odpowiedzi
	 *
	 * @param  {type} answer description
	 * @return {type}        description
	 */
	isOptionChecked(answer) {
		let check = false;
		// console.log('answer', answer, this.isNoneSelected());

		if (answer.none) {
			return this.isNoneSelected();
		}

		if (this.selectedAnswers.indexOf(answer.index) !== -1) {
			check = true;
		}

		this.data.answerData.forEach((item, i) => {
			if (
				item.main &&
				this.selectedAnswers.indexOf(item.index) !== -1 &&
				item.answers.indexOf(answer.answers[0]) !== -1
			) {
				check = true;
			}
		});

		return check;
	}
}

export const filterCreatorNPSQuestion = {
	template: tpl,
	controller: FilterCreatorNPSQuestionController,
	require: {
		creator: '^filterCreator',
	},
	bindings: {
		ruleData: '<',
		question: '<',
	},
};
