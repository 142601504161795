import { AclFeature } from '../components/acl/acl.config';

const angular = require('angular');

class ReportHelper {
	constructor(
		modalService,
		i18n,
		surveyProviderService,
		$rootScope,
		questionHelper,
		$state,
		aclManager
	) {
		'ngInject';

		this.$uibModal = modalService.getUibModal();
		this.modalService = modalService;
		this.i18n = i18n;
		this.surveyProviderService = surveyProviderService;
		this.$rootScope = $rootScope;
		this.questionHelper = questionHelper;
		this.$state = $state;
		this.aclManager = aclManager;

		this.addToReportModal = null;

		this.reportPartTypes = {
			TEXT: 0,
			TABLE: 1,
			CHART: 2,
			TABLE_CHART: 3,
			TABLE_OF_CONTENTS: 4,
			SUMMARY: 5,
			TABLE_TREND: 6,
			TREND: 7,
			CROSS_TABLES_TABLE_CHART: 8,
			CROSS_TABLES_TABLE: 9,
			CROSS_TABLES_CHART: 10,
			RANKING: 11,
			TREND_TABLE_CHART: 12,
			TREND_TABLE: 13,
			TREND_CHART: 14,
		};

		this.defaultValues = {
			chapterTitle: i18n.__('Nowy rozdział', 'reports'),
			reportTitle: i18n.__('Szkic raportu', 'reports'),
			partTitle: i18n.__('Nowa sekcja', 'reports'),
			partText: i18n.__('Treść nowej sekcji', 'reports'),
			theme: {
				headlineColor: '#00bfa5',
				textColor: '#032c3e',
				descriptionColor: '#aaaaaa',
				// chartColor: '#ff0000'
				chartColor: '',
			},
			logos: {
				pl: 'webankieta.pl/images/logo/skin/webankieta-logo.png',
				en: 'startquestion.com/images/logo/skin/startquestion-logo.png',
				cz: 'netquest.cz/images/logo/skin/netquest-logo.png',
				sk: 'netquest.sk/images/logo/skin/netquest-logo.png',
			},
		};

		this.emptyPart = {
			type: 0,
			title: '',
			question: null,
			text: '',
			position: 0,
		};

		this.emptyPartWithText = {
			type: 0,
			title: this.defaultValues.partTitle,
			question: null,
			text: this.defaultValues.partText,
			position: 0,
		};

		this.emptyChapter = {
			title: this.defaultValues.chapterTitle,
			position: 0,
		};

		this.additionalSettingsTypes = {
			timelineMode: 1,
			isDynamicAxisY: 2,
			crossTableRowPercent: 3,
			crossTableColumnPercent: 4,
			crossTableRotated: 5,
			npsScore: 6,
			npsLtr: 7,
			ratingTopBox: 8,
			ratingTopTwoBoxes: 9,
			ratingAvg: 10,
			total: 11,
			trendCritics: 12,
			trendNeutrals: 13,
			trendPromotors: 14,
			trendDate: 15,
			rankingDisplayMode: 16,
			ratingBottomBox: 17,
			ratingBottomTwoBoxes: 18,
			trendSortedColumn: 19,
			trendSortedType: 20,
			crossTableDisplayMode: 21,
			crossTableChartSeries: 22,
			crossTableChartFormat: 23,
			trendChartSeries: 24,
			trendChartFormat: 25,
		};
	}

	getReportPartTypes() {
		return this.reportPartTypes;
	}

	getGeneratedPartTitle(type) {
		if (type === this.reportPartTypes.TABLE_OF_CONTENTS) {
			return this.i18n.__('Spis treści', 'reports');
		} else if (type === this.reportPartTypes.SUMMARY) {
			return this.i18n.__('Podsumowanie', 'reports');
		}
	}

	getDefaultValues() {
		return this.defaultValues;
	}

	getEmptyReportClone() {
		// return angular.copy({
		let empty = {
			title: this.defaultValues.reportTitle,
			theme: {
				descriptionColor: '',
				headlineColor: '',
				textColor: '',
				chartColor: '',
			},
			showPageNumbers: false,
			showTableOfContents: true,
			showSummary: false,
			chapters: [
				{
					title: '0',
					position: 0,
					parts: [
						{
							type: 4,
							position: 0,
							text: '',
							question: '',
							title: '',
						},
					],
				},
				{
					title: this.i18n.__('Rozdział 1.', 'reports'),
					position: 1,
					parts: [
						{
							type: 0,
							position: 0,
							title: this.i18n.__('Wstęp', 'reports'),
							question: '',
							text: 'Poniżej przedstawione zostały wyniki badania przeprowadzonego między %s. W badaniu wzięło udział %s osób.',
						},
					],
				},
			],
			// });
		};

		return empty;
	}

	getEmptyReportClonePromise() {
		return new Promise((resolve, reject) => {
			this.surveyProviderService.getCurrentSurveyPromise().then(
				(survey) => {
					let report = this.getEmptyReportClone();
					let startTime = Number(survey.publishDate) * 1000;

					let title = survey.title;
					title = title.replace(/(\r\n|\n|\r)/gm, ' ');

					if (survey.status === 2) {
						let startDate = new Date(startTime);
						let start =
							startDate.getFullYear() +
							'/' +
							(Number(startDate.getMonth()) + 1) +
							'/' +
							startDate.getDate();
						let endDate = new Date(Number(survey.endDate) * 1000);
						let end =
							endDate.getFullYear() +
							'/' +
							(Number(endDate.getMonth()) + 1) +
							'/' +
							endDate.getDate();

						if (isNaN(survey.endDate)) {
							report.chapters[1].parts[0].text = this.i18n._s(
								'Poniżej przedstawione zostały wyniki badania %s',
								title,
								'reports'
							);
						} else {
							report.chapters[1].parts[0].text = this.i18n._s(
								report.chapters[1].parts[0].text,
								start + ' - ' + end,
								survey.fillCount,
								'reports'
							);
						}
					} else {
						report.chapters[1].parts[0].text = this.i18n._s(
							'Poniżej przedstawione zostały wyniki badania %s',
							title,
							'reports'
						);
					}

					this.getDefaultThemePromise().then(
						(theme) => {
							report.theme = theme.theme;

							if (theme.logo) {
								report.logo = theme.logo;
							}

							resolve(report);
							return report;
						},
						(theme) => {
							report.theme = theme.theme;
							if (theme.logo) {
								report.logo = theme.logo;
							}
							resolve(report);
							return report;
						}
					);
				},
				() => {
					reject(null);
				}
			);
		});
	}

	getEmptyPartClone(partType, questionData) {
		let part = angular.copy(this.emptyPart);
		partType = partType !== undefined && partType !== null ? Number(partType) : null;
		// console.log('getEmptyPartClone', partType, questionData);
		if (questionData && this.questionHelper.isNPS(questionData)) {
			if (
				!partType ||
				partType !== this.reportPartTypes.TABLE ||
				partType !== this.reportPartTypes.TREND
			) {
				part.type = this.reportPartTypes.TABLE_TREND;
			}
			part.additionalSettings = [
				{
					type: this.additionalSettingsTypes.timelineMode,
					setup: this.questionHelper.getTimelineModes().week,
				},
			];
		} else if (partType !== undefined && partType !== null) {
			part.type = partType;
		} else if (questionData) {
			part.type = this.reportPartTypes.TABLE_CHART;
		}

		return part;
	}

	getEmptyChapterClone() {
		return angular.copy(this.emptyChapter);
	}

	getTableOfContentsClone() {
		let clone = angular.copy(this.emptyPart);
		clone.type = this.reportPartTypes.TABLE_OF_CONTENTS;
		return clone;
	}

	getSummaryClone() {
		let clone = angular.copy(this.emptyPart);
		clone.type = this.reportPartTypes.SUMMARY;
		return clone;
	}

	getQuestionHelper() {
		return this.questionHelper;
	}

	openAddToReportModal(opts) {
		this.addToReportModal = this.$uibModal.open({
			component: 'addToReportModal',
			// size: '450',
			size: '400',
			resolve: {
				questionCtrl: () => opts.questionCtrl,
				questionControllers: () => opts.questionControllers,
				addWholeSurvey: () => opts.addWholeSurvey,
				filterId: () => opts.filterId,
				onlyNew: () => opts.onlyNew,
			},
		});
		this.addToReportModal.result.then(
			() => {},
			() => {}
		);
	}

	openReportStatusModal(opts) {
		let titleText;
		let text;
		let id = opts && opts.id ? opts.id : null;
		let icon;
		let buttonText;
		let buttonText2;

		if (opts) {
			if (opts.new) {
				titleText = this.i18n.__('Raport został stworzony', 'reports');
			} else {
				titleText = this.i18n.__('Dodano do raportu', 'reports');
			}

			text = this.i18n.__('Przejdź do edycji, by go dostosować do swoich potrzeb.', 'reports');
			buttonText = this.i18n.__('Przejdź do edycji', 'reports');
			buttonText2 = this.i18n.__('Kontynuuj', 'reports');

			if (opts.new) {
				icon = 'added-to-report';
			} else {
				icon = 'added-part';
			}
		}

		this.modalService
			.show({
				size: '440',
				title: titleText,
				text,
				icon: {
					color: 'green',
					utilIcon: icon,
				},
				button: {
					id: 'ok',
					text: buttonText,
					type: 'button--gray',
					closeOnClick: true,
				},
				button2: {
					id: 'ok',
					text: buttonText2,
					type: 'button--wide',
					closeOnClick: true,
					stayOnSite: true,
				},
			})
			.then((action) => {
				if (action === 'ok' && id) {
					parent.location.href = `/user#angular?r=/reports/generate/${id}`;
				}
			});
	}

	openNewReportModal(opts) {
		this.newReportModal = this.modalService.getUibModal().open({
			component: 'newReportModal',
			size: '500',
			resolve: {
				successCallback: () => opts.successCallback,
			},
		});
		this.newReportModal.result.then(
			() => {},
			() => {}
		);
	}

	getDefaultThemePromise() {
		let def = this.defaultValues.theme;
		let theme = {
			theme: {},
			logo: null,
		};
		return new Promise((resolve, reject) => {
			if (this.surveyProviderService.isWidget()) {
				this.surveyProviderService.getWidgetSkinPromise().then(
					(data) => {
						theme.theme = data.theme || def;
						theme.theme.chartColor = def.chartColor;
						if (data.logo) {
							theme.logo = data.logo;
						}
						resolve(theme);
						return theme;
					},
					(errData) => {
						theme.theme = def;
						resolve(theme);
						return theme;
					}
				);
			} else {
				this.surveyProviderService.getSurveyStylePromise().then(
					(data) => {
						theme.theme = data.theme || def;
						theme.theme.chartColor = def.chartColor;
						if (data.logo) {
							theme.logo = data.logo;
						}
						resolve(theme);
						return theme;
					},
					(errData) => {
						theme.theme = def;
						resolve(theme);
						return theme;
					}
				);
			}
		});
	}

	cleanUpReportTitle(val) {
		if (val) {
			let nameRegex = /['"`.]/g;
			val = val.replace(nameRegex, '');
			val = val.trim();
		}
		return val;
	}

	getAdditionalSettingsTypes() {
		return this.additionalSettingsTypes;
	}

	questionHasAdditionalSettings(questionData) {
		return this.questionHelper.isNPS(questionData);
	}

	isCrossTable(partType) {
		return (
			partType === this.reportPartTypes.CROSS_TABLES_TABLE_CHART ||
			partType === this.reportPartTypes.CROSS_TABLES_TABLE ||
			partType === this.reportPartTypes.CROSS_TABLES_CHART
		);
	}

	isRanking(partType) {
		return partType === this.reportPartTypes.RANKING;
	}

	isTrend(partType) {
		return (
			partType === this.reportPartTypes.TREND_TABLE_CHART ||
			partType === this.reportPartTypes.TREND_TABLE ||
			partType === this.reportPartTypes.TREND_CHART
		);
	}

	isSpecialPart(partType) {
		return (
			partType === this.reportPartTypes.TEXT ||
			partType === this.reportPartTypes.SUMMARY ||
			partType === this.reportPartTypes.TABLE_OF_CONTENTS
		);
	}

	isQuestionPart(partType) {
		return (
			partType === this.reportPartTypes.TABLE_CHART ||
			partType === this.reportPartTypes.TABLE ||
			partType === this.reportPartTypes.CHART ||
			partType === this.reportPartTypes.TABLE_TREND ||
			partType === this.reportPartTypes.TREND
		);
	}

	getExportFormats() {
		const self = this;

		return [
			{
				format: 'pdf',
				title: 'PDF',
				icon: 'report-pdf',
				disabled: !self.isReportPdfEnabled(),
				disabledInfo: self.i18n.__(`Usługa jest niedostępna`, 'reports'),
			},
			self.getHtmlFormat(),
		];
	}

	getHtmlFormat() {
		const self = this;

		const format = {
			format: 'html',
			title: 'Online',
			icon: 'report-html',
			disabled: !self.isReportHtmlEnabled(),
			disabledInfo: self.i18n.__(`Raporty online nie są dostępne na Twoim koncie`, 'reports'),
		};

		if (this.isReportHtmlEnabled()) {
			format.additionalOptions = {
				seo: {
					name: 'seo',
					label: self.i18n.__(
						'Opublikuj raport w internecie (widoczny dla wyszukiwarek)',
						'reports'
					),
					checked: false,
					additionalInfo: `<p>${self.i18n._s(
						'%sUwaga!%s Zawartość tego raportu będzie widoczna publicznie. Oznacza to, że dowolna osoba będzie mogła wyszukać ten raport za pomocą wyszukiwarki internetowej (np. Google, Bing itp.) i zobaczyć wyniki tego raportu oraz jego treść. Przed skorzystaniem z tej opcji upewnij się, że w tym raporcie nie znajdują się wrażliwe dane (np. dane osobowe, adresy e-mail, numery telefonów).',
						`<strong>`,
						'</strong>',
						'reports'
					)}</p>`,
				},
			};
		}

		if (this.isReportHtmlEnabled() && this.isReportLiveEnabled()) {
			format.additionalOptions.live = {
				name: 'live',
				label: self.i18n._s(
					'Raport %s (nowe wypełnienia ankiety aktualizują raport)',
					`<span class="liveReport">live</span>`,
					'reports'
				),
				checked: false,
				additionalInfo: `<p>${self.i18n.__(
					'Wyniki tego raportu będą odświeżane gdy pojawią nowe wypełnienia ankiety.',
					'reports'
				)}</p>`,
			};
		}

		return format;
	}

	getTypesForTrendSorting() {
		return [
			this.additionalSettingsTypes.npsLtr,
			this.additionalSettingsTypes.npsScore,
			this.additionalSettingsTypes.total,
			this.additionalSettingsTypes.ratingAvg,
			this.additionalSettingsTypes.ratingBottomBox,
			this.additionalSettingsTypes.ratingBottomTwoBoxes,
			this.additionalSettingsTypes.ratingTopBox,
			this.additionalSettingsTypes.ratingTopTwoBoxes,
			this.additionalSettingsTypes.trendCritics,
			this.additionalSettingsTypes.trendNeutrals,
			this.additionalSettingsTypes.trendPromotors,
			this.additionalSettingsTypes.trendDate,
			this.additionalSettingsTypes.trendSortedColumn,
		];
	}

	isReportPdfEnabled() {
		return this.aclManager.isFeatureEnabled(AclFeature.reportPDF);
	}

	isReportHtmlEnabled() {
		return this.aclManager.isFeatureEnabled(AclFeature.reportHTML);
	}

	isReportLiveEnabled() {
		return this.aclManager.isFeatureEnabled(AclFeature.reportLIVE);
	}
}

export default ReportHelper;
