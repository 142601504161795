const successTpl = require('../utils/successModal.html');
// const confirmTpl = require('../utils/confirmModal.html');
import '../../scss/modalService.scss';

class ModalService {
	constructor($uibModal, $rootScope, $timeout, i18n) {
		'ngInject';

		this.modal = $uibModal;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.i18n = i18n;

		// TODO: tablica modali lub obsługa eventów do zamykania
		this.currentModal = null;
		this.nestedModal = null;
		this.confirmModal = null;
		this.alerts = [];

		this.alertTypes = {
			info: 'info',
			error: 'error',
			warning: 'warning',
		};

		$rootScope.alerts = this.alerts;
	}

	getUibModal() {
		return this.modal;
	}

	show(config) {
		let self = this;

		let showModal = (resolve, reject) => {
			let newModal;
			newModal = this.modal.open({
				template: successTpl,
				size: config.size,
				controller: ($scope, config) => {
					'ngInject';

					$scope.config = config;
					$scope.DATA_INFO = window.DATA_INFO;
					config.close = this.close.bind(this);

					$scope.click1 = () => {
						if (config.button.closeOnClick) {
							this.close();
						}

						resolve(config.button.id || 'button1');
					};

					$scope.click2 = () => {
						if (config.button2.closeOnClick) {
							this.close();
						}

						if (config.button2.stayOnSite) {
							return;
						}

						resolve(config.button2.id || 'button2');
					};
				},
				resolve: {
					config: () => config,
				},
			});
			newModal.result.then(
				() => {},
				() => {}
			);
			if (config.level) {
				self.nestedModal = newModal;
			} else {
				self.currentModal = newModal;
			}
		};

		return new Promise(showModal);
	}

	loading(text) {
		let self = this;

		let config = {
			size: 'sm',
			level: 1,
			loading: 1,
			title: text,
			text: '',
			button: {
				type: 'button--big button--gray',
				text: this.i18n.__('Zamknij', 'reports'),
			},
			icon: {
				color: 'gray',
				svg: '',
				font: 'fa-spinner',
			},
		};

		this.show(config).then(() => {
			self.close();
		});
	}

	close() {
		if (this.nestedModal) {
			this.nestedModal.dismiss('cancel');
			this.nestedModal = null;
		}
		this.currentModal.dismiss('cancel');
		this.currentModal = null;
	}

	closeConfirm() {
		this.confirmModal.close();
		this.confirmModal = null;
	}

	showConfirm(config) {
		let self = this;

		let defaultConfig = {
			title: config.title,
			text: config.text,
			button: {
				type: 'button--big button--gray',
				text: config.cancelText,
			},
			button2: {
				type: 'button--big',
				text: config.confirmText,
			},
			icon: {
				color: 'gray',
				svg: '',
				font: 'fa-question',
			},
		};

		let showModal = (resolve, reject) => {
			let newModal = this.modal.open({
				template: successTpl,
				size: '400',
				controller: ($scope, config) => {
					'ngInject';

					$scope.config = config;

					$scope.click1 = () => {
						// resolve('button1');
						this.closeConfirm();
						reject('');
					};
					config.close = $scope.click1;

					$scope.click2 = () => {
						this.closeConfirm();
						resolve(true);
					};
				},
				resolve: {
					config: () => defaultConfig,
				},
			});

			this.confirmModal = newModal;
		};

		return new Promise(showModal);
	}

	closeAlert(index) {
		this.alerts.splice(index, 1);
		this.$timeout(() => {
			this.$rootScope.$digest();
		});
	}

	alert(config) {
		let found = false;
		// let foundIndex = null;
		if (!config.type) {
			config.type = this.alertTypes.info;
		}
		if (config.dismissOnTimeout) {
			// config.dismissTimeout = 10000;
			config.dismissTimeout = 5000;
		}

		for (let i = 0; i < this.alerts.length; i++) {
			if (this.alerts[i].text === config.text && this.alerts[i].type === config.type) {
				// this.alerts.splice(i, 1);
				// foundIndex = i;
				found = true;
				break;
			}
		}

		if (!found) {
			this.alerts.push(config);
		}
		this.$timeout(() => {
			this.$rootScope.$digest();
		});
	}

	openShowOtherAnswersModal(
		questionIndex,
		answerIndex,
		questionData,
		others,
		matrixData,
		pageLimit = null
	) {
		const modalInstance = this.modal.open({
			component: 'showOtherAnswersModal',
			size: '75per',
			resolve: {
				questionIndex: () => questionIndex,
				answerIndex: () => answerIndex,
				questionData: () => questionData,
				others: () => others,
				matrixData: () => matrixData,
				pageLimit: () => pageLimit,
			},
		});

		modalInstance.result.then(
			() => {},
			() => {}
		);
		modalInstance.opened.then(
			() => {},
			() => {}
		);
	}

	openCompletionsWithQuestionModal(questionIndex, questionData) {
		this.modal
			.open({
				component: 'completionsWithQuestionModal',
				size: '75per',
				resolve: {
					questionIndex: () => questionIndex,
					// answerIndex: () => answerIndex,
					questionData: () => questionData,
				},
			})
			.result.then(
				() => {},
				() => {}
			);
	}

	openExportReportModal(report, onExport) {
		this.modal
			.open({
				plain: true,
				component: 'reportExport',
				size: '500',
				resolve: {
					report: () => {
						return report;
					},
					onExport: () => {
						return onExport;
					},
				},
			})
			.result.then(
				() => {},
				() => {}
			);
	}

	openBeforeExitModal() {
		this.show({
			title: '',
			text: 'Lorem ipsum',
			button: {
				text: 'Anuluj',
				type: 'button--big button--gray',
				click: 'close',
			},
			button2: {
				text: 'Zapisz',
				type: 'button--big',
				click: () => {
					alert(';)');
				},
			},
		});
	}

	openDistributionChartModal(questionIndex, answerIndex, questionData, others) {
		this.modal
			.open({
				component: 'distributionChartModal',
				size: '75per',
				resolve: {
					questionIndex: () => questionIndex,
					answerIndex: () => answerIndex,
					questionData: () => questionData,
					// ,
					// others: () => others
				},
			})
			.result.then(
				() => {},
				() => {}
			);
		// modal.result.then(() => {}, () => {});
		// return modal;
	}

	openExtendedAnswersModal(questionData, opts) {
		// opts = {
		//     answer,
		//     row,
		//     reasons,
		//     others,
		//     isNPS
		// };

		this.modal
			.open({
				component: 'extendedAnswersModal',
				size: '75per',
				resolve: {
					questionData: () => questionData,
					reasons: () => opts.reasons,
					others: () => opts.others,
					row: () => opts.row,
					answer: () => opts.answer,
					isNPS: () => opts.isNPS,
				},
			})
			.result.then(
				() => {},
				() => {}
			);
		// modal.result.then(() => {}, () => {});
		// return modal;
	}

	openExportDataModal(optionData, completedOnlyCallkback, fullCallback, onCancel) {
		let modal = this.modal.open({
			component: 'exportDataModal',
			size: '500',
			resolve: {
				optionData: () => optionData,
				completedOnlyCallkback: () => completedOnlyCallkback,
				fullCallback: () => fullCallback,
				onCancel: () => onCancel,
			},
		});
		modal.result.then(
			() => {},
			() => {}
		);
		return modal;
	}
}

export default ModalService;
